define("machine-monitoring/configuration/device/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      let actLocation = 0;

      if (model.get('location')) {
        actLocation = this.get("store").findRecord("location", model.get('location'));
      }

      controller.set("actLocation", actLocation);
      let actCustomer = 0;

      if (model.get('customer')) {
        actCustomer = this.get("store").findRecord("customer", model.get('customer'));
      }

      controller.set("actCustomer", actCustomer);
    },

    renderTemplate(controller, model) {
      this.render("configuration/device", {
        into: "configuration",
        outlet: "configchild",
        controller: controller,
        model: model
      });
    }

  });

  _exports.default = _default;
});