define("machine-monitoring/dashboard/route", ["exports", "jquery", "machine-monitoring/app"], function (_exports, _jquery, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    thatDonutdataService: Ember.inject.service("that-donutdata"),

    model() {
      return _jquery.default.get(_app.default.API_URL + "/analytics");
    },

    setupController(controller, model) {
      this._super(controller, model);

      this.get("thatDonutdataService").cleanup();

      if (model.attentionNeeded.length == 0 && model.faultyMachineTypes.length == 0 && model.proactiveHourly.length == 0) {
        this.transitionTo("devices");
      }

      let attentionNeeded = model.attentionNeeded;
      let maxAttentionNeededAlerts = 0;
      attentionNeeded.forEach(thisAttentionNeeded => {
        let thisAllAlerts = thisAttentionNeeded.alertsCount.warnings + thisAttentionNeeded.alertsCount.criticals + thisAttentionNeeded.alertsCount.emergencies;

        if (thisAllAlerts > maxAttentionNeededAlerts) {
          maxAttentionNeededAlerts = thisAllAlerts;
        }
      });
      let faultyMachineTypes = model.faultyMachineTypes;
      let maxFaultyMachineTypesAlerts = 0;
      faultyMachineTypes.forEach(thisMachineType => {
        let thisAllAlerts = thisMachineType.warnings + thisMachineType.criticals + thisMachineType.emergencies;

        if (thisAllAlerts > maxFaultyMachineTypesAlerts) {
          maxFaultyMachineTypesAlerts = thisAllAlerts;
        }
      });
      controller.set("maxAttentionNeededAlerts", maxAttentionNeededAlerts);
      this.get("thatDonutdataService").plus(maxAttentionNeededAlerts);
      controller.set("attentionNeeded", attentionNeeded);
      controller.set("proactiveHourly", model.proactiveHourly);
      controller.set("proactiveTwelveHourly", model.proactiveTwelveHourly);
      controller.set("proactiveDaily", model.proactiveDaily);
      controller.set("proactiveWeekly", model.proactiveWeekly);
      controller.set("maxFaultyMachineTypesAlerts", maxFaultyMachineTypesAlerts);
      controller.set("faultyMachineTypes", faultyMachineTypes);
      attentionNeeded.forEach(thisAttentionNeeded => {
        let thisAttentionNeededId = thisAttentionNeeded.id;
        let thatDonutInfos = {};
        thatDonutInfos[thisAttentionNeededId] = thisAttentionNeeded.alertsCount;
        this.get("thatDonutdataService").add(thatDonutInfos);
      });
    },

    activate() {
      (0, _jquery.default)(function () {
        (0, _jquery.default)('[data-toggle="tooltip"]').tooltip();
      });
      window.addEventListener("resize", () => {
        let attentionNeededCanvasWidth = (0, _jquery.default)(".attentionNeededDiv").width();
        let newAttentionNeededCanvasWidth = attentionNeededCanvasWidth + 30;
        (0, _jquery.default)(".attentionNeededCanvas").width(newAttentionNeededCanvasWidth);
        let mostFaultyCanvasWidth = (0, _jquery.default)(".mostFaultyDiv").width();
        let newMostFaultyCanvasWidth = mostFaultyCanvasWidth * 0.6;
        (0, _jquery.default)(".mostFaultyCanvas").width(newMostFaultyCanvasWidth);
        let canvasWidth = (0, _jquery.default)(".detailCanvasDiv").width();
        (0, _jquery.default)(".detailCanvas").width(canvasWidth);
        let otherCanvasWidth = (0, _jquery.default)(".deviceOverviewDiv").width();
        (0, _jquery.default)(".deviceOverviewCanvas").width(otherCanvasWidth);
      });
    },

    renderTemplate(controller, model) {
      this.render("dashboard", {
        into: "application",
        outlet: "content",
        controller: controller,
        model: model
      });
    }

  });

  _exports.default = _default;
});