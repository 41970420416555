define("machine-monitoring/helpers/safe-css-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.safeCssColor = safeCssColor;
  _exports.default = void 0;

  function safeCssColor(color) {
    return Ember.String.htmlSafe("color:" + color + " !important;");
  }

  var _default = Ember.Helper.helper(safeCssColor);

  _exports.default = _default;
});