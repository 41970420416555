define("machine-monitoring/dashboard/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    alertMeta: null,

    init() {
      this._super(...arguments);

      this.allAlertStarts = [];
    },

    actions: {
      showThisDevice(usedId) {
        let deviceFound = this.store.findRecord("device", usedId);
        this.transitionToRoute("devices.details", deviceFound);
      }

    }
  });

  _exports.default = _default;
});