define("machine-monitoring/components/alert-diagram-detail/component", ["exports", "machine-monitoring/app", "chartjs", "jquery"], function (_exports, _app, _chartjs, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: "div",
    classNames: ["card", "fullWidth", "marginTop10"],
    alertTypeName: null,
    alertTypeDescription: null,
    overviewChartData: null,
    overviewChartObj: null,
    overviewChartOptions: null,
    overviewChartTickWidth: null,
    detailChartData: null,
    detailChartObj: null,
    detailChartOptions: null,
    valueCount: null,
    samplesCount: null,
    progressSecond: null,
    duration: null,
    signalsCount: null,
    ceilAlert: true,
    floorAlert: true,

    init() {
      this._super(...arguments);

      this.allChartData = [];
      let chosenAlert = this.get("chosenAlert");
      let alertTypeId = chosenAlert.alertTypeId;
      let thisSignalTypeUsed = null;
      let allSignalTypes = this.get("allSignalTypes");
      allSignalTypes.forEach(thisSignal => {
        let thisSignalId = thisSignal.get("signalTypeId");

        if (thisSignalId === alertTypeId) {
          thisSignalTypeUsed = thisSignal;
        }
      });
      let signalUnit = thisSignalTypeUsed.get("unit");
      this.set("thisSignalTypeUsed", thisSignalTypeUsed);
      let alertType = thisSignalTypeUsed.get("alertType");
      let alertTypeName = alertType.get("name");
      this.set("alertTypeName", alertTypeName);
      let alertTypeDescription = alertType.get("description");
      this.set("alertTypeDescription", alertTypeDescription);
      let relatedSignals = chosenAlert.relatedSignals;
      let signalsCount = 1;
      let usedRelatedSignals = [];

      if (relatedSignals) {
        let relatedSignalsCount = relatedSignals.length;
        signalsCount = relatedSignalsCount;
        let relatedSignalsWithMain = relatedSignals.includes(alertTypeId);

        if (!relatedSignalsWithMain) {
          signalsCount = signalsCount + 1;
        }

        relatedSignals.forEach(thisRelatedSignal => {
          allSignalTypes.forEach(thisSignalTypes => {
            let thisTypeId = thisSignalTypes.get("signalTypeId");

            if (thisRelatedSignal === thisTypeId) {
              usedRelatedSignals.pushObject(thisSignalTypes);
            }
          });
        });
      } //-------------------------------------------------Overview Chart


      let overviewData = {
        labels: [],
        datasets: [{
          //ceil warn
          borderColor: "rgba(255,255,55,1)",
          pointRadius: 0,
          borderWidth: 1,
          fill: false,
          data: []
        }, {
          //ceil critical
          borderColor: "rgba(255,162,36,1)",
          pointRadius: 0,
          borderWidth: 1,
          fill: false,
          data: []
        }, {
          //ceil emergency
          borderColor: "rgba(255,0,0,1)",
          pointRadius: 0,
          borderWidth: 1,
          fill: false,
          data: []
        }, {
          //floor warn
          borderColor: "rgba(255,255,55,1)",
          pointRadius: 0,
          borderWidth: 1,
          fill: false,
          data: []
        }, {
          //floor critical
          borderColor: "rgba(255,162,36,1)",
          pointRadius: 0,
          borderWidth: 1,
          fill: false,
          data: []
        }, {
          //floor emergency
          borderColor: "rgba(255,0,0,1)",
          pointRadius: 0,
          borderWidth: 1,
          fill: false,
          data: []
        }, {
          backgroundColor: "rgba(255,255,0,1)",
          borderColor: "rgba(255,255,0,1)",
          tension: 0.3,
          pointRadius: 0,
          borderWidth: 1,
          data: []
        }]
      };
      /*
           if (signalsCount > 1){
           for (let iv = 1; iv < signalsCount; iv ++){
           let addedSignalGraph = {
           backgroundColor: "rgba(155,23,255,0.3)",
           borderColor: "rgba(155,23,255,1)",
           tension : 0.3,
           pointRadius: 0,
           borderWidth: 1,
           data: []
           };
           overviewData.datasets.pushObject(addedSignalGraph);
           }
           }
           */

      this.set("overviewChartData", overviewData);
      let overviewOptions = {
        animation: {
          duration: 0
        },
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: false
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: signalUnit
            },
            afterFit: scale => {
              this.set("overviewChartTickWidth", Math.round(scale.width));
            }
          }]
        }
      };
      this.set("overviewChartOptions", overviewOptions); //-------------------------------------------------Detail Chart

      let detailData = {
        labels: [],
        datasets: [{
          //ceil warn
          borderColor: "rgba(255,255,55,1)",
          pointRadius: 0,
          borderWidth: 1,
          fill: false,
          data: [],
          yAxisID: "yId1"
        }, {
          //ceil critical
          borderColor: "rgba(255,162,36,1)",
          pointRadius: 0,
          borderWidth: 1,
          fill: false,
          data: [],
          yAxisID: "yId1"
        }, {
          //ceil emergency
          borderColor: "rgba(255,0,0,1)",
          pointRadius: 0,
          borderWidth: 1,
          fill: false,
          data: [],
          yAxisID: "yId1"
        }, {
          //floor warn
          borderColor: "rgba(255,255,55,1)",
          pointRadius: 0,
          borderWidth: 1,
          fill: false,
          data: [],
          yAxisID: "yId1"
        }, {
          //floor critical
          borderColor: "rgba(255,162,36,1)",
          pointRadius: 0,
          borderWidth: 1,
          fill: false,
          data: [],
          yAxisID: "yId1"
        }, {
          //floor emergency
          borderColor: "rgba(255,0,0,1)",
          pointRadius: 0,
          borderWidth: 1,
          fill: false,
          data: [],
          yAxisID: "yId1"
        }, {
          backgroundColor: "rgba(0,255,0,0.2)",
          borderColor: "rgba(0,255,0,1)",
          pointBackgroundColor: "rgba(0,255,0,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          tension: 0.3,
          data: [],
          yAxisID: "yId1"
        }]
      };

      if (signalsCount > 1) {
        for (let iw = 1; iw < signalsCount; iw++) {
          let addedSignalGraph = {
            backgroundColor: "rgba(155,23,255,0.3)",
            borderColor: "rgba(155,23,255,1)",
            pointBackgroundColor: "rgba(155,23,255,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(220,220,220,1)",
            tension: 0.3,
            data: [],
            yAxisID: "yId2"
          };
          detailData.datasets.pushObject(addedSignalGraph);
        }
      }

      this.set("detailChartData", detailData);
      let detailOptions = {
        animation: {
          duration: 0
        },
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: false
          }],
          yAxes: [{
            display: true,
            position: "left",
            ticks: {
              beginAtZero: true
            },
            scaleLabel: {
              display: true,
              labelString: signalUnit
            },
            id: "yId1"
          }, {
            display: true,
            position: "right",
            ticks: {
              beginAtZero: true
            },
            scaleLabel: {
              display: false
            },
            id: "yId2"
          }]
        },
        pointRadius: 4,
        pointBorderWidth: 1,
        pointHoverRadius: 20,
        borderWidth: 2
      };
      this.set("usedRelatedSignals", usedRelatedSignals);
      this.set("detailChartOptions", detailOptions);
      this.set("samplingRate", 200);
      this.set("valueCount", 30);
      this.set("showChosenSample", 15);
      this.set("progressSecond", 0);
      this.set("xInCanvas", 50);
      this.set("signalsCount", signalsCount);
      this.set("relatedSignals", relatedSignals);
      let alertStart = chosenAlert.alertSpan.start;
      this.set("alertStart", alertStart);
      let alertEnd = chosenAlert.alertSpan.end;
      let duration = (alertEnd - alertStart) / 1000000; // duration = millisekunden

      let durationInSeconds = Math.floor(duration / 1000);
      let durationInMillis = Math.round(duration % 1000);
      this.set("duration", durationInSeconds + "," + durationInMillis);
    },

    didInsertElement() {
      this._super(...arguments); //-------------------------------------------------Overview Chart


      let overviewOptions = this.get("overviewChartOptions");
      let overviewChartData = this.get("overviewChartData");
      let overviewCanvasElement = this.get("element").getElementsByTagName("CANVAS")[0];
      let ctxOverview = overviewCanvasElement.getContext("2d");
      let newOverviewChart = new _chartjs.default(ctxOverview, {
        type: "line",
        data: overviewChartData,
        options: overviewOptions
      });
      let allChartData = this.get("allChartData");
      let chosenAlert = this.get("chosenAlert");
      let deviceId = chosenAlert.deviceId;
      let signalId = chosenAlert.alertTypeId;
      this.set("signalId", signalId);
      let alertStart = this.get("alertStart");
      let samplesCount = 0; //----------------- get Values for alertLines in Chart

      let thisSignalTypeUsed = this.get("thisSignalTypeUsed");
      let alertType = thisSignalTypeUsed.get("alertType");
      let alertProperties = alertType.get("alertProperties");
      let alertCeil = alertProperties.get("ceiling");
      let ceilAlert = alertCeil.get("active");
      this.set("ceilAlert", ceilAlert);
      let alertCeilWarn = alertCeil.get("warn");
      this.set("alertCeilWarn", alertCeilWarn);
      let alertCeilCritical = alertCeil.get("critical");
      this.set("alertCeilCritical", alertCeilCritical);
      let alertCeilEmergency = alertCeil.get("emergency");
      this.set("alertCeilEmergency", alertCeilEmergency);
      let alertFloor = alertProperties.get("floor");
      let floorAlert = alertFloor.get("active");
      this.set("floorAlert", floorAlert);
      let alertFloorWarn = alertFloor.get("warn");
      this.set("alertFloorWarn", alertFloorWarn);
      let alertFloorCritical = alertFloor.get("critical");
      this.set("alertFloorCritical", alertFloorCritical);
      let alertFloorEmergency = alertFloor.get("emergency");
      this.set("alertFloorEmergency", alertFloorEmergency); //----------------- URL request

      _jquery.default.get(_app.default.API_URL + "/alerts/" + deviceId + "/" + signalId + "/" + alertStart, data => {
        let probe = data.alertValues.get("firstObject");

        if (probe) {
          let box = data.alertValues;
          let signalsCount = this.get("signalsCount");
          let relatedSignals = this.get("relatedSignals");

          if (relatedSignals) {
            let alreadyContains = relatedSignals.includes(signalId);

            if (!alreadyContains) {
              relatedSignals.unshiftObject(signalId);
            }
          } else {
            relatedSignals = [signalId];
          }

          let allSortedValuesRaw = [signalsCount];
          let allSortedValuesOrd = [signalsCount];

          for (let ix = 0; ix < signalsCount; ix++) {
            allSortedValuesRaw[ix] = [];
            allSortedValuesOrd[ix] = [];
          }

          box.forEach(thisBoxEntry => {
            let thisBoxEntryIndex = relatedSignals.indexOf(thisBoxEntry.signal);
            allSortedValuesRaw[thisBoxEntryIndex].pushObject(thisBoxEntry);
          });
          let firstSortedValuesLength = allSortedValuesRaw[0].length;
          let allSortedValuesLength = allSortedValuesRaw.length;

          for (let ia = 0; ia < allSortedValuesLength; ia++) {
            let thatSortedValue = allSortedValuesRaw[ia];
            let thatSortedValueLength = thatSortedValue.length;

            if (thatSortedValueLength < firstSortedValuesLength) {
              let zi = 0,
                  z = firstSortedValuesLength / thatSortedValueLength,
                  ip = 0,
                  realSteps = 0,
                  thisSignal = thatSortedValue[0].signal,
                  prefReal = 0;

              for (let ig = 0; ig <= firstSortedValuesLength; ig++) {
                if (ig >= zi) {
                  zi = zi + z;

                  if (ig === 0) {
                    allSortedValuesOrd[ia][ig] = allSortedValuesRaw[ia][1];
                  } else {
                    realSteps++;
                    let thisReal = 0;

                    if (realSteps < thatSortedValueLength) {
                      thisReal = allSortedValuesRaw[ia][realSteps].value;
                      prefReal = thisReal;
                    } else {
                      thisReal = prefReal;
                    }

                    allSortedValuesOrd[ia][ig] = {
                      value: thisReal,
                      signal: thisSignal
                    };

                    if (ig > ip + 1) {
                      let lastReal = allSortedValuesOrd[ia][ip].value;
                      let realDiff = thisReal - lastReal;
                      let stepsLeft = ig - realSteps + 1;
                      let realDiffStep = realDiff / stepsLeft;

                      for (let ih = 1; ih < stepsLeft; ih++) {
                        allSortedValuesOrd[ia][ip + ih] = {
                          value: lastReal + realDiffStep * ih,
                          signal: thisSignal
                        };
                      }
                    }

                    ip = ig;
                  }
                }
              }
            } else {
              allSortedValuesOrd[ia] = allSortedValuesRaw[ia];
            }
          } //----------------- Chart Data


          for (let iy = 0; iy < firstSortedValuesLength; iy++) {
            let thisTime = allSortedValuesOrd[0][iy].time;
            newOverviewChart.data.labels.push(thisTime);

            if (ceilAlert) {
              newOverviewChart.data.datasets[0].data.push(alertCeilWarn);
              newOverviewChart.data.datasets[1].data.push(alertCeilCritical);
              newOverviewChart.data.datasets[2].data.push(alertCeilEmergency);
            }

            if (floorAlert) {
              newOverviewChart.data.datasets[3].data.push(alertFloorWarn);
              newOverviewChart.data.datasets[4].data.push(alertFloorCritical);
              newOverviewChart.data.datasets[5].data.push(alertFloorEmergency);
            }

            let bag = [thisTime];

            for (let iu = 0; iu < signalsCount; iu++) {
              let thisValue = allSortedValuesOrd[iu][iy].value;

              if (iu === 0) {
                newOverviewChart.data.datasets[6].data.push(thisValue);
              }

              bag.pushObject(thisValue);
            }

            allChartData.pushObject(bag);
          }

          if (allChartData) {
            this.set("allChartData", allChartData);
            newOverviewChart.update();
          }

          samplesCount = firstSortedValuesLength;
          this.set("samplesCount", samplesCount); //-------------------------------------------------Detail Chart begin

          let valueCount = this.get("valueCount");

          if (valueCount > firstSortedValuesLength) {
            valueCount = firstSortedValuesLength;
          }

          this.set("valueCount", valueCount);
          let usedRelatedSignals = this.get("usedRelatedSignals");
          let detailOptions = this.get("detailChartOptions");
          let detailChartData = this.get("detailChartData");
          let detailCanvasElement = this.get("element").getElementsByTagName("CANVAS")[1];
          let ctxDetail = detailCanvasElement.getContext("2d");
          let relatedSignalUnit = [];
          detailOptions.tooltips = {
            callbacks: {
              labelColor: function (tooltipItem, chart) {
                let thatColor = chart.config.data.datasets[tooltipItem.datasetIndex].borderColor;
                return {
                  borderColor: thatColor,
                  backgroundColor: thatColor
                };
              },
              footer: function (tooltipItems, data) {
                let thisSignal = relatedSignalUnit[tooltipItems.get("firstObject").datasetIndex];
                let thisSignalUnit;

                if (thisSignal) {
                  usedRelatedSignals.forEach(thisRelatedSignal => {
                    if (thisSignal === thisRelatedSignal.get("signalTypeId")) {
                      thisSignalUnit = thisRelatedSignal.get("name") + " in " + thisRelatedSignal.get("unit");
                    }
                  });
                }

                if (thisSignalUnit) {
                  return thisSignalUnit;
                }
              }
            }
          };
          let newDetailChart = new _chartjs.default(ctxDetail, {
            type: "line",
            data: detailChartData,
            options: detailOptions
          });

          for (let i = 0; i < valueCount; i++) {
            let thisLabel = box[i].time - alertStart;
            let formattedTime = Math.floor(thisLabel / 1000000);
            let formattedTimeSeconds;

            if (formattedTime < 0) {
              formattedTimeSeconds = Math.ceil(formattedTime / 1000);

              if (formattedTimeSeconds === 0) {
                formattedTimeSeconds = "-" + 0;
              }
            } else {
              formattedTimeSeconds = Math.floor(formattedTime / 1000);
            }

            let formattedTimeMillis = Math.round(formattedTime % 1000);

            if (formattedTimeMillis < 0) {
              formattedTimeMillis = formattedTimeMillis * -1;
            }

            if (formattedTimeMillis < 10) {
              formattedTimeMillis = "00" + formattedTimeMillis;
            } else if (formattedTimeMillis < 100) {
              formattedTimeMillis = "0" + formattedTimeMillis;
            }

            newDetailChart.data.labels.push(formattedTimeSeconds + ":" + formattedTimeMillis);

            if (ceilAlert) {
              newDetailChart.data.datasets[0].data.push(alertCeilWarn);
              newDetailChart.data.datasets[1].data.push(alertCeilCritical);
              newDetailChart.data.datasets[2].data.push(alertCeilEmergency);
            }

            if (floorAlert) {
              newDetailChart.data.datasets[3].data.push(alertFloorWarn);
              newDetailChart.data.datasets[4].data.push(alertFloorCritical);
              newDetailChart.data.datasets[5].data.push(alertFloorEmergency);
            }

            for (let iu = 0; iu < signalsCount; iu++) {
              let thatValue = allSortedValuesOrd[iu][i].value;
              relatedSignalUnit[6 + iu] = allSortedValuesOrd[iu][i].signal;
              newDetailChart.data.datasets[6 + iu].data.push(thatValue);
            }
          }

          newDetailChart.update();
          this.set("detailChartObj", newDetailChart); //-------------------------------------------------Detail Chart end
          //prepare to chartliner

          let canvasRect = overviewCanvasElement.getBoundingClientRect();
          let overviewChartTickWidth = this.get("overviewChartTickWidth");
          let widthPerSample = (canvasRect.width - overviewChartTickWidth) / samplesCount;
          this.set("widthPerSample", widthPerSample);
          this.showChartLiner(true);
        }
      });

      ctxOverview.save();
      this.set("overviewChartObj", newOverviewChart);
    },

    click(event) {
      let targetClassName = event.target.className;

      if (targetClassName === "overviewDiagram" || targetClassName === "diagramPointer" || targetClassName === "diagramPointerCase") {
        let overviewChartTickWidth = this.get("overviewChartTickWidth");
        let pageX = event.pageX;
        let canvas = this.get("element").getElementsByTagName("CANVAS")[0];
        let canvasRect = canvas.getBoundingClientRect();
        let xInCanvas = pageX - canvasRect.left - overviewChartTickWidth;
        this.set("xInCanvas", xInCanvas);
        let xInDiagram = Math.floor(xInCanvas);

        if (xInDiagram >= 0) {
          let samplesCount = this.get("samplesCount");
          let widthPerSample = (canvasRect.width - overviewChartTickWidth) / (samplesCount - 1);
          this.set("widthPerSample", widthPerSample);
          let chosenSample = Math.round(xInDiagram / widthPerSample + 1);
          this.set("progressSamples", chosenSample);
          let showChosenSample = this.get("showChosenSample");
          let valueCount = this.get("valueCount");

          if (samplesCount > valueCount) {
            let halfValueCount = Math.round(valueCount / 2);
            showChosenSample = chosenSample - halfValueCount;
          }

          if (showChosenSample < 0) {
            showChosenSample = 0;
          }

          let maxShownSample = samplesCount - valueCount;

          if (showChosenSample > maxShownSample) {
            showChosenSample = maxShownSample;
          }

          this.set("showChosenSample", showChosenSample);
          this.showChangedChart();
          this.showChartLiner();
        }
      }
    },

    showChartLiner(withoutClick) {
      let xInCanvas;

      if (withoutClick) {
        xInCanvas = 0;
      } else {
        xInCanvas = this.get("xInCanvas");
      }

      let canvas = this.get("element").getElementsByTagName("CANVAS")[0];
      let canvasRect = canvas.getBoundingClientRect();
      let widthPerSample = this.get("widthPerSample");
      let valueCount = this.get("valueCount");
      let alertId = this.get("chosenAlert.alertId");
      let borderDiv = document.getElementById(alertId);
      let overviewChartTickWidth = this.get("overviewChartTickWidth");
      borderDiv.style.top = "0px";
      borderDiv.style.left = overviewChartTickWidth + "px";
      borderDiv.style.right = "0px";
      let canvasRectWidth = canvasRect.width - overviewChartTickWidth;
      borderDiv.style.width = canvasRectWidth + "px";
      borderDiv.style.height = canvasRect.height + "px";
      let borderDivCase = borderDiv.lastElementChild;
      let caseWidth = widthPerSample * (valueCount - 1);

      if (caseWidth > canvasRectWidth) {
        caseWidth = canvasRectWidth;
      }

      borderDivCase.style.width = caseWidth + "px";
      let caseLeft = caseWidth / valueCount / 2;
      let checkPositionLeft = xInCanvas + caseLeft - caseWidth / 2;
      let maxPositionLeft = canvasRect.width - overviewChartTickWidth - caseWidth;

      if (checkPositionLeft < 0) {
        checkPositionLeft = 0;
      } else if (checkPositionLeft > maxPositionLeft) {
        checkPositionLeft = maxPositionLeft;
      }

      borderDivCase.style.left = checkPositionLeft + "px";
    },

    showChangedChart() {
      let alertStart = this.get("alertStart");
      let valueCount = this.get("valueCount");
      let signalsCount = this.get("signalsCount");
      let allChartData = this.get("allChartData");
      let detailChartObj = this.get("detailChartObj");
      let showChosenSample = this.get("showChosenSample");
      let ceilAlert = this.get("ceilAlert");
      let alertCeilWarn = this.get("alertCeilWarn");
      let alertCeilCritical = this.get("alertCeilCritical");
      let alertCeilEmergency = this.get("alertCeilEmergency");
      let floorAlert = this.get("floorAlert");
      let alertFloorWarn = this.get("alertFloorWarn");
      let alertFloorCritical = this.get("alertFloorCritical");
      let alertFloorEmergency = this.get("alertFloorEmergency");
      detailChartObj.data.labels.clear();

      for (let ir = 0; ir < signalsCount; ir++) {
        detailChartObj.data.datasets[6 + ir].data.clear();
      }

      for (let i = 0; i < valueCount; i++) {
        let thisLabel = allChartData[showChosenSample + i];
        let thisTimeFull = thisLabel[0] - alertStart;
        let formattedTime = Math.floor(thisTimeFull / 1000000);
        let formattedTimeSeconds;

        if (formattedTime < 0) {
          formattedTimeSeconds = Math.ceil(formattedTime / 1000);

          if (formattedTimeSeconds === 0) {
            formattedTimeSeconds = "-" + 0;
          }
        } else {
          formattedTimeSeconds = Math.floor(formattedTime / 1000);
        }

        let formattedTimeMillis = Math.round(formattedTime % 1000);

        if (formattedTimeMillis < 0) {
          formattedTimeMillis = formattedTimeMillis * -1;
        }

        if (formattedTimeMillis < 10) {
          formattedTimeMillis = "00" + formattedTimeMillis;
        } else if (formattedTimeMillis < 100) {
          formattedTimeMillis = "0" + formattedTimeMillis;
        }

        detailChartObj.data.labels.push(formattedTimeSeconds + ":" + formattedTimeMillis);

        if (ceilAlert) {
          detailChartObj.data.datasets[0].data.push(alertCeilWarn);
          detailChartObj.data.datasets[1].data.push(alertCeilCritical);
          detailChartObj.data.datasets[2].data.push(alertCeilEmergency);
        }

        if (floorAlert) {
          detailChartObj.data.datasets[3].data.push(alertFloorWarn);
          detailChartObj.data.datasets[4].data.push(alertFloorCritical);
          detailChartObj.data.datasets[5].data.push(alertFloorEmergency);
        }

        for (let iu = 1; iu < signalsCount + 1; iu++) {
          let thatValue = thisLabel[iu];
          detailChartObj.data.datasets[5 + iu].data.push(thatValue);
        }
      }

      detailChartObj.update();
      this.set("detailChartObj", detailChartObj);
    },

    timestampToReadableTime(timestamp) {
      let originTimeStamp = Math.round(timestamp / 1000000); //1493283428168

      let thisTimeStamp = new Date(originTimeStamp);
      let hours = thisTimeStamp.getHours();
      let minutes = thisTimeStamp.getMinutes();
      let seconds = thisTimeStamp.getSeconds();

      if (minutes < 10) {
        minutes = '0' + minutes;
      }

      if (seconds < 10) {
        seconds = '0' + seconds;
      }

      return hours + ':' + minutes + ':' + seconds;
    },

    timestampToReadableDay(timestamp) {
      let originTimeStamp = Math.round(timestamp / 1000000); //1493283428168

      let thisTimeStamp = new Date(originTimeStamp);
      let days = thisTimeStamp.getDate();
      let months = thisTimeStamp.getMonth() + 1;
      let years = thisTimeStamp.getFullYear();

      if (days < 10) {
        days = '0' + days;
      }

      if (months < 10) {
        months = '0' + months;
      }

      return days + '.' + months + '.' + years;
    },

    actions: {
      closeButton() {
        let chosenAlert = this.get("chosenAlert");
        this.closeButton(chosenAlert);
      },

      saveButton() {
        let chosenAlert = this.get("chosenAlert");
        this.saveButton(chosenAlert);
      },

      downloadButton() {
        let device = this.get('device');
        let chosenAlert = this.get("chosenAlert");
        let timeStart = this.timestampToReadableTime(chosenAlert.alertSpan.start);
        let timeEnd = this.timestampToReadableTime(chosenAlert.alertSpan.end);
        let alertDay = this.timestampToReadableDay(chosenAlert.alertSpan.start);

        _jquery.default.get(_app.default.API_URL + "/alerts/" + chosenAlert.deviceId + "/" + chosenAlert.alertTypeId + "/" + chosenAlert.alertSpan.start, data => {
          let probe = data.alertValues.get("firstObject");

          if (probe) {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += '//Device Model:' + device.deviceModel + ';Serial:' + device.deviceSerial + ';Time Start:' + timeStart + ';Time End:' + timeEnd + ';Day:' + alertDay + '\r\n';
            let signalTypeIndex = [];
            signalTypeIndex.push('Time');
            let signalTypeValues = [];
            signalTypeValues.push([]);
            data.alertValues.forEach(thisBoxEntry => {
              if (signalTypeIndex.indexOf(thisBoxEntry.signal) < 0) {
                signalTypeIndex.push(thisBoxEntry.signal);
                signalTypeValues.push([]);
              }

              let thisTime = Math.round(thisBoxEntry.time / 1000000);

              if (signalTypeValues[0].indexOf(thisTime) < 0) {
                signalTypeValues[0].push(thisTime);
              }

              signalTypeValues[signalTypeIndex.indexOf(thisBoxEntry.signal)].push(thisBoxEntry.value);
            });
            let headRow = 'Time;';
            let signalsAndTime = signalTypeIndex.length;

            for (let i = 1; i < signalsAndTime; i++) {
              headRow = headRow + device.signalTypes.content[signalTypeIndex[i] - 1].name;

              if (i < signalsAndTime - 1) {
                headRow = headRow + ';';
              } else {
                headRow = headRow + '\r\n';
                csvContent += headRow;
              }
            }

            for (let i = 1; i < signalTypeValues[0].length; i++) {
              let j = 0;
              let row = '';

              while (j < signalTypeValues.length) {
                row = row + signalTypeValues[j][i];

                if (j < signalTypeValues.length - 1) {
                  row = row + ';';
                } else {
                  row = row + '\r\n';
                }

                j++;
              }

              csvContent += row;
            }

            let encodedUri = encodeURI(csvContent);
            let link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            let fileName = device.deviceSerial + '_' + Math.round(chosenAlert.alertSpan.start / 1000000) + '.csv';
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          }
        });
      },

      valueCountDown() {
        let valueCount = this.get("valueCount");

        if (valueCount > 0) {
          if (valueCount > 20) {
            this.set("valueCount", valueCount - 10);
          } else {
            this.set("valueCount", 10);
          }
        }

        this.showChangedChart();
        this.showChartLiner();
      },

      valueCountUp() {
        let valueCount = this.get("valueCount");
        let samplesCount = this.get("samplesCount");

        if (valueCount < 250) {
          if (valueCount < samplesCount - 10) {
            this.set("valueCount", valueCount + 10);
          } else {
            this.set("valueCount", samplesCount);
          }
        }

        this.showChangedChart();
        this.showChartLiner();
      }

    }
  });

  _exports.default = _default;
});