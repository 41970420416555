define("machine-monitoring/configuration/device/edit/signal/controller", ["exports", "machine-monitoring/app", "jquery"], function (_exports, _app, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    thisSignalType: null,
    newSamplingRate: null,
    allIcons: null,
    thisSignalIcon: "edit",

    buildIcons() {
      this.set("allIcons", [{
        name: "address-card",
        selected: false
      }, {
        name: "atom",
        selected: false
      }, {
        name: "asterisk",
        selected: false
      }, {
        name: "balance-scale",
        selected: false
      }, {
        name: "battery-empty",
        selected: false
      }, {
        name: "battery-half",
        selected: false
      }, {
        name: "battery-full",
        selected: false
      }, {
        name: "bell",
        selected: false
      }, {
        name: "burn",
        selected: false
      }, {
        name: "certificate",
        selected: false
      }, {
        name: "check",
        selected: false
      }, {
        name: "circle",
        selected: false
      }, {
        name: "cog",
        selected: false
      }, {
        name: "cogs",
        selected: false
      }, {
        name: "coins",
        selected: false
      }, {
        name: "compass",
        selected: false
      }, {
        name: "compact-disc",
        selected: false
      }, {
        name: "database",
        selected: false
      }, {
        name: "exchange-alt",
        selected: false
      }, {
        name: "fan",
        selected: false
      }, {
        name: "fill",
        selected: false
      }, {
        name: "fill-drip",
        selected: false
      }, {
        name: "fire",
        selected: false
      }, {
        name: "flask",
        selected: false
      }, {
        name: "hourglass",
        selected: false
      }, {
        name: "hourglass-start",
        selected: false
      }, {
        name: "hourglass-half",
        selected: false
      }, {
        name: "hourglass-end",
        selected: false
      }, {
        name: "oil-can",
        selected: false
      }, {
        name: "ruler",
        selected: false
      }, {
        name: "tachometer-alt",
        selected: false
      }, {
        name: "thermometer",
        selected: false
      }, {
        name: "thermometer-empty",
        selected: false
      }, {
        name: "thermometer-quarter",
        selected: false
      }, {
        name: "thermometer-half",
        selected: false
      }, {
        name: "thermometer-three-quarters",
        selected: false
      }, {
        name: "thermometer-full",
        selected: false
      }, {
        name: "tint",
        selected: false
      }, {
        name: "tint-slash",
        selected: false
      }, {
        name: "tools",
        selected: false
      }, {
        name: "wrench",
        selected: false
      }]);
      let thisSignalIcon = this.get('thisSignalType.icon');

      if (thisSignalIcon) {
        this.get('allIcons').filterBy('name', thisSignalIcon)[0].selected = true;
      }
    },

    actions: {
      firstRunAfterRender() {
        this.buildIcons();
      },

      dismissChange() {
        this.get("model").rollbackAttributes();
        let thisModelId = this.get("model").get("id");
        let thisDevice = this.get("store").findRecord("device", thisModelId);
        this.transitionToRoute("configuration.device.edit", thisDevice);
      },

      finishEdit() {
        this.transitionToRoute("configuration.device.edit", this.get("model"));
      },

      deleteSignal() {
        let thisSignalType = this.get("thisSignalType");
        let signalTypeIdToDelete = thisSignalType.get("signalTypeId");
        let oldModel = this.get("model");
        let oldSignalTypes = oldModel.get("signalTypes");
        let newSignalTypes = [];
        oldSignalTypes.forEach(oldSignalType => {
          let oldSignalTypeId = oldSignalType.get("signalTypeId");

          if (signalTypeIdToDelete !== oldSignalTypeId) {
            let oldAlertType = oldSignalType.get("alertType");
            let oldAlertProperties = oldAlertType.get("alertProperties");
            let oldCeiling = oldAlertProperties.get("ceiling");
            let oldFloor = oldAlertProperties.get("floor");
            let newFloor = {
              active: oldFloor.get("active"),
              critical: oldFloor.get("critical"),
              emergency: oldFloor.get("emergency"),
              warn: oldFloor.get("warn")
            };
            let newCeil = {
              active: oldCeiling.get("active"),
              critical: oldCeiling.get("critical"),
              emergency: oldCeiling.get("emergency"),
              warn: oldCeiling.get("warn")
            };
            let newAlertProperties = {
              ceiling: newCeil,
              floor: newFloor
            };
            let oldRelatedSignals = oldAlertType.get("relatedSignals");
            let newRelatedSignals = [];
            oldRelatedSignals.forEach(thisRelated => {
              newRelatedSignals.pushObject(thisRelated);
            });
            let newAlerttype = {
              alertTypeId: oldAlertType.get("alertTypeId"),
              name: oldAlertType.get("name"),
              description: oldAlertType.get("description"),
              alertProperties: newAlertProperties,
              relatedSignals: newRelatedSignals
            };
            let oldSamplingRates = oldSignalType.get("samplingRates");
            let newSamplingRates = [];
            oldSamplingRates.forEach(thisSamplingRate => {
              newSamplingRates.pushObject(thisSamplingRate);
            });
            let newSignaltype = {
              signalTypeId: oldSignalType.get("signalTypeId"),
              name: oldSignalType.get("name"),
              unit: oldSignalType.get("unit"),
              snapshotSchedule: oldSignalType.get("snapshotSchedule"),
              samplingRates: newSamplingRates,
              description: oldSignalType.get("description"),
              alertType: newAlerttype
            };
            newSignalTypes.pushObject(newSignaltype);
          }
        });
        let newDevice = {
          deviceId: oldModel.get("deviceId"),
          deviceType: oldModel.get("deviceType"),
          deviceTypeName: oldModel.get("deviceTypeName"),
          deviceModel: oldModel.get("deviceModel"),
          deviceManufacturer: oldModel.get("deviceManufacturer"),
          deviceSerial: oldModel.get("deviceSerial"),
          deviceYear: oldModel.get("deviceYear"),
          picturePath: oldModel.get("picturePath"),
          health: oldModel.get("health"),
          signalTypes: newSignalTypes
        };
        let modelId = oldModel.get("id");
        let stringifyModel = JSON.stringify(newDevice);

        _jquery.default.ajax({
          method: "PUT",
          url: _app.default.API_URL + "/devices/" + modelId,
          contentType: "text/plain",
          data: stringifyModel
        }).done(data => {
          this.transitionToRoute("configuration.device.edit", oldModel);
        });

        (0, _jquery.default)("#deleteSignalModal").modal("hide");
      },

      finishDuplicateSignal() {
        let model = this.get("model");
        let newSignalTypeId = 0;
        let allSignalTypes = model.get("signalTypes");
        allSignalTypes.forEach(thisOldSignalType => {
          let thisOldId = thisOldSignalType.get("signalTypeId");

          if (thisOldId > newSignalTypeId) {
            newSignalTypeId = thisOldId;
          }
        });
        newSignalTypeId = newSignalTypeId + 1;
        let thisSignalType = this.get("thisSignalType");
        let oldAlertType = thisSignalType.get("alertType");
        let oldAlertProperties = oldAlertType.get("alertProperties");
        let oldCeiling = oldAlertProperties.get("ceiling");
        let oldFloor = oldAlertProperties.get("floor");
        let newFloor = {
          active: oldFloor.get("active"),
          critical: oldFloor.get("critical"),
          emergency: oldFloor.get("emergency"),
          warn: oldFloor.get("warn")
        };
        let newCeil = {
          active: oldCeiling.get("active"),
          critical: oldCeiling.get("critical"),
          emergency: oldCeiling.get("emergency"),
          warn: oldCeiling.get("warn")
        };
        let newAlertProperties = {
          ceiling: newCeil,
          floor: newFloor
        };
        let oldRelatedSignals = oldAlertType.get("relatedSignals");
        let newRelatedSignals = [];
        oldRelatedSignals.forEach(thisRelated => {
          newRelatedSignals.pushObject(thisRelated);
        });
        let newAlerttype = {
          alertTypeId: oldAlertType.get("alertTypeId"),
          name: oldAlertType.get("name"),
          description: oldAlertType.get("description"),
          alertProperties: newAlertProperties,
          relatedSignals: newRelatedSignals
        };
        let oldSamplingRates = thisSignalType.get("samplingRates");
        let newSamplingRates = [];
        oldSamplingRates.forEach(thisSamplingRate => {
          newSamplingRates.pushObject(thisSamplingRate);
        });
        let newSignaltype = {
          signalTypeId: newSignalTypeId,
          name: thisSignalType.get("name"),
          unit: thisSignalType.get("unit"),
          snapshotSchedule: thisSignalType.get("snapshotSchedule"),
          samplingRates: newSamplingRates,
          description: thisSignalType.get("description"),
          alertType: newAlerttype
        };
        model.get("signalTypes").pushObject(newSignaltype);
        this.transitionToRoute("configuration.device.edit", this.get("model"));
      },

      editMeasuringUnit(thatUnit) {
        this.set('thisSignalType.unit', thatUnit);
      },

      editSamplingRates() {
        (0, _jquery.default)("#modalEditSamplingRates").modal("show");
      },

      editIcon() {
        (0, _jquery.default)("#modalEditIcon").modal("show");
      },

      abortSelectIcon() {
        (0, _jquery.default)("#modalEditIcon").modal("hide");
      },

      finishtSelectIcon() {
        (0, _jquery.default)("#modalEditIcon").modal("hide");
      },

      selectThisIcon(thisIcon) {
        this.get('allIcons').forEach(thatIcon => {
          Ember.set(thatIcon, "selected", false);
        });
        this.set('thisSignalIcon', thisIcon.name);
        Ember.set(thisIcon, "selected", true);
        this.set('thisSignalType.icon', thisIcon.name);
      },

      addSamplingRate() {
        let newSamplingRate = parseInt(this.get("newSamplingRate"));
        let thisSignalType = this.get("thisSignalType");
        let thisSamplingRates = thisSignalType.get("samplingRates");
        thisSamplingRates.pushObject(newSamplingRate);
        this.set("newSamplingRate", null);
      },

      removeThisSamplingRate(thisSample) {
        let thisSignalType = this.get("thisSignalType");
        let thisSamplingRates = thisSignalType.get("samplingRates");
        thisSamplingRates.removeObject(thisSample);
      },

      finishSamplingRatesSetting() {
        (0, _jquery.default)("#modalEditSamplingRates").modal("hide");
      },

      editRelatedSignals() {
        let model = this.get("model");
        let signalTypes = model.get("signalTypes");
        let relatedSignals = this.get("thisSignalType.alertType.relatedSignals");
        signalTypes.forEach(thisSignalType => {
          thisSignalType.set("related", false);
          let thatSignalId = thisSignalType.get("signalTypeId");
          relatedSignals.forEach(relatedSignal => {
            if (thatSignalId === relatedSignal) {
              thisSignalType.set("related", true);
            }
          });
        });
        (0, _jquery.default)("#modalEditRelatedSignals").modal("show");
      },

      toggleRelatedSignalType(thisSignalType) {
        let oldState = thisSignalType.get("related");
        thisSignalType.set("related", !oldState);
      },

      finishtRelatedSignalssSetting() {
        let model = this.get("model");
        let signalTypes = model.get("signalTypes");
        let relatedSignals = this.get("thisSignalType.alertType.relatedSignals");
        relatedSignals.clear();
        signalTypes.forEach(thisSignalType => {
          if (thisSignalType.get("related")) {
            relatedSignals.addObject(thisSignalType.get("signalTypeId"));
          }
        });
        (0, _jquery.default)("#modalEditRelatedSignals").modal("hide");
      },

      abortRelatedSignalsSetting() {
        let model = this.get("model");
        let signalTypes = model.get("signalTypes");
        signalTypes.forEach(thisSignalType => {
          thisSignalType.set("related", false);
        });
        (0, _jquery.default)("#modalEditRelatedSignals").modal("hide");
      }

    }
  });

  _exports.default = _default;
});