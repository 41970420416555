define("machine-monitoring/configuration/customers/newcustomer/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      let newCustomer = this.get("store").createRecord("customer");
      controller.set("newCustomer", newCustomer);
    },

    renderTemplate(controller, model) {
      this.render("configuration/customers/newcustomer", {
        into: "application",
        outlet: "content",
        controller: controller,
        model: model
      });
    }

  });

  _exports.default = _default;
});