define("machine-monitoring/devices/controller", ["exports", "machine-monitoring/app"], function (_exports, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    allData: null,
    alertMeta: null,

    init() {
      this._super(...arguments);

      this.allAlertStarts = [];
    },

    actions: {
      showThisDevice(thisModel) {
        let deviceIdString = thisModel.get("id");
        _app.default.chosenDeviceId = parseInt(deviceIdString);
        this.transitionToRoute("devices.details", thisModel);
      }

    }
  });

  _exports.default = _default;
});