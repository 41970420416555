define("machine-monitoring/configuration/device/anomalies/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u4jifU6e",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/configuration/device/anomalies/template.hbs"
    }
  });

  _exports.default = _default;
});