define("machine-monitoring/configuration/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.get("store").findAll("device");
    },

    renderTemplate(controller, model) {
      this.render("configuration", {
        into: "application",
        outlet: "content",
        controller: controller,
        model: model
      });
      this.render("config-navigation", {
        into: "application",
        outlet: "navigation",
        controller: controller,
        model: model
      });
      controller.send("firstRunAfterRender");
    }

  });

  _exports.default = _default;
});