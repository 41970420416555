define("machine-monitoring/components/sensor-template-tooltip/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hasXY1: false,
    hasXY2: false,
    hasTime: false,
    nameX1: null,
    nameY1: null,
    nameX2: null,
    nameY2: null,
    nameTime: [],

    init() {
      this._super(arguments);

      let that = this;
      let box = 0;
      let recordSensors = this.get('recordSensors');
      let signalTypes = this.get('signalTypes');
      let nameTime = [];

      if (recordSensors.x1 && recordSensors.x1 > 0) {
        this.set('hasXY1', true);
      }

      if (recordSensors.x2 && recordSensors.x2 > 0) {
        this.set('hasXY2', true);
      }

      if (recordSensors.time && recordSensors.time[0] > 0) {
        this.set('hasTime', true);
        box = recordSensors.time.length;
      }

      signalTypes.forEach(function (thatType) {
        if (thatType.signalTypeId == recordSensors.x1) {
          that.set('nameX1', thatType.name);
        }

        if (thatType.signalTypeId == recordSensors.x2) {
          that.set('nameX2', thatType.name);
        }

        if (thatType.signalTypeId == recordSensors.y1) {
          that.set('nameY1', thatType.name);
        }

        if (thatType.signalTypeId == recordSensors.y2) {
          that.set('nameY2', thatType.name);
        }

        for (let i = 0; i < box; i++) {
          if (thatType.signalTypeId == recordSensors.time[i]) {
            nameTime.push(thatType.name);
          }
        }
      });
      that.set('nameTime', nameTime);
    }

  });

  _exports.default = _default;
});