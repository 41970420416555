define("machine-monitoring/devices/details/anomalie/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel(transition) {
      this.set("device", transition.resolvedModels["devices.details"]);
    },

    model() {
      let thisDevice = this.get("device");
      return Ember.RSVP.hash({
        device: thisDevice,
        signalTypes: thisDevice.get("signalTypes")
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      let allSignalTypes = model.signalTypes;
      allSignalTypes.forEach(thisSignalType => {
        thisSignalType.set("selectedS", false);
      });
    },

    renderTemplate(controller, model) {
      this.render("devices/details/anomalie/create", {
        into: "devices/details",
        controller: controller,
        model: model
      });
    }

  });

  _exports.default = _default;
});