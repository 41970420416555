define("machine-monitoring/services/that-donutdata", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    data: null,
    maxAlerts: null,

    init() {
      this._super(...arguments);

      this.set('data', []);
    },

    add(item) {
      this.get('data').pushObject(item);
    },

    remove(item) {
      this.get('data').removeObject(item);
    },

    cleanup() {
      this.set('data', []);
    },

    plus(value) {
      this.set('maxAlerts', value);
    }

  });

  _exports.default = _default;
});