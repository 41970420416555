define("machine-monitoring/templates/device-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T1887EUk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\",true],[10,\"class\",\"nav-item\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-outline-primary navbar-btn ml-2\"],[3,\"action\",[[23,0,[]],\"toggleDeviceInfo\"]],[8],[1,[24,[\"model\",\"deviceModel\"]],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/templates/device-button.hbs"
    }
  });

  _exports.default = _default;
});