define("machine-monitoring/devices/details/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    thatDonutdataService: Ember.inject.service("that-donutdata"),

    afterModel() {
      this.controllerFor("devices/details").set("thirdNaviRoute", 'overview');
      this.transitionTo("devices.details.alerts");
    },

    setupController(controller, model) {
      controller.set("model", model);
      let deviceId = model.get("id");
      let thatDonutdataService = this.get("thatDonutdataService");
      let donutdata = thatDonutdataService.get("data");
      controller.set("donutdata", false);
      donutdata.forEach(thisDonutData => {
        if (thisDonutData[deviceId]) {
          controller.set("donutdata", thisDonutData[deviceId]);
        }
      });
      controller.set("maxAlerts", thatDonutdataService.get("maxAlerts"));
    },

    renderTemplate(controller, model) {
      this.render("devices/details", {
        into: "application",
        outlet: "content",
        controller: controller,
        model: model
      });
      this.render("extend-navigation", {
        into: "application",
        outlet: "navigation",
        controller: controller,
        model: model
      });
      this.render("device-button", {
        into: "application",
        outlet: "device-button",
        controller: controller,
        model: model
      });
    },

    deactivate() {
      this.controller.send('leaveRoute');
    }

  });

  _exports.default = _default;
});