define("machine-monitoring/devices/details/overview/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate() {
      Ember.run.later(() => {
        this.controllerFor("devices/details/overview").send("firstRunAfterRender");
      }, 100);
    },

    beforeModel(transition) {
      this.set("device", transition.resolvedModels["devices.details"]);
    },

    model() {
      return this.get("device");
    },

    setupController(controller, model) {
      this._super(controller, model);

      let allTypes = [];
      let signalTypes = model.get("signalTypes");
      signalTypes.forEach(thisType => {
        let ceilActive = thisType.alertType.alertProperties.ceiling.active;
        let ceilWarn = thisType.alertType.alertProperties.ceiling.warn;
        let ceilEmergency = thisType.alertType.alertProperties.ceiling.emergency;
        let ceilCritical = thisType.alertType.alertProperties.ceiling.critical;
        let floorActive = thisType.alertType.alertProperties.floor.active;
        let floorWarn = thisType.alertType.alertProperties.floor.warn;
        let floorEmergency = thisType.alertType.alertProperties.floor.emergency;
        let floorCritical = thisType.alertType.alertProperties.floor.critical;
        let thisTypeId = thisType.get("signalTypeId");
        let thisCase = {
          name: thisType.get("name"),
          unit: thisType.get("unit"),
          icon: thisType.get("icon"),
          value: 0,
          min: 0,
          max: 0,
          ceil: {
            active: ceilActive,
            warn: ceilWarn,
            emergency: ceilEmergency,
            critical: ceilCritical
          },
          floor: {
            active: floorActive,
            warn: floorWarn,
            emergency: floorEmergency,
            critical: floorCritical
          },
          show: true,
          id: thisTypeId
        };
        allTypes[thisTypeId] = thisCase;
      });
      controller.set("allTypes", allTypes);
    },

    renderTemplate(controller, model) {
      this.render("devices/details/overview", {
        into: "devices/details",
        controller: controller,
        model: model
      });
    },

    deactivate() {
      this.controllerFor("devices/details/overview").send("runOnDeactivate");
    }

  });

  _exports.default = _default;
});