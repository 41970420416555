define("machine-monitoring/configuration/device/controller", ["exports", "machine-monitoring/app", "jquery"], function (_exports, _app, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showTypes: false,
    actions: {
      editDevice() {
        let model = this.model;
        this.transitionToRoute("configuration.device.edit", model);
      },

      dlJson() {
        let model = this.model;
        let d = new Date();
        let dateYear = d.getFullYear();
        let dateMonth = d.getMonth() + 1;
        let dateDay = d.getDate();
        let dateHours = d.getHours();
        let dateMinutes = d.getMinutes();
        let dateSeconds = d.getSeconds();

        if (dateMonth < 10) {
          dateMonth = '0' + dateMonth;
        }

        if (dateDay < 10) {
          dateDay = '0' + dateDay;
        }

        if (dateHours < 10) {
          dateHours = '0' + dateHours;
        }

        if (dateMinutes < 10) {
          dateMinutes = '0' + dateMinutes;
        }

        if (dateSeconds < 10) {
          dateSeconds = '0' + dateSeconds;
        }

        let dateName = '-d_' + dateYear + dateMonth + dateDay + '_' + dateHours + dateMinutes + dateSeconds;
        let downloadName = "s_" + model.get('deviceSerial') + dateName + ".json";
        let modelId = model.get("id");

        _jquery.default.ajax({
          url: _app.default.API_URL + "/devices/" + modelId
        }).done(data => {
          let dlElem = document.getElementById("dlElem");
          dlElem.setAttribute("href", "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data)));
          dlElem.setAttribute("download", downloadName);
          dlElem.click();
        });
      },

      ulJson() {
        let uploadContent = "test";
        let uploadFileInput = document.getElementById("uploadFileInput");
        uploadFileInput.click();

        uploadFileInput.onchange = () => {
          let files = uploadFileInput.files;

          if (files != null) {
            uploadContent = files[0];
            let reader = new FileReader();

            reader.onload = () => {
              _jquery.default.ajax({
                url: _app.default.API_URL + "/devices/" + this.get("model").get("id"),
                method: "PUT",
                data: reader.result,
                contentType: 'text/plain',
                success: () => {
                  this.get("model").reload();
                },
                error: error => {
                  console.log("error: ", error);
                }
              });
            };

            reader.readAsText(uploadContent);
          }
        };
      },

      duplicateDevice() {
        (0, _jquery.default)("#duplicateDeviceModal").modal("show");
      },

      finishDuplicateDevice() {
        let oldModel = this.get("model");
        let oldSignalTypes = oldModel.get("signalTypes");
        let newSignalTypes = [];
        oldSignalTypes.forEach(oldSignalType => {
          let oldAlertType = oldSignalType.get("alertType");
          let oldAlertProperties = oldAlertType.get("alertProperties");
          let oldCeiling = oldAlertProperties.get("ceiling");
          let oldFloor = oldAlertProperties.get("floor");
          let newFloor = {
            active: oldFloor.get("active"),
            critical: oldFloor.get("critical"),
            emergency: oldFloor.get("emergency"),
            warn: oldFloor.get("warn")
          };
          let newCeil = {
            active: oldCeiling.get("active"),
            critical: oldCeiling.get("critical"),
            emergency: oldCeiling.get("emergency"),
            warn: oldCeiling.get("warn")
          };
          let newAlertProperties = {
            ceiling: newCeil,
            floor: newFloor
          };
          let oldRelatedSignals = oldAlertType.get("relatedSignals");
          let newRelatedSignals = [];
          oldRelatedSignals.forEach(thisRelated => {
            newRelatedSignals.pushObject(thisRelated);
          });
          let newAlerttype = {
            alertTypeId: oldAlertType.get("alertTypeId"),
            name: oldAlertType.get("name"),
            description: oldAlertType.get("description"),
            alertProperties: newAlertProperties,
            relatedSignals: newRelatedSignals
          };
          let oldSamplingRates = oldSignalType.get("samplingRates");
          let newSamplingRates = [];
          oldSamplingRates.forEach(thisSamplingRate => {
            newSamplingRates.pushObject(thisSamplingRate);
          });
          let newSignaltype = {
            signalTypeId: oldSignalType.get("signalTypeId"),
            name: oldSignalType.get("name"),
            unit: oldSignalType.get("unit"),
            samplingRates: newSamplingRates,
            description: oldSignalType.get("description"),
            alertType: newAlerttype
          };
          newSignalTypes.pushObject(newSignaltype);
        });
        let newDevice = {
          deviceId: oldModel.get("deviceId"),
          deviceType: oldModel.get("deviceType"),
          deviceTypeName: oldModel.get("deviceTypeName"),
          deviceModel: oldModel.get("deviceModel"),
          deviceManufacturer: oldModel.get("deviceManufacturer"),
          deviceSerial: oldModel.get("deviceSerial"),
          deviceYear: oldModel.get("deviceYear"),
          picturePath: oldModel.get("picturePath"),
          health: oldModel.get("health"),
          signalTypes: newSignalTypes
        };
        let stringifiedNewDevice = JSON.stringify(newDevice);

        _jquery.default.post(_app.default.API_URL + "/devices", stringifiedNewDevice, blub => {
          let newDeviceId = blub.id;
          let newLoadedDevice = this.get("store").findRecord("device", newDeviceId);
          this.transitionToRoute("configuration.device.edit", newLoadedDevice);
        });

        (0, _jquery.default)("#duplicateDeviceModal").modal("hide");
      },

      deleteDevice() {
        let model = this.get("model");
        let modelId = model.get("id");

        _jquery.default.ajax({
          method: "DELETE",
          url: _app.default.API_URL + "/devices/" + modelId
        }).done(() => {
          model.deleteRecord();
        });

        this.transitionToRoute("configuration");
      },

      toggleShowTypes() {
        let showTypes = this.get("showTypes");
        this.set("showTypes", !showTypes);
        document.getElementById("toggleTypesShowing").blur();
      }

    }
  });

  _exports.default = _default;
});