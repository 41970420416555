define("machine-monitoring/devices/details/signal/realtime/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel(transition) {
      this.set("device", transition.resolvedModels["devices.details"]);
    },

    model() {
      let thisDevice = this.get("device");
      return Ember.RSVP.hash({
        device: thisDevice,
        signalTypes: thisDevice.get("signalTypes")
      });
    },

    afterModel(model) {
      let thisDeviceOnline = model.device.online;

      if (thisDeviceOnline) {
        Ember.run.later(() => {
          this.controllerFor("devices/details/signal/realtime").send("firstRunAfterRender");
        }, 100);
      }
    },

    setupController(controller, model) {
      this._super(controller, model);

      let allSignalTypes = model.signalTypes;
      allSignalTypes.forEach(thisSignalType => {
        thisSignalType.set("selected", false);
      });
      controller.set('displayModeOne', false);
      controller.set('displayModeTwo', false);
      controller.set('displayModeTre', false);
      controller.set('displayModeFor', false);
      controller.set('displayModeFiv', false);
      let loadMode = this.controllerFor("devices/details/signal").get("loadMode");
      controller.set("loadMode", loadMode);

      switch (loadMode) {
        case 1:
          controller.set("displayModeOne", true);
          break;

        case 2:
          controller.set("displayModeTwo", true);
          controller.set("colWidth", 12);
          break;

        case 3:
          controller.set("displayModeTre", true);
          controller.set("colWidth", 4);
          break;

        case 4:
          controller.set("displayModeFor", true);
          controller.set("colWidth", 6);
          break;

        case 5:
          controller.set("displayModeFiv", true);
          controller.set("colWidth", 100);
          break;
      }

      this.controllerFor("devices/details/signal").set("selectedX", 0);
      this.controllerFor("devices/details/signal").set("selectedY", 0);
      this.controllerFor("devices/details/signal").set("selectedT", 0);
    },

    renderTemplate(controller, model) {
      this.render('devices/details/signal/realtime', {
        into: 'devices/details',
        controller: controller,
        model: model
      });
      window.addEventListener("resize", () => {
        controller.send("resizeWindow");
      });
      this.controllerFor("devices/details").set('thirdNaviRoute', 'osci');
      this.controllerFor("devices/details/signal").send("drawBreadcrumbs");
    },

    actions: {
      refreshModel() {
        this.refresh();
      }

    },

    deactivate() {
      window.removeEventListener("resize", () => {
        this.controllerFor("devices/details/signal/realtime").send("resizeWindow");
      });
      this.controller.send("runToLeave");
    }

  });

  _exports.default = _default;
});