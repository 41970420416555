define("machine-monitoring/configuration/device/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      let locations = this.get("store").findAll("location");
      controller.set("locations", locations);
      let customers = this.get("store").findAll("customer");
      controller.set("customers", customers);
      let actLocation = this.get("store").peekRecord("location", model.get('location'));
      controller.set("actLocation", actLocation);
      let actCustomer = this.get("store").peekRecord("customer", model.get('customer'));
      controller.set("actCustomer", actCustomer);
    },

    renderTemplate(controller, model) {
      this.render("configuration/device/edit", {
        into: "application",
        outlet: "content",
        controller: controller,
        model: model
      });
      this.render("config-details", {
        into: "configuration/device/edit",
        outlet: "configEditDetail",
        controller: controller,
        model: model
      });
      controller.send("firstRunAfterRender");
    }

  });

  _exports.default = _default;
});