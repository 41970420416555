define("machine-monitoring/components/device-detail-donut/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TXpmEb2X",
    "block": "{\"symbols\":[],\"statements\":[[7,\"canvas\",true],[10,\"width\",\"150\"],[10,\"height\",\"150\"],[10,\"class\",\"detailCanvas\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/components/device-detail-donut/template.hbs"
    }
  });

  _exports.default = _default;
});