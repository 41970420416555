define("machine-monitoring/location/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate(controller, model) {
      this.render("location", {
        into: "application",
        outlet: "content",
        controller: controller,
        model: model
      });
      this.render("production-navigation", {
        into: "application",
        outlet: "navigation",
        controller: controller,
        model: model
      });
      controller.send('resetBreadcrumb');
    }

  });

  _exports.default = _default;
});