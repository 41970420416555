define("machine-monitoring/models/signaltype", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    signalTypeId: _emberData.default.attr(),
    uuid: _emberData.default.attr(),
    name: _emberData.default.attr(),
    unit: _emberData.default.attr(),
    snapshotSchedule: _emberData.default.attr(),
    icon: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    samplingRates: _emberDataModelFragments.default.array(),
    description: _emberData.default.attr(),
    visible: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    related: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    alertType: _emberDataModelFragments.default.fragment('alerttype'),
    selectedS: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    selectedT: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    selectedX1: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    selectedY1: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    selectedX2: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    selectedY2: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    selectedXn: Ember.computed('selectedX1', 'selectedX2', function () {
      let test = false;

      if (this.selectedX1 || this.selectedX2) {
        test = true;
      }

      return test;
    }),
    selectedYn: Ember.computed('selectedY1', 'selectedY2', function () {
      let test = false;

      if (this.selectedY1 || this.selectedY2) {
        test = true;
      }

      return test;
    })
  });

  _exports.default = _default;
});