define("machine-monitoring/components/alert-diagram-bar/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ["severity"],
    severity: "signalDiagramEventEmergency",
    attributeBindings: ["offsetReturn:style", "dataToggle:data-toggle", "dataHtml:data-html", "tooltipTitle:title"],
    classNames: ["cursor-pointer"],
    thisSignalId: null,
    dataToggle: "tooltip",
    dataHtml: "true",
    tooltipTitle: "test",

    /*
              newSignalDiv.setAttribute("data-toggle", "tooltip");
              newSignalDiv.setAttribute("data-html", "true");
              newSignalDiv.setAttribute("title", "test");
              // data-toggle="tooltip" data-html="true" title="{{data.DeviceInfo.deviceManufacturer}}"
      */
    offsetLeft: null,
    offsetTop: null,
    offsetReturn: Ember.computed("offsetLeft", "thisAlert.visibleAlert", function () {
      let offsetLeft = this.get("offsetLeft");
      let offsetTop = this.get("offsetTop");
      let thisAlert = this.get("thisAlert");
      let visibleAlert = thisAlert.visibleAlert;
      let opacity = 0.5;

      if (visibleAlert) {
        opacity = 1;
      }

      return Ember.String.htmlSafe("left:" + offsetLeft + "px;top:" + offsetTop + "px;opacity:" + opacity + ";");
    }),

    init() {
      this._super(...arguments);

      let thisAlert = this.get("thisAlert");
      let thatTime = new Date(Math.round(parseInt(thisAlert.alertSpan.start) / 1000000));
      let minutes = thatTime.getMinutes();
      let seconds = thatTime.getSeconds();

      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      let tooltipString = "at Time: " + thatTime.getHours() + ":" + minutes + ":" + seconds + "<br>Value: " + thisAlert.value;
      this.set("tooltipTitle", tooltipString);
      let alertTypeId = thisAlert.alertTypeId;
      this.set("signalTypeId", alertTypeId);
      let allSignalTypesObject = this.get("allSignalTypesObject");
      let thisSignalCount = allSignalTypesObject[alertTypeId];
      allSignalTypesObject[alertTypeId] = thisSignalCount + 1;
      this.set("allSignalTypesObject", allSignalTypesObject);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      let thisAlert = this.get("thisAlert");
      let severity = thisAlert.severity; // WARN, CRITICAL, EMERGENCY, INFORMATION

      if (severity === "WARNING") {
        this.set("severity", "signalDiagramEventWarn");
      } else if (severity === "CRITICAL") {
        this.set("severity", "signalDiagramEventCritical");
      } else if (severity === "INFORMATION") {
        this.set("severity", "signalDiagramEventInformation");
      }

      let signalTypeId = this.get("signalTypeId");
      let shortForId = this.get("shortForId");
      let elementId = shortForId + "t" + signalTypeId;
      let thisSignalTypeDiv = document.getElementById(elementId);

      if (thisSignalTypeDiv === null) {
        let signalTypeName = thisAlert.signalTypeName;
        let allSignalDiagrams = document.getElementById("allSignalDiagrams");
        let newSignalDiv = document.createElement("div");
        newSignalDiv.setAttribute("id", elementId);
        newSignalDiv.setAttribute("class", "col-12 signalDiagramHimself");
        let newText = document.createTextNode(signalTypeName);
        newSignalDiv.appendChild(newText);
        allSignalDiagrams.appendChild(newSignalDiv);
      }
    },

    didRender() {
      this._super(...arguments);

      this.setPosition();
      this.loadTooltip();
    },

    didUpdateAttrs() {
      this._super(...arguments);

      this.setPosition();
      this.loadTooltip();
    },

    setPosition() {
      let box = document.getElementById("allSignalDiagrams"); // div um alle Diagramme

      let boxOffsetWidth = box.offsetWidth - 160;
      let thisAlert = this.get("thisAlert");
      let signalTypeId = this.get("signalTypeId");
      let shortForId = this.get("shortForId");
      let elementId = shortForId + "t" + signalTypeId;
      let oldestAlertTime = this.get("oldestAlertTime");
      let newestAlertTime = this.get("newestAlertTime");
      let pxPerTime = boxOffsetWidth / (newestAlertTime - oldestAlertTime);
      let alertStart = Math.round(thisAlert.alertSpan.start / 1000000);
      let alertPx = pxPerTime * (newestAlertTime - alertStart);
      let thisSignalTypeDiv = document.getElementById(elementId); //let xPos = 0;

      let yPos = 0;

      while (thisSignalTypeDiv) {
        //xPos += (thisSignalTypeDiv.offsetLeft - thisSignalTypeDiv.scrollLeft + thisSignalTypeDiv.clientLeft);
        yPos += thisSignalTypeDiv.offsetTop - thisSignalTypeDiv.scrollTop + thisSignalTypeDiv.clientTop;
        thisSignalTypeDiv = thisSignalTypeDiv.offsetParent;
      }

      let headerYPos = 0;
      let signalDiagramHeaderDiv = document.getElementById("signalDiagramHeader");

      while (signalDiagramHeaderDiv) {
        headerYPos += signalDiagramHeaderDiv.offsetTop - signalDiagramHeaderDiv.scrollTop + signalDiagramHeaderDiv.clientTop;
        signalDiagramHeaderDiv = signalDiagramHeaderDiv.offsetParent;
      }

      let direction = thisAlert.direction;

      if (direction === "LOW") {
        yPos += 20;
      }

      let offsetLeft = boxOffsetWidth - alertPx + 70;
      this.set("offsetLeft", offsetLeft);
      this.set("offsetTop", yPos - headerYPos);
    },

    loadTooltip() {
      let elementJQ = (0, _jquery.default)("#" + this.elementId);
      (0, _jquery.default)(function () {
        elementJQ.tooltip();
      });
    },

    click() {
      let thisAlert = this.get("thisAlert");
      let visibleAlertState = thisAlert.visibleAlert;
      Ember.set(thisAlert, "visibleAlert", !visibleAlertState);
    },

    willDestroyElement() {
      this._super(...arguments);

      let signalTypeId = this.get("signalTypeId");
      let allSignalTypesObject = this.get("allSignalTypesObject");
      let thisSignalCount = allSignalTypesObject[signalTypeId];
      let newCount = thisSignalCount - 1;
      allSignalTypesObject[signalTypeId] = newCount;
      this.set("allSignalTypesObject", allSignalTypesObject);

      if (newCount === 0) {
        let shortForId = this.get("shortForId");
        let elementId = shortForId + "t" + signalTypeId;
        let diagramBarLine = document.getElementById(elementId);

        if (diagramBarLine) {
          diagramBarLine.remove();
        }
      }
    }

  });

  _exports.default = _default;
});