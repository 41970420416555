define("machine-monitoring/comparison/route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate() {
      window.addEventListener("resize", () => {
        (0, _jquery.default)("#setupComparison").height(window.innerHeight - 68);
        this.controller.send("resizeCanvas");
      });
      Ember.run.later(() => {
        this.controllerFor("comparison").send("firstRunAfterRender");
      }, 100);
    },

    renderTemplate(controller, model) {
      this.render("comparison", {
        into: "application",
        outlet: "content",
        controller: controller,
        model: model
      });
      this.render("comparison-navi", {
        into: "application",
        outlet: "navigation",
        controller: controller,
        model: model
      });
    },

    deactivate() {
      window.removeEventListener("resize", () => {
        (0, _jquery.default)("#setupComparison").height(window.innerHeight - 68);
        this.controller.send("resizeCanvas");
      });
      this.controller.send("lastRunAfterDeaktivate");
    }

  });

  _exports.default = _default;
});