define("machine-monitoring/configuration/newdevice/controller", ["exports", "machine-monitoring/app", "jquery"], function (_exports, _app, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      dismissNewDevice() {
        let newDevice = this.get("newDevice");
        newDevice.deleteRecord();
        this.transitionToRoute("configuration");
      },

      saveNewDevice() {
        let newDevice = this.get("newDevice");

        _jquery.default.post(_app.default.API_URL + "/devices", JSON.stringify(newDevice), blub => {
          newDevice.deleteRecord();
          let newDeviceId = blub.id;
          let newLoadedDevice = this.get("store").findRecord("device", newDeviceId);
          this.transitionToRoute("configuration.device.edit", newLoadedDevice);
        });
      }

    }
  });

  _exports.default = _default;
});