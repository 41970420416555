define("machine-monitoring/models/alerttype", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    alertTypeId: _emberData.default.attr(),
    uuid: _emberData.default.attr(),
    name: _emberData.default.attr(),
    description: _emberData.default.attr(),
    alertProperties: _emberDataModelFragments.default.fragment('alertProperties'),
    relatedSignals: _emberDataModelFragments.default.array()
  });

  _exports.default = _default;
});