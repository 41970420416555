define("machine-monitoring/devices/details/alerts/learning/sensor/controller", ["exports", "machine-monitoring/app", "chartjs", "jquery"], function (_exports, _app, _chartjs, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    socketService: Ember.inject.service("websockets"),
    liveDataRun: false,
    actClusterChart: null,
    sensorId: null,
    options: null,
    allConfigFileNames: null,
    saveConfigFileName: null,
    loadConfigFileName: null,
    initResX: 128,
    initResY: 64,
    initMaxCluster: 128,
    anomalyValue: 0,
    lastAnomalyTimeReadable: 0,
    prevLastClusterId: null,
    prevLastClusterType: null,
    renamingClusterType: null,
    renamingClusterId: null,
    resizeClusterType: null,
    resizeClusterValue: 0,
    showTypeText: "",
    prevTypeSize: 0,
    newClusterName: null,
    acceptedClusters: null,
    anomaliesClusters: null,
    basisClusters: null,
    knownClusters: null,
    acceptedClustersLength: 0,
    anomaliesClustersLength: 0,
    basisClustersLength: 0,
    knownClustersLength: 0,
    wsRun: false,
    showSingleCluster: false,
    typeToClearName: null,
    typeToClearId: null,
    choosenClusterName: null,
    choosenClusterType: null,
    choosenClusterTypeColor: null,
    choosenClusterId: null,
    doEditConfig: false,
    event: null,

    init() {
      this._super(...arguments);

      window.addEventListener("resize", () => {
        this.changeDivContainer();
      });
      this.set("acceptedClusters", []);
      this.set("anomaliesClusters", []);
      this.set("basisClusters", []);
      this.set("knownClusters", []);
    },

    initCanvas() {
      let options = this.get("options");
      let actData = {
        labels: [],
        datasets: [{
          backgroundColor: "rgba(0,255,0,0.6)",
          borderColor: "rgba(0,255,0,1)",
          data: []
        }, {
          backgroundColor: "rgba(127,127,127,1)",
          borderColor: "rgba(127,127,127,1)",
          data: []
        }]
      };
      let actOptions = {
        animation: {
          duration: 0
        },
        responsive: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            stacked: true,
            display: false
          }],
          yAxes: [{
            stacked: false,
            display: false,
            ticks: {
              beginAtZero: true,
              suggestedMax: options.maxTicks
            }
          }]
        }
      };
      let winHei = (0, _jquery.default)(window).height();
      let actualClusterCanvas = document.getElementById("actualClusterCanvas");
      actualClusterCanvas.style.border = "1px solid #1686B0";
      let jqActualClusterCanvas = (0, _jquery.default)("#actualClusterCanvas");
      jqActualClusterCanvas.height(winHei / 3);
      let wrapperDivWidth = (0, _jquery.default)("#actualClusterWrapper").width();
      jqActualClusterCanvas.width(wrapperDivWidth);
      let actClusterContext = actualClusterCanvas.getContext("2d");
      let actClusterNewChart = new _chartjs.default(actClusterContext, {
        type: "bar",
        data: actData,
        options: actOptions
      });
      this.set("actClusterChart", actClusterNewChart);
      Ember.run.later(() => {
        this.changeDivContainer();
      }, 400);
    },

    changeDivContainer() {
      let winHei = (0, _jquery.default)(window).height();
      let learnedClusterHeadline = (0, _jquery.default)("#learnedClusterHeadline");
      let basisDiv = (0, _jquery.default)("#basisDiv");

      if (basisDiv) {
        let spaceForDiv = winHei - (learnedClusterHeadline.outerHeight(true) + basisDiv.offset().top);

        if (spaceForDiv < 240) {
          spaceForDiv = 240;
        }

        (0, _jquery.default)("#basisDivChild").height(spaceForDiv);
        (0, _jquery.default)("#acceptedDivChild").height(spaceForDiv);
        (0, _jquery.default)("#knownDivChild").height(spaceForDiv);
        (0, _jquery.default)("#anomalieDivChild").height(spaceForDiv);
        document.getElementById("basisDivChild").style.maxHeight = spaceForDiv + "px";
        document.getElementById("acceptedDivChild").style.maxHeight = spaceForDiv + "px";
        document.getElementById("knownDivChild").style.maxHeight = spaceForDiv + "px";
        document.getElementById("anomalieDivChild").style.maxHeight = spaceForDiv + "px";
      }
    },

    setupWebsocketConnection() {
      let wsRun = this.get("wsRun");

      if (!wsRun) {
        const socket = this.get("socketService").socketFor(_app.default.WS_URL);
        socket.on("open", this.wsOpenHandler, this);
        socket.on("message", this.wsMessageHandler, this);
        socket.on('error', this.wsErrorHandler, this);
        this.set("socketRef", socket);
        this.set("wsRun", true);
      }
    },

    wsOpenHandler() {
      this.startWebsocketData();
    },

    wsMessageHandler(event) {
      if (event.data === "ping") {
        return;
      }

      let data = _jquery.default.parseJSON(event.data);

      let lastSpectrum = data.last_spectrum;
      let sensorStatus = data.sensor_status;
      let isAcceptedClusters = data.accepted_clusters;
      let thisSensor = this.get("model");
      let modelId = thisSensor.get("id");

      if (sensorStatus) {
        let options = {
          maxTicks: sensorStatus.init_config.resolution_y,
          sensorId: modelId,
          basis_cluster_capacity: sensorStatus.algorithm_config.cluster_capacity,
          other_cluster_capacity: sensorStatus.detection_config.cluster_capacity
        };
        this.set("options", options);
      }

      if (isAcceptedClusters) {
        this.doWithClusters(data);
      }

      if (lastSpectrum) {
        this.doWithLastSpectrum(lastSpectrum, sensorStatus);
      }
    },

    wsErrorHandler() {
      _jquery.default.get(_app.default.API_URL + "/time").fail(function (jqXHR) {
        if (jqXHR.readyState == 4 && jqXHR.status == 401) {
          window.location.replace(_app.default.API_HOST + "/user-service/");
        }
      });
    },

    closeWebSockets() {
      this.stopWebsocketData();
      const socket = this.get("socketRef");

      if (socket) {
        socket.off("open", this.wsOpenHandler);
        socket.off("message", this.wsMessageHandler);
        socket.off("close", this.wsCloseHandler);
      }

      this.set("acceptedClusters", null);
      this.set("anomaliesClusters", null);
      this.set("basisClusters", null);
      this.set("knownClusters", null);
      this.set("wsRun", false);
      this.set("doEditConfig", false);
      Ember.run.later(() => {
        this.get("socketService").closeSocketFor(_app.default.WS_URL);
      }, 100);
    },

    startWebsocketData() {
      let sensorId = this.get("sensorId");

      _jquery.default.post(_app.default.API_ML_URL + "/sensors/" + sensorId + "/last-spectrum/start");
    },

    stopWebsocketData() {
      let sensorId = this.get("sensorId");

      _jquery.default.post(_app.default.API_ML_URL + "/sensors/" + sensorId + "/last-spectrum/stop");
    },

    doWithClusters(data) {
      let acceptedClusters = data.accepted_clusters.cluster;
      let anomaliesClusters = data.anomalies_clusters.cluster;
      let basisClusters = data.basis_clusters.cluster;
      let knownClusters = data.known_anomalies_clusters.cluster;

      if (acceptedClusters.length > 0) {
        let editLength = acceptedClusters.length;
        let oldAcceptedClusters = this.get("acceptedClusters");
        let oldAcceptedClustersLength = 0;

        if (oldAcceptedClusters) {
          oldAcceptedClustersLength = oldAcceptedClusters.length;
        } else {
          oldAcceptedClusters = [];
        }

        this.set("acceptedClusters", []);

        for (let i = 0; i < editLength; i++) {
          let thisCluster = acceptedClusters[i];
          let thisClusterId = thisCluster.id;
          thisCluster.elementId = "cl" + thisClusterId + "acc";
          thisCluster.type = 1;
          let didDone = false;

          for (let k = 0; k < oldAcceptedClustersLength; k++) {
            let thatCluster = oldAcceptedClusters[k];
            let thatClusterId = thatCluster.id;

            if (thatClusterId === thisClusterId) {
              if (thisCluster.values.length > 0) {
                oldAcceptedClusters[k] = thisCluster;
              } else {
                oldAcceptedClusters.splice(k, 1);
                oldAcceptedClustersLength = oldAcceptedClustersLength - 1;
              }

              didDone = true;
            }
          }

          if (didDone === false) {
            oldAcceptedClusters.push(thisCluster);
          }
        }

        this.set("acceptedClusters", oldAcceptedClusters);
        let newOldAcceptedClustersLength = oldAcceptedClusters.length;
        this.set("acceptedClustersLength", newOldAcceptedClustersLength);
      }

      if (anomaliesClusters.length > 0) {
        let editLength = anomaliesClusters.length;
        let oldAnomaliesClusters = this.get("anomaliesClusters");
        let oldAnomaliesClustersLength = 0;

        if (oldAnomaliesClusters) {
          oldAnomaliesClustersLength = oldAnomaliesClusters.length;
        } else {
          oldAnomaliesClusters = [];
        }

        this.set("anomaliesClusters", []);

        for (let i = 0; i < editLength; i++) {
          let thisCluster = anomaliesClusters[i];
          let thisClusterId = thisCluster.id;
          thisCluster.elementId = "cl" + thisClusterId + "ano";
          thisCluster.type = 3;
          let didDone = false;

          for (let k = 0; k < oldAnomaliesClustersLength; k++) {
            let thatCluster = oldAnomaliesClusters[k];
            let thatClusterId = thatCluster.id;

            if (thatClusterId === thisClusterId) {
              if (thisCluster.values.length > 0) {
                oldAnomaliesClusters[k] = thisCluster;
              } else {
                oldAnomaliesClusters.splice(k, 1);
                oldAnomaliesClustersLength = oldAnomaliesClustersLength - 1;
              }

              didDone = true;
            }
          }

          if (didDone === false) {
            oldAnomaliesClusters.push(thisCluster);
          }
        }

        this.set("anomaliesClusters", oldAnomaliesClusters);
        let newOldAnomaliesClustersLength = oldAnomaliesClusters.length;
        this.set("anomaliesClustersLength", newOldAnomaliesClustersLength);
      }

      if (basisClusters.length > 0) {
        let editLength = basisClusters.length;
        let oldBasisClusters = this.get("basisClusters");
        let oldBasisClustersLength = 0;

        if (oldBasisClusters) {
          oldBasisClustersLength = oldBasisClusters.length;
        } else {
          oldBasisClusters = [];
        }

        this.set("basisClusters", []);

        for (let i = 0; i < editLength; i++) {
          let thisCluster = basisClusters[i];
          let thisClusterId = thisCluster.id;
          thisCluster.elementId = "cl" + thisClusterId + "bas";
          thisCluster.type = 0;
          let didDone = false;

          for (let k = 0; k < oldBasisClustersLength; k++) {
            let thatCluster = oldBasisClusters[k];
            let thatClusterId = thatCluster.id;

            if (thatClusterId === thisClusterId) {
              if (thisCluster.values.length > 0) {
                oldBasisClusters[k] = thisCluster;
              } else {
                oldBasisClusters.splice(k, 1);
                oldBasisClustersLength = oldBasisClustersLength - 1;
              }

              didDone = true;
            }
          }

          if (didDone === false) {
            oldBasisClusters.push(thisCluster);
          }
        }

        this.set("basisClusters", oldBasisClusters);
        let newOldBasisClustersLength = oldBasisClusters.length;
        this.set("basisClustersLength", newOldBasisClustersLength);
      }

      if (knownClusters.length > 0) {
        let editLength = knownClusters.length;
        let oldKnownClusters = this.get("knownClusters");
        let oldKnownClustersLength = 0;

        if (oldKnownClusters) {
          oldKnownClustersLength = oldKnownClusters.length;
        } else {
          oldKnownClusters = [];
        }

        this.set("knownClusters", []);

        for (let i = 0; i < editLength; i++) {
          let thisCluster = knownClusters[i];
          let thisClusterId = thisCluster.id;
          thisCluster.elementId = "cl" + thisClusterId + "kno";
          thisCluster.type = 2;
          let didDone = false;

          for (let k = 0; k < oldKnownClustersLength; k++) {
            let thatCluster = oldKnownClusters[k];
            let thatClusterId = thatCluster.id;

            if (thatClusterId === thisClusterId) {
              if (thisCluster.values.length > 0) {
                oldKnownClusters[k] = thisCluster;
              } else {
                oldKnownClusters.splice(k, 1);
                oldKnownClustersLength = oldKnownClustersLength - 1;
              }

              didDone = true;
            }
          }

          if (didDone === false) {
            oldKnownClusters.push(thisCluster);
          }
        }

        this.set("knownClusters", oldKnownClusters);
        let newOldKnownClustersLength = oldKnownClusters.length;
        this.set("knownClustersLength", newOldKnownClustersLength);
      }

      this.changeDivContainer();
    },

    releasePrevCluster(lastType, lastId) {
      switch (lastType) {
        case "BASIS":
          {
            let basisClustersUse = this.get("basisClusters");

            if (basisClustersUse) {
              let basisClustersUseLength = basisClustersUse.length;

              for (let i = 0; i < basisClustersUseLength; i++) {
                let thisCluster = basisClustersUse[i];
                let thisClusterId = thisCluster.id;

                if (thisClusterId === lastId) {
                  thisCluster.selected = false;
                }
              }
            }

            break;
          }

        case "ACCEPTED":
          {
            let acceptedClustersUse = this.get("acceptedClusters");

            if (acceptedClustersUse) {
              let acceptedClustersUseLength = acceptedClustersUse.length;

              for (let i = 0; i < acceptedClustersUseLength; i++) {
                let thisCluster = acceptedClustersUse[i];
                let thisClusterId = thisCluster.id;

                if (thisClusterId === lastId) {
                  thisCluster.selected = false;
                }
              }
            }

            break;
          }

        case "KNOWN_ANOMALIES":
          {
            let knownClustersUse = this.get("knownClusters");

            if (knownClustersUse) {
              let knownClustersLength = knownClustersUse.length;

              for (let i = 0; i < knownClustersLength; i++) {
                let thisCluster = knownClustersUse[i];
                let thisClusterId = thisCluster.id;

                if (thisClusterId === lastId) {
                  thisCluster.selected = false;
                }
              }
            }

            break;
          }

        case "ANOMALIES":
          {
            let anomaliesClustersUse = this.get("anomaliesClusters");

            if (anomaliesClustersUse) {
              let anomaliesClustersUseLength = anomaliesClustersUse.length;

              for (let i = 0; i < anomaliesClustersUseLength; i++) {
                let thisCluster = anomaliesClustersUse[i];
                let thisClusterId = thisCluster.id;

                if (thisClusterId === lastId) {
                  thisCluster.selected = false;
                }
              }
            }

            break;
          }
      }
    },

    doWithLastSpectrum(lastSpectrum, sensorStatus) {
      let prevLastClusterType = this.get("prevLastClusterType");
      let prevLastClusterId = this.get("prevLastClusterId");
      this.releasePrevCluster(prevLastClusterType, prevLastClusterId); // "last_anomaly_timestamp": "1518083769608774567",

      this.set("lastAnomalyTimeReadable", parseInt(sensorStatus.last_anomaly_timestamp));
      let thisSpectrumHitId = lastSpectrum.closest_cluster_id;
      let thisSpectrumHitType = lastSpectrum.closest_cluster_type;
      let actClusterChart = this.get("actClusterChart");
      actClusterChart.data.labels = [];
      actClusterChart.data.datasets[0].data = [];
      let responseLength = lastSpectrum.values.length;

      for (let i = 0; i < responseLength; i++) {
        let thisValue = lastSpectrum.values[i];
        actClusterChart.data.labels.push(i);
        actClusterChart.data.datasets[0].data.push(thisValue);
      }

      actClusterChart.data.datasets[0].backgroundColor = "rgba(0,255,0,0.5)";
      actClusterChart.data.datasets[0].borderColor = "rgba(0,255,0,1)";
      actClusterChart.data.datasets[1].data = [];
      let usedDatas = [];

      if (thisSpectrumHitId >= 0) {
        switch (thisSpectrumHitType) {
          case "BASIS":
            {
              let basisClustersUse = this.get("basisClusters");

              if (basisClustersUse) {
                let basisClustersUseLength = basisClustersUse.length;

                for (let i = 0; i < basisClustersUseLength; i++) {
                  let thisCluster = basisClustersUse[i];
                  let thisClusterId = thisCluster.id;

                  if (thisClusterId === thisSpectrumHitId) {
                    usedDatas = thisCluster;
                    thisCluster.selected = true;
                  }
                }
              }

              break;
            }

          case "ACCEPTED":
            {
              let acceptedClustersUse = this.get("acceptedClusters");

              if (acceptedClustersUse) {
                actClusterChart.data.datasets[0].backgroundColor = "rgba(0,255,255,0.5)";
                actClusterChart.data.datasets[0].borderColor = "rgba(0,255,255,0.5)";
                let acceptedClustersUseLength = acceptedClustersUse.length;

                for (let i = 0; i < acceptedClustersUseLength; i++) {
                  let thisCluster = acceptedClustersUse[i];
                  let thisClusterId = thisCluster.id;

                  if (thisClusterId === thisSpectrumHitId) {
                    usedDatas = thisCluster;
                    thisCluster.selected = true;
                  }
                }
              }

              break;
            }

          case "KNOWN_ANOMALIES":
            {
              let knownClustersUse = this.get("knownClusters");

              if (knownClustersUse) {
                actClusterChart.data.datasets[0].backgroundColor = "rgba(255,0,0,0.5)";
                actClusterChart.data.datasets[0].borderColor = "rgba(255,0,0,0.5)";
                let knownClustersLength = knownClustersUse.length;

                for (let i = 0; i < knownClustersLength; i++) {
                  let thisCluster = knownClustersUse[i];
                  let thisClusterId = thisCluster.id;

                  if (thisClusterId === thisSpectrumHitId) {
                    usedDatas = thisCluster;
                    thisCluster.selected = true;
                  }
                }
              }
            }
            break;

          case "ANOMALIES":
            {
              let anomaliesClustersUse = this.get("anomaliesClusters");

              if (anomaliesClustersUse) {
                actClusterChart.data.datasets[0].backgroundColor = "rgba(255,255,0,0.5)";
                actClusterChart.data.datasets[0].borderColor = "rgba(255,255,0,0.5)";
                let anomaliesClustersUseLength = anomaliesClustersUse.length;

                for (let i = 0; i < anomaliesClustersUseLength; i++) {
                  let thisCluster = anomaliesClustersUse[i];
                  let thisClusterId = thisCluster.id;

                  if (thisClusterId === thisSpectrumHitId) {
                    usedDatas = thisCluster;
                    thisCluster.selected = true;
                  }
                }
              }

              break;
            }

          default:
            break;
        }
      }

      for (let j = 0; j < responseLength; j++) {
        if (usedDatas.values) {
          actClusterChart.data.datasets[1].data.push(usedDatas.values[j]);
        } else {
          actClusterChart.data.datasets[1].data.push(0);
        }
      }

      actClusterChart.update();
      let doEditConfig = this.get("doEditConfig");

      if (!doEditConfig) {
        this.handleSensor(sensorStatus);
      }

      this.set("prevLastClusterId", thisSpectrumHitId);
      this.set("prevLastClusterType", thisSpectrumHitType);
      let anomalyValue = lastSpectrum.anomaly_value;
      this.set("anomalyValue", anomalyValue);
      let toggleDetectingButton = (0, _jquery.default)("#toggleDetectingButton").height();
      let thisColor = "1";
      let backgroundHeight = toggleDetectingButton - 2;
      let thisSensor = this.get("model");
      let anomaly_threshold = thisSensor.get("anomaly_threshold");
      let topMargin = 0;

      if (anomaly_threshold > 0) {
        let thatHeight = Math.round(toggleDetectingButton / 2 / anomaly_threshold * anomalyValue);
        let maxColorTres = anomaly_threshold * 2;
        let colorSteps = 120 / maxColorTres;
        thisColor = 120 - anomalyValue * colorSteps;

        if (thisColor < 1) {
          thisColor = 1;
        } else if (thisColor > 120) {
          thisColor = 120;
        }

        if (thatHeight < backgroundHeight) {
          topMargin = toggleDetectingButton - thatHeight;
          backgroundHeight = thatHeight;
        }
      }

      let backgroundColor = "hsl(" + thisColor + ", 100%, 60%)";
      let anomalyValueBorderDiv = (0, _jquery.default)("#anomalyValueBorderDiv");
      anomalyValueBorderDiv.height(toggleDetectingButton);
      anomalyValueBorderDiv.width(toggleDetectingButton);
      let anomalyValueDiv = (0, _jquery.default)("#anomalyValueDiv");
      anomalyValueDiv.height(backgroundHeight);
      anomalyValueDiv.width(toggleDetectingButton - 2);
      anomalyValueDiv.css("background-color", backgroundColor);
      anomalyValueDiv.css("margin-top", topMargin);
    },

    handleSensor(sensorStatus) {
      let thisSensor = this.get("model");
      let isInitialized = thisSensor.get("is_initialized");
      let newIsInitialized = sensorStatus.is_initialized;

      if (newIsInitialized === undefined) {
        newIsInitialized = false;
      }

      if (isInitialized !== newIsInitialized) {
        if (newIsInitialized !== true) {
          newIsInitialized = false;
        }

        thisSensor.set("is_initialized", newIsInitialized);
      }

      let isDetecting = thisSensor.get("is_detecting");
      let newIsDetecting = sensorStatus.is_detecting;

      if (newIsDetecting === undefined) {
        newIsDetecting = false;
      }

      if (isDetecting !== newIsDetecting) {
        if (newIsDetecting !== true) {
          newIsDetecting = false;
        }

        thisSensor.set("is_detecting", newIsDetecting);
      }

      let isLearning = thisSensor.get("is_learning");
      let newIsLearning = sensorStatus.is_learning;

      if (newIsLearning === undefined) {
        newIsLearning = false;
      }

      if (isLearning !== newIsLearning) {
        if (newIsLearning !== true) {
          newIsLearning = false;
        }

        thisSensor.set("is_learning", newIsLearning);
      }

      let useMinMax = thisSensor.get("use_min_max");
      let newUseMinMax = sensorStatus.algorithm_config.use_min_max;

      if (newUseMinMax === undefined) {
        newUseMinMax = false;
      }

      if (useMinMax !== newUseMinMax) {
        if (newUseMinMax !== true) {
          newUseMinMax = false;
        }

        thisSensor.set("use_min_max", newUseMinMax);
      }

      let useMaxClusters = thisSensor.get("max_cluster");
      let newMaxClusters = sensorStatus.init_config.max_cluster;

      if (newMaxClusters === undefined) {
        newMaxClusters = 0;
      }

      if (useMaxClusters !== newMaxClusters) {
        thisSensor.set("max_cluster", newMaxClusters);
      }

      let useFiltering = thisSensor.get("use_filtering");
      let newUseFiltering = sensorStatus.algorithm_config.use_filtering;

      if (newUseFiltering === undefined) {
        newUseFiltering = false;
      }

      if (useFiltering !== newUseFiltering) {
        if (newUseFiltering !== true) {
          newUseFiltering = false;
        }

        thisSensor.set("use_filtering", newUseFiltering);
      }

      let useHullCurve = thisSensor.get("use_hull_curve");
      let newUseHullCurve = sensorStatus.algorithm_config.use_hull_curve;

      if (newUseHullCurve === undefined) {
        newUseHullCurve = false;
      }

      if (useHullCurve !== newUseHullCurve) {
        if (newUseHullCurve !== true) {
          newUseHullCurve = false;
        }

        thisSensor.set("use_hull_curve", newUseHullCurve);
      }

      let scale = thisSensor.get("scale");
      let newScale = sensorStatus.algorithm_config.scale;

      if (newScale === undefined) {
        newScale = 0;
      }

      if (scale !== newScale) {
        thisSensor.set("scale", newScale);
      }

      let maxDistance = thisSensor.get("max_distance");
      let newMaxDistance = sensorStatus.algorithm_config.max_distance;

      if (newMaxDistance === undefined) {
        newMaxDistance = 0;
      }

      if (maxDistance !== newMaxDistance) {
        thisSensor.set("max_distance", newMaxDistance);
      }

      let maxDistanceSum = thisSensor.get("max_distance_sum");
      let newMaxDistanceSum = sensorStatus.algorithm_config.max_distance_sum;

      if (newMaxDistanceSum === undefined) {
        newMaxDistanceSum = 0;
      }

      if (maxDistanceSum !== newMaxDistanceSum) {
        thisSensor.set("max_distance_sum", newMaxDistanceSum);
      }

      let clusterCapacity = thisSensor.get("cluster_capacity");
      let newClusterCapacity = sensorStatus.algorithm_config.cluster_capacity;

      if (newClusterCapacity === undefined) {
        newClusterCapacity = 0;
      }

      if (clusterCapacity !== newClusterCapacity) {
        thisSensor.set("cluster_capacity", newClusterCapacity);
      }

      let anomalyThreshold = thisSensor.get("anomaly_threshold");
      let newAnomalyThreshold = sensorStatus.anomaly_capture_config.anomaly_threshold;

      if (newAnomalyThreshold === undefined) {
        newAnomalyThreshold = 0;
      }

      if (anomalyThreshold !== newAnomalyThreshold) {
        thisSensor.set("anomaly_threshold", newAnomalyThreshold);
      }

      let maxAcceptedClusters = thisSensor.get("max_accepted_clusters");
      let newMaxAcceptedClusters = sensorStatus.anomaly_capture_config.max_accepted_clusters;

      if (newMaxAcceptedClusters === undefined) {
        newMaxAcceptedClusters = 0;
      }

      if (maxAcceptedClusters !== newMaxAcceptedClusters) {
        thisSensor.set("max_accepted_clusters", newMaxAcceptedClusters);
      }

      let maxAnomalyClusters = thisSensor.get("max_anomaly_clusters");
      let newMaxAnomalyClusters = sensorStatus.anomaly_capture_config.max_anomaly_clusters;

      if (newMaxAnomalyClusters === undefined) {
        newMaxAnomalyClusters = 0;
      }

      if (maxAnomalyClusters !== newMaxAnomalyClusters) {
        thisSensor.set("max_anomaly_clusters", newMaxAnomalyClusters);
      }

      let maxKnownClusters = thisSensor.get("max_known_anomaly_clusters");
      let newMaxKnownClusters = sensorStatus.anomaly_capture_config.max_known_anomaly_clusters;

      if (newMaxKnownClusters === undefined) {
        newMaxKnownClusters = 0;
      }

      if (maxKnownClusters !== newMaxKnownClusters) {
        thisSensor.set("max_known_anomaly_clusters", newMaxKnownClusters);
      }
    },

    startLoadConfigModal() {
      let sensorId = this.get("sensorId");

      _jquery.default.get(_app.default.API_ML_URL + "/sensors/" + sensorId + "/persistence").then(responseData => {
        this.set("allConfigFileNames", responseData.names);
      });

      (0, _jquery.default)("#modalLoadConfig").modal("show");
    },

    resetAllLocals() {
      this.set("allConfigFileNames", null);
      this.set("saveConfigFileName", null);
      this.set("loadConfigFileName", null);
      this.set("anomalyValue", 0);
      this.set("lastAnomalyTimeReadable", 0);
      this.set("prevLastClusterId", null);
      this.set("prevLastClusterType", null);
      this.set("renamingClusterType", null);
      this.set("renamingClusterId", null);
      this.set("newClusterName", "");
      this.set("acceptedClustersLength", 0);
      this.set("anomaliesClustersLength", 0);
      this.set("basisClustersLength", 0);
      this.set("knownClustersLength", 0);
      this.set("doEditConfig", false);
      this.set("event", null);
      this.set("acceptedClusters", []);
      this.set("anomaliesClusters", []);
      this.set("basisClusters", []);
      this.set("knownClusters", []);
      this.set("wsRun", false);
    },

    actions: {
      drop(src, ev) {
        let model = this.get("model");
        let clusterType = parseInt(ev.dataTransfer.getData("type"));
        let formatSrc = parseInt(src);
        let maxAccepted = parseInt(model.get("max_accepted_clusters"));
        let maxAnomaly = parseInt(model.get("max_anomaly_clusters"));
        let maxKnown = parseInt(model.get("max_known_anomaly_clusters"));
        let acceptedClustersLength = this.get("acceptedClustersLength");
        let anomaliesClustersLength = this.get("anomaliesClustersLength");
        let knownClustersLength = this.get("knownClustersLength");
        let i = false;

        if (acceptedClustersLength < maxAccepted || anomaliesClustersLength < maxAnomaly || knownClustersLength < maxKnown) {
          i = true;
        }

        if (clusterType !== 0 && formatSrc !== clusterType && i) {
          let clusterId = ev.dataTransfer.getData("id");
          let sensorId = this.get("sensorId");
          let jsonToSend = {
            src_cluster_type: clusterType,
            dst_cluster_type: formatSrc,
            sensor_id: parseInt(sensorId),
            cluster_id: parseInt(clusterId)
          };

          _jquery.default.post(_app.default.API_ML_URL + "/sensors/" + sensorId + "/clusters/change", JSON.stringify(jsonToSend));
        }
      },

      allowDrop(ev) {
        ev.preventDefault();
      },

      firstRunAfterRender() {
        let thisSensor = this.get("model");
        let modelId = thisSensor.get("id");
        let options = {
          maxTicks: thisSensor.get("resolution_y"),
          sensorId: modelId,
          cluster_capacity: thisSensor.get("cluster_capacity")
        };
        this.set("options", options);
        this.set("sensorId", modelId);
        this.set("doEditConfig", false);
        this.initCanvas();

        if (thisSensor.doNext === 1) {
          (0, _jquery.default)("#modalInitSensor").modal("show");
        } else if (thisSensor.doNext === 8) {
          this.setupWebsocketConnection();
        }
      },

      abortInitSensor() {
        (0, _jquery.default)("#modalInitSensor").modal("hide");
      },

      loadConfig() {
        (0, _jquery.default)("#modalInitSensor").modal("hide");
        this.startLoadConfigModal();
      },

      reInitAction() {
        let model = this.get("model");
        let oldResX = model.get("resolution_x");
        let oldResY = model.get("resolution_y");
        let oldMaxC = model.get("max_cluster");
        this.set("initResX", oldResX);
        this.set("initResY", oldResY);
        this.set("initMaxCluster", oldMaxC);
        (0, _jquery.default)("#modalInitSensor").modal("show");
      },

      finishInitSensor() {
        let model = this.get("model");
        let modelId = model.get("id");
        model.set("is_initialized", true);
        (0, _jquery.default)("#modalInitSensor").modal("hide");
        let initResX = this.get("initResX");
        let initResY = this.get("initResY");
        let initMaxCluster = this.get("initMaxCluster");
        let jsonToSend = {
          sensor_id: parseInt(modelId),
          resolution_x: parseInt(initResX),
          resolution_y: parseInt(initResY),
          max_cluster: parseInt(initMaxCluster)
        };

        _jquery.default.post(_app.default.API_ML_URL + "/sensors/" + modelId + "/create", JSON.stringify(jsonToSend), () => {
          this.setupWebsocketConnection();
          this.set("initResX", 128);
          this.set("initResY", 64);
          this.set("initMaxCluster", 128);
        });
      },

      toggleDetecting() {
        let thisSensor = this.get("model");
        let modelId = thisSensor.get("id");
        let signalProviderUrl = _app.default.API_ML_URL + "/sensors/" + modelId;
        let is_detecting = thisSensor.get("is_detecting");
        let is_learning = thisSensor.get("is_learning");

        if (is_detecting) {
          let postUrl = signalProviderUrl + "/detection/stop";
          thisSensor.set("is_detecting", !is_detecting);

          _jquery.default.post(postUrl, JSON.stringify({}));
        } else {
          if (is_learning) {
            let postLearnUrl = signalProviderUrl + "/learning/stop";
            thisSensor.set("is_learning", !is_learning);

            _jquery.default.post(postLearnUrl, JSON.stringify({}));
          }

          let postUrl = signalProviderUrl + "/detection/start";
          thisSensor.set("is_detecting", !is_detecting);

          _jquery.default.post(postUrl, JSON.stringify({}));
        }
      },

      toggleLearning() {
        let thisSensor = this.get("model");
        let modelId = thisSensor.get("id");
        let signalProviderUrl = _app.default.API_ML_URL + "/sensors/" + modelId;
        let is_learning = thisSensor.get("is_learning");
        let is_detecting = thisSensor.get("is_detecting");

        if (is_learning) {
          let postUrl = signalProviderUrl + "/learning/stop";
          thisSensor.set("is_learning", !is_learning);

          _jquery.default.post(postUrl, JSON.stringify({}));
        } else {
          if (is_detecting) {
            let postDetUrl = signalProviderUrl + "/detection/stop";
            thisSensor.set("is_detecting", !is_detecting);

            _jquery.default.post(postDetUrl, JSON.stringify({}));
          }

          let postUrl = signalProviderUrl + "/learning/start";
          thisSensor.set("is_learning", !is_learning);

          _jquery.default.post(postUrl, JSON.stringify({}));
        }
      },

      resetLearning() {
        let thisSensor = this.get("model");
        let sensorId = this.get("sensorId");

        _jquery.default.post(_app.default.API_ML_URL + "/sensors/" + sensorId + "/learning/reset", JSON.stringify({}), () => {
          thisSensor.set("is_learning", false);
        });
      },

      dreamAction() {
        let sensorId = this.get("sensorId");

        _jquery.default.post(_app.default.API_ML_URL + "/sensors/" + sensorId + "/dream", JSON.stringify({}));
      },

      loadAction() {
        this.startLoadConfigModal();
      },

      chooseThisFile(thisFileName) {
        this.set("loadConfigFileName", thisFileName);
      },

      abortLoadConfig() {
        (0, _jquery.default)("#modalLoadConfig").modal("hide");
        this.set("loadConfigFileName", "");
      },

      finishLoadConfig() {
        let loadConfigFileName = this.get("loadConfigFileName");
        let sensorId = this.get("sensorId");
        let jsonToSend = {
          sensor_id: parseInt(sensorId),
          name: loadConfigFileName
        };

        _jquery.default.post(_app.default.API_ML_URL + "/sensors/" + sensorId + "/persistence/load", JSON.stringify(jsonToSend), () => {
          this.closeWebSockets();
          this.resetAllLocals();
          this.set("loadConfigFileName", "");
          Ember.run.later(() => {
            this.setupWebsocketConnection();
            this.send("refreshModel");
          }, 1000);
        });

        (0, _jquery.default)("#modalLoadConfig").modal("hide");
      },

      saveAction() {
        (0, _jquery.default)("#modalSaveConfig").modal("show");
      },

      abortSaveConfig() {
        (0, _jquery.default)("#modalSaveConfig").modal("hide");
        this.set("saveConfigFileName", "");
      },

      finishSaveConfig() {
        let saveConfigFileName = this.get("saveConfigFileName");
        let sensorId = this.get("sensorId");
        let jsonToSend = {
          sensor_id: parseInt(sensorId),
          name: saveConfigFileName
        };

        _jquery.default.post(_app.default.API_ML_URL + "/sensors/" + sensorId + "/persistence/save", JSON.stringify(jsonToSend), () => {
          this.set("saveConfigFileName", "");
        });

        (0, _jquery.default)("#modalSaveConfig").modal("hide");
      },

      configAction() {
        this.set("doEditConfig", true);
        (0, _jquery.default)("#modalEditConfig").modal("show");
      },

      abortEditConfig() {
        this.get("model").rollbackAttributes();
        (0, _jquery.default)("#modalEditConfig").modal("hide");
        this.set("doEditConfig", false);
      },

      finishEditConfig() {
        let model = this.get("model");
        let modelId = model.get("id");
        let modelIdInt = parseInt(modelId);
        let signalProviderUrl = _app.default.API_ML_URL + "/sensors/" + modelId;
        let algorithmJson = {
          sensor_id: modelIdInt,
          scale: parseInt(model.get("alg_scale")),
          max_distance: parseInt(model.get("alg_max_distance")),
          max_distance_sum: parseInt(model.get("alg_max_distance_sum")),
          cluster_capacity: parseInt(model.get("alg_cluster_capacity")),
          use_min_max: model.get("alg_use_min_max"),
          use_filtering: model.get("alg_use_filtering"),
          use_hull_curve: model.get("alg_use_hull_curve")
        };
        let detectionJson = {
          sensor_id: modelIdInt,
          scale: parseInt(model.get("det_scale")),
          max_distance: parseInt(model.get("det_max_distance")),
          max_distance_sum: parseInt(model.get("det_max_distance_sum")),
          cluster_capacity: parseInt(model.get("det_cluster_capacity")),
          use_min_max: model.get("det_use_min_max"),
          use_filtering: model.get("det_use_filtering"),
          use_hull_curve: model.get("det_use_hull_curve")
        };
        let anomalyJson = {
          sensor_id: modelIdInt,
          anomaly_threshold: parseInt(model.get("anomaly_threshold")),
          max_anomaly_clusters: parseInt(model.get("max_anomaly_clusters")),
          max_accepted_clusters: parseInt(model.get("max_accepted_clusters")),
          max_known_anomaly_clusters: parseInt(model.get("max_known_anomaly_clusters"))
        };

        _jquery.default.post(signalProviderUrl + "/configure/algorithm", JSON.stringify(algorithmJson), () => {
          _jquery.default.post(signalProviderUrl + "/configure/anomaly", JSON.stringify(anomalyJson), () => {
            _jquery.default.post(signalProviderUrl + "/configure/detection", JSON.stringify(detectionJson), () => {
              this.send("refreshModel");
              this.set("doEditConfig", false);
            });
          });
        });

        (0, _jquery.default)("#modalEditConfig").modal("hide");
      },

      renameCluster(clusterType, clusterId, clusterName) {
        this.set("renamingClusterType", clusterType);
        this.set("renamingClusterId", clusterId);

        if (clusterName) {
          this.set("newClusterName", clusterName);
        } else {
          this.set("newClusterName", "");
        }

        (0, _jquery.default)("#modalInputClusterName").modal("show");
      },

      enlargeCluster(cluster) {
        let showSingleCluster = this.get("showSingleCluster");

        if (showSingleCluster) {
          let choClusterNewChart = this.get("choClusterNewChart");
          choClusterNewChart.clear();
          (0, _jquery.default)("#choosenClusterCanvas").remove();
          (0, _jquery.default)("#chartModalDarkWrapper").prepend('<canvas id="choosenClusterCanvas" height="600"></canvas>');
          let lightDiv = document.getElementById("chartModalLightWrapper");
          lightDiv.style.visibility = "hidden";
          let darkDiv = document.getElementById("chartModalDarkWrapper");
          darkDiv.style.visibility = "hidden";
          this.set("showSingleCluster", false);
        } else {
          let thatName = null;

          if (cluster.name) {
            thatName = cluster.name;
          }

          this.set("choosenClusterName", thatName);
          let thatTypeName = "Learned";
          let thatTypeColor = Ember.String.htmlSafe("color:#00ff00");

          switch (cluster.type) {
            case 1:
              thatTypeName = "Accepted";
              thatTypeColor = Ember.String.htmlSafe("color:#00ffff");
              break;

            case 2:
              thatTypeName = "Known Failures";
              thatTypeColor = Ember.String.htmlSafe("color:#ff0000");
              break;

            case 3:
              thatTypeName = "Anomalies";
              thatTypeColor = Ember.String.htmlSafe("color:#ffff00");
              break;
          }

          this.set("choosenClusterType", thatTypeName);
          this.set("choosenClusterTypeColor", thatTypeColor);
          this.set("choosenClusterId", cluster.id);
          let winHei = (0, _jquery.default)(window).height();
          let winWid = (0, _jquery.default)(window).width();
          let thisDarkDiv = document.getElementById("chartModalDarkWrapper");
          thisDarkDiv.style.visibility = "visible";
          thisDarkDiv.style.width = winWid - 38 + "px";
          let thisLightDiv = document.getElementById("chartModalLightWrapper");
          thisLightDiv.style.visibility = "visible";
          thisLightDiv.style.height = winHei + "px";
          thisLightDiv.style.width = winWid + "px";
          let choosenClusterCanvas = document.getElementById("choosenClusterCanvas");
          choosenClusterCanvas.setAttribute("width", winWid - 40);
          choosenClusterCanvas.setAttribute("height", "600");
          let choosenClusterContext = choosenClusterCanvas.getContext("2d");
          let actClusterNewChart = this.get("actClusterChart");
          let chartOptions = actClusterNewChart.options;
          let choData = {
            labels: [],
            datasets: [{
              backgroundColor: "rgba(0,255,0,1)",
              borderColor: "rgba(0,255,0,1)",
              data: []
            }]
          };

          for (let i = 0; i < cluster.values.length; i++) {
            choData.labels.push(i);
            choData.datasets[0].data.push(cluster.values[i]);
          }

          let choClusterNewChart = new _chartjs.default(choosenClusterContext, {
            type: "bar",
            data: choData,
            options: chartOptions
          });
          this.set("choClusterNewChart", choClusterNewChart);
          this.set("showSingleCluster", true);
        }
      },

      abortSaveNewName() {
        this.set("renamingClusterType", null);
        this.set("renamingClusterId", null);
        this.set("newClusterName", "");
        (0, _jquery.default)("#modalInputClusterName").modal("hide");
      },

      finishSaveNewName() {
        let newClusterName = this.get("newClusterName");
        let clusterType = this.get("renamingClusterType");
        let clusterId = this.get("renamingClusterId");
        let sensorId = this.get("sensorId");
        let jsonToSend = {
          sensor_id: parseInt(sensorId),
          cluster_type: parseInt(clusterType),
          cluster_id: parseInt(clusterId),
          name: newClusterName
        };

        _jquery.default.post(_app.default.API_ML_URL + "/sensors/" + sensorId + "/clusters/name", JSON.stringify(jsonToSend), () => {
          this.set("renamingClusterType", "");
          this.set("renamingClusterId", "");
          this.set("newClusterName", "");
        });

        (0, _jquery.default)("#modalInputClusterName").modal("hide");
      },

      clearAll(thisType) {
        let thatTypeName = "Learned";

        switch (thisType) {
          case "1":
            thatTypeName = "Accepted";
            break;

          case "2":
            thatTypeName = "Known Failures";
            break;

          case "3":
            thatTypeName = "Anomalies";
            break;
        }

        this.set("typeToClearName", thatTypeName);
        this.set("typeToClearId", thisType);
        (0, _jquery.default)("#modalClearThisType").modal("show"); // typeToClear
      },

      abortClearType() {
        this.set("typeToClearName", "");
        this.set("typeToClearId", "");
        (0, _jquery.default)("#modalClearThisType").modal("hide");
      },

      finishClearType() {
        let thisType = this.get("typeToClearId");
        let sensorId = this.get("sensorId");
        let jsonToSend = {
          sensor_id: parseInt(sensorId),
          cluster_type: parseInt(thisType)
        };

        _jquery.default.post(_app.default.API_ML_URL + "/sensors/" + sensorId + "/clusters/clear", JSON.stringify(jsonToSend), () => {
          this.set("typeToClearName", "");
          this.set("typeToClearId", "");
        });

        (0, _jquery.default)("#modalClearThisType").modal("hide");
      },

      resizeTypeModal(clusterType) {
        this.set("resizeClusterType", clusterType);
        let thisTypeSizeUsed = 0;
        let prevTypeSize = 0;
        let showTypeText = "";

        switch (clusterType) {
          case "0":
            thisTypeSizeUsed = this.get("basisClustersLength");
            prevTypeSize = this.get("model.max_cluster");
            showTypeText = "Learned";
            break;

          case "1":
            thisTypeSizeUsed = this.get("acceptedClustersLength");
            prevTypeSize = this.get("model.max_accepted_clusters");
            showTypeText = "Accepted";
            break;

          case "2":
            thisTypeSizeUsed = this.get("knownClustersLength");
            prevTypeSize = this.get("model.max_known_anomaly_clusters");
            showTypeText = "Known Failures";
            break;

          case "3":
            thisTypeSizeUsed = this.get("anomaliesClustersLength");
            prevTypeSize = this.get("model.max_anomaly_clusters");
            showTypeText = "Anomalies";
            break;
        }

        this.set("resizeClusterValue", prevTypeSize);
        this.set("showTypeText", showTypeText);
        this.set("thisTypeSizeUsed", thisTypeSizeUsed);
        (0, _jquery.default)("#modalResizeClusterType").modal("show");
      },

      finishResizeClusterType() {
        let thisTypeSizeUsed = this.get("thisTypeSizeUsed");
        let resizeClusterValue = this.get("resizeClusterValue");

        if (resizeClusterValue < thisTypeSizeUsed) {
          (0, _jquery.default)("#modalResizeClusterType").modal("hide");
          (0, _jquery.default)("#modalResizeClusterTypeError").modal("show"); // abort save
        } else {
          let sensorId = this.get("sensorId");
          let clusterType = this.get("resizeClusterType");
          let jsonToSend = {
            sensor_id: parseInt(sensorId),
            cluster_type: parseInt(clusterType),
            value: parseInt(resizeClusterValue)
          };

          _jquery.default.post(_app.default.API_ML_URL + "/sensors/" + sensorId + "/clusters/resize", JSON.stringify(jsonToSend), () => {
            this.set("resizeClusterValue", 0);
            this.set("showTypeText", "");
            this.set("thisTypeSizeUsed", null);
            this.set("resizeClusterType", null);
            this.set("prevTypeSize", 0);
          });

          (0, _jquery.default)("#modalResizeClusterType").modal("hide");
        }
      },

      abortResizeClusterType() {
        this.set("resizeClusterValue", 0);
        this.set("showTypeText", "");
        this.set("thisTypeSizeUsed", null);
        this.set("resizeClusterType", null);
        this.set("prevTypeSize", 0);
        (0, _jquery.default)("#modalResizeClusterType").modal("hide");
      },

      checkResizeError() {
        (0, _jquery.default)("#modalResizeClusterTypeError").modal("hide");
        (0, _jquery.default)("#modalResizeClusterType").modal("show");
      },

      leaveRoute() {
        this.closeWebSockets();
        this.resetAllLocals();
      }

    }
  });

  _exports.default = _default;
});