define("machine-monitoring/templates/config-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uTWSJ0E+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\",true],[10,\"class\",\"nav-item\"],[8],[7,\"p\",true],[10,\"class\",\"navbar-text mb-0\"],[8],[1,[28,\"fa-icon\",[\"chevron-right\"],null],false],[9],[9],[0,\"\\n\"],[7,\"li\",true],[11,\"class\",[29,[\"nav-item \",[28,\"if\",[[24,[\"routeActiveConfigDevice\"]],\"active\"],null]]]],[8],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",\"nav-link\"],[3,\"action\",[[23,0,[]],\"configDeviceModeAction\"]],[8],[1,[28,\"t\",[\"devices\"],null],false],[9],[9],[0,\"\\n\"],[7,\"li\",true],[11,\"class\",[29,[\"nav-item \",[28,\"if\",[[24,[\"routeActiveConfigCustomer\"]],\"active\"],null]]]],[8],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",\"nav-link\"],[3,\"action\",[[23,0,[]],\"configCustomerModeAction\"]],[8],[1,[28,\"t\",[\"customers\"],null],false],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/templates/config-navigation.hbs"
    }
  });

  _exports.default = _default;
});