define("machine-monitoring/models/record", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    name: _emberData.default.attr(),
    start: _emberData.default.attr(),
    end: _emberData.default.attr(),
    signalTypes: _emberDataModelFragments.default.array(),
    deviceId: _emberData.default.attr(),
    session: _emberData.default.attr(),
    xy: _emberData.default.attr(),
    type: _emberData.default.attr(),
    selected: false,
    UTCString: Ember.computed("start", function () {
      let startTime = this.get("start") / 1000000;
      let d = new Date(startTime);
      return d.toUTCString();
    }),
    dateString: Ember.computed("start", function () {
      let startTime = this.get("start") / 1000000;
      let d = new Date(startTime);
      let getDate = d.getDate();
      let getMonth = d.getMonth() + 1;
      return getDate + "." + getMonth + ".";
    }),
    timeString: Ember.computed("start", function () {
      let startTime = this.get("start") / 1000000;
      let d = new Date(startTime);
      let getHours = d.getHours();
      let getMinutes = d.getMinutes();
      let getSeconds = d.getSeconds();

      if (getSeconds < 10) {
        getSeconds = "0" + getSeconds;
      }

      if (getMinutes < 10) {
        getMinutes = "0" + getMinutes;
      }

      return getHours + ":" + getMinutes + ":" + getSeconds;
    })
  });

  _exports.default = _default;
});