define("machine-monitoring/helpers/timestamp-to-readable-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timestampToReadableComplete = timestampToReadableComplete;
  _exports.default = void 0;

  function timestampToReadableComplete(params) {
    let [arg1] = params;
    return arg1.toUTCString();
  }

  var _default = Ember.Helper.helper(timestampToReadableComplete);

  _exports.default = _default;
});