define("machine-monitoring/templates/comparison-navi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kiyACKLT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\",true],[10,\"class\",\"nav-item\"],[8],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"navbar-text mb-0\"],[8],[1,[28,\"fa-icon\",[\"chevron-right\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"li\",true],[10,\"class\",\"nav-item\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-outline-primary navbar-btn ml-2\"],[3,\"action\",[[23,0,[]],\"toggleSetup\"]],[8],[1,[28,\"t\",[\"setup\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"modelLoaded\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\",true],[10,\"class\",\"nav-item\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-outline-primary navbar-btn ml-2\"],[3,\"action\",[[23,0,[]],\"toggleInfoPanel\"]],[8],[1,[28,\"t\",[\"infos\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"li\",true],[10,\"class\",\"nav-item\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-outline-primary navbar-btn ml-2\"],[3,\"action\",[[23,0,[]],\"toggleOptionPanel\"]],[8],[1,[28,\"t\",[\"options\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/templates/comparison-navi.hbs"
    }
  });

  _exports.default = _default;
});