define("machine-monitoring/devices/details/anomalie/details/controller", ["exports", "machine-monitoring/app", "jquery"], function (_exports, _app, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    pairstate: null,
    isLoading: false,
    errorText: null,
    imageToShow: null,
    nLearnable: Ember.computed("pairstate", function () {
      return !(this.get('pairstate') == "learning" || this.get('pairstate') == "lining" || this.get('pairstate') == "initialized");
    }),
    learning: Ember.computed("pairstate", function () {
      return this.get('pairstate') == "learning";
    }),
    nLineable: Ember.computed("pairstate", function () {
      return !(this.get('pairstate') == "learning" || this.get('pairstate') == "lining");
    }),
    lining: Ember.computed("pairstate", function () {
      return this.get('pairstate') == "lining";
    }),
    nDetectable: Ember.computed("pairstate", function () {
      return !(this.get('pairstate') == "initialized" || this.get('pairstate') == "detecting");
    }),
    detecting: Ember.computed("pairstate", function () {
      return this.get('pairstate') == "detecting";
    }),
    nDownloadable: Ember.computed("pairstate", function () {
      return !(this.get('pairstate') == "initialized");
    }),
    nSaveable: Ember.computed("pairstate", function () {
      return !(this.get('pairstate') == "initialized");
    }),
    distanceNCalcable: Ember.computed("pairstate", function () {
      return !(this.get('pairstate') == "initialized");
    }),
    actions: {
      runAfterRender() {
        let state = this.get('thatXYPair.state');

        if (state == null) {
          state = 'initialized';
        }

        this.set('pairstate', state);
      },

      actLearning() {
        let pairstate = this.get('pairstate');
        let thatXYPair = this.get('thatXYPair');
        let url = _app.default.API_URL + "/pf/diagram/" + thatXYPair.deviceId + "/" + thatXYPair.xSensorID + "/" + thatXYPair.ySensorID;
        let newState = "";

        if (pairstate == "learning") {
          url = url + "/stop-learning";
          newState = 'initialized';
        } else {
          url = url + "/start-learning";
          newState = 'learning';
        }

        _jquery.default.ajax({
          method: "POST",
          url: url
        }).done(() => {
          Ember.set(thatXYPair, 'state', newState);
          this.set('pairstate', newState);
        });
      },

      actLine() {
        let pairstate = this.get('pairstate');
        let thatXYPair = this.get('thatXYPair');
        let url = _app.default.API_URL + "/pf/diagram/" + thatXYPair.deviceId + "/" + thatXYPair.xSensorID + "/" + thatXYPair.ySensorID;
        let newState = "";

        if (pairstate == "lining") {
          url = url + "/end-line";
          newState = 'learning';
        } else {
          url = url + "/start-line";
          newState = 'lining';
        }

        _jquery.default.ajax({
          method: "POST",
          url: url
        }).done(() => {
          this.set('pairstate', newState);
        });
      },

      calcDistance() {
        let thatXYPair = this.get('thatXYPair');
        let url = _app.default.API_URL + "/pf/diagram/" + thatXYPair.deviceId + "/" + thatXYPair.xSensorID + "/" + thatXYPair.ySensorID + "/calc-distance";
        this.set('isLoading', true);

        _jquery.default.ajax({
          method: "POST",
          url: url
        }).done(() => {
          this.set('isLoading', false);
        });
      },

      dismissCreating() {
        this.transitionToRoute("devices.details.anomalie", _app.default.chosenDeviceId);
      },

      saveCreating() {
        let thatXYPair = this.get('thatXYPair');
        let profileName = this.get('profileName');
        this.set('thatXYPair.name', profileName);
        let toSend = JSON.stringify({
          fileName: profileName
        });
        let url = _app.default.API_URL + "/pf/diagram/" + thatXYPair.deviceId + "/" + thatXYPair.xSensorID + "/" + thatXYPair.ySensorID + "/save-profile";

        _jquery.default.ajax({
          method: "POST",
          url: url,
          contentType: "application/json; charset=utf-8",
          data: toSend
        }).done(() => {
          Ember.set(thatXYPair, 'state', 'initialized');
          this.set('thatXYPair.saved', true);
        });
      },

      showImage() {
        let thatXYPair = this.get('thatXYPair');
        let url = _app.default.API_URL + "/pf/diagram/" + thatXYPair.deviceId + "/" + thatXYPair.xSensorID + "/" + thatXYPair.ySensorID + "/image";

        _jquery.default.ajax({
          method: "GET",
          url: url
        }).fail(data => {
          this.set('errorText', data.responseJSON.error);
        }).done(() => {
          this.set('errorText', null);
          this.set('imageToShow', url);
        });

        (0, _jquery.default)("#showAnomalieImage").modal("show");
      },

      actDetecting() {
        let thatXYPair = this.get('thatXYPair');
        let pairstate = this.get('pairstate');
        let url = _app.default.API_URL + "/pf/diagram/" + thatXYPair.deviceId + "/" + thatXYPair.xSensorID + "/" + thatXYPair.ySensorID;
        let newState = "";

        if (pairstate == "detecting") {
          url = url + "/stop-detection";
          newState = 'initialized';
        } else {
          url = url + "/start-detection";
          newState = 'detecting';
        }

        _jquery.default.ajax({
          method: "POST",
          url: url
        }).done(() => {
          Ember.set(thatXYPair, 'state', newState);
          this.set('pairstate', newState);
        });
      },

      resetLearning() {
        (0, _jquery.default)("#reallyResetLearning").modal("show");
      },

      reallyResetLearning() {
        (0, _jquery.default)("#reallyResetLearning").modal("hide");
        let thatXYPair = this.get('thatXYPair');
        let url = _app.default.API_URL + "/pf/diagram/" + thatXYPair.deviceId + "/" + thatXYPair.xSensorID + "/" + thatXYPair.ySensorID + "/reset-learning";

        _jquery.default.ajax({
          method: "POST",
          url: url
        }).done(() => {
          Ember.set(thatXYPair, 'state', 'initialized');
          this.set('pairstate', 'initialized');
        });
      },

      deletePair() {
        (0, _jquery.default)("#reallyDeletePair").modal("show");
      },

      reallyDeletePair() {
        (0, _jquery.default)("#reallyDeletePair").modal("hide");
        let thatXYPair = this.get('thatXYPair');
        let url = _app.default.API_URL + "/pf/diagram/" + thatXYPair.deviceId + "/" + thatXYPair.xSensorID + "/" + thatXYPair.ySensorID;

        _jquery.default.ajax({
          method: "DELETE",
          url: url
        }).done(() => {
          this.transitionToRoute('devices.details.anomalie');
        });
      }

    }
  });

  _exports.default = _default;
});