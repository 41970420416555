define("machine-monitoring/devices/details/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    generelChartWidth: 500,
    generelChartHeight: 150,
    deviceInfoShown: false,
    thirdNaviRoute: null,

    init() {
      this._super(...arguments);

      this.secondfirstOptions = {
        responsive: true,
        showScale: false,
        showTooltips: false,
        scaleShowHorizontalLines: false,
        scaleShowVerticalLines: false,
        scaleShowGridLines: false,
        pointDot: false,
        pointDotRadius: 0,
        pointDotStrokeWidth: 0,
        datasetStroke: true,
        datasetStrokeWidth: 4,
        routeActiveAlerts: false,
        routeActiveOsci: false,
        routeActiveOverview: false,
        routeActiveAnomalie: false
      };
    },

    drawSecondBreadcrumbs(thatActive) {
      let that = this;
      this.set("routeActiveAlerts", false);
      this.set("routeActiveOsci", false);
      this.set("routeActiveOverview", false);
      this.set("routeActiveAnomalie", false);

      switch (thatActive) {
        case "alerting":
          {
            that.set("routeActiveAlerts", true);
            break;
          }

        case "signal":
          {
            that.set("routeActiveOsci", true);
            break;
          }

        case "overview":
          {
            that.set("routeActiveOverview", true);
            break;
          }

        case "anomalie":
          {
            that.set("routeActiveAnomalie", true);
            break;
          }

        default:
          {
            break;
          }
      }
    },

    setDeviceInfoBox(toThat) {
      this.set("deviceInfoShown", toThat);

      if (toThat) {
        $("#deviceInfo").offset({
          left: 0
        });
      } else {
        $("#deviceInfo").offset({
          left: -($("#deviceInfo").width() + 50)
        });
      }
    },

    actions: {
      alertsModeAction() {
        this.set('thirdNaviRoute', 'alerts');
        this.drawSecondBreadcrumbs("alerting");
        this.transitionToRoute("devices.details.alerts");
      },

      rtModeAction() {
        this.set('thirdNaviRoute', 'overview');
        this.drawSecondBreadcrumbs("signal");
        this.transitionToRoute("devices.details.signal");
      },

      overviewModeAction() {
        this.set('thirdNaviRoute', 'overview');
        this.drawSecondBreadcrumbs("overview");
        this.transitionToRoute("devices.details.overview");
      },

      anomalieModeAction() {
        this.set('thirdNaviRoute', 'overview');
        this.drawSecondBreadcrumbs("anomalie");
        this.transitionToRoute("devices.details.anomalie");
      },

      enterRoute(toHere) {
        this.drawSecondBreadcrumbs(toHere);
      },

      leaveRoute() {
        this.drawSecondBreadcrumbs();
      },

      toggleDeviceInfo() {
        let deviceInfoShown = this.get("deviceInfoShown");
        this.setDeviceInfoBox(!deviceInfoShown);
      }

    }
  });

  _exports.default = _default;
});