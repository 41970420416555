define("machine-monitoring/configuration/device/edit/signal/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      let chosenSignaltype = this.controllerFor("configuration/device/edit").get("chosenSignaltype");
      controller.set("thisSignalType", chosenSignaltype);
    },

    renderTemplate(controller, model) {
      this.render("configuration/device/edit/signal", {
        into: "configuration/device/edit",
        outlet: "configEditDetail",
        controller: controller,
        model: model
      });
      controller.send("firstRunAfterRender");
    }

  });

  _exports.default = _default;
});