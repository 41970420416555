define("machine-monitoring/location/production/controller", ["exports", "konva", "gauge"], function (_exports, _konva, _gauge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    reloadContentInterval: null,
    defaultLeft: 10,
    defaultTop: 58,

    init() {
      this._super(...arguments);

      this.textBoxIds = [];
      this.usedGauges = [];
      this.usedIndicators = [];
      this.usedIndicatorBlocks = [];
      this.usedLevels = [];
    },

    canvasWrapperStyle: Ember.computed('model.{attrs.height,attrs.width}', function () {
      let model = this.get('model');
      let canvasHeight = parseInt(model.attrs.height) + 6;
      let canvasWidth = parseInt(model.attrs.width) + 6;
      let canvasColor = model.attrs.fill;
      return Ember.String.htmlSafe("height: " + canvasHeight + "px; width: " + canvasWidth + "px; background-color: " + canvasColor + ";");
    }),

    createKonvaRect(element) {
      return new _konva.default.Rect({
        myController: this,
        id: element.attrs.id,
        x: parseInt(element.attrs.x),
        y: parseInt(element.attrs.y),
        width: parseInt(element.attrs.width),
        height: parseInt(element.attrs.height),
        fill: element.attrs.fill,
        stroke: element.attrs.stroke,
        strokeWidth: element.attrs.strokeWidth,
        elementType: element.attrs.elementType,
        strokeScaleEnabled: false,
        opacity: element.attrs.tmpOpacity,
        tmpZIndex: element.attrs.tmpZIndex,
        draggable: false,
        resizing: false
      });
    },

    createKonvaCircle(element) {
      return new _konva.default.Circle({
        myController: this,
        id: element.attrs.id,
        x: parseInt(element.attrs.x),
        y: parseInt(element.attrs.y),
        radius: parseInt(element.attrs.radius),
        fill: element.attrs.fill,
        stroke: element.attrs.stroke,
        strokeWidth: element.attrs.strokeWidth,
        elementType: element.attrs.elementType,
        strokeScaleEnabled: false,
        opacity: element.attrs.tmpOpacity,
        tmpZIndex: element.attrs.tmpZIndex,
        draggable: false,
        resizing: false
      });
    },

    createKonvaEllipse(element) {
      return new _konva.default.Ellipse({
        myController: this,
        id: element.attrs.id,
        x: parseInt(element.attrs.x),
        y: parseInt(element.attrs.y),
        radiusX: parseInt(element.attrs.radiusX),
        radiusY: parseInt(element.attrs.radiusY),
        fill: element.attrs.fill,
        stroke: element.attrs.stroke,
        strokeWidth: element.attrs.strokeWidth,
        elementType: element.attrs.elementType,
        strokeScaleEnabled: false,
        opacity: element.attrs.tmpOpacity,
        tmpZIndex: element.attrs.tmpZIndex,
        draggable: false,
        resizing: false
      });
    },

    createKonvaLine(element) {
      return new _konva.default.Line({
        myController: this,
        id: element.attrs.id,
        points: element.attrs.points,
        stroke: element.attrs.stroke,
        strokeWidth: element.attrs.strokeWidth,
        lineCap: element.attrs.lineCap,
        lineJoin: element.attrs.lineJoin,
        elementType: element.attrs.elementType,
        strokeScaleEnabled: false,
        opacity: element.attrs.tmpOpacity,
        tmpZIndex: element.attrs.tmpZIndex,
        draggable: false,
        resizing: false
      });
    },

    createKonvaText(element) {
      return new _konva.default.Text({
        myController: this,
        id: element.attrs.id,
        x: parseInt(element.attrs.x),
        y: parseInt(element.attrs.y),
        text: element.attrs.text,
        tmpText: element.attrs.tmpText,
        fontSize: parseInt(element.attrs.fontSize),
        fontFamily: element.attrs.fontFamily,
        fill: element.attrs.fill,
        elementType: element.attrs.elementType,
        opacity: element.attrs.tmpOpacity,
        tmpZIndex: element.attrs.tmpZIndex
      });
    },

    createKonvaValues(element, shifted, thisId) {
      let rndNumber = Math.floor(Math.random() * 1000) + 1;
      return new _konva.default.Text({
        id: thisId + "shifted" + shifted + "q",
        x: parseInt(element.attrs.x) + 20,
        y: parseInt(element.attrs.y) + 20 * (shifted + 1),
        text: rndNumber,
        tmpText: rndNumber,
        fontSize: 20,
        fontFamily: element.attrs.fontFamily,
        fill: "#000000",
        elementType: 5
      });
    },

    createIndicator(element) {
      let color = element.attrs.firstColor;

      if (element.attrs.value) {
        color = element.attrs.secondColor;
      }

      let box = new _konva.default.Circle({
        myController: this,
        id: element.attrs.id,
        x: parseInt(element.attrs.x),
        y: parseInt(element.attrs.y),
        radius: 6,
        fill: color,
        firstColor: element.attrs.firstColor,
        secondColor: element.attrs.secondColor,
        elementType: element.attrs.elementType,
        opacity: element.attrs.tmpOpacity,
        tmpZIndex: element.attrs.tmpZIndex,
        draggable: false,
        resizing: false
      });
      this.get('usedIndicators').pushObject(box);
      return box;
    },

    createIndicatBlock(element) {
      let counter = element.attrs.counter;
      let IndicatorGroup = new _konva.default.Group({
        id: element.attrs.id,
        x: element.attrs.x,
        y: element.attrs.y,
        firstColor: element.attrs.firstColor,
        secondColor: element.attrs.secondColor,
        counter: counter,
        opacity: element.attrs.tmpOpacity,
        elementType: element.attrs.elementType
      });

      for (let i = 0; i < counter; i++) {
        let color = element.attrs.firstColor;

        if (i == element.attrs.value) {
          color = element.attrs.secondColor;
        }

        let box = new _konva.default.Circle({
          x: 20 * i,
          y: 0,
          radius: 6,
          fill: color
        });
        IndicatorGroup.add(box);
      }

      this.get('usedIndicatorBlocks').pushObject(IndicatorGroup);
      return IndicatorGroup;
    },

    createGauge(element) {
      let newGaugeElement = document.createElement("div");
      newGaugeElement.classList.add("gauge-container");
      newGaugeElement.classList.add("production-gauge");
      newGaugeElement.id = element.attrs.id;
      newGaugeElement.style.left = this.get('defaultLeft') + parseInt(element.attrs.x) + "px";
      newGaugeElement.style.top = this.get('defaultTop') + parseInt(element.attrs.y) + "px";
      newGaugeElement.style.height = parseInt(element.attrs.height) + "px";
      newGaugeElement.style.width = parseInt(element.attrs.width) + "px";
      newGaugeElement.style.zIndex = 12000;
      document.getElementById("gaugeWrapper").appendChild(newGaugeElement);
      let actValue = parseInt(element.attrs.value);
      let firColor = "#5ee432";
      let secColor = "#fffa50";
      let thiColor = "#f7aa38";
      let fouColor = "#ef4655";

      if (element.attrs.gaugetype == "invert") {
        firColor = "#ef4655";
        secColor = "#f7aa38";
        thiColor = "#fffa50";
        fouColor = "#5ee432";
      }

      let thisGauge = new _gauge.default(newGaugeElement, {
        min: 0,
        max: 100,
        dialStartAngle: 180,
        dialEndAngle: 0,
        value: actValue,
        color: function (value) {
          if (value < 25) {
            return firColor;
          } else if (value < 50) {
            return secColor;
          } else if (value < 55) {
            return thiColor;
          } else {
            return fouColor;
          }
        }
      });
      thisGauge.setValue(actValue);
      this.get('usedGauges').pushObject(thisGauge);
    },

    createLevel(element) {
      let fillHeight = element.attrs.height / element.attrs.maxValue * element.attrs.value;
      let levelGroup = new _konva.default.Group({
        x: element.attrs.x,
        y: element.attrs.y
      });
      let border = new _konva.default.Line({
        points: [0, 0, 0, element.attrs.height, element.attrs.width, element.attrs.height, element.attrs.width, 0],
        stroke: "#000000",
        strokeWidth: element.attrs.strokeWidth,
        lineCap: 'round',
        lineJoin: 'round'
      });
      levelGroup.add(border);
      let fillColor = element.attrs.fill;

      if (element.attrs.dynamicColors) {
        fillColor = this.dynamicLevelColor(element.attrs);
      }

      let filling = new _konva.default.Rect({
        x: element.attrs.strokeWidth / 2,
        y: element.attrs.height - fillHeight,
        width: element.attrs.width - element.attrs.strokeWidth,
        height: fillHeight - element.attrs.strokeWidth / 2,
        maxValue: element.attrs.maxValue,
        value: element.attrs.value,
        elementHeight: element.attrs.height,
        fill: fillColor,
        fillColors: element.attrs.fillColors,
        dynamicColors: element.attrs.dynamicColors,
        steps: element.attrs.steps
      });
      levelGroup.add(filling);

      if (element.attrs.type == 1) {
        levelGroup.rotate(90);
      }

      this.get('usedLevels').pushObject(levelGroup);
      return levelGroup;
    },

    dynamicLevelColor(elementAttrs) {
      let percently = 100 / (elementAttrs.maxValue / elementAttrs.value);
      let targetColor = elementAttrs.fillColors[0];
      let stepsPercently = 100 / elementAttrs.steps;

      for (let i = 0; i < elementAttrs.steps; i++) {
        if (percently > stepsPercently * i) {
          targetColor = elementAttrs.fillColors[i];
        }
      }

      return targetColor;
    },

    reloadTextContent() {
      this.get('usedGauges').forEach(thisGauge => {
        let rndNumber = Math.floor(Math.random() * 100);
        thisGauge.setValue(rndNumber);
      });
      this.get("textBoxIds").forEach(thisId => {
        let rndNumber = Math.floor(Math.random() * 1000) + 1;
        this.get('canvasStage').findOne('#' + thisId).text(rndNumber);
      });
      this.get('usedIndicators').forEach(thisIndicator => {
        let thatColor = thisIndicator.attrs.firstColor;

        if (Math.round(Math.random()) == 1) {
          thatColor = thisIndicator.attrs.secondColor;
        }

        thisIndicator.fill(thatColor);
      });
      this.get('usedIndicatorBlocks').forEach(thisBlock => {
        let rndNumber = Math.floor(Math.random() * thisBlock.attrs.counter);
        let counter = thisBlock.attrs.counter;

        for (let i = 0; i < counter; i++) {
          let thatColor = thisBlock.attrs.firstColor;

          if (i == rndNumber) {
            thatColor = thisBlock.attrs.secondColor;
          }

          thisBlock.children[i].attrs.fill = thatColor;
        }
      });
      this.get('usedLevels').forEach(thisLevel => {
        let rndNumber = Math.floor(Math.random() * 100);
        let fillHeight = Math.round((thisLevel.children[1].attrs.elementHeight - thisLevel.children[0].attrs.strokeWidth / 2) / thisLevel.children[1].attrs.maxValue * rndNumber);
        let newY = thisLevel.children[1].attrs.elementHeight - fillHeight - thisLevel.children[0].attrs.strokeWidth / 2;

        if (thisLevel.children[1].attrs.dynamicColors) {
          thisLevel.children[1].attrs.value = rndNumber;
          thisLevel.children[1].attrs.fill = this.dynamicLevelColor(thisLevel.children[1].attrs);
        }

        thisLevel.children[1].attrs.height = fillHeight;
        thisLevel.children[1].setAttr('y', newY);
      });
      this.get('canvasLayer').draw();
    },

    actions: {
      startBuildingCanvas() {
        let that = this;
        let holdTimer = setInterval(function () {
          let isItThere = document.getElementById('canvasWrapper');

          if (isItThere) {
            clearInterval(holdTimer);
            that.send('buildCanvas');
          }
        }, 100);
      },

      buildCanvas() {
        let model = this.get('model');
        let that = this;
        var canvasHeight = parseInt(model.attrs.height);
        var canvasWidth = parseInt(model.attrs.width);
        var stage = new _konva.default.Stage({
          container: 'canvasWrapper',
          width: canvasWidth,
          height: canvasHeight
        });
        var layer = new _konva.default.Layer();
        model.children[0].children.forEach(element => {
          if (element.className == "Rect") {
            let box = this.createKonvaRect(element);
            layer.add(box);
            box.setZIndex(element.attrs.tmpZIndex);

            if (element.attrs.hasText) {
              let thatRectId = element.attrs.id;

              for (let i = 0; i < element.attrs.hasText; i++) {
                let boxText = this.createKonvaValues(element, i, thatRectId);
                that.get('textBoxIds').pushObject(boxText.attrs.id);
                layer.add(boxText);
              }
            }
          } else if (element.className == "Circle") {
            let box = this.createKonvaCircle(element);
            layer.add(box);
            box.setZIndex(element.attrs.tmpZIndex);
          } else if (element.className == "Ellipse") {
            let box = this.createKonvaEllipse(element);
            layer.add(box);
            box.setZIndex(element.attrs.tmpZIndex);
          } else if (element.className == "Line") {
            let box = this.createKonvaLine(element);
            layer.add(box);
            box.setZIndex(element.attrs.tmpZIndex);
          } else if (element.className == "Text") {
            let box = this.createKonvaText(element, 0);
            layer.add(box);
            box.setZIndex(element.attrs.tmpZIndex);
          } else if (element.className == "Indicator") {
            let box = this.createIndicator(element);
            layer.add(box);
          } else if (element.className == "IndicatBlock") {
            let box = this.createIndicatBlock(element);
            layer.add(box);
          } else if (element.className == "Gauge") {
            this.createGauge(element);
          } else if (element.className == "Level") {
            let box = this.createLevel(element);
            layer.add(box);
          }
        });
        stage.add(layer);
        layer.draw();
        this.set('canvasLayer', layer);
        this.set('canvasStage', stage);
        let reloadContentInterval = setInterval(function () {
          that.reloadTextContent();
        }, 3000);
        this.set('reloadContentInterval', reloadContentInterval);
      },

      runToLeave() {
        let reloadContentInterval = this.get('reloadContentInterval');

        if (reloadContentInterval) {
          clearInterval(reloadContentInterval);
          this.set("reloadContentInterval", null);
        }
      }

    }
  });

  _exports.default = _default;
});