define("machine-monitoring/devices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JllcMvRx",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"dashboardWrapper\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"marginLeft40\"],[8],[1,[28,\"t\",[\"allMachines\"],null],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"allDevices\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"allDevices\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"model\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"device-overview\",null,[[\"data\",\"showThisDevice\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"showThisDevice\"],[[\"data\"],[\"data\"]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h3\",true],[8],[1,[28,\"t\",[\"sryNoData\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/devices/template.hbs"
    }
  });

  _exports.default = _default;
});