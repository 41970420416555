define("machine-monitoring/components/machines-proactive/component", ["exports", "machine-monitoring/app", "chartjs", "jquery"], function (_exports, _app, _chartjs, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    chartData: null,
    chartObj: null,
    chartOptions: null,
    classNames: ["col-2", "cursor-pointer"],

    init() {
      this._super(...arguments);

      let warningsData = [];
      let criticalsData = [];
      let emergenciesData = [];
      let labels = [];
      let kind = this.get("kind");
      let warningData = this.get("data.warnings");
      let criticalData = this.get("data.criticals");
      let emergencyData = this.get("data.emergencies");
      let warningColor = "hsl(65, 80%, 60%)";
      let criticalColor = "hsl(20, 80%, 60%)";
      let emergencyColor = "hsl(355, 60%, 45%)";

      if (warningData) {
        warningData.forEach(thisData => {
          let millis = parseInt(thisData.time);
          let newDate = new Date(millis);
          let rightFormatDate = "";

          if (kind === "3") {
            rightFormatDate = newDate.getUTCDate() + "";
          } else if (kind === "7") {
            rightFormatDate = newDate.getUTCDate() + "";
          } else if (kind === "12") {
            rightFormatDate = newDate.getHours() + ":00";
          } else if (kind === "30") {
            rightFormatDate = newDate.getUTCMonth() + 1 + "";
          }

          labels.push(rightFormatDate);
          warningsData.push(thisData.alertCount);
        });
      } else if (criticalData) {
        criticalData.forEach(thisData => {
          let millis = parseInt(thisData.time);
          let newDate = new Date(millis);
          let rightFormatDate = "";

          if (kind === "3") {
            rightFormatDate = newDate.getUTCDate() + "";
          } else if (kind === "7") {
            rightFormatDate = newDate.getUTCDate() + "";
          } else if (kind === "12") {
            rightFormatDate = newDate.getHours() + ":00";
          } else if (kind === "30") {
            rightFormatDate = newDate.getUTCMonth() + 1 + "";
          }

          labels.push(rightFormatDate);
        });
      } else if (emergencyData) {
        emergencyData.forEach(thisData => {
          let millis = parseInt(thisData.time);
          let newDate = new Date(millis);
          let rightFormatDate = "";

          if (kind === "3") {
            rightFormatDate = newDate.getUTCDate() + "";
          } else if (kind === "7") {
            rightFormatDate = newDate.getUTCDate() + "";
          } else if (kind === "12") {
            rightFormatDate = newDate.getHours() + ":00";
          } else if (kind === "30") {
            rightFormatDate = newDate.getUTCMonth() + 1 + "";
          }

          labels.push(rightFormatDate);
        });
      }

      if (criticalData) {
        criticalData.forEach(thisData => {
          criticalsData.push(thisData.alertCount);
        });
      }

      if (emergencyData) {
        emergencyData.forEach(thisData => {
          emergenciesData.push(thisData.alertCount);
        });
      }

      let barGraph = {
        labels: labels,
        datasets: [{
          label: "Warnings",
          backgroundColor: warningColor,
          borderWidth: 0,
          data: warningsData
        }, {
          label: "Criticals",
          backgroundColor: criticalColor,
          borderWidth: 0,
          data: criticalsData
        }, {
          label: "Emergencies",
          backgroundColor: emergencyColor,
          borderWidth: 0,
          data: emergenciesData
        }]
      };
      this.set("chartData", barGraph);
      let options = {
        legend: {
          display: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        scales: {
          xAxes: [{
            display: false
          }]
        }
      };
      this.set("chartOptions", options);
    },

    didInsertElement() {
      let canvasElement = this.get("element").getElementsByTagName("CANVAS")[0];
      let ctx = canvasElement.getContext("2d");
      let options = this.get("chartOptions");
      let data = this.get("chartData");
      let myNewChart = new _chartjs.default(ctx, {
        type: "bar",
        data: data,
        options: options
      });
      this.set("chartObj", myNewChart);
    },

    actions: {
      showThisDevice() {
        let thisData = this.data;
        (0, _jquery.default)("#machine-card-b-" + thisData.deviceID).tooltip('hide');
        let onlineStatus = this.data.DeviceInfo.online;

        if (onlineStatus) {
          _app.default.chosenDeviceId = thisData.deviceID;
          this.showThisDevice(thisData.deviceID);
        }
      }

    }
  });

  _exports.default = _default;
});