define("machine-monitoring/components/pagination-addin/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      changePage(thatWay) {
        this.changePage(this.get('kindOf') + thatWay);
      }

    },

    didReceiveAttrs() {
      this._super(...arguments);

      let pages = this.get('pages');
      let currentPage = this.get('currentPage');

      if (pages > 1) {
        this.set("morePages", true);

        if (currentPage > 1) {
          this.set("possiblePrevPage", true);
        } else {
          this.set("possiblePrevPage", false);
        }

        if (currentPage >= pages) {
          this.set("possibleNextPage", false);
        } else {
          this.set("possibleNextPage", true);
        }
      } else {
        this.set("morePages", false);
      }
    }

  });

  _exports.default = _default;
});