define("machine-monitoring/components/machine-types-most-faulty/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "90Fyot7U",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"m-y mostFaultyDiv\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card mb-0\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card-body cardBodyWithoutPadding\"],[8],[0,\"\\n      \"],[7,\"h5\",true],[8],[1,[24,[\"data\",\"machineType\"]],false],[9],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"canvas\",true],[10,\"width\",\"150\"],[10,\"height\",\"150\"],[10,\"class\",\"mostFaultyCanvas\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/components/machine-types-most-faulty/template.hbs"
    }
  });

  _exports.default = _default;
});