define("machine-monitoring/location/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routeActiveLocations: false,
    routeActiveProduction: false,

    drawBreadcrumbs(thisRoute) {
      let that = this;
      this.set("routeActiveLocations", false);
      this.set("routeActiveProduction", false);

      switch (thisRoute) {
        case "locations":
          {
            that.set("routeActiveLocations", true);
            break;
          }

        case "production":
          {
            that.set("routeActiveProduction", true);
            break;
          }

        default:
          {
            that.set("routeActiveLocations", true);
            break;
          }
      }
    },

    actions: {
      locationsModeAction() {
        this.drawBreadcrumbs("locations");
        this.transitionToRoute("location");
      },

      drawBreadcrumbs(thisRoute) {
        this.drawBreadcrumbs(thisRoute);
      },

      resetBreadcrumb() {
        this.drawBreadcrumbs("locations");
      },

      loadLocation(thisLocation) {
        console.log('loadLocation thisLocation: ', thisLocation);
        this.drawBreadcrumbs("production");
        this.transitionToRoute("location.production");
      }

    }
  });

  _exports.default = _default;
});