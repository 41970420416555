define("machine-monitoring/devices/details/overview/controller", ["exports", "machine-monitoring/app", "jquery"], function (_exports, _app, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    socketService: Ember.inject.service("websockets"),
    isLoading: false,
    deviceId: 0,
    timeNow: null,
    boxSize: 2,
    boxSizeClass: Ember.computed('boxSize', function () {
      return "col-" + this.boxSize;
    }),
    isBig: Ember.computed('boxSize', function () {
      return this.boxSize == "4";
    }),
    statcardDisplayStyle: Ember.computed('isBig', function () {
      let display = "none";

      if (this.isBig) {
        display = "block";
      }

      return Ember.String.htmlSafe("height:100%;width:100%;display:" + display + ";");
    }),

    setupWebsocketConnection() {
      const socket = this.get("socketService").socketFor(_app.default.WS_URL);
      socket.on("open", this.wsOpenHandler, this);
      socket.on("message", this.wsMessageHandler, this);
      socket.on('error', this.wsErrorHandler, this);
      this.set("socketRef", socket);
    },

    wsOpenHandler() {
      _jquery.default.get(_app.default.API_URL + "/live/" + this.get("deviceId") + "/500");
    },

    wsMessageHandler(event) {
      if (event.data === "ping") {
        return;
      }

      let data = _jquery.default.parseJSON(event.data);

      let extracted = String(data.ts).substring(0, 13);
      let d = new Date(Number(extracted));
      let secondsNow;
      let boxS = d.getSeconds().toString();

      if (d.getSeconds() < 10) {
        secondsNow = "0" + boxS;
      } else {
        secondsNow = boxS;
      }

      let minutesNow;
      let boxM = d.getMinutes().toString();

      if (d.getMinutes() < 10) {
        minutesNow = "0" + boxM;
      } else {
        minutesNow = boxM;
      }

      this.set("timeNow", d.getHours() + ":" + minutesNow + "." + secondsNow);
      data.vals.forEach(thisValue => {
        let thisValueId = thisValue.sID;
        let allTypes = this.get("allTypes");
        let shortenValue = Math.round(thisValue.val * 1000) / 1000;
        let shortenMin = Math.round(thisValue.min * 1000) / 1000;
        let shortenMax = Math.round(thisValue.max * 1000) / 1000;
        let shortenMean = Math.round(thisValue.mean * 1000) / 1000;
        let shortenSpread = Math.round(thisValue.spread * 1000) / 1000;
        let shortenStdDev = Math.round(thisValue.stdDev * 1000) / 1000;
        Ember.set(allTypes[thisValueId], "value", shortenValue);
        Ember.set(allTypes[thisValueId], "min", shortenMin);
        Ember.set(allTypes[thisValueId], "max", shortenMax);
        Ember.set(allTypes[thisValueId], "mean", shortenMean);
        Ember.set(allTypes[thisValueId], "spread", shortenSpread);
        Ember.set(allTypes[thisValueId], "stdDev", shortenStdDev);
        Ember.set(allTypes[thisValueId], "time", extracted);
      });
    },

    wsErrorHandler() {
      _jquery.default.get(_app.default.API_URL + "/time").fail(function (jqXHR) {
        if (jqXHR.readyState == 4 && jqXHR.status == 401) {
          window.location.replace(_app.default.API_HOST + "/user-service/");
        }
      });
    },

    closeWebSockets() {
      this.stopWebsocketData();
      const socket = this.get("socketRef");

      if (socket) {
        socket.off("open", this.wsOpenHandler);
        socket.off("message", this.wsMessageHandler);
      }

      Ember.run.later(() => {
        this.get("socketService").closeSocketFor(_app.default.WS_URL);
      }, 100);
    },

    stopWebsocketData() {
      _jquery.default.get(_app.default.API_URL + "/live/" + this.get("deviceId"));
    },

    actions: {
      firstRunAfterRender() {
        this.set('isLoading', false);
        let device = this.get("model");
        let deviceId = device.get("id");
        this.set("deviceId", deviceId);
        this.set('boxSize', 2);
        this.setupWebsocketConnection();
      },

      toggleView() {
        let boxSize = this.get('boxSize');

        if (boxSize == 2) {
          boxSize = 4;
        } else {
          boxSize = 2;
        }

        this.set('boxSize', boxSize);
      },

      toggleSignals() {
        (0, _jquery.default)("#modalToggleSignals").modal("show");
      },

      toggleSignal(thatSignal) {
        let oldState = thatSignal.show;
        Ember.set(thatSignal, 'show', !oldState);
      },

      runOnDeactivate() {
        this.closeWebSockets();
        this.set("allTypes", null);
      }

    }
  });

  _exports.default = _default;
});