define("machine-monitoring/devices/details/alerts/learning/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.get('store').findAll('sensor');
    },

    renderTemplate(controller, model) {
      this.render("devices/details/alerts/learning", {
        into: "devices/details",
        controller: controller,
        model: model
      });
      this.controllerFor("devices/details").set('thirdNaviRoute', 'learning');
    }

  });

  _exports.default = _default;
});