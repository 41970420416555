define("machine-monitoring/devices/details/alerts/learning/sensor/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model); //doNext: 0-nothing, 1-init, 2-startDetect, 3-startLearning, 4-config, 5-load, 6-save, 7-dream

    },

    activate() {
      this._super();

      Ember.run.later(() => {
        this.controllerFor("devices/details/alerts/learning/sensor").send("firstRunAfterRender");
      }, 100);
    },

    renderTemplate(controller, model) {
      this.render("devices/details/alerts/learning/sensor", {
        into: "application",
        outlet: "content",
        controller: controller,
        model: model
      });
    },

    actions: {
      refreshModel() {
        this.refresh();
      }

    },

    deactivate() {
      this._super();

      this.controllerFor("devices/details/alerts/learning/sensor").send("leaveRoute");
    }

  });

  _exports.default = _default;
});