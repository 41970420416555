define("machine-monitoring/configuration/controller", ["exports", "machine-monitoring/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routeActiveConfigDevice: false,
    routeActiveConfigCustomer: false,

    init() {
      this._super(...arguments);

      console.log('Version: ', _environment.default.version);
      console.log('Build Date: ', _environment.default.buildDate);
      console.log('Commit: ', _environment.default.commit);
    },

    drawSecondBreadcrumbs(thatActive) {
      let that = this;
      this.set("routeActiveConfigDevice", false);
      this.set("routeActiveConfigCustomer", false);

      switch (thatActive) {
        case "device":
          {
            that.set("routeActiveConfigDevice", true);
            break;
          }

        case "customer":
          {
            that.set("routeActiveConfigCustomer", true);
            break;
          }

        default:
          {
            break;
          }
      }
    },

    actions: {
      firstRunAfterRender() {
        this.drawSecondBreadcrumbs("device");
      },

      configDeviceModeAction() {
        this.drawSecondBreadcrumbs("device");
        this.transitionToRoute("configuration");
      },

      configCustomerModeAction() {
        this.drawSecondBreadcrumbs("customer");
        this.transitionToRoute("configuration.customers");
      },

      loadDevice(thisDevice) {
        this.transitionToRoute("configuration.device", thisDevice);
      },

      newDevice() {
        this.transitionToRoute("configuration.newdevice");
      }

    }
  });

  _exports.default = _default;
});