define("machine-monitoring/router", ["exports", "machine-monitoring/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('index', {
      path: '/'
    });
    this.route('dashboard');
    this.route('devices', function () {
      this.route('details', {
        path: 'details/:device_id'
      }, function () {
        this.route('alerts', function () {
          this.route('learning', function () {
            this.route('sensor', {
              path: ':sensor_id'
            });
          });
        });
        this.route('signal', function () {
          this.route('realtime');
          this.route('record');
        });
        this.route('overview', function () {
          this.route('bigscreen');
        });
        this.route('anomalie', function () {
          this.route('create', function () {});
          this.route('twod');
          this.route('threed');
          this.route('details');
        });
      });
    });
    this.route('configuration', function () {
      this.route('device', {
        path: ':device_id'
      }, function () {
        this.route('anomalies', function () {
          this.route('anomaly');
        });
        this.route('edit', function () {
          this.route('signal');
        });
      });
      this.route('newdevice');
      this.route('customers', function () {
        this.route('customer', {
          path: ':customer_id'
        }, function () {
          this.route('location');
          this.route('edit');
        });
        this.route('newcustomer');
      });
    });
    this.route('comparison');

    if (PRODUCTIONMONITORING) {
      this.route('location', function () {
        this.route('production');
      });
    }
  });
  var _default = Router;
  _exports.default = _default;
});