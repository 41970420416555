define("machine-monitoring/components/single-small-spectrum/component", ["exports", "machine-monitoring/app", "chartjs", "jquery"], function (_exports, _app, _chartjs, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ["draggable"],
    draggable: false,
    classNames: ["marginBottom15"],
    classNameBindings: ["otherOver", "isSelected"],
    otherOver: false,
    isSelected: false,
    smallClusterChart: null,
    frozen: false,
    capacity: null,
    clusterName: null,
    unseen: "unseen",

    dragOver(event) {
      this.set("otherOver", true);
      event.preventDefault();
    },

    drop(event) {
      let clusterId = event.dataTransfer.getData("id");
      let clusterType = event.dataTransfer.getData("type");
      let cluster = this.get("cluster");
      let sensorId = this.get("options").sensorId;
      let jsonToSend = {
        sensor_id: parseInt(sensorId),
        src_cluster_type: parseInt(clusterType),
        dst_cluster_type: parseInt(cluster.type),
        src_cluster_id: parseInt(clusterId),
        dst_cluster_id: parseInt(cluster.id)
      };

      _jquery.default.post(_app.default.API_ML_URL + "/sensors/" + sensorId + "/clusters/merge", JSON.stringify(jsonToSend));
    },

    dragEnter() {
      this.set("otherOver", true);
    },

    dragLeave() {
      this.set("otherOver", false);
    },

    dragEnd() {
      this.set("otherOver", false);
    },

    dragStart(event) {
      let cluster = this.get("cluster");
      event.originalEvent.dataTransfer.setData("id", cluster.id);
      event.originalEvent.dataTransfer.setData("type", cluster.type);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      let cluster = this.get("cluster");

      if (cluster.type !== 0) {
        this.set("draggable", true);
      } else {
        this.set("draggable", false);
      }

      this.set("isSelected", cluster.selected);

      if (cluster.name) {
        this.set("unseen", "seen");
        this.set("clusterName", cluster.name);
      } else {
        this.set("unseen", "unseen");
        this.set("clusterName", "unnamed");
      }

      this.renderCapacityBar();
    },

    didInsertElement() {
      this._super(...arguments);

      this.buildChart();
      let cluster = this.get("cluster");
      this.set("frozen", cluster.frozen);
      this.set("capacity", cluster.capacity);
      this.renderCapacityBar();
    },

    renderCapacityBar() {
      let cluster = this.get("cluster");
      let options = this.get("options");
      let cluster_capacity = 0;

      if (cluster.type === 0) {
        cluster_capacity = options.basis_cluster_capacity;
      } else {
        cluster_capacity = options.other_cluster_capacity;
      }

      let smallCanvas = document.getElementById("canv" + cluster.elementId);

      if (smallCanvas) {
        let smallCanvasWidth = smallCanvas.offsetWidth;
        let innerFillWidth = smallCanvasWidth / cluster_capacity * cluster.capacity;

        if (innerFillWidth > smallCanvasWidth) {
          innerFillWidth = smallCanvasWidth;
        }

        let innerFillName = "innerFill" + cluster.elementId;
        let innerFillDiv = document.getElementById(innerFillName);
        innerFillDiv.style.height = "8px";
        innerFillDiv.style.width = innerFillWidth + "px";
        let outerFillName = "outerFill" + cluster.elementId;
        let outerFillDiv = document.getElementById(outerFillName);
        outerFillDiv.style.width = smallCanvasWidth + "px";
        outerFillDiv.style.height = "10px";
        let borderColor = "1px solid #1686B0";
        let getStrike = cluster.strike;

        if (getStrike) {
          smallCanvas.style.backgroundColor = "rgba(255,179,33,0.1)";
          borderColor = "1px solid #FF0000";
        }

        smallCanvas.style.border = borderColor;
      }
    },

    buildChart() {
      let options = this.get("options");
      let cluster = this.get("cluster");
      let values = cluster.values;
      let frozen = cluster.frozen;
      let valuesLength = values.length;
      let canvas = this.get("element").getElementsByTagName("CANVAS")[0];
      let smallCanvasContext = canvas.getContext("2d");
      let barColor = "rgba(0,255,0,0.8)";

      if (frozen) {
        barColor = "rgba(0,160,255,0.8)";
      }

      let actData = {
        labels: [],
        datasets: [{
          backgroundColor: barColor,
          borderColor: barColor,
          pointBackgroundColor: barColor,
          data: []
        }]
      };
      let actOptions = {
        animation: {
          duration: 0
        },
        responsive: true,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: false
          }],
          yAxes: [{
            display: false,
            ticks: {
              beginAtZero: true,
              suggestedMax: options.maxTicks
            }
          }]
        },
        point: {
          radius: 0
        }
      };
      let smallClusterNewChart = new _chartjs.default(smallCanvasContext, {
        type: "bar",
        data: actData,
        options: actOptions
      });

      for (let i = 0; i < valuesLength; i++) {
        let thisValue = values[i];
        smallClusterNewChart.data.labels.push(i);
        smallClusterNewChart.data.datasets[0].data.push(thisValue);
      }

      smallClusterNewChart.update();
      this.set("smallClusterChart", smallClusterNewChart);
    },

    actions: {
      toggleFrozen() {
        let cluster = this.get("cluster");
        let frozen = cluster.frozen;
        let sensorId = this.get("options").sensorId;
        let signalProviderUrl = _app.default.API_ML_URL + "/sensors/" + sensorId + "/clusters/freeze";

        if (frozen) {
          signalProviderUrl = _app.default.API_ML_URL + "/sensors/" + sensorId + "/clusters/defreeze";
        }

        let jsonToSend = {
          sensor_id: parseInt(sensorId),
          value: cluster.id,
          cluster_type: cluster.type
        };

        _jquery.default.post(signalProviderUrl, JSON.stringify(jsonToSend), () => {
          cluster.frozen = !frozen;
          this.set("frozen", !frozen);
          this.buildChart();
        });
      },

      removeMe() {
        let cluster = this.get("cluster");
        let sensorId = this.get("options").sensorId;
        let jsonToSend = {
          sensor_id: parseInt(sensorId),
          value: cluster.id,
          cluster_type: cluster.type
        };

        _jquery.default.post(_app.default.API_ML_URL + "/sensors/" + sensorId + "/clusters/remove", JSON.stringify(jsonToSend));
      },

      renameMe() {
        let cluster = this.get("cluster");
        this.renameCluster(cluster.type, cluster.id, cluster.name);
      },

      enlargeMe() {
        let cluster = this.get("cluster");
        this.enlargeCluster(cluster);
      }

    }
  });

  _exports.default = _default;
});