define("machine-monitoring/devices/details/signal/route", ["exports", "jquery", "machine-monitoring/app"], function (_exports, _jquery, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel(transition) {
      this.set("device", transition.resolvedModels["devices.details"]);
    },

    model() {
      let thisDevice = this.get("device");
      return Ember.RSVP.hash({
        device: thisDevice,
        signalTypes: thisDevice.get("signalTypes"),
        records: this.get("store").query("record", {})
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      let allSignalTypes = model.signalTypes;
      allSignalTypes.forEach(thisSignalType => {
        thisSignalType.set("selected", false);
      });
      let singeledRecords = [];
      let tmpSessionList = [];
      let allRecords = model.records;
      allRecords.forEach(thisRecord => {
        thisRecord.set("selected", false);

        if (!tmpSessionList.includes(thisRecord.session)) {
          singeledRecords.pushObject(thisRecord);
          tmpSessionList.push(thisRecord.session);
        }
      });

      _jquery.default.get(_app.default.API_URL + "/configuration/application/record-view-templates/" + model.device.id).then(responseData => {
        controller.set('recordSensorTemplate', responseData);
        controller.send("resizeWindow");
      });

      controller.set('singeledRecords', singeledRecords);
      controller.set('allRecordsLength', allRecords.length);
      controller.set("machineOnline", model.device.online);
      window.addEventListener("resize", () => {
        controller.send("resizeWindow");
      });
    },

    renderTemplate(controller, model) {
      this.render("devices/details/signal", {
        into: "devices/details",
        controller: controller,
        model: model
      });
      this.render("osci-navigation", {
        into: "extend-navigation",
        outlet: "extend-navigation",
        controller: controller,
        model: model
      });
      controller.send('resetBreadcrumb');
    },

    actions: {
      didTransition() {
        this.controllerFor("devices/details").set('thirdNaviRoute', 'overview');
        this.controllerFor("devices/details/signal").send("firstRunActions");
        return true;
      },

      willTransition() {
        this.refresh();
      },

      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});