define("machine-monitoring/components/device-detail-donut/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      let canvas = this.get('element').getElementsByTagName("CANVAS")[0];
      let graphics = canvas.getContext("2d");
      let maxAttentionNeededAlerts = this.get('maxAlerts');
      let warningData = this.get('data.warnings');
      let criticalData = this.get('data.criticals');
      let emergencyData = this.get('data.emergencies');
      let allAlerts = warningData + criticalData + emergencyData;
      let noAlerts = maxAttentionNeededAlerts - allAlerts;
      let everyAlertStep = 360 / maxAttentionNeededAlerts;
      let arcGreen = 0;

      if (maxAttentionNeededAlerts > allAlerts) {
        arcGreen = noAlerts * everyAlertStep;
      }

      let CX = canvas.width / 2,
          CY = canvas.height / 2,
          sx = CX,
          sy = CY,
          arcYellow = warningData * everyAlertStep + arcGreen,
          arcOrange = criticalData * everyAlertStep + arcYellow,
          stepGr2Ye = 55 / (arcYellow - arcGreen),
          stepYe2Or = 45 / (arcOrange - arcYellow),
          stepOr2Re = 25 / (360 - arcOrange);

      for (let i = 0; i < 360; i += 0.1) {
        let rad = i * (2 * Math.PI) / 360;

        if (i <= arcGreen) {
          graphics.strokeStyle = "hsl(120, 80%, 60%)";
        } else if (i > arcGreen && i <= arcYellow) {
          let thisStep = i - arcGreen;
          let thisColor = Math.round(120 - thisStep * stepGr2Ye);
          graphics.strokeStyle = "hsl(" + thisColor + ", 80%, 60%)";
        } else if (i > arcYellow && i <= arcOrange) {
          let thisStep = i - arcYellow;
          let thisColor = Math.round(65 - thisStep * stepYe2Or);
          graphics.strokeStyle = "hsl(" + thisColor + ", 80%, 60%)";
        } else if (i > arcOrange) {
          let thisStep = i - arcOrange;
          let thisColor = Math.round(20 - thisStep * stepOr2Re);

          if (thisColor < 0) {
            thisColor = 360 - thisColor;
          }

          graphics.strokeStyle = "hsl(" + thisColor + ", 80%, 60%)";
        }

        graphics.beginPath();
        graphics.moveTo(CX + sx * 0.6 * Math.cos(rad - Math.PI / 2), CY + sy * 0.6 * Math.sin(rad - Math.PI / 2));
        graphics.lineTo(CX + sx * Math.cos(rad - Math.PI / 2), CY + sy * Math.sin(rad - Math.PI / 2));
        graphics.stroke();
      }

      let canvasWidth = (0, _jquery.default)(".detailCanvasDiv").width();
      (0, _jquery.default)(".detailCanvas").width(canvasWidth);
    }

  });

  _exports.default = _default;
});