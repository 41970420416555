define("machine-monitoring/devices/details/signal/record/controller", ["exports", "machine-monitoring/app", "chartjs", "jquery"], function (_exports, _app, _chartjs, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    signalCount: 0,
    chartsAlreadyBuilt: false,
    idToSave: null,
    averageDataRate: 0,
    displayMode: 1,
    modelSession: null,
    isLoading: true,
    noRecord: true,
    windowResizedTrigger: false,
    recordDuration: 0,
    recordBegin: 0,
    recordPoints: 0,
    // XY
    metaDataOne: null,
    metaDataTwo: null,
    xyIds: [],
    xyData: null,
    xyAllData: [],
    prevXYData: null,
    refreshRateXY: 0,
    // Record
    selectedRecord: null,
    recordLoaded: false,
    rawValuesCount: null,
    rawXYValuesCount: null,
    recordTypesCount: null,
    recordTimeTypesCount: null,
    pxPerValue: null,
    chosenValueIndex: 0,
    chosenXYValueIndex: 0,
    recordRun: false,
    recordRunInterval: null,
    chartAreaLeft: 50,
    playSpeed: 100,
    // st*** = ShortTerm Diagram
    recordStValueCount: 40,
    recordStChartOptions: null,
    // lt*** = LongTerm Diagram
    recordLtChartObj: null,
    recordLtChartData: null,
    recordLtChartOptions: null,
    ltBarLeft: null,
    mouseDownX: null,
    mouseDownZ: false,
    recordEnlarged: null,
    recordBackgroundTrue: true,
    recordLimitBackgroundTrue: true,
    recordLimitLineTrue: true,
    recordXScaleTrue: false,
    overviewAbsView: false,
    signalMap: null,
    legendValues: null,
    rawValues: null,
    rawRelValues: null,
    rawXYValues: null,
    allUsedTypes: null,
    rawValueMinMax: null,
    recordStChartDatas: null,
    recordStChartObj: null,
    withoutValueCounter: false,

    init() {
      this._super(...arguments);

      this.clearObjects();
    },

    clearObjects() {
      this.set('overviewAbsView', false);
      this.set('signalMap', []);
      this.set('legendValues', []);
      this.set('rawValues', []);
      this.set('rawRelValues', []);
      this.set('rawXYValues', []);
      this.set('allUsedTypes', []);
      this.set('rawValueMinMax', []);
      this.set('recordStChartDatas', []);
      this.set('recordStChartObj', []);
    },

    generateXYDatas() {
      let xyIds = this.get("xyIds");
      let allCustomObjectsXYOne = [];
      let allCustomObjectsXYTwo = [];
      let count = 0;
      let xySignalList = [];
      xyIds.forEach(thisPair => {
        xySignalList.push(thisPair.x);
        xySignalList.push(thisPair.y);
        let allSignalTypes = this.get("model.signalTypes");
        allSignalTypes.forEach(thisSignal => {
          let matched = 0;
          let realTimeSignalCustomObject = {};

          if (thisSignal.signalTypeId == thisPair.x) {
            matched++;
            count++;
            realTimeSignalCustomObject = {
              typeId: thisSignal.get("signalTypeId"),
              name: thisSignal.get("name"),
              unit: thisSignal.get("unit"),
              length: 0,
              orientation: 0,
              liveView: false
            };
          } else if (thisSignal.signalTypeId == thisPair.y) {
            matched++;
            count++;
            realTimeSignalCustomObject = {
              typeId: thisSignal.get("signalTypeId"),
              name: thisSignal.get("name"),
              unit: thisSignal.get("unit"),
              length: 0,
              orientation: 1,
              liveView: false
            };
          }

          if (matched > 0) {
            if (count < 3) {
              allCustomObjectsXYOne.pushObject(realTimeSignalCustomObject);
            } else {
              allCustomObjectsXYTwo.pushObject(realTimeSignalCustomObject);
            }
          }
        });
      });
      this.set("xySignalList", xySignalList);
      this.set("metaDataOne", allCustomObjectsXYOne);
      this.set("metaDataTwo", allCustomObjectsXYTwo);
      return true;
    },

    requestDeviceMetas() {
      let displayMode = this.get("displayMode");
      let idsToRecordXY = this.get("idsToRecordXY");

      if (idsToRecordXY) {
        let selectedRecord = this.get("selectedRecord");
        let startEndString = "?start=" + selectedRecord.get("start") + "&end=" + selectedRecord.get("end") + "&db=records";

        _jquery.default.get(_app.default.API_URL + "/values/minmax/" + this.get("model.device").get("id") + "/" + idsToRecordXY + startEndString, response => {
          let metaDataOne = this.get("metaDataOne");
          let metaDataTwo = this.get("metaDataTwo");
          response.signalTypes.forEach(thisValue => {
            if (metaDataOne[0] && metaDataOne[0].typeId == thisValue.id) {
              metaDataOne[0].min = thisValue.min;
              metaDataOne[0].max = thisValue.max;
            } else if (metaDataOne[1] && metaDataOne[1].typeId == thisValue.id) {
              metaDataOne[1].min = thisValue.min;
              metaDataOne[1].max = thisValue.max;
            }

            if (metaDataTwo[0] && metaDataTwo[0].typeId == thisValue.id) {
              metaDataTwo[0].min = thisValue.min;
              metaDataTwo[0].max = thisValue.max;
            } else if (metaDataTwo[1] && metaDataTwo[1].typeId == thisValue.id) {
              metaDataTwo[1].min = thisValue.min;
              metaDataTwo[1].max = thisValue.max;
            }
          });
          this.set("metaDataOne", metaDataOne);
          this.set("metaDataTwo", metaDataTwo);

          if (displayMode == 2 || displayMode == 4) {
            this.constructXYDiag();
          } else if (displayMode == 3 || displayMode == 5) {
            this.constructXYDiag();
            this.constructTimeDiag();
          }

          (0, _jquery.default)("#detailWrapper").removeClass("invisible");
          (0, _jquery.default)("#detailWrapper").addClass("visible");
          this.set("isLoading", false);
        });
      }
    },

    initCanvas() {
      let detailWrapper = (0, _jquery.default)("#detailWrapper");

      if (detailWrapper) {
        let allUsedTypes = this.get("allUsedTypes");
        let recordEnlarged = this.get("recordEnlarged");
        let firstSignal = recordEnlarged;

        if (!recordEnlarged) {
          firstSignal = allUsedTypes.get("firstObject");
        }

        let isIt = (0, _jquery.default)("#stRecordCanvas" + firstSignal);

        if (isIt.length > 0) {
          let signalCount = allUsedTypes.length;
          let canvasOffset = isIt.offset().top;
          let windowHeight = window.innerHeight;
          let canvasHeightTo = windowHeight - canvasOffset - 45;

          if (!recordEnlarged) {
            canvasHeightTo = canvasHeightTo / signalCount;
          }

          if (canvasHeightTo < 100) {
            canvasHeightTo = 100;
          }

          let recordStChartObj = this.get("recordStChartObj");

          for (let ii = 0; ii < signalCount; ii++) {
            let thisCanvasString = "#stRecordCanvas" + allUsedTypes[ii];
            let thisCanvasBody = (0, _jquery.default)(thisCanvasString);
            thisCanvasBody.height(canvasHeightTo);
            let thisStChartObj = recordStChartObj[ii];

            if (thisStChartObj) {
              thisStChartObj.update();
            }
          }

          Ember.run.later(() => {
            recordStChartObj.forEach(thisStChart => {
              thisStChart.update();
            });
          }, 100);
        }
      } else {
        Ember.run.later(() => {
          this.initCanvas();
        }, 200);
      }
    },

    // Records
    initRecordDiagramConfig() {
      let recordTypesCount = this.get("recordTypesCount");
      let fourColorsBorder = ["rgba(0,255,0,1)", "rgba(255,0,255,1)", "rgba(0,255,255,1)", "rgba(255,255,0,1)"];
      let fourColorsBackground = ["rgba(0,255,0,0.3)", "rgba(255,0,255,0.3)", "rgba(0,255,255,0.3)", "rgba(255,255,0,0.3)"];
      let ltData = {
        labels: [],
        datasets: []
      };
      let recordStChartDatas = this.get("recordStChartDatas");

      for (let iw = 0; iw < recordTypesCount; iw++) {
        let ltDataSnipped = {
          spanGaps: true,
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: fourColorsBorder[iw],
          radius: 0,
          borderWidth: 1,
          tension: 0.3,
          data: []
        };
        ltData.datasets.pushObject(ltDataSnipped);
        let stData = {
          labels: [],
          datasets: [{
            spanGaps: true,
            backgroundColor: fourColorsBackground[iw],
            borderColor: fourColorsBorder[iw],
            pointBackgroundColor: fourColorsBorder[iw],
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(220,220,220,1)",
            tension: 0.3,
            fill: this.get("recordBackgroundTrue"),
            steppedLine: false,
            data: []
          }, {
            spanGaps: true,
            backgroundColor: "rgba(175,175,175,0.3)",
            borderColor: "rgba(255,95,0,1)",
            pointRadius: 0,
            borderWidth: 1,
            fill: "start",
            spanGaps: true,
            showLine: true,
            steppedLine: false,
            data: []
          }, {
            spanGaps: true,
            backgroundColor: "rgba(175,175,175,0.3)",
            borderColor: "rgba(255,95,0,1)",
            pointRadius: 0,
            borderWidth: 1,
            fill: "end",
            spanGaps: true,
            showLine: true,
            steppedLine: false,
            data: []
          }]
        };
        recordStChartDatas[iw] = stData;
      }

      this.set("recordLtChartData", ltData);
      this.set("recordStChartDatas", recordStChartDatas);
      let ltOptions = {
        animation: {
          duration: 0
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: false
          }],
          yAxes: [{
            gridLines: {
              display: true,
              color: "rgba(255,255,255,0.1)"
            },
            afterFit: scaleInstance => {
              scaleInstance.width = 40;
            }
          }]
        },
        point: {
          radius: 0
        }
      };
      this.set("recordLtChartOptions", ltOptions);
      let that = this;

      let customTooltip = function (tooltip) {
        let tooltipEl = document.getElementById("chartjs-tooltip");

        if (tooltip.dataPoints) {
          that.verticalStBar(tooltip.caretX);
          let thatIndex = tooltip.dataPoints[0].index;

          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<table></table>";

            this._chart.canvas.parentNode.appendChild(tooltipEl);
          }

          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          tooltipEl.classList.remove("above", "below", "no-transform");

          if (tooltip.yAlign) {
            tooltipEl.classList.add(tooltip.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          let titleLines = tooltip.title || [];
          let innerHtml = "<thead>";
          titleLines.forEach(title => {
            innerHtml += "<tr><th>Time: " + that.constructReadableTime(title) + "</th></tr>";
          });
          innerHtml += "</thead><tbody>";
          recordStChartDatas.forEach((thisData, i) => {
            if (thisData.labels.length > 0) {
              let style = "background:" + fourColorsBorder[i];
              style += "; border-color:" + fourColorsBorder[i];
              style += "; border-width: 2px";
              let span = '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
              innerHtml += "<tr><td>" + span + thisData.datasets[0].data[thatIndex] + "</td></tr>";
            }
          });
          innerHtml += "</tbody>";
          let tableRoot = tooltipEl.querySelector("table");
          tableRoot.innerHTML = innerHtml;
          let positionY = this._chart.canvas.offsetTop;
          let positionX = this._chart.canvas.offsetLeft;
          tooltipEl.style.opacity = 1;
          tooltipEl.style.zIndex = 100;
          tooltipEl.style.left = positionX + tooltip.caretX + "px";
          tooltipEl.style.top = positionY + tooltip.caretY + "px";
          tooltipEl.style.fontSize = tooltip.fontSize;
          tooltipEl.style.fontStyle = tooltip._fontStyle;
          tooltipEl.style.padding = tooltip.yPadding + "px " + tooltip.xPadding + "px";
        } else {
          if (tooltipEl) {
            tooltipEl.style.opacity = 0;
          }

          that.removeVerticalStBar();
        }
      };

      let stOptions = {
        animation: {
          duration: 0
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: false
          }],
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true
            },
            scaleLabel: {
              display: true,
              labelString: "todo"
            },
            gridLines: {
              display: true,
              color: "rgba(255,255,255,0.1)"
            },
            afterFit: scaleInstance => {
              scaleInstance.width = 50;
            }
          }]
        },
        pointRadius: 4,
        pointBorderWidth: 1,
        pointHoverRadius: 20,
        borderWidth: 2,
        tooltips: {
          enabled: false,
          intersect: false,
          position: "nearest",
          custom: customTooltip
        }
      };
      this.set("recordStChartOptions", stOptions);
    },

    initXYRecordDiagramConfig() {
      let recordTypesCount = this.get("recordTypesCount");
      let fourColorsBorder = ["rgba(0,255,0,1)", "rgba(255,0,255,1)", "rgba(0,255,255,1)", "rgba(255,255,0,1)"];
      let ltData = {
        labels: [],
        datasets: []
      };

      for (let iw = 0; iw < recordTypesCount; iw++) {
        let ltDataSnipped = {
          spanGaps: true,
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: fourColorsBorder[iw],
          radius: 0,
          borderWidth: 1,
          tension: 0.3,
          data: []
        };
        ltData.datasets.pushObject(ltDataSnipped);
      }

      this.set("recordLtChartData", ltData);
      let ltOptions = {
        animation: {
          duration: 0
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: false
          }],
          yAxes: [{
            gridLines: {
              display: true,
              color: "rgba(255,255,255,0.1)"
            },
            afterFit: scaleInstance => {
              scaleInstance.width = 40;
            }
          }]
        },
        point: {
          radius: 0
        }
      };
      this.set("recordLtChartOptions", ltOptions);
    },

    verticalStBar(thisX) {
      let shortSignalColJQ = (0, _jquery.default)("#shortSignalCol");
      let firstChild = shortSignalColJQ[0].children[0];
      let thisMargin = (shortSignalColJQ[0].clientWidth - firstChild.clientWidth) / 2;
      let fourChildHeight = firstChild.clientHeight * this.get("recordTimeTypesCount");
      let stRecordVerticalDiv = document.getElementById("stRecordVerticalDiv");

      if (!stRecordVerticalDiv) {
        let newDiv = document.createElement("div");
        newDiv.setAttribute("id", "stRecordVerticalDiv");
        newDiv.style.width = "3px";
        newDiv.style.height = fourChildHeight + "px";
        newDiv.style.position = "absolute";
        newDiv.style.top = "0px";
        newDiv.style.left = thisX + thisMargin + "px";
        newDiv.style.backgroundColor = "rgba(255,0,0,0.5)";
        newDiv.style.pointerEvents = "none";
        document.getElementById("shortSignalCol").appendChild(newDiv);
      } else {
        stRecordVerticalDiv.style.height = fourChildHeight + "px";
        stRecordVerticalDiv.style.top = "0px";
        stRecordVerticalDiv.style.left = thisX + thisMargin + "px";
      }
    },

    removeVerticalStBar() {
      let stRecordVerticalDiv = (0, _jquery.default)("#stRecordVerticalDiv");

      if (stRecordVerticalDiv.length > 0) {
        stRecordVerticalDiv.remove();
      }
    },

    fillTimeRecordDiagram() {
      let recordXScale = this.get("recordXScaleTrue");
      let rawValues = this.get("rawValues");
      let allUsedTypes = this.get("allUsedTypes");
      let usedTypesLength = allUsedTypes.length;
      let rawValuesCount = rawValues.vals.length;
      this.set("rawValuesCount", rawValuesCount);
      let recordStValueCount = this.get("recordStValueCount");

      if (recordStValueCount > rawValuesCount) {
        recordStValueCount = rawValuesCount;
        this.set("recordStValueCount", recordStValueCount);
      }

      let shortSignalCol = document.getElementById("shortSignalCol");
      let shortRealTimeColWidth = (0, _jquery.default)("#shortRealTimeCol").width();
      let recordStChartDatas = this.get("recordStChartDatas");
      let recordLtChartOptions = this.get("recordLtChartOptions");
      let recordLtChartData = this.get("recordLtChartData");
      let recordStChartObj = this.get("recordStChartObj");
      let ltCanvasElement = document.getElementById("longRecordCanvas");
      let ltContext = ltCanvasElement.getContext("2d");
      let ltNewChart = new _chartjs.default(ltContext, {
        type: "line",
        data: recordLtChartData,
        options: recordLtChartOptions
      });
      let recordStChartOptions = this.get("recordStChartOptions");
      let legendValues = [];
      let il = 0;
      let fourColorsBorder = ["rgba(0,255,0,1)", "rgba(255,0,255,1)", "rgba(0,255,255,1)", "rgba(255,255,0,1)"];
      let rawValueMinMax = this.get("rawValueMinMax");
      let displayMode = this.get("displayMode");

      if (displayMode == 1 || displayMode == 3 || displayMode == 5) {
        allUsedTypes.forEach(thisUsedType => {
          let thisName = rawValues.names[thisUsedType];

          if (thisName) {
            let indexOfThisType = allUsedTypes.indexOf(thisUsedType);
            let newDiv = document.createElement("div");
            newDiv.setAttribute("id", "stRecordDiv" + thisUsedType);
            let newCanvas = document.createElement("canvas");
            newCanvas.setAttribute("id", "stRecordCanvas" + thisUsedType);
            newCanvas.setAttribute("width", shortRealTimeColWidth);
            newCanvas.setAttribute("height", "200");
            newDiv.appendChild(newCanvas);
            shortSignalCol.appendChild(newDiv);
            let stContext = newCanvas.getContext("2d");

            if (il === usedTypesLength - 1) {
              recordStChartOptions.scales.xAxes[0].display = recordXScale;
            }

            recordStChartOptions.scales.yAxes[0].scaleLabel.labelString = thisName;

            if (rawValues.units[thisUsedType] == "binary") {
              recordStChartDatas[indexOfThisType].datasets[0].steppedLine = "middle";
            } else if (rawValues.units[thisUsedType] == "counter") {
              recordStChartDatas[indexOfThisType].datasets[0].steppedLine = "before";
            }

            let stNewChart = new _chartjs.default(stContext, {
              type: "line",
              data: recordStChartDatas[indexOfThisType],
              options: recordStChartOptions
            });
            let thisTypeAllValuesLength = this.get('recordPoints');
            let recordTimeDiff = this.get("recordTimeDiff");
            let interResult = recordTimeDiff / thisTypeAllValuesLength / 1000000;
            let averageDataRate = Math.round(interResult);
            this.set("averageDataRate", averageDataRate);
            let valueCounter = 0;
            let rawLength = rawValues.vals.length;

            for (let iz = 0; iz < rawLength; iz++) {
              let thisEntry = rawValues.vals[iz];
              let thisValue = thisEntry[thisUsedType];
              ltNewChart.data.datasets[indexOfThisType].data.push(thisValue);
              let thisLabel = thisEntry.time;

              if (indexOfThisType === 0) {
                ltNewChart.data.labels.push(thisLabel);
              }

              if (valueCounter < recordStValueCount) {
                stNewChart.data.labels.push(thisLabel);
                let thatValue = thisValue;

                if (valueCounter + 1 == recordStValueCount && isNaN(thisValue)) {
                  let whileCondition = true;
                  let whileCounter = 1;

                  while (whileCondition) {
                    let thatData = rawValues.vals[iz + whileCounter];
                    let theseValue = thatData[thisUsedType];

                    if (Number.isInteger(theseValue)) {
                      thatValue = theseValue;
                      whileCondition = false;
                    } else if (iz + whileCounter >= rawLength) {
                      whileCondition = false;
                    } else {
                      whileCounter++;
                    }
                  }
                }

                stNewChart.data.datasets[0].data.push(thatValue);
              }

              valueCounter++;
              recordStChartObj[indexOfThisType] = stNewChart;
            }

            stNewChart.update();
            let thisMin = Math.round(rawValueMinMax[thisUsedType].min * 100) / 100;
            let thisMax = Math.round(rawValueMinMax[thisUsedType].max * 100) / 100;
            let thisLegendValue = {
              name: thisName,
              unit: rawValues.units[thisUsedType],
              color: fourColorsBorder[il],
              type: thisUsedType,
              selected: false,
              visible: true,
              enlarged: false,
              showLimit: true,
              minAlert: thisMin,
              minActive: false,
              maxAlert: thisMax,
              maxActive: false,
              allMin: thisMin,
              allMax: thisMax
            };
            legendValues.pushObject(thisLegendValue);
            il++;
          }
        });
      }

      ltNewChart.update();
      this.set("legendValues", legendValues);
      this.set("recordLtChartObj", ltNewChart);
      this.reDrawLtChart();
      this.set("recordStChartObj", recordStChartObj);
      Ember.run.later(() => {
        this.initCanvas();
      }, 200);
      this.paintLtBar();
    },

    fillXYRecordDiagram() {
      let rawXYValues = this.get("rawXYValues");
      let xyAllDataLength = this.get("xyAllDataLength");
      this.set("rawXYValuesCount", xyAllDataLength);
      let recordLtChartOptions = this.get("recordLtChartOptions");
      let recordLtChartData = this.get("recordLtChartData");
      let ltCanvasElement = document.getElementById("longRecordCanvas");
      let ltContext = ltCanvasElement.getContext("2d");
      let ltNewChart = new _chartjs.default(ltContext, {
        type: "line",
        data: recordLtChartData,
        options: recordLtChartOptions
      });
      let count = 0;
      let xyIds = this.get("xyIds");
      xyIds.forEach(thatIds => {
        let thisTypeAllXValues = rawXYValues[thatIds.x].vals;
        let thisTypeAllYValues = rawXYValues[thatIds.y].vals;
        thisTypeAllXValues.forEach(thisPair => {
          ltNewChart.data.datasets[count].data.push(thisPair.val);

          if (count === 0) {
            ltNewChart.data.labels.push(thisPair.time);
          }
        });
        count++;
        thisTypeAllYValues.forEach(thisPair => {
          ltNewChart.data.datasets[count].data.push(thisPair.val);
        });
        count++;
      });
      ltNewChart.update();
      let allUsedTypes = [];
      let thisRecord = this.get("selectedRecord");
      let allSignalTypes = this.get("model.signalTypes");
      let thisRecordTypes = thisRecord.get("signalTypes");
      let legendValues = [];
      let fourColorsBorder = ["rgba(0,255,0,1)", "rgba(255,0,255,1)", "rgba(0,255,255,1)", "rgba(255,255,0,1)"];
      let colorcounter = 0;
      thisRecordTypes.forEach(thisRecordType => {
        allSignalTypes.forEach(thisSignalType => {
          let thisSignalTypeId = thisSignalType.get("signalTypeId");

          if (thisRecordType === thisSignalTypeId) {
            allUsedTypes.addObject(thisRecordType);
            let thisLegendValue = {
              name: thisSignalType.get("name"),
              unit: thisSignalType.get("unit"),
              color: fourColorsBorder[colorcounter],
              type: thisRecordType,
              selected: false,
              visible: true
            };
            legendValues.pushObject(thisLegendValue);
            colorcounter++;
          }
        });
      });
      this.set("allUsedTypes", allUsedTypes);
      this.set("legendValues", legendValues);
      this.set("recordLtChartObj", ltNewChart);
      this.reDrawLtChart();
      this.paintLtBar();
    },

    addSliderListener() {
      let valueSlider = document.getElementById("valueSlider");
      let speedSlider = document.getElementById("speedSlider");

      if (valueSlider && speedSlider) {
        valueSlider.addEventListener("input", () => {
          this.valueSlider();
        });
        speedSlider.addEventListener("input", () => {
          this.speedSlider();
        });
      } else {
        Ember.run.later(() => {
          this.addSliderListener();
        }, 100);
      }
    },

    paintLtBar() {
      this.addSliderListener();
      let chosenValueIndex = this.get("chosenValueIndex");
      let recordLtChartObj = this.get("recordLtChartObj");
      let chartAreaLeft = recordLtChartObj.chartArea.left + 10;
      this.set("chartAreaLeft", chartAreaLeft);
      let recordLtChartArea = this.get("recordLtChartObj").chartArea;
      let chartArea = Math.round(recordLtChartArea.right) - Math.round(recordLtChartArea.left);
      let displayMode = this.get("displayMode");
      let rawValuesCount = 10;

      if (displayMode == 1 || displayMode == 3 || displayMode == 5) {
        rawValuesCount = this.get("rawValuesCount");
      } else if (displayMode == 2 || displayMode == 4) {
        rawValuesCount = this.get("rawXYValuesCount");
        this.set("rawValuesCount", rawValuesCount);
      }

      let pxPerValue = chartArea / rawValuesCount;
      this.set("pxPerValue", pxPerValue);
      let recordStValueCount = this.get("recordStValueCount");
      let recordStValueCountWidth = recordStValueCount * pxPerValue;

      if (recordStValueCountWidth < 2) {
        recordStValueCountWidth = 2;
      }

      let ltBarLeft = pxPerValue * chosenValueIndex + chartAreaLeft;
      let thisCanvasWidth = (0, _jquery.default)("#longRecordCanvas").width();
      let maxLeft = thisCanvasWidth - recordStValueCountWidth + 10;

      if (ltBarLeft > maxLeft) {
        ltBarLeft = maxLeft;
      }

      this.set("ltBarLeft", ltBarLeft);
      let ltBarDiv = document.getElementById("ltBarDiv");

      if (ltBarDiv === null) {
        let newDiv = document.createElement("div");
        newDiv.setAttribute("id", "ltBarDiv");
        newDiv.setAttribute("class", "cursor-crosshair");
        newDiv.style.position = "absolute";
        newDiv.style.left = ltBarLeft + "px";
        newDiv.style.top = "0px";
        newDiv.style.width = recordStValueCountWidth + "px";
        let box = (0, _jquery.default)("#longRecordCanvas");

        if (box) {
          newDiv.style.height = box.height() + "px";
        } else {
          newDiv.style.height = "248px";
        }

        newDiv.style.backgroundColor = "rgba(255,75,66,0.5)";
        let longRecordCol = document.getElementById("longRecordCol");
        longRecordCol.appendChild(newDiv);
      } else {
        ltBarDiv.style.left = ltBarLeft + "px";
        ltBarDiv.style.width = recordStValueCountWidth + "px";
      }
    },

    loadThisRecord(thisRecord) {
      this.set('playSpeedXY', 0);
      this.clearObjects();
      let modelSession = [];
      this.get("model.records").forEach(thatRecord => {
        thatRecord.set("selected", false);

        if (thatRecord.session == thisRecord.session) {
          modelSession.pushObject(thatRecord);
        }
      });
      this.set('overviewAbsView', false);
      let ltBarDiv = (0, _jquery.default)("#ltBarDiv");

      if (ltBarDiv.length > 0) {
        ltBarDiv.remove();
      }

      let recordLtChartObj = this.get("recordLtChartObj");

      if (recordLtChartObj) {
        recordLtChartObj.destroy();
      }

      this.set("chosenValueIndex", 0);
      this.set("chosenXYValueIndex", 0);
      let recordStChartObj = this.get("recordStChartObj");

      if (recordStChartObj) {
        recordStChartObj.forEach(thisChartObj => {
          thisChartObj.destroy();
        });
      }

      let shortSignalCol = document.getElementById("shortSignalCol");

      if (shortSignalCol) {
        while (shortSignalCol.firstChild) {
          shortSignalCol.removeChild(shortSignalCol.firstChild);
        }
      }

      this.removeVerticalStBar();
      this.set("recordEnlarged", null);
      thisRecord.set("selected", true);
      this.set("selectedRecord", thisRecord);
      this.set("displayModeOne", false);
      this.set("displayModeTwo", false);
      this.set("displayModeTre", false);
      this.set("displayModeFor", false);
      this.set("displayModeFiv", false);
      this.set("withoutValueCounter", false);
      this.set("recordStValueCount", 40);
      let displayMode = 1;

      if (modelSession.length == 1) {
        if (modelSession[0].xy.length == 0) {
          displayMode = 1;
          this.set("displayModeOne", true);
          (0, _jquery.default)("#detailWrapper").removeClass("invisible");
          this.constructTimeDiag();
        } else if (modelSession[0].xy.length == 1) {
          displayMode = 2;
          this.set("withoutValueCounter", true);
          this.set("recordStValueCount", 1);
          this.set("displayModeTwo", true);
          this.set("colWidth", 12);
        } else if (modelSession[0].xy.length == 2) {
          this.set("withoutValueCounter", true);
          this.set("recordStValueCount", 1);
          displayMode = 4;
          this.set("displayModeFor", true);
          this.set("colWidth", 6);
        }
      } else if (modelSession.length == 2) {
        if (modelSession[0].xy.length == 1 || modelSession[1].xy.length == 1) {
          displayMode = 3;
          this.set("displayModeTre", true);
          this.set("colWidth", 4);
        } else if (modelSession[0].xy.length == 2 || modelSession[1].xy.length == 2) {
          displayMode = 5;
          this.set("displayModeFiv", true);
          this.set("colWidth", 100);
        }
      }

      this.set("displayMode", displayMode);
      let signalTypeIdString = "";
      let thatIds = [];

      if (displayMode > 1) {
        if (modelSession[0].xy.length > 0) {
          modelSession[0].xy.forEach(thatXY => {
            signalTypeIdString = signalTypeIdString + thatXY.xSensor + "," + thatXY.ySensor + ",";
            let thisIds = {
              x: thatXY.xSensor,
              y: thatXY.ySensor
            };
            thatIds.pushObject(thisIds);
          });
        } else if (modelSession[1].xy.length > 0) {
          modelSession[1].xy.forEach(thatXY => {
            signalTypeIdString = signalTypeIdString + thatXY.xSensor + "," + thatXY.ySensor + ",";
            let thisIds = {
              x: thatXY.xSensor,
              y: thatXY.ySensor
            };
            thatIds.pushObject(thisIds);
          });
        }

        this.set("xyIds", thatIds);
        this.set("modelSession", modelSession);
        this.set("idsToRecordXY", signalTypeIdString.substring(0, signalTypeIdString.length - 1));

        if (this.generateXYDatas()) {
          this.requestDeviceMetas();
        }
      }
    },

    constructTimeDiag() {
      let thisRecord = this.get("selectedRecord");
      let rawValueMinMax = [];
      let allSignalTypes = this.get("model.signalTypes");
      let rawValues = {
        signalTypes: [],
        names: {},
        units: {},
        vals: []
      };
      let allUsedTypes = [];
      let thisRecordTypes = thisRecord.get("signalTypes");
      thisRecordTypes.forEach(thisRecordType => {
        allSignalTypes.forEach(thisSignalType => {
          let thisSignalTypeId = thisSignalType.get("signalTypeId");

          if (thisRecordType === thisSignalTypeId) {
            allUsedTypes.addObject(thisRecordType);
            rawValues.signalTypes.push(thisRecordType);
            rawValues.names[thisRecordType] = thisSignalType.get("name");
            rawValues.units[thisRecordType] = thisSignalType.get("unit");
            rawValueMinMax[thisRecordType] = [];
          }
        });
      });
      this.set("allUsedTypes", allUsedTypes);
      let recordTimeTypesCount = thisRecordTypes.get("length");
      let recordTypesCount = recordTimeTypesCount;
      this.set("recordTimeTypesCount", recordTimeTypesCount);
      let displayMode = this.get("displayMode");

      if (displayMode == 3) {
        recordTypesCount = recordTypesCount + 2;
      } else if (displayMode == 5) {
        recordTypesCount = recordTypesCount + 4;
      }

      this.set("recordTypesCount", recordTypesCount);
      let thisRecordId = thisRecord.get("id");

      _jquery.default.ajax({
        url: _app.default.API_URL + "/recordinfos/" + _app.default.chosenDeviceId + "/" + thisRecordId
      }).done(data => {
        this.set("isLoading", false);
        let allValues = data.values;
        let lowTime = allValues.get("firstObject").time;
        let highTime = allValues.get("firstObject").time;
        let startTime = allValues.get("firstObject").time;
        allValues.forEach(thisValue => {
          let thisType = thisValue.signal;
          let newValue = thisValue.value;
          let thisValueTime = thisValue.time;

          if (thisValueTime > highTime) {
            highTime = thisValueTime;
          }

          if (thisValueTime < lowTime) {
            lowTime = thisValueTime;
          }

          let thisTestDown = rawValueMinMax[thisType].min;
          let typeOfThis = typeof thisTestDown;

          if (typeOfThis === "number") {
            let oldMin = rawValueMinMax[thisType].min;
            let oldMax = rawValueMinMax[thisType].max;

            if (newValue < oldMin) {
              rawValueMinMax[thisType].min = newValue;
            } else if (newValue > oldMax) {
              rawValueMinMax[thisType].max = newValue;
            }
          } else {
            rawValueMinMax[thisType].min = newValue;
            rawValueMinMax[thisType].max = newValue;
          }

          let test = rawValues.vals.find(data => data.time == thisValueTime);

          if (test) {
            test[thisType] = newValue;
          } else {
            let box = {
              time: thisValueTime
            };
            box[thisType] = newValue;
            rawValues.vals.push(box);
          }
        });
        let usedSensordIds = rawValues.signalTypes;
        rawValues.vals.forEach(thatValues => {
          usedSensordIds.forEach(thatId => {
            if (typeof thatValues[thatId] === "undefined") {
              thatValues[thatId] = NaN;
            }
          });
        });
        this.set("recordPoints", rawValues.vals.length);
        let timeDiff = highTime - lowTime;
        this.set("recordTimeDiff", timeDiff);
        this.set("rawValueMinMax", rawValueMinMax);
        this.set("rawValues", rawValues);
        this.set("recordDuration", Math.round(timeDiff / 1000000000));
        this.set("recordBegin", this.constructReadableDate(startTime));
        this.initRecordDiagramConfig();
        this.fillTimeRecordDiagram();
        this.paintLtBar();
      });

      this.set("recordLoaded", true);
    },

    constructReadableTime(thisValueTime) {
      let extracted = String(thisValueTime).substring(0, 13);
      let d = new Date(Number(extracted));
      let seconds;
      let boxA = d.getSeconds();

      if (boxA < 10) {
        seconds = "0" + boxA;
      } else {
        seconds = boxA;
      }

      let minutes;
      let boxB = d.getMinutes();

      if (boxB < 10) {
        minutes = "0" + boxB;
      } else {
        minutes = boxB;
      }

      return d.getHours() + ":" + minutes.toString() + "." + seconds.toString() + "." + d.getMilliseconds().toString();
    },

    constructReadableDate(thisValueTime) {
      let extracted = String(thisValueTime).substring(0, 13);
      let d = new Date(Number(extracted));
      let seconds;
      let boxA = d.getSeconds();

      if (boxA < 10) {
        seconds = "0" + boxA;
      } else {
        seconds = boxA;
      }

      let minutes;
      let boxB = d.getMinutes();

      if (boxB < 10) {
        minutes = "0" + boxB;
      } else {
        minutes = boxB;
      }

      return d.getDate() + "." + (d.getMonth() + 1) + "." + (d.getFullYear() - 2000) + " | " + d.getHours() + ":" + minutes.toString() + "." + seconds.toString();
    },

    constructXYDiag() {
      let metaDataOne = this.get("metaDataOne");
      let metaDataTwo = this.get("metaDataTwo");
      let modelSession = this.get("modelSession");
      let thisRecordId = 0;

      if (modelSession.length == 1) {
        thisRecordId = modelSession[0].get("id");
      } else if (modelSession.length == 2) {
        if (modelSession[0].type == "xy") {
          thisRecordId = modelSession[0].get("id");
        } else if (modelSession[1].type == "xy") {
          thisRecordId = modelSession[1].get("id");
        }
      }

      let allSignalTypes = this.get("model.signalTypes");
      let rawValueMinMax = [];
      let xyIds = this.get("xyIds");
      let xyAllData = {};
      xyIds.forEach(thatIds => {
        allSignalTypes.forEach(thisSignal => {
          if (thisSignal.signalTypeId == thatIds.x) {
            xyAllData[thatIds.x] = {
              name: thisSignal.get("name"),
              unit: thisSignal.get("unit"),
              signalType: thatIds.x,
              vals: []
            };
          } else if (thisSignal.signalTypeId == thatIds.y) {
            xyAllData[thatIds.y] = {
              name: thisSignal.get("name"),
              unit: thisSignal.get("unit"),
              signalType: thatIds.y,
              vals: []
            };
          }
        });
        rawValueMinMax[thatIds.x] = [];
        rawValueMinMax[thatIds.y] = [];
      });

      _jquery.default.get(_app.default.API_URL + "/recordinfos/" + _app.default.chosenDeviceId + "/" + thisRecordId, response => {
        this.set("isLoading", false);
        let allValues = response.values;
        let lowTime = allValues.get("firstObject").time;
        let highTime = allValues.get("firstObject").time;
        let startTime = allValues.get("firstObject").time;
        allValues.forEach(thatValue => {
          let thatId = thatValue.signal;
          let newValue = thatValue.value;
          /* TODO: fix readable Time
          let thatData = {
            sID: thatId,
            time: this.constructReadableTime(thatValue.time),
            val: newValue
          };
          */

          let thatData = {
            sID: thatId,
            time: thatValue.time,
            val: newValue
          };
          xyAllData[thatId].vals.pushObject(thatData);

          if (thatValue.time > highTime) {
            highTime = thatValue.time;
          }

          if (thatValue.time < lowTime) {
            lowTime = thatValue.time;
          }

          let thisTestDown = rawValueMinMax[thatId].min;
          let typeOfThis = typeof thisTestDown;

          if (typeOfThis === "number") {
            let oldMin = rawValueMinMax[thatId].min;
            let oldMax = rawValueMinMax[thatId].max;

            if (newValue < oldMin) {
              rawValueMinMax[thatId].min = newValue;
            } else if (newValue > oldMax) {
              rawValueMinMax[thatId].max = newValue;
            }
          } else {
            rawValueMinMax[thatId].min = newValue;
            rawValueMinMax[thatId].max = newValue;
          }
        });
        this.set("rawValueMinMax", rawValueMinMax);
        let timeDiff = highTime - lowTime;
        this.set("recordTimeDiff", timeDiff);
        this.set("rawXYValues", xyAllData);
        this.set("prevXYData", null);
        let xyFirstId = xyIds.get("firstObject");
        let xyAllDataLength = xyAllData[xyFirstId.x].vals.length;

        if (metaDataOne.length > 0) {
          let idOneOne = metaDataOne[0].typeId;
          metaDataOne[0].length = xyAllData[idOneOne].vals.length;
          let idOneTwo = metaDataOne[1].typeId;
          metaDataOne[1].length = xyAllData[idOneTwo].vals.length;
          this.set("metaDataOne", metaDataOne);
        }

        if (metaDataTwo.length > 0) {
          let idTwoOne = metaDataTwo[0].typeId;
          metaDataTwo[0].length = xyAllData[idTwoOne].vals.length;
          let idTwoTwo = metaDataTwo[1].typeId;
          metaDataTwo[1].length = xyAllData[idTwoTwo].vals.length;
          this.set("metaDataTwo", metaDataTwo);
        }

        this.set("xyAllDataLength", xyAllDataLength);
        this.set("refreshRateXY", Math.round(timeDiff / xyAllDataLength / 1000000));
        this.set("recordLoaded", true);
        let displayMode = this.get("displayMode");

        if (displayMode == 2) {
          this.set("legendValues", []);
          this.set("recordTypesCount", 2);
        } else if (displayMode == 3) {} else if (displayMode == 4) {
          this.set("legendValues", []);
          this.set("recordTypesCount", 4);
        } else if (displayMode == 5) {}

        if (displayMode == 2 || displayMode == 4) {
          this.set("recordPoints", xyAllDataLength);
          this.set("recordBegin", this.constructReadableDate(startTime));
          this.set("recordDuration", Math.round(timeDiff / 1000000000));
          this.initXYRecordDiagramConfig();
          this.fillXYRecordDiagram();
        }
      });
    },

    reDrawLtChart() {
      let overviewAbsView = this.get("overviewAbsView");
      let legendValues = this.get("legendValues");
      let allUsedTypes = this.get("allUsedTypes");
      let ltChart = this.get("recordLtChartObj");
      let tmpBox;
      let displayMode = this.get("displayMode");

      if (overviewAbsView) {
        if (displayMode == 2 || displayMode == 4) {
          tmpBox = this.get("rawXYValues");
        } else {
          let box = this.get("rawValues");
          tmpBox = [];
          allUsedTypes.forEach(thatUsedType => {
            tmpBox[thatUsedType] = {
              vals: []
            };
            box.vals.forEach(thisValues => {
              tmpBox[thatUsedType].vals.push({
                val: thisValues[thatUsedType]
              });
            });
          });
        }
      } else {
        let rawRelValues = this.get("rawRelValues");

        if (rawRelValues.length == 0) {
          let rawValues = 0;

          if (displayMode == 2 || displayMode == 4) {
            rawValues = {
              signalTypes: [],
              names: {},
              units: {},
              vals: []
            };
            let tmpRawValues = this.get("rawXYValues");

            for (let [thatKey, thatValue] of Object.entries(tmpRawValues)) {
              rawValues.signalTypes.push(thatValue.signalType);
              rawValues.names[thatValue.signalType] = thatValue.name;
              rawValues.units[thatValue.signalType] = thatValue.unit;
              thatValue.vals.forEach(thatRawVals => {
                let test = rawValues.vals.find(data => data.time == thatRawVals.time);

                if (test) {
                  test[thatValue.signalType] = thatRawVals.val;
                } else {
                  let box = {
                    time: thatRawVals.time
                  };
                  box[thatValue.signalType] = thatRawVals.val;
                  rawValues.vals.push(box);
                }
              });
            }
          } else {
            rawValues = this.get("rawValues");
          }

          let rawValueMinMax = this.get("rawValueMinMax");
          allUsedTypes.forEach(thatUsedType => {
            rawRelValues[thatUsedType] = {
              vals: []
            };
            rawValues.vals.forEach(singlePair => {
              let newValue = (singlePair[thatUsedType] - rawValueMinMax[thatUsedType].min) / (rawValueMinMax[thatUsedType].max - rawValueMinMax[thatUsedType].min);
              rawRelValues[thatUsedType].vals.push({
                val: newValue
              });
            });
          });
          this.set("rawRelValues", rawRelValues);
        }

        tmpBox = rawRelValues;
      }

      let count = 0;
      allUsedTypes.forEach(thatUsedType => {
        ltChart.data.datasets[count].data = [];
        let visible = false;
        legendValues.forEach(thisLegendValue => {
          if (thisLegendValue.type == thatUsedType) {
            visible = thisLegendValue.visible;
          }
        });

        if (visible) {
          tmpBox[thatUsedType].vals.forEach(singlePair => {
            ltChart.data.datasets[count].data.push(singlePair.val);
          });
        }

        count++;
      });
      ltChart.update();
      this.set("recordLtChartObj", ltChart);
    },

    reDrawStChart() {
      let displayMode = this.get("displayMode");

      if (displayMode == 1 || displayMode == 3 || displayMode == 5) {
        let chosenValueIndex = this.get("chosenValueIndex");
        let recordStChartObj = this.get("recordStChartObj");
        let recordStValueCount = this.get("recordStValueCount");
        let rawValues = this.get("rawValues");
        let allUsedTypes = this.get("allUsedTypes");
        let legendValues = this.get("legendValues");
        allUsedTypes.forEach(thisUsedType => {
          let test = rawValues.vals;

          if (test) {
            let takeThat = chosenValueIndex;
            let rawLength = test.length;

            if (chosenValueIndex + recordStValueCount > rawLength) {
              takeThat = rawLength - recordStValueCount;
            }

            if (takeThat < 0) {
              takeThat = 0;
            }

            let indexOfThisType = allUsedTypes.indexOf(thisUsedType);
            let thisChartObj = recordStChartObj[indexOfThisType];
            let thisLegendValue = legendValues[indexOfThisType + 1];
            thisChartObj.data.labels = [];
            thisChartObj.data.datasets[0].data = [];
            thisChartObj.data.datasets[1].data = [];
            thisChartObj.data.datasets[2].data = [];

            for (let iz = 0; iz < recordStValueCount; iz++) {
              let thisIndex = takeThat + iz;
              let thisData = test[thisIndex];

              if (typeof thisData !== "undefined") {
                thisChartObj.data.labels.push(thisData.time);
                let thatValue = thisData[thisUsedType];

                if (iz == 0 && isNaN(thatValue)) {
                  let whileCondition = true;
                  let whileCounter = 1;

                  while (whileCondition) {
                    let thatData = test[thisIndex - whileCounter];
                    let theseValue = thatData[thisUsedType];

                    if (Number.isInteger(theseValue)) {
                      thatValue = theseValue;
                      whileCondition = false;
                    } else if (whileCounter == thisIndex) {
                      whileCondition = false;
                    } else {
                      whileCounter++;
                    }
                  }
                } else if (iz + 1 == recordStValueCount && isNaN(thatValue)) {
                  let whileCondition = true;
                  let whileCounter = 1;

                  while (whileCondition) {
                    let thatData = test[thisIndex + whileCounter];
                    let theseValue = thatData[thisUsedType];

                    if (Number.isInteger(theseValue)) {
                      thatValue = theseValue;
                      whileCondition = false;
                    } else if (thisIndex + whileCounter >= rawLength) {
                      whileCondition = false;
                    } else {
                      whileCounter++;
                    }
                  }
                }

                thisChartObj.data.datasets[0].data.push(thatValue);
              }
            }

            if (thisLegendValue) {
              thisChartObj.data.datasets[1].showLine = thisLegendValue.minActive;

              if (thisLegendValue.minActive) {
                thisChartObj.data.datasets[1].data[0] = thisLegendValue.minAlert;
                thisChartObj.data.datasets[1].data[recordStValueCount] = thisLegendValue.minAlert;
              }

              thisChartObj.data.datasets[2].showLine = thisLegendValue.maxActive;

              if (thisLegendValue.maxActive) {
                thisChartObj.data.datasets[2].data[0] = thisLegendValue.maxAlert;
                thisChartObj.data.datasets[2].data[recordStValueCount] = thisLegendValue.maxAlert;
              }
            }

            thisChartObj.update();
            Ember.run.later(() => {
              thisChartObj.update();
            }, 100);
          }
        });
      }
    },

    reDrawXYChart() {
      let rawXYValues = this.get("rawXYValues");
      let chosenXYValueIndex = this.get("chosenXYValueIndex");

      if (chosenXYValueIndex > 0) {
        let newDataArray = {
          vals: []
        };

        for (let i = 0; i < chosenXYValueIndex; i++) {
          let rawXYValuesKeys = Object.keys(rawXYValues);

          for (let j = 0; j < rawXYValuesKeys.length; j++) {
            let singleValue = rawXYValues[rawXYValuesKeys[j]].vals[i];
            let thatValue = {
              val: singleValue.val,
              sID: singleValue.sID
            };
            newDataArray.vals.pushObject(thatValue);
          }
        }

        this.set("prevXYData", newDataArray);
      }
    },

    updateXYChart() {
      let newValue = this.get("chosenXYValueIndex");
      let rawXYValues = this.get("rawXYValues");
      let xySignalList = this.get("xySignalList");
      let thatValues = {
        vals: []
      };
      xySignalList.forEach(thisId => {
        let box = rawXYValues[thisId].vals[newValue];
        thatValues.vals.pushObject(box);
      });
      this.set("xyData", thatValues);
    },

    valueSlider() {
      let thisSlider = document.getElementById("valueSlider");
      this.set("recordStValueCount", parseInt(thisSlider.value));
      this.reDrawStChart();
      this.paintLtBar();
    },

    speedSlider() {
      let thisSlider = document.getElementById("speedSlider");
      this.set("playSpeed", parseInt(thisSlider.value));

      if (this.get("recordRun")) {
        if (this.stopRecordRun() == true) {
          this.startRecordRun();
        }
      }
    },

    startRecordRun() {
      let displayMode = this.get("displayMode");
      let rawValuesCount = this.get("rawValuesCount");
      let recordStValueCount = this.get("recordStValueCount");
      let maxCounts = rawValuesCount - recordStValueCount;
      let playSpeed = this.get("playSpeed");
      let playSpeedLT = 100;
      let refreshRateXY = this.get("refreshRateXY");
      let playSpeedXY = refreshRateXY / playSpeed * 100;
      let averageDataRate = this.get("averageDataRate");
      let playSpeedTime = averageDataRate / playSpeed * 100;

      if (displayMode == 2 || displayMode == 4) {
        playSpeedLT = playSpeedXY;
      } else {
        playSpeedLT = playSpeedTime;
      }

      let recordRunInterval = setInterval(() => {
        this.removeVerticalStBar();
        let chosenValueIndex = this.get("chosenValueIndex");
        let newValue = chosenValueIndex;

        if (maxCounts > newValue) {
          this.reDrawStChart();
          this.paintLtBar();
          this.set("chosenValueIndex", newValue + 1);
        } else {
          this.set("recordRun", false);
          this.stopRecordRun();
        }
      }, playSpeedLT); // XY

      let recordRunXYInterval = null;

      if (displayMode > 1) {
        recordRunXYInterval = setInterval(() => {
          let chosenXYValueIndex = this.get("chosenXYValueIndex");
          let newXYValue = chosenXYValueIndex;

          if (maxCounts > newXYValue) {
            this.updateXYChart();
            this.set("chosenXYValueIndex", newXYValue + 1);
          }
        }, playSpeedXY);
      }

      this.set("recordRunInterval", recordRunInterval);
      this.set("recordRunXYInterval", recordRunXYInterval);
    },

    stopRecordRun() {
      let recordRunInterval = this.get("recordRunInterval");
      clearInterval(recordRunInterval);
      let recordRunXYInterval = this.get("recordRunXYInterval");

      if (recordRunXYInterval) {
        clearInterval(recordRunXYInterval);
        this.set("recordRunXYInterval", null);
      }

      return true;
    },

    timestampToReadableTime(timestamp) {
      let originTimeStamp = Math.round(timestamp / 1000000); //1493283428168

      let thisTimeStamp = new Date(originTimeStamp);
      let hours = thisTimeStamp.getHours();
      let minutes = thisTimeStamp.getMinutes();
      let seconds = thisTimeStamp.getSeconds();

      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      return hours + ":" + minutes + ":" + seconds;
    },

    timestampToReadableDay(timestamp) {
      let originTimeStamp = Math.round(timestamp / 1000000); //1493283428168

      let thisTimeStamp = new Date(originTimeStamp);
      let days = thisTimeStamp.getDate();
      let months = thisTimeStamp.getMonth() + 1;
      let years = thisTimeStamp.getFullYear();

      if (days < 10) {
        days = "0" + days;
      }

      if (months < 10) {
        months = "0" + months;
      }

      return days + "." + months + "." + years;
    },

    mouseActions(mouseEvent) {
      let firstX = this.get("mouseDownX");
      let xPosition = 0;
      let targetId = mouseEvent.target.id;

      if (targetId === "ltBarDiv") {
        let ltBarLeft = this.get("ltBarLeft");
        let chartAreaLeft = this.get("chartAreaLeft");
        xPosition = ltBarLeft + mouseEvent.layerX - chartAreaLeft;
      } else if (targetId === "longRecordCanvas") {
        xPosition = mouseEvent.layerX - 40;
      } else {
        return;
      }

      if (xPosition < 0) {
        xPosition = 0;
      }

      let pxPerValue = this.get("pxPerValue");
      let firstValueToShow = 0;

      if (xPosition == firstX) {
        let clickedValue = xPosition / pxPerValue;
        let halfValueCount = this.get("recordStValueCount") / 2;
        firstValueToShow = Math.round(clickedValue - halfValueCount);
      } else {
        let xBegin = 0;
        let mouseWay = 0;

        if (xPosition < firstX) {
          xBegin = xPosition;
          mouseWay = firstX - xPosition;
        } else {
          xBegin = firstX;
          mouseWay = xPosition - firstX;
        }

        let newCount = Math.round(mouseWay * (1 / pxPerValue));
        this.set("recordStValueCount", newCount);
        firstValueToShow = Math.round(xBegin / pxPerValue);
      }

      if (firstValueToShow < 0) {
        firstValueToShow = 0;
      }

      this.set("chosenValueIndex", firstValueToShow);
      this.set("chosenXYValueIndex", firstValueToShow);
      this.reDrawStChart();
      let displayMode = this.get("displayMode");

      if (displayMode > 1) {
        this.reDrawXYChart();
      }

      this.paintLtBar();
    },

    toggleAbsRel() {
      let overviewAbsView = this.get("overviewAbsView");
      let legendValues = this.get("legendValues");

      if (overviewAbsView) {
        let firstTrue = true;
        legendValues.forEach(thisLegend => {
          Ember.set(thisLegend, "visible", false);

          if (firstTrue) {
            Ember.set(thisLegend, "visible", true);
            firstTrue = false;
          }
        });
      } else {
        legendValues.forEach(thisLegend => {
          Ember.set(thisLegend, "visible", true);
        });
      }

      this.reDrawLtChart();
    },

    actions: {
      resizeWindow() {
        let windowResizedTrigger = this.get("windowResizedTrigger");
        this.set("windowResizedTrigger", !windowResizedTrigger);
      },

      firstRunAfterRender() {
        this.clearObjects();
        let preSelectedRecord = this.get("preSelectedRecord");

        if (preSelectedRecord) {
          this.set("noRecord", false);
          Ember.run.scheduleOnce("afterRender", () => {
            this.loadThisRecord(preSelectedRecord);
          });
        } else {
          this.set("noRecord", true);
        }
      },

      switchToAbsolute() {
        let overviewAbsView = true;
        this.set("overviewAbsView", overviewAbsView);
        this.toggleAbsRel();
      },

      switchToRelative() {
        let overviewAbsView = false;
        this.set("overviewAbsView", overviewAbsView);
        this.toggleAbsRel();
      },

      setRecordStValueCount() {
        let recordStValueCount = parseInt(this.get("recordStValueCount"));
        let rawValuesCount = this.get("rawValuesCount");

        if (recordStValueCount > rawValuesCount) {
          recordStValueCount = rawValuesCount;
        }

        this.set("recordStValueCount", recordStValueCount);
        this.reDrawStChart();
        this.paintLtBar();
      },

      //////////// on the fly configuration
      toggleRecordBackground() {
        let recordBackgroundTrue = this.get("recordBackgroundTrue");
        this.set("recordBackgroundTrue", !recordBackgroundTrue);
        let recordStChartObj = this.get("recordStChartObj");
        recordStChartObj.forEach(stThisChart => {
          stThisChart.config.data.datasets[0].fill = !recordBackgroundTrue;
          stThisChart.update();
        });
      },

      toggleRecordLimitBackground() {
        let recordLimitBackgroundTrue = this.get("recordLimitBackgroundTrue");
        let switchOneTo = false;
        let switchTwoTo = false;

        if (!recordLimitBackgroundTrue) {
          switchOneTo = "start";
          switchTwoTo = "end";
        }

        this.set("recordLimitBackgroundTrue", !recordLimitBackgroundTrue);
        let recordStChartObj = this.get("recordStChartObj");
        recordStChartObj.forEach(stThisChart => {
          stThisChart.config.data.datasets[1].fill = switchOneTo;
          stThisChart.config.data.datasets[2].fill = switchTwoTo;
          stThisChart.update();
        });
      },

      toggleRecordLimitLine() {
        let recordLimitLineTrue = this.get("recordLimitLineTrue");
        this.set("recordLimitLineTrue", !recordLimitLineTrue);
        let switchTo = "rgba(255,95,0,0)";

        if (!recordLimitLineTrue) {
          switchTo = "rgba(255,95,0,1)";
        }

        let recordStChartObj = this.get("recordStChartObj");
        recordStChartObj.forEach(stThisChart => {
          stThisChart.config.data.datasets[1].borderColor = switchTo;
          stThisChart.config.data.datasets[2].borderColor = switchTo;
          stThisChart.update();
        });
      },

      toggleRecordXScale() {
        let recordXScaleTrue = this.get("recordXScaleTrue");
        this.set("recordXScaleTrue", !recordXScaleTrue);
        let signalCount = this.get("recordStChartObj").length - 1;
        let stAllCharts = this.get("recordStChartObj");
        let stThisChart = stAllCharts[signalCount];
        stThisChart.options.scales.xAxes[0].display = !recordXScaleTrue;
        stThisChart.update();
      },

      //////////// do things with records
      chooseRecord(thisRecord) {
        this.set("recordLoaded", true);
        this.set("noRecord", false);
        this.set("isLoading", true);
        this.loadThisRecord(thisRecord);
      },

      ////////////////////////// temporary deactivated
      scaleToMe(thisType) {
        let legendValues = this.get("legendValues");
        legendValues.forEach(thatValue => {
          Ember.set(thatValue, "selected", false);
        });
        Ember.set(thisType, "selected", true);
        let recordLtChartObj = this.get("recordLtChartObj");

        if (thisType.type === "complete") {
          delete recordLtChartObj.options.scales.yAxes[0].ticks.min;
          delete recordLtChartObj.options.scales.yAxes[0].ticks.max;
        } else {
          let rawValueMinMax = this.get("rawValueMinMax");
          let thatMin = rawValueMinMax[thisType.type].min;
          let thatMax = rawValueMinMax[thisType.type].max;

          if (thatMin < 0) {
            let boxMin = Math.abs(thatMin);
            let boxBoxMin = boxMin * 1.05;
            thatMin = boxBoxMin * -1;
          } else {
            thatMin = thatMin * 1.05;
          }

          if (thatMax < 0) {
            let boxMax = Math.abs(thatMax);
            let boxBoxMax = boxMax * 1.05;
            thatMax = boxBoxMax * -1;
          } else {
            thatMax = thatMax * 1.05;
          }

          recordLtChartObj.options.scales.yAxes[0].ticks.min = Math.round(thatMin);
          recordLtChartObj.options.scales.yAxes[0].ticks.max = Math.round(thatMax);
        }

        recordLtChartObj.update();
      },

      showOnlyMe(thisType) {
        let legendValues = this.get("legendValues");
        legendValues.forEach(thisLegend => {
          Ember.set(thisLegend, "visible", false);
        });
        Ember.set(thisType, "visible", true);
        this.reDrawLtChart();
      },

      toggleShowMe(thisType) {
        let oldState = thisType.visible;
        Ember.set(thisType, "visible", !oldState);
        this.reDrawLtChart();
      },

      mouseDown(mouseEvent) {
        let xPosition = 0;
        let targetId = mouseEvent.target.id;

        if (targetId === "ltBarDiv") {
          let ltBarLeft = this.get("ltBarLeft");
          let chartAreaLeft = this.get("chartAreaLeft");
          xPosition = ltBarLeft + mouseEvent.layerX - chartAreaLeft;
        } else if (targetId === "longRecordCanvas") {
          xPosition = mouseEvent.layerX - 40;
        } else {
          return;
        }

        if (xPosition < 0) {
          xPosition = 0;
        }

        this.set("mouseDownX", xPosition);
        this.set("mouseDownZ", true);
        this.removeVerticalStBar();
      },

      mouseMoved(mouseEvent) {
        let mouseDownZ = this.get("mouseDownZ");

        if (mouseDownZ) {
          this.mouseActions(mouseEvent);
        }
      },

      mouseUp(mouseEvent) {
        this.set("mouseDownZ", false);
        this.mouseActions(mouseEvent);
      },

      toggleRunRecord() {
        let recordRun = this.get("recordRun");

        if (recordRun) {
          this.stopRecordRun();
        } else {
          this.startRecordRun();
        }

        this.set("recordRun", !recordRun);
      },

      valuesChanged() {
        this.reDrawStChart();
      },

      enlargeThis(thisType) {
        let recordEnlarged = this.get("recordEnlarged");
        let legendValues = this.get("legendValues");

        if (recordEnlarged === thisType) {
          legendValues.forEach(thisLegendValue => {
            let thisLegendType = thisLegendValue.type;
            let thisDiv = document.getElementById("stRecordDiv" + thisLegendType);

            if (thisDiv) {
              Ember.set(thisLegendValue, "enlarged", false);
              thisDiv.style.display = "block";
            }
          });
          this.set("recordEnlarged", null);
        } else {
          legendValues.forEach(thisLegendValue => {
            let thisLegendType = thisLegendValue.type;

            if (thisLegendType === thisType) {
              this.set("recordEnlarged", thisType);
              Ember.set(thisLegendValue, "enlarged", true);
              let thisDiv = document.getElementById("stRecordDiv" + thisLegendType);
              thisDiv.style.display = "block";
            } else {
              let thisDiv = document.getElementById("stRecordDiv" + thisLegendType);

              if (thisDiv) {
                Ember.set(thisLegendValue, "enlarged", false);
                thisDiv.style.display = "none";
              }
            }
          });
        }

        this.initCanvas();
      },

      loadRecordDown(selected) {
        let thisRecord = this.get("selectedRecord");
        let device = this.get("model.device");
        let timeStart = this.timestampToReadableTime(thisRecord.start);
        let timeEnd = this.timestampToReadableTime(thisRecord.end);
        let alertDay = this.timestampToReadableDay(thisRecord.start);
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += "//Device Model:" + device.deviceModel + ";Serial:" + device.deviceSerial + "Record Name:" + thisRecord.name + ";Time Start:" + timeStart + ";Time End:" + timeEnd + ";Day:" + alertDay + "\r\n";
        let shortenValues = [];
        let headerRow = "Time;";
        let displayMode = this.get("displayMode");

        if (displayMode == 1 || displayMode == 3 || displayMode == 5) {
          let rawValues = this.get("rawValues");
          let rawValuesKeys = Object.keys(rawValues);

          for (let j = 0; j < rawValuesKeys.length; j++) {
            shortenValues.push(rawValues[rawValuesKeys[j]]);
          }
        }

        if (displayMode > 1) {
          let rawValues = this.get("rawXYValues");
          let rawValuesKeys = Object.keys(rawValues);

          for (let j = 0; j < rawValuesKeys.length; j++) {
            shortenValues.push(rawValues[rawValuesKeys[j]]);
          }
        }

        let valueCounter = 0;
        let firstValueToDL = 0;
        let recordTypesCount = shortenValues.length;

        if (selected) {
          firstValueToDL = this.get("chosenValueIndex");
          valueCounter = firstValueToDL + this.get("recordStValueCount");
        } else {
          valueCounter = shortenValues[0].vals.length;

          for (let j = 0; j < recordTypesCount; j++) {
            if (shortenValues[j].vals.length > valueCounter) {
              valueCounter = shortenValues[j].vals.length;
            }
          }
        }

        for (let i = firstValueToDL; i < valueCounter; i++) {
          let regRow = "";

          for (let j = 0; j < recordTypesCount; j++) {
            if (i == firstValueToDL) {
              headerRow += shortenValues[j].name;

              if (j < recordTypesCount - 1) {
                headerRow += ";";
              } else {
                headerRow += "\r\n";
                csvContent += headerRow;
              }
            }

            if (j == 0) {
              regRow += shortenValues[j].vals[i].time + ";";
            }

            if (shortenValues[j].vals[i]) {
              regRow += shortenValues[j].vals[i].val;
            }

            if (j < recordTypesCount - 1) {
              regRow += ";";
            } else {
              regRow += "\r\n";
            }
          }

          csvContent += regRow;
        }

        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        let fileName = device.deviceSerial + "_" + Math.round(thisRecord.start / 1000000) + ".csv";
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      },

      runToLeave() {
        this.set("isLoading", true);
        this.set("recordEnlarged", null);
        let shortRealTimeCol = document.getElementById("shortSignalCol");

        if (shortRealTimeCol) {
          while (shortRealTimeCol.firstChild) {
            shortRealTimeCol.removeChild(shortRealTimeCol.firstChild);
          }
        }
      }

    }
  });

  _exports.default = _default;
});