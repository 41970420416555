define("machine-monitoring/configuration/customers/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.get("store").findAll("customer");
    },

    renderTemplate(controller, model) {
      console.log('customer model: ', model);
      this.render("configuration/customers", {
        into: "application",
        outlet: "content",
        controller: controller,
        model: model
      });
    }

  });

  _exports.default = _default;
});