define("machine-monitoring/components/load-more/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      loadMore() {
        this.loadMore();
      }

    },

    didReceiveAttrs() {
      this._super(...arguments);

      let allData = this.get('allData');
      let currentShown = this.get('currentShown');

      if (currentShown < allData) {
        this.set("possibleNext", true);
      } else {
        this.set("possibleNext", false);
      }
    }

  });

  _exports.default = _default;
});