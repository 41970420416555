define("machine-monitoring/devices/details/anomalie/create/controller", ["exports", "machine-monitoring/app", "jquery"], function (_exports, _app, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    anomalieController: Ember.inject.controller('devices.details.anomalie'),
    diagram: {},
    xSensor: false,
    ySensor: false,
    sensorChoosen: null,
    thatDiagram: null,
    actions: {
      dismissCreating() {
        this.set('diagram', {});
        this.set('xSensor', false);
        this.set('ySensor', false);
        this.transitionToRoute('devices.details.anomalie');
      },

      chooseSensor(thatSensor) {
        this.set('sensorChoosen', thatSensor);
        (0, _jquery.default)("#modalChooseSignal").modal("show");
      },

      selectThisSignalType(signalType) {
        let selectedS = signalType.selectedS;
        let thatSensor = this.get('sensorChoosen');

        if (thatSensor) {
          if (selectedS) {
            this.set('ySensor', false);
          } else {
            this.set('ySensor', signalType);
          }
        } else {
          if (selectedS) {
            this.set('xSensor', false);
          } else {
            this.set('xSensor', signalType);
          }
        }

        signalType.set('selectedS', !selectedS);
      },

      closeModal() {
        let allSignalTypes = this.get('model.signalTypes');
        allSignalTypes.forEach(element => {
          element.set('selectedS', false);
        });
      },

      saveCreating() {
        let that = this;
        let deviceId = _app.default.chosenDeviceId;
        let xSensorID = parseInt(this.get('xSensor.signalTypeId'));
        let ySensorID = parseInt(this.get('ySensor.signalTypeId'));
        let diagram = this.get('diagram');
        let diagramToSend = {
          x_rate: parseFloat(diagram.xSensorRate),
          y_rate: parseFloat(diagram.ySensorRate),
          width: parseInt(diagram.width),
          height: parseInt(diagram.height),
          min_x: parseFloat(diagram.minX),
          max_x: parseFloat(diagram.maxX),
          min_y: parseFloat(diagram.minY),
          max_y: parseFloat(diagram.maxY),
          max_dist: parseInt(diagram.maxDistance),
          count_cached_values: parseInt(diagram.countCached)
        };
        let stringifiedDiagramData = JSON.stringify(diagramToSend);
        let url = _app.default.API_URL + "/pf/diagram/" + deviceId + "/" + xSensorID + "/" + ySensorID;

        _jquery.default.ajax({
          method: "POST",
          url: url,
          contentType: "text/plain",
          data: stringifiedDiagramData
        }).done(function () {
          _jquery.default.ajax({
            url: url + "/opts"
          }).done(data => {
            let xyPairToUse = {
              opts: data,
              deviceId: deviceId,
              xSensorID: xSensorID,
              ySensorID: ySensorID,
              saved: false,
              name: null
            };
            that.set('thatDiagram', xyPairToUse);
            that.get("anomalieController").set("xyPairToUse", xyPairToUse);
            that.transitionToRoute("devices.details.anomalie.details", deviceId);
          });
        }).fail(function (jqXHR) {
          console.log('post failed jqXHR: ', jqXHR);
        });
      }

    }
  });

  _exports.default = _default;
});