define("machine-monitoring/location/production/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      /*
      let test = $.getJSON("example_fabric_schmenatic.json");
      console.log('model test: ',test);
      return test;
      */
      let test = {
        "attrs": {
          "width": 1500,
          "height": 890,
          "fill": "#e6e6e6"
        },
        "className": "Stage",
        "children": [{
          "attrs": {},
          "className": "Layer",
          "children": [{
            "attrs": {
              "id": "konvaElementR1",
              "x": 85,
              "y": 60,
              "width": "160",
              "height": "80",
              "fill": "#acceae",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 1,
              "tmpOpacity": 1,
              "tmpZIndex": 0,
              "hasText": 0
            },
            "className": "Rect"
          }, {
            "attrs": {
              "id": "konvaElementR2",
              "x": 300,
              "y": 60,
              "width": "160",
              "height": "80",
              "fill": "#acceae",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 1,
              "tmpOpacity": 1,
              "tmpZIndex": 1,
              "hasText": 2
            },
            "className": "Rect"
          }, {
            "attrs": {
              "id": "konvaElementR3",
              "x": 525,
              "y": 60,
              "width": "375",
              "height": "80",
              "fill": "#acceae",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 1,
              "tmpOpacity": 1,
              "tmpZIndex": 2,
              "hasText": 0
            },
            "className": "Rect"
          }, {
            "attrs": {
              "id": "konvaElementR5",
              "x": 85,
              "y": 180,
              "width": "160",
              "height": "80",
              "fill": "#d0aeae",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 1,
              "tmpOpacity": 1,
              "tmpZIndex": 4,
              "hasText": 1
            },
            "className": "Rect"
          }, {
            "attrs": {
              "id": "konvaElementR6",
              "x": 300,
              "y": 180,
              "width": "160",
              "height": "80",
              "fill": "#d0aeae",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 1,
              "tmpOpacity": 1,
              "tmpZIndex": 5,
              "hasText": 1
            },
            "className": "Rect"
          }, {
            "attrs": {
              "id": "konvaElementR7",
              "x": 525,
              "y": 180,
              "width": "375",
              "height": "80",
              "fill": "#d0aeae",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 1,
              "tmpOpacity": 1,
              "tmpZIndex": 6,
              "hasText": 0
            },
            "className": "Rect"
          }, {
            "attrs": {
              "id": "konvaElementR9",
              "x": 85,
              "y": 290,
              "width": "380",
              "height": "80",
              "fill": "#acb0ca",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 1,
              "tmpOpacity": 1,
              "tmpZIndex": 8,
              "hasText": 0
            },
            "className": "Rect"
          }, {
            "attrs": {
              "id": "konvaElementR10",
              "x": 85,
              "y": 410,
              "width": "380",
              "height": "80",
              "fill": "#acb0ca",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 1,
              "tmpOpacity": 1,
              "tmpZIndex": 9,
              "hasText": 0
            },
            "className": "Rect"
          }, {
            "attrs": {
              "id": "konvaElementR11",
              "x": 525,
              "y": 290,
              "width": "160",
              "height": "200",
              "fill": "#c1c1c1",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 1,
              "tmpOpacity": 1,
              "tmpZIndex": 10,
              "hasText": 8
            },
            "className": "Rect"
          }, {
            "attrs": {
              "id": "konvaElementR12",
              "x": 740,
              "y": 290,
              "width": "160",
              "height": "80",
              "fill": "#c1c1c1",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 1,
              "tmpOpacity": 1,
              "tmpZIndex": 11,
              "hasText": 0
            },
            "className": "Rect"
          }, {
            "attrs": {
              "id": "konvaElementR13",
              "x": 740,
              "y": 410,
              "width": "160",
              "height": "80",
              "fill": "#c1c1c1",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 1,
              "tmpOpacity": 1,
              "tmpZIndex": 12,
              "hasText": 0
            },
            "className": "Rect"
          }, {
            "attrs": {
              "id": "konvaElementR14",
              "x": 990,
              "y": 120,
              "width": "450",
              "height": "240",
              "fill": "#000244",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 1,
              "tmpOpacity": 1,
              "tmpZIndex": 7,
              "hasText": 0
            },
            "className": "Rect"
          }, {
            "attrs": {
              "id": "konvaElementCi1",
              "x": 125,
              "y": 580,
              "radius": 20,
              "fill": "#ff0000",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 2,
              "tmpOpacity": 1,
              "tmpZIndex": 16
            },
            "className": "Circle"
          }, {
            "attrs": {
              "id": "konvaElementCi2",
              "x": 170,
              "y": 580,
              "radius": 40,
              "fill": "#ab0000",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 2,
              "tmpOpacity": 1,
              "tmpZIndex": 15
            },
            "className": "Circle"
          }, {
            "attrs": {
              "id": "konvaElementCi3",
              "x": 250,
              "y": 580,
              "radius": 60,
              "fill": "#560000",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 2,
              "tmpOpacity": 1,
              "tmpZIndex": 14
            },
            "className": "Circle"
          }, {
            "attrs": {
              "id": "konvaElementCi4",
              "x": 350,
              "y": 580,
              "radius": 80,
              "fill": "#000000",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 2,
              "tmpOpacity": 1,
              "tmpZIndex": 13
            },
            "className": "Circle"
          }, {
            "attrs": {
              "id": "konvaElementEl1",
              "x": 750,
              "y": 580,
              "radiusX": 160,
              "radiusY": 40,
              "fill": "#00ffff",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 3,
              "tmpOpacity": 1,
              "tmpZIndex": 14
            },
            "className": "Ellipse"
          }, {
            "attrs": {
              "id": "konvaElementEl2",
              "x": 820,
              "y": 610,
              "radiusX": 160,
              "radiusY": 40,
              "fill": "#009999",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 3,
              "tmpOpacity": 1,
              "tmpZIndex": 14
            },
            "className": "Ellipse"
          }, {
            "attrs": {
              "id": "konvaElementEl3",
              "x": 890,
              "y": 640,
              "radiusX": 160,
              "radiusY": 40,
              "fill": "#004444",
              "stroke": "#000000",
              "strokeWidth": 1,
              "elementType": 3,
              "tmpOpacity": 1,
              "tmpZIndex": 14
            },
            "className": "Ellipse"
          }, {
            "attrs": {
              "id": "konvaElementLi1",
              "points": [85, 800, 85, 700, 1080, 700, 1080, 450, 1440, 450, 1440, 700, 1250, 550, 1250, 800],
              "stroke": "#0000ff",
              "strokeWidth": 10,
              "lineCap": "round",
              "lineJoin": "round",
              "elementType": 4,
              "tmpOpacity": 1,
              "tmpZIndex": 15
            },
            "className": "Line"
          }, {
            "attrs": {
              "id": "konvaElementTe1",
              "x": 1000,
              "y": 60,
              "text": "Examples of Elements",
              "tmpText": "Examples of Elements",
              "fontSize": 40,
              "fontFamily": "Calibri",
              "fill": "#000000",
              "elementType": 5,
              "tmpOpacity": 1,
              "tmpZIndex": 26
            },
            "className": "Text"
          }, {
            "attrs": {
              "id": "konvaElementTe2",
              "x": 90,
              "y": 70,
              "text": "Rect",
              "tmpText": "Rect",
              "fontSize": 30,
              "fontFamily": "Calibri",
              "fill": "#000000",
              "elementType": 5,
              "tmpOpacity": 1,
              "tmpZIndex": 26
            },
            "className": "Text"
          }, {
            "attrs": {
              "id": "konvaElementTe3",
              "x": 90,
              "y": 510,
              "text": "Circle",
              "tmpText": "Circle",
              "fontSize": 30,
              "fontFamily": "Calibri",
              "fill": "#000000",
              "elementType": 5,
              "tmpOpacity": 1,
              "tmpZIndex": 16
            },
            "className": "Text"
          }, {
            "attrs": {
              "id": "konvaElementTe4",
              "x": 710,
              "y": 565,
              "text": "Ellipse",
              "tmpText": "Ellipse",
              "fontSize": 30,
              "fontFamily": "Calibri",
              "fill": "#000000",
              "elementType": 5,
              "tmpOpacity": 1,
              "tmpZIndex": 26
            },
            "className": "Text"
          }, {
            "attrs": {
              "id": "konvaElementTe5",
              "x": 100,
              "y": 660,
              "text": "Line",
              "tmpText": "Line",
              "fontSize": 30,
              "fontFamily": "Calibri",
              "fill": "#000000",
              "elementType": 5,
              "tmpOpacity": 1,
              "tmpZIndex": 26
            },
            "className": "Text"
          }, {
            "attrs": {
              "id": "konvaElementTe6",
              "x": 1000,
              "y": 130,
              "text": "Gauge",
              "tmpText": "Gauge",
              "fontSize": 30,
              "fontFamily": "Calibri",
              "fill": "#ffffff",
              "elementType": 5,
              "tmpOpacity": 1,
              "tmpZIndex": 26
            },
            "className": "Text"
          }, {
            "attrs": {
              "id": "konvaElementTe7",
              "x": 90,
              "y": 425,
              "text": "Indicators",
              "tmpText": "Indicators",
              "fontSize": 30,
              "fontFamily": "Calibri",
              "fill": "#000000",
              "elementType": 5,
              "tmpOpacity": 1,
              "tmpZIndex": 26
            },
            "className": "Text"
          }, {
            "attrs": {
              "id": "konvaElementTe8",
              "x": 90,
              "y": 300,
              "text": "Level",
              "tmpText": "Level",
              "fontSize": 30,
              "fontFamily": "Calibri",
              "fill": "#000000",
              "elementType": 5,
              "tmpOpacity": 1,
              "tmpZIndex": 26
            },
            "className": "Text"
          }, {
            "attrs": {
              "id": "konvaElementTe9",
              "x": 90,
              "y": 330,
              "text": "Type 0",
              "tmpText": "Type 0",
              "fontSize": 30,
              "fontFamily": "Calibri",
              "fill": "#000000",
              "elementType": 5,
              "tmpOpacity": 1,
              "tmpZIndex": 26
            },
            "className": "Text"
          }, {
            "attrs": {
              "id": "konvaElementTe10",
              "x": 530,
              "y": 70,
              "text": "Level",
              "tmpText": "Level",
              "fontSize": 30,
              "fontFamily": "Calibri",
              "fill": "#000000",
              "elementType": 5,
              "tmpOpacity": 1,
              "tmpZIndex": 26
            },
            "className": "Text"
          }, {
            "attrs": {
              "id": "konvaElementTe11",
              "x": 530,
              "y": 100,
              "text": "Type 1",
              "tmpText": "Type 1",
              "fontSize": 30,
              "fontFamily": "Calibri",
              "fill": "#000000",
              "elementType": 5,
              "tmpOpacity": 1,
              "tmpZIndex": 26
            },
            "className": "Text"
          }, {
            "attrs": {
              "id": "konvaElementTe12",
              "x": 550,
              "y": 200,
              "text": "IndicatorBlock",
              "tmpText": "IndicatorBlock",
              "fontSize": 30,
              "fontFamily": "Calibri",
              "fill": "#000000",
              "elementType": 5,
              "tmpOpacity": 1,
              "tmpZIndex": 16
            },
            "className": "Text"
          }, {
            "attrs": {
              "id": "konvaElementTe13",
              "x": 750,
              "y": 300,
              "text": "Level",
              "tmpText": "Level",
              "fontSize": 30,
              "fontFamily": "Calibri",
              "fill": "#000000",
              "elementType": 5,
              "tmpOpacity": 1,
              "tmpZIndex": 26
            },
            "className": "Text"
          }, {
            "attrs": {
              "id": "konvaElementTe14",
              "x": 750,
              "y": 330,
              "text": "dynamicColors",
              "tmpText": "dynamicColors",
              "fontSize": 14,
              "fontFamily": "Calibri",
              "fill": "#000000",
              "elementType": 5,
              "tmpOpacity": 1,
              "tmpZIndex": 26
            },
            "className": "Text"
          }, {
            "attrs": {
              "id": "konvaElementIn1",
              "x": 100,
              "y": 470,
              "value": 0,
              "elementType": 6,
              "tmpOpacity": 1,
              "firstColor": "#ff00ff",
              "secondColor": "#00ff00"
            },
            "className": "Indicator"
          }, {
            "attrs": {
              "id": "konvaElementIn2",
              "x": 125,
              "y": 470,
              "value": 1,
              "elementType": 6,
              "tmpOpacity": 1,
              "firstColor": "#ff0000",
              "secondColor": "#00ff00"
            },
            "className": "Indicator"
          }, {
            "attrs": {
              "id": "konvaElementIn3",
              "x": 150,
              "y": 470,
              "value": 1,
              "elementType": 6,
              "tmpOpacity": 1,
              "firstColor": "#00ffff",
              "secondColor": "#0000ff"
            },
            "className": "Indicator"
          }, {
            "attrs": {
              "id": "konvaElementIn4",
              "x": 175,
              "y": 470,
              "value": 1,
              "elementType": 6,
              "tmpOpacity": 1,
              "firstColor": "#0088ff",
              "secondColor": "#88ff00"
            },
            "className": "Indicator"
          }, {
            "attrs": {
              "id": "konvaElementIn5",
              "x": 230,
              "y": 470,
              "value": 1,
              "elementType": 6,
              "tmpOpacity": 1,
              "firstColor": "#000028",
              "secondColor": "#0000ff"
            },
            "className": "Indicator"
          }, {
            "attrs": {
              "id": "konvaElementIn6",
              "x": 255,
              "y": 470,
              "value": 1,
              "elementType": 6,
              "tmpOpacity": 1,
              "firstColor": "#282800",
              "secondColor": "#ffff00"
            },
            "className": "Indicator"
          }, {
            "attrs": {
              "id": "konvaElementIn7",
              "x": 280,
              "y": 470,
              "value": 1,
              "elementType": 6,
              "tmpOpacity": 1,
              "firstColor": "#002800",
              "secondColor": "#00ff00"
            },
            "className": "Indicator"
          }, {
            "attrs": {
              "id": "konvaElementIn8",
              "x": 305,
              "y": 470,
              "value": 1,
              "elementType": 6,
              "tmpOpacity": 1,
              "firstColor": "#280028",
              "secondColor": "#ff00ff"
            },
            "className": "Indicator"
          }, {
            "attrs": {
              "id": "konvaElementIb1",
              "x": 560,
              "y": 240,
              "value": 1,
              "counter": 6,
              "elementType": 6,
              "tmpOpacity": 1,
              "firstColor": "#002800",
              "secondColor": "#00ff00"
            },
            "className": "IndicatBlock"
          }, {
            "attrs": {
              "id": "konvaElementGa1",
              "x": 1000,
              "y": 160,
              "width": 100,
              "height": 100,
              "value": 20,
              "elementType": 8,
              "tmpOpacity": 1
            },
            "className": "Gauge"
          }, {
            "attrs": {
              "id": "konvaElementGa2",
              "x": 1110,
              "y": 160,
              "width": 100,
              "height": 100,
              "value": 40,
              "elementType": 8,
              "tmpOpacity": 1
            },
            "className": "Gauge"
          }, {
            "attrs": {
              "id": "konvaElementGa3",
              "x": 1220,
              "y": 160,
              "width": 200,
              "height": 200,
              "value": 60,
              "elementType": 8,
              "tmpOpacity": 1
            },
            "className": "Gauge"
          }, {
            "attrs": {
              "id": "konvaElementGa5",
              "x": 1000,
              "y": 270,
              "width": 100,
              "height": 100,
              "value": 20,
              "elementType": 8,
              "tmpOpacity": 1,
              "gaugetype": "invert"
            },
            "className": "Gauge"
          }, {
            "attrs": {
              "id": "konvaElementGa6",
              "x": 1110,
              "y": 270,
              "width": 100,
              "height": 100,
              "value": 40,
              "elementType": 8,
              "tmpOpacity": 1,
              "gaugetype": "invert"
            },
            "className": "Gauge"
          }, {
            "attrs": {
              "id": "konvaElementGa7",
              "x": 1220,
              "y": 320,
              "width": 50,
              "height": 50,
              "value": 60,
              "elementType": 8,
              "tmpOpacity": 1
            },
            "className": "Gauge"
          }, {
            "attrs": {
              "id": "konvaElementGa8",
              "x": 1330,
              "y": 320,
              "width": 50,
              "height": 50,
              "value": 80,
              "elementType": 8,
              "tmpOpacity": 1,
              "gaugetype": "invert"
            },
            "className": "Gauge"
          }, {
            "attrs": {
              "id": "konvaElementLe1",
              "x": 200,
              "y": 310,
              "width": 50,
              "height": 50,
              "value": 20,
              "maxValue": 100,
              "fill": "#00ff00",
              "strokeWidth": 4,
              "elementType": 9,
              "tmpOpacity": 1,
              "type": 0,
              "dynamicColors": false
            },
            "className": "Level"
          }, {
            "attrs": {
              "id": "konvaElementLe2",
              "x": 260,
              "y": 310,
              "width": 50,
              "height": 50,
              "value": 20,
              "maxValue": 100,
              "fill": "#ffff00",
              "strokeWidth": 4,
              "elementType": 9,
              "tmpOpacity": 1,
              "type": 0,
              "dynamicColors": false
            },
            "className": "Level"
          }, {
            "attrs": {
              "id": "konvaElementLe3",
              "x": 320,
              "y": 310,
              "width": 50,
              "height": 50,
              "value": 20,
              "maxValue": 100,
              "fill": "#ff4d08",
              "strokeWidth": 4,
              "elementType": 9,
              "tmpOpacity": 1,
              "type": 0,
              "dynamicColors": false
            },
            "className": "Level"
          }, {
            "attrs": {
              "id": "konvaElementLe4",
              "x": 380,
              "y": 310,
              "width": 50,
              "height": 50,
              "value": 20,
              "maxValue": 100,
              "fill": "#ff0000",
              "strokeWidth": 4,
              "elementType": 9,
              "tmpOpacity": 1,
              "type": 0,
              "dynamicColors": false
            },
            "className": "Level"
          }, {
            "attrs": {
              "id": "konvaElementLe5",
              "x": 740,
              "y": 95,
              "width": 20,
              "height": 120,
              "value": 20,
              "maxValue": 100,
              "fill": "#00ff00",
              "strokeWidth": 4,
              "elementType": 9,
              "tmpOpacity": 1,
              "type": 1,
              "dynamicColors": false
            },
            "className": "Level"
          }, {
            "attrs": {
              "id": "konvaElementLe6",
              "x": 880,
              "y": 95,
              "width": 20,
              "height": 120,
              "value": 20,
              "maxValue": 100,
              "fill": "#ff0000",
              "strokeWidth": 4,
              "elementType": 9,
              "tmpOpacity": 1,
              "type": 1,
              "dynamicColors": false
            },
            "className": "Level"
          }, {
            "attrs": {
              "id": "konvaElementLe7",
              "x": 840,
              "y": 310,
              "width": 50,
              "height": 50,
              "value": 20,
              "maxValue": 100,
              "steps": 4,
              "fillColors": ["#00ff00", "#ffff00", "#ff8708", "#ff0000"],
              "strokeWidth": 4,
              "elementType": 9,
              "tmpOpacity": 1,
              "type": 0,
              "dynamicColors": true
            },
            "className": "Level"
          }]
        }]
      };
      return test;
    },

    renderTemplate(controller, model) {
      this.render("location/production", {
        into: "application",
        outlet: "content",
        controller: controller,
        model: model
      });
    },

    actions: {
      didTransition() {
        this.controllerFor("location").send("drawBreadcrumbs", "production");
        this.controller.send('startBuildingCanvas');
        return true;
      }

    },

    deactivate() {
      this.controller.send("runToLeave");
    }

  });

  _exports.default = _default;
});