define("machine-monitoring/configuration/customers/newcustomer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rI0qvOKK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-10 offset-md-1\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card-header text-white bg-primary\"],[8],[0,\"\\n      \"],[1,[28,\"fa-icon\",[\"cog\"],null],false],[0,\" \"],[1,[28,\"t\",[\"newCustomer\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card-body\"],[8],[0,\"\\n      \"],[7,\"form\",true],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"form-group row\"],[8],[0,\"\\n          \"],[7,\"label\",true],[10,\"for\",\"deviceType\"],[10,\"class\",\"col-2  col-form-label\"],[8],[1,[28,\"t\",[\"name\"],null],false],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"col-10\"],[8],[0,\"\\n            \"],[1,[28,\"input\",null,[[\"type\",\"class\",\"id\",\"placeholder\",\"value\"],[\"text\",\"form-control\",\"deviceType\",[28,\"t\",[\"name\"],null],[24,[\"newCustomer\",\"name\"]]]]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-outline-warning\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"dismissNewCustomer\"]],[8],[0,\"\\n        \"],[1,[28,\"fa-icon\",[\"times-circle\"],null],false],[0,\" \"],[1,[28,\"t\",[\"dismiss\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-outline-success float-right\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"saveNewCustomer\"]],[8],[0,\"\\n        \"],[1,[28,\"fa-icon\",[\"check-circle\"],null],false],[0,\" \"],[1,[28,\"t\",[\"save\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/configuration/customers/newcustomer/template.hbs"
    }
  });

  _exports.default = _default;
});