define("machine-monitoring/templates/osci-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FSo63AfM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\",true],[10,\"class\",\"nav-item\"],[8],[7,\"p\",true],[10,\"class\",\"navbar-text mb-0\"],[8],[1,[28,\"fa-icon\",[\"chevron-right\"],null],false],[9],[9],[0,\"\\n\"],[7,\"li\",true],[11,\"class\",[29,[\"nav-item \",[28,\"if\",[[24,[\"routeActiveOsciOverview\"]],\"active\"],null]]]],[8],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",\"nav-link\"],[3,\"action\",[[23,0,[]],\"osciOverviewModeAction\"]],[8],[1,[28,\"t\",[\"overview\"],null],false],[9],[9],[0,\"\\n\"],[7,\"li\",true],[11,\"class\",[29,[\"nav-item \",[28,\"if\",[[24,[\"routeActiveOsciRecord\"]],\"active\"],null]]]],[8],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",\"nav-link\"],[3,\"action\",[[23,0,[]],\"osciRecordModeAction\"]],[8],[1,[28,\"t\",[\"record\"],null],false],[9],[9],[0,\"\\n\"],[7,\"li\",true],[11,\"class\",[29,[\"nav-item \",[28,\"if\",[[24,[\"routeActiveOsciOsci\"]],\"active\"],null]]]],[8],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"class\",\"nav-link not-active\"],[8],[1,[28,\"t\",[\"oscilloscope\"],null],false],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/templates/osci-navigation.hbs"
    }
  });

  _exports.default = _default;
});