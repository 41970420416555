define("machine-monitoring/templates/production-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "78KPxBVX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\",true],[10,\"class\",\"nav-item\"],[8],[7,\"p\",true],[10,\"class\",\"navbar-text mb-0\"],[8],[1,[28,\"fa-icon\",[\"chevron-right\"],null],false],[9],[9],[0,\"\\n\"],[7,\"li\",true],[11,\"class\",[29,[\"nav-item \",[28,\"if\",[[24,[\"routeActiveLocations\"]],\"active\"],null]]]],[8],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",\"nav-link\"],[3,\"action\",[[23,0,[]],\"locationsModeAction\"]],[8],[1,[28,\"t\",[\"locations\"],null],false],[9],[9],[0,\"\\n\"],[7,\"li\",true],[11,\"class\",[29,[\"nav-item \",[28,\"if\",[[24,[\"routeActiveProduction\"]],\"active\"],null]]]],[8],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"class\",\"nav-link not-active\"],[8],[1,[28,\"t\",[\"production\"],null],false],[9],[9],[0,\"\\n\"],[1,[28,\"outlet\",[\"extend-navigation\"],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/templates/production-navigation.hbs"
    }
  });

  _exports.default = _default;
});