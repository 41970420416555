define("machine-monitoring/app", ["exports", "machine-monitoring/resolver", "ember-load-initializers", "machine-monitoring/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let APP = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  APP.PROTOCOL_WS = '';

  if (window.location.protocol == 'https:') {
    APP.PROTOCOL_WS = 'wss';
  } else {
    APP.PROTOCOL_WS = 'ws';
  }

  APP.API_HOST = window.location.origin;
  APP.API_URL = APP.API_HOST + '/api';
  APP.API_ML_URL = APP.API_HOST + '/api/ml';
  APP.WS_URL = APP.PROTOCOL_WS + '://' + window.location.host + '/ws';
  APP.moXHR = false;
  (0, _emberLoadInitializers.default)(APP, _environment.default.modulePrefix);
  var _default = APP;
  _exports.default = _default;
});