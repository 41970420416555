define("machine-monitoring/components/osci-limits/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "li",
    classNames: ["list-group-item"],
    minActive: false,
    minActiveId: 0,
    maxActive: false,
    maxActiveId: 0,
    thisEnlarged: false,

    init() {
      this._super(...arguments);

      let minActiveId = this.get("thisLegendValue").type + "MinActive";
      this.set("minActiveId", minActiveId);
      let maxActiveId = this.get("thisLegendValue").type + "MaxActive";
      this.set("maxActiveId", maxActiveId);
    },

    didInsertElement() {
      let minIdStringSlider = this.get("thisLegendValue").type + "MinSlider";
      let lowValueSlider = document.getElementById(minIdStringSlider);
      lowValueSlider.addEventListener("input", () => {
        this.lowValueSlider(minIdStringSlider);
      });
      let maxIdStringSlider = this.get("thisLegendValue").type + "MaxSlider";
      let highValueSlider = document.getElementById(maxIdStringSlider);
      highValueSlider.addEventListener("input", () => {
        this.highValueSlider(maxIdStringSlider);
      });
      let minIdStringCheck = this.get("thisLegendValue").type + "MinActive";
      let lowValueCheck = document.getElementById(minIdStringCheck);
      lowValueCheck.addEventListener("change", () => {
        this.lowValueCheck();
      });
      let maxIdStringCheck = this.get("thisLegendValue").type + "MaxActive";
      let highValueCheck = document.getElementById(maxIdStringCheck);
      highValueCheck.addEventListener("change", () => {
        this.highValueCheck();
      });
    },

    lowValueSlider(minIdString) {
      let thisSlider = document.getElementById(minIdString);
      let box = parseFloat(thisSlider.value);
      let thisLegendValue = this.get("thisLegendValue");
      Ember.set(thisLegendValue, "minAlert", box);
    },

    highValueSlider(maxIdString) {
      let thisSlider = document.getElementById(maxIdString);
      let box = parseFloat(thisSlider.value);
      let thisLegendValue = this.get("thisLegendValue");
      Ember.set(thisLegendValue, "maxAlert", box);
    },

    lowValueCheck() {
      let minActive = this.get("minActive");
      this.set("minActive", !minActive);
      let thisLegendValue = this.get("thisLegendValue");
      Ember.set(thisLegendValue, "minActive", !minActive);
    },

    highValueCheck() {
      let maxActive = this.get("maxActive");
      this.set("maxActive", !maxActive);
      let thisLegendValue = this.get("thisLegendValue");
      Ember.set(thisLegendValue, "maxActive", !maxActive);
    },

    actions: {
      updateMaxValue() {
        let thisLegendValue = this.get("thisLegendValue");
        let box = thisLegendValue.maxAlert;
        Ember.set(thisLegendValue, "maxAlert", box);
      },

      updateMinValue() {
        let thisLegendValue = this.get("thisLegendValue");
        let box = thisLegendValue.minAlert;
        Ember.set(thisLegendValue, "minAlert", box);
      },

      toggleEnlarge() {
        let hasEnlarge = this.get("hasEnlarge");

        if (hasEnlarge) {
          let thisEnlarged = this.get("thisEnlarged");
          this.set("thisEnlarged", !thisEnlarged);
          Ember.set(this.get("thisLegendValue"), "enlarged", !thisEnlarged);
          this.enlargeThis();
        }
      }

    }
  });

  _exports.default = _default;
});