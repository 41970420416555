define("machine-monitoring/devices/details/anomalie/details/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      let thatXYPair = this.controllerFor("devices/details/anomalie").get("xyPairToUse");
      controller.set("thatXYPair", thatXYPair);
    },

    renderTemplate(controller, model) {
      this.render("devices/details/anomalie/details", {
        into: "devices/details",
        controller: controller,
        model: model
      });
      controller.send("runAfterRender");
    }

  });

  _exports.default = _default;
});