define("machine-monitoring/components/single-small-spectrum/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SSBD58xQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[7,\"small\",true],[11,\"class\",[22,\"unseen\"]],[8],[1,[22,\"clusterName\"],false],[9],[9],[0,\"\\n\"],[7,\"canvas\",false],[12,\"height\",\"150\"],[12,\"width\",\"150\"],[12,\"class\",\"detailCanvas\"],[12,\"id\",[29,[\"canv\",[24,[\"cluster\",\"elementId\"]]]]],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"enlargeMe\"]],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"outerFill\"],[11,\"id\",[29,[\"outerFill\",[24,[\"cluster\",\"elementId\"]]]]],[8],[7,\"div\",true],[10,\"class\",\"innerFill\"],[11,\"id\",[29,[\"innerFill\",[24,[\"cluster\",\"elementId\"]]]]],[8],[9],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-primary btn-sm marginTop5\"],[3,\"action\",[[23,0,[]],\"toggleFrozen\"]],[8],[4,\"if\",[[24,[\"frozen\"]]],null,{\"statements\":[[1,[28,\"fa-icon\",[\"tint\"],null],false]],\"parameters\":[]},{\"statements\":[[1,[28,\"fa-icon\",[\"snowflake\"],null],false]],\"parameters\":[]}],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-inv-warning btn-sm marginTop5\"],[3,\"action\",[[23,0,[]],\"removeMe\"]],[8],[1,[28,\"fa-icon\",[\"times\"],null],false],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-inv-warning btn-sm marginTop5\"],[3,\"action\",[[23,0,[]],\"renameMe\"]],[8],[1,[28,\"fa-icon\",[\"comment\"],null],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/components/single-small-spectrum/template.hbs"
    }
  });

  _exports.default = _default;
});