define("machine-monitoring/devices/details/alerts/route", ["exports", "jquery", "machine-monitoring/app"], function (_exports, _jquery, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate() {
      this.controllerFor("devices/details").set('thirdNaviRoute', 'alerts');
    },

    model() {
      let deviceId = _app.default.chosenDeviceId;
      return this.get("store").findRecord("device", deviceId);
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('isLoading', true);
      let deviceId = model.get("id");
      let oldestAlert;

      _jquery.default.get(_app.default.API_URL + "/time").then(timeData => {
        let timeNow = Math.round(timeData.time / 1000000);
        oldestAlert = timeNow;
        controller.set("timeNow", timeNow);
        let shortForId = timeNow % 1000000;
        controller.set("shortForId", shortForId);
      });

      _jquery.default.get(_app.default.API_URL + "/alerts/" + deviceId + "?size=80").then(responseData => {
        controller.set('isLoading', false);
        let metaData = responseData.meta;
        controller.set("alertMeta", metaData);
        let alertInformations = metaData.informations;
        controller.set("alertInformations", alertInformations);
        let alertWarnings = metaData.warnings;
        controller.set("alertWarnings", alertWarnings);
        let alertCriticals = metaData.criticals;
        controller.set("alertCriticals", alertCriticals);
        let alertEmergencies = metaData.emergencies;
        controller.set("alertEmergencies", alertEmergencies);
        let allAlertsCount = alertWarnings + alertCriticals + alertEmergencies + alertInformations;
        controller.set("allAlertsCount", allAlertsCount);
        let alertData = responseData.alerts;
        let iCount = 0;
        alertData.forEach(thisData => {
          let dateNow = new Date();
          let timeStampNow = dateNow.getTime();
          thisData.alertId = timeStampNow + iCount;
          thisData.visibleAlert = false;
          let alertStart = thisData.alertSpan.start;
          let shortenedAlertStart = Math.round(alertStart / 1000000);

          if (shortenedAlertStart < oldestAlert) {
            oldestAlert = shortenedAlertStart;
          }

          iCount++;
        });
        controller.set("allAlerts", alertData);
        controller.set("oldestAlert", oldestAlert);
      });

      let allSignalTypes = model.get("signalTypes");
      controller.set("allSignalTypes", allSignalTypes);
      let allSignalTypesObject = {};

      if (allSignalTypes) {
        allSignalTypes.forEach(thisSignal => {
          let thisSignalId = thisSignal.get("signalTypeId");
          allSignalTypesObject[thisSignalId] = 0;
        });
      }

      controller.set("allSignalTypesObject", allSignalTypesObject);
      controller.set("thisZIndex", 1000);
      window.addEventListener("resize", () => {
        controller.send("resizeWindow");
      });
    },

    renderTemplate(controller, model) {
      this.render("devices/details/alerts", {
        into: "devices/details",
        controller: controller,
        model: model
      });
      this.render("alerts-navigation", {
        into: "extend-navigation",
        outlet: "extend-navigation",
        controller: controller,
        model: model
      });
    },

    actions: {
      didTransition() {
        this.controllerFor("devices/details").send("enterRoute", 'alerting');
        this.controllerFor("devices/details/alerts").send("firstRunActions");
        return true;
      }

    },

    deactivate() {
      window.removeEventListener("resize", () => {
        this.controllerFor("devices/details/alerts").send("resizeWindow");
      });
      this.controllerFor("devices/details/alerts").send("runOnDeactivate");
    }

  });

  _exports.default = _default;
});