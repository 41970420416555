define("machine-monitoring/configuration/customers/newcustomer/controller", ["exports", "machine-monitoring/app", "jquery"], function (_exports, _app, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      dismissNewCustomer() {
        let newCustomer = this.get("newCustomer");
        newCustomer.deleteRecord();
        this.transitionToRoute("configuration");
      },

      saveNewCustomer() {
        let newCustomer = this.get("newCustomer");

        _jquery.default.post(_app.default.API_URL + "/customers", JSON.stringify(newCustomer), blub => {
          newCustomer.deleteRecord();
          let newCustomerId = blub.id;
          let newLoadedCustomer = this.get("store").findRecord("customer", newCustomerId);
          this.transitionToRoute("configuration.customers.customer.edit", newLoadedCustomer);
        });
      }

    }
  });

  _exports.default = _default;
});