define("machine-monitoring/helpers/timestamp-to-readable-seconds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timestampToReadableSeconds = timestampToReadableSeconds;
  _exports.default = void 0;

  function timestampToReadableSeconds(params) {
    let [arg1] = params;
    let thisTimeStamp = new Date(arg1);
    let hours = thisTimeStamp.getHours();
    let minutes = thisTimeStamp.getMinutes();
    let seconds = thisTimeStamp.getSeconds();
    let milliseconds = thisTimeStamp.getMilliseconds();

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return hours + ":" + minutes + ":" + seconds + "," + milliseconds;
  }

  var _default = Ember.Helper.helper(timestampToReadableSeconds);

  _exports.default = _default;
});