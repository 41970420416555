define("machine-monitoring/components/xy-diag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qn5eZdCU",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"XYCanvasOverlay\"],[8],[9],[0,\"\\n\"],[7,\"canvas\",true],[10,\"width\",\"150\"],[10,\"height\",\"150\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/components/xy-diag/template.hbs"
    }
  });

  _exports.default = _default;
});