define("machine-monitoring/components/overview-statcard/component", ["exports", "chartjs", "machine-monitoring/app", "jquery"], function (_exports, _chartjs, _app, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['thatClassName'],
    statcardMainStyle: Ember.computed('thatBGColor', function () {
      return Ember.String.htmlSafe("background-color:" + this.thatBGColor + ";color:" + this.thatColor + ";");
    }),
    statcardDescStyle: Ember.computed('thatColor', function () {
      return Ember.String.htmlSafe("color:" + this.thatColor + ";");
    }),
    statcardDisplayStyle: Ember.computed('isBig', function () {
      let display = "none";

      if (this.isBig) {
        display = "block";
      }

      return Ember.String.htmlSafe("height:100%;width:100%;display:" + display + ";");
    }),
    thatClassName: Ember.computed('boxSize', function () {
      return "col-" + this.boxSize;
    }),
    bigChartObj: null,
    bigChartOpen: false,

    createSmallChart() {
      let canvasElement = this.get("element").getElementsByTagName("CANVAS")[0];

      if (canvasElement) {
        let chartData = {
          labels: [],
          datasets: [{
            backgroundColor: "rgba(0,0,0,0.5)",
            borderColor: "rgba(0,0,0,0.5)",
            tension: 0.3,
            pointRadius: 0,
            borderWidth: 1,
            data: []
          }]
        };
        let chartOptions = {
          animation: {
            duration: 0
          },
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              display: false
            }],
            yAxes: [{
              display: false
            }]
          }
        };
        let ctx = canvasElement.getContext("2d");
        let newChart = new _chartjs.default(ctx, {
          type: "line",
          data: chartData,
          options: chartOptions
        });
        this.set('overviewChart', newChart);
      }
    },

    createBigChart() {
      let chartData = {
        labels: [],
        datasets: [{
          backgroundColor: "rgba(0,116,190,1)",
          borderColor: "rgba(0,116,190,1)",
          tension: 0.3,
          pointRadius: 0,
          borderWidth: 1,
          data: []
        }]
      };
      let chartOptions = {
        animation: {
          duration: 0
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: true,
            ticks: {
              callback: function (value) {
                let thisTimeStamp = new Date(parseInt(value));
                let hours = thisTimeStamp.getHours();
                let minutes = thisTimeStamp.getMinutes();
                let seconds = thisTimeStamp.getSeconds();

                if (minutes < 10) {
                  minutes = "0" + minutes;
                }

                if (seconds < 10) {
                  seconds = "0" + seconds;
                }

                return hours + ":" + minutes + ":" + seconds;
              }
            }
          }],
          yAxes: [{
            display: true,
            position: "left",
            scaleLabel: {
              display: false
            },
            gridLines: {
              display: true,
              color: "rgba(255,255,255,0.1)"
            },
            afterFit: scaleInstance => {
              scaleInstance.width = 50;
            }
          }]
        },
        pointRadius: 1,
        pointBorderWidth: 0,
        pointHoverRadius: 20,
        borderWidth: 2
      };
      let bigCanvasElement = document.getElementById(this.thisChartCanvasId);
      let bigContext = bigCanvasElement.getContext("2d");
      let bigNewChart = new _chartjs.default(bigContext, {
        type: "line",
        data: chartData,
        options: chartOptions
      });
      this.set("bigChartObj", bigNewChart);
    },

    thatBGColor: "#0074BE",
    thatColor: "#ffffff",

    init() {
      this._super(...arguments);

      this.thisChartModalId = "modalOverviewBigChart" + this.get('thisType.id');
      this.thisChartCanvasId = "modalBigChart" + this.get('thisType.id');
    },

    willUpdate() {
      this._super(...arguments);

      let actValue = this.get('thisType.value');
      let thatTime = this.get('thisType.time');

      if (thatTime > 14932834281680) {
        thatTime = Math.round(thatTime / 1000000); //1493283428168
      }

      let thatBGColor = "#0074BE";
      let thatColor = "#ffffff";
      let ceilActive = this.get('thisType.ceil.active');
      let floorActive = this.get('thisType.floor.active');

      if (ceilActive && floorActive) {
        let ceilWarn = this.get('thisType.ceil.warn');
        let ceilCrit = this.get('thisType.ceil.critical');
        let ceilEmer = this.get('thisType.ceil.emergency');
        let floorWarn = this.get('thisType.floor.warn');
        let floorCrit = this.get('thisType.floor.critical');
        let floorEmer = this.get('thisType.floor.emergency');
        let thatNormalDiff = ceilWarn - floorWarn;
        let halfNormalDiff = thatNormalDiff / 2;
        let thatNormalMid = halfNormalDiff + floorWarn;
        let thatValue = actValue;

        if (actValue < 0) {
          thatValue = -actValue;
        }

        if (actValue > thatNormalMid) {
          if (actValue < ceilWarn) {
            let smBox = 60 / halfNormalDiff * (halfNormalDiff - thatValue);
            let box = smBox + 60;
            thatBGColor = "hsl(" + box + ", 100%, 50%)";
            thatColor = "#000000";
          } else if (actValue > ceilWarn && actValue < ceilCrit) {
            let smBox = ceilCrit - ceilWarn;
            let box = 25 / smBox * (smBox - thatValue) + 35;
            thatBGColor = "hsl(" + box + ", 100%, 50%)";
            thatColor = "#000000";
          } else if (actValue > ceilCrit && actValue < ceilEmer) {
            let smBox = ceilEmer - ceilCrit;
            let box = 35 / smBox * (smBox - thatValue);
            thatBGColor = "hsl(" + box + ", 100%, 50%)";
          } else {
            thatBGColor = "hsl(0, 100%, 50%)";
          }
        } else {
          if (actValue > floorWarn) {
            let smBox = 60 / halfNormalDiff * (halfNormalDiff - thatValue);
            let box = smBox + 60;
            thatBGColor = "hsl(" + box + ", 100%, 50%)";
            thatColor = "#000000";
          } else if (actValue < floorWarn && actValue > floorCrit) {
            let smBox = floorCrit - floorWarn;
            let box = 25 / smBox * (smBox - thatValue) + 35;
            thatBGColor = "hsl(" + box + ", 100%, 50%)";
            thatColor = "#000000";
          } else if (actValue < floorCrit && actValue < floorEmer) {
            let smBox = floorEmer - floorCrit;
            let box = 35 / smBox * (smBox - thatValue);
            thatBGColor = "hsl(" + box + ", 100%, 50%)";
          } else {
            thatBGColor = "hsl(0, 100%, 50%)";
          }
        }
      } else if (floorActive) {
        let floorWarn = this.get('thisType.floor.warn');
        let floorCrit = this.get('thisType.floor.critical');
        let floorEmer = this.get('thisType.floor.emergency');

        if (actValue > floorWarn) {
          thatBGColor = "hsl(120, 100%, 50%)";
          thatColor = "#000000";
        } else if (actValue < floorWarn && actValue > floorCrit) {
          thatBGColor = "hsl(60, 100%, 50%)";
          thatColor = "#000000";
        } else if (actValue < floorCrit && actValue < floorEmer) {
          thatBGColor = "hsl(35, 100%, 50%)";
        } else {
          thatBGColor = "hsl(0, 100%, 50%)";
        }
      } else if (ceilActive) {
        let ceilWarn = this.get('thisType.ceil.warn');
        let ceilCrit = this.get('thisType.ceil.critical');
        let ceilEmer = this.get('thisType.ceil.emergency');

        if (actValue < ceilWarn) {
          thatBGColor = "hsl(120, 100%, 50%)";
          thatColor = "#000000";
        } else if (actValue > ceilWarn && actValue < ceilCrit) {
          thatBGColor = "hsl(60, 100%, 50%)";
          thatColor = "#000000";
        } else if (actValue > ceilCrit && actValue < ceilEmer) {
          thatBGColor = "hsl(35, 100%, 50%)";
        } else {
          thatBGColor = "hsl(0, 100%, 50%)";
        }
      }

      this.set('thatColor', thatColor);
      this.set('thatBGColor', thatBGColor);
      let canvasElement = this.get("element").getElementsByTagName("CANVAS")[0];
      let overviewChart = this.get('overviewChart');

      if (canvasElement && !overviewChart) {
        this.createSmallChart();
      } else if (canvasElement && overviewChart) {
        overviewChart.data.datasets[0].data.push(actValue);
        overviewChart.data.labels.push(thatTime);

        if (overviewChart.data.datasets[0].data.length > 50) {
          overviewChart.data.datasets[0].data.shift();
          overviewChart.data.labels.shift();
        }

        overviewChart.update();
      }

      if (this.get('bigChartOpen')) {
        let bigChartObj = this.get('bigChartObj');
        bigChartObj.data.datasets[0].data.push(actValue);
        bigChartObj.data.labels.push(thatTime);

        if (bigChartObj.data.datasets[0].data.length > 400) {
          bigChartObj.data.datasets[0].data.shift();
          bigChartObj.data.labels.shift();
        }

        bigChartObj.update();
      }
    },

    actions: {
      chartsDetailOpen() {
        if (this.get('isLoading') == false) {
          this.set('isLoading', true);
          this.createBigChart();
          let deviceId = this.get('deviceId');
          let signald = this.get('thisType.id');

          _jquery.default.ajax({
            url: _app.default.API_URL + "/values/history/" + deviceId + "/" + signald + "?count=400"
          }).done(data => {
            this.set('isLoading', false);
            this.set('bigChartOpen', true);
            (0, _jquery.default)("#" + this.thisChartModalId).modal("show");
            let bigChartObj = this.get('bigChartObj');
            data.values.sortBy('time').forEach(thisValue => {
              bigChartObj.data.datasets[0].data.push(thisValue.value);
              let thatTime = thisValue.time;

              if (thatTime > 14932834281680) {
                thatTime = Math.round(thatTime / 1000000); //1493283428168
              }

              bigChartObj.data.labels.push(thatTime);
            });
            bigChartObj.update();
            let bigCanvasElement = document.getElementById(this.thisChartCanvasId);
            bigCanvasElement.style.height = "600px";
          });
        }
      },

      chartsDetailClose() {
        this.set('bigChartOpen', false);
        (0, _jquery.default)("#" + this.thisChartModalId).modal("hide");
      },

      closeCard() {
        this.set('thisType.show', false);
      }

    }
  });

  _exports.default = _default;
});