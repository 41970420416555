define("machine-monitoring/components/sensor-template-tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S6ixafMm",
    "block": "{\"symbols\":[\"thisName\"],\"statements\":[[4,\"if\",[[24,[\"hasXY1\"]]],null,{\"statements\":[[1,[28,\"t\",[\"XY\"],null],false],[0,\" \"],[1,[28,\"t\",[\"diagram\"],null],false],[0,\" 1: \"],[1,[22,\"nameX1\"],false],[0,\" / \"],[1,[22,\"nameY1\"],false],[0,\" \"],[7,\"br\",true],[8],[9]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"hasXY2\"]]],null,{\"statements\":[[1,[28,\"t\",[\"XY\"],null],false],[0,\" \"],[1,[28,\"t\",[\"diagram\"],null],false],[0,\" 2: \"],[1,[22,\"nameX2\"],false],[0,\" / \"],[1,[22,\"nameY2\"],false],[0,\" \"],[7,\"br\",true],[8],[9]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"hasTime\"]]],null,{\"statements\":[[1,[28,\"t\",[\"time\"],null],false],[0,\" \"],[1,[28,\"t\",[\"diagram\"],null],false],[0,\": \"],[4,\"each\",[[24,[\"nameTime\"]]],null,{\"statements\":[[1,[23,1,[]],false],[0,\"; \"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/components/sensor-template-tooltip/template.hbs"
    }
  });

  _exports.default = _default;
});