define("machine-monitoring/components/load-more/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iMnMc1s3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"possibleNext\"]]],null,{\"statements\":[[0,\"  \"],[7,\"nav\",true],[10,\"aria-label\",\"pagination\"],[8],[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"pagination justify-content-center\"],[8],[0,\"\\n      \"],[7,\"li\",true],[11,\"class\",[29,[\"page-item \",[28,\"if\",[[24,[\"possibleNext\"]],\"\",\"disabled\"],null]]]],[8],[0,\"\\n        \"],[7,\"a\",false],[12,\"class\",\"page-link\"],[12,\"href\",\"#\"],[12,\"aria-label\",\"load more\"],[3,\"action\",[[23,0,[]],\"loadMore\"]],[8],[0,\"\\n          \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[1,[28,\"t\",[\"loadMore\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/components/load-more/template.hbs"
    }
  });

  _exports.default = _default;
});