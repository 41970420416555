define("machine-monitoring/devices/details/alerts/controller", ["exports", "jquery", "machine-monitoring/app"], function (_exports, _jquery, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    detailsController: Ember.inject.controller('devices.details'),
    timeNow: null,
    thisZIndex: 1000,
    alertsShown: 10,
    slicedRecordAlerts: null,
    windowWidth: 1,
    allAlertsCount: 0,
    alertsShownList: 80,
    routeActiveSecondAlerts: false,
    routeActiveSecondLearning: false,

    getPosition(el) {
      let xPos = 0,
          yPos = 0;

      while (el) {
        if (el.tagName === "BODY") {
          let xScroll = el.scrollLeft || document.documentElement.scrollLeft;
          let yScroll = el.scrollTop || document.documentElement.scrollTop;
          xPos += el.offsetLeft - xScroll + el.clientLeft;
          yPos += el.offsetTop - yScroll + el.clientTop;
        } else {
          xPos += el.offsetLeft - el.scrollLeft + el.clientLeft;
          yPos += el.offsetTop - el.scrollTop + el.clientTop;
        }

        el = el.offsetParent;
      }

      return {
        x: xPos,
        y: yPos
      };
    },

    showActualBar(thisTime, pos) {
      let TimeStampNow = new Date(thisTime);
      let hours = TimeStampNow.getHours();
      let minutes = TimeStampNow.getMinutes();
      let seconds = TimeStampNow.getSeconds();

      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      let timeTextNode = document.createTextNode(hours + ":" + minutes + ":" + seconds);
      let box = document.getElementById("allSignalDiagrams"); // div um alle Diagramme

      if (!box) {
        return;
      }

      let boxOffsetWidth = box.offsetWidth; // breite des Diagramm-Div

      let topDiv = document.getElementById("signalDiagramHeader");
      let oldZIndex = this.get("thisZIndex");
      let newBar = document.createElement("div");
      newBar.className = "signalDiagramTimePointer";

      if (pos === "joker") {
        pos = 80;
        newBar.style.left = boxOffsetWidth - 79 + "px";
      } else {
        newBar.style.left = boxOffsetWidth - pos - 160 + "px";
      }

      newBar.style.zIndex = oldZIndex;
      this.set("thisZIndex", oldZIndex - 1);
      let newP = document.createElement("p");
      newP.appendChild(timeTextNode);
      newBar.appendChild(newP);
      topDiv.appendChild(newBar);
    },

    changeDiagramFaderBar() {
      let box = document.getElementById("allSignalDiagrams"); // div um alle Diagramme

      let boxOffsetWidth = box.offsetWidth; // breite des Diagramm-Div

      let signalDiagramFaderBarFirst = document.getElementById("signalDiagramFaderBarFirst");
      signalDiagramFaderBarFirst.style.left = boxOffsetWidth - 80 + "px";
      let signalDiagramFaderBarSecond = document.getElementById("signalDiagramFaderBarSecond");
      signalDiagramFaderBarSecond.style.left = boxOffsetWidth - 74 + "px";
    },

    showAlertsBars(alertsShown) {
      let box = document.getElementById("allSignalDiagrams"); // div um alle Diagramme

      let boxOffsetWidth = box.offsetWidth; // breite des Diagramm-Div

      let recordedAlerts = this.get("allAlerts");
      let recordedAlertsLength = recordedAlerts.length;

      if (recordedAlertsLength < alertsShown) {
        alertsShown = recordedAlertsLength;
      }

      let oldestAlertTime = this.get("timeNow"); //1493283786028

      let newestAlertTime = 0;
      let slicedRecordAlerts = recordedAlerts.slice(0, alertsShown);
      this.set("slicedRecordAlerts", slicedRecordAlerts);
      slicedRecordAlerts.forEach(thisAlert => {
        let alertTypeId = thisAlert.alertTypeId;
        let thisAlertSignalType = this.get("allSignalTypes");
        thisAlertSignalType.forEach(thisSignalType => {
          let thisSignalTypeId = thisSignalType.get("signalTypeId");

          if (alertTypeId === thisSignalTypeId) {
            thisAlert.signalTypeName = thisSignalType.get("name");
          }
        });
        let alertStart = parseInt(thisAlert.alertSpan.start);
        let alertShortStart = Math.round(alertStart / 1000000);

        if (oldestAlertTime > alertShortStart) {
          oldestAlertTime = alertShortStart;
        }

        if (newestAlertTime < alertShortStart) {
          newestAlertTime = alertShortStart;
        }
      });
      let timeDiff = 100;

      if (newestAlertTime !== oldestAlertTime) {
        timeDiff = newestAlertTime - oldestAlertTime;
      } else {
        oldestAlertTime = oldestAlertTime - 100;
      }

      this.set("newestAlertTime", newestAlertTime);
      this.set("oldestAlertTime", oldestAlertTime);
      let pxPerTime = (boxOffsetWidth - 160) / timeDiff;
      slicedRecordAlerts.forEach(thisAlert => {
        let alertStart = parseInt(thisAlert.alertSpan.start);
        let alertShortStart = Math.round(alertStart / 1000000);
        let alertPx = pxPerTime * (newestAlertTime - alertShortStart);
        this.showActualBar(alertShortStart, alertPx);
      });
    },

    resizeAlertsList() {
      let alertsControl = (0, _jquery.default)("#alertsControl");

      if (alertsControl && alertsControl.offset()) {
        let alertsDivOffset = alertsControl.offset().top;
        let newDivHeight = window.innerHeight - alertsDivOffset - 65;
        (0, _jquery.default)("#alertsControlScrollableList").css({
          maxHeight: newDivHeight
        });
      }
    },

    generateDiagramIcons() {
      let test1 = false;
      let test2 = false;
      let createDiagramContent = setInterval(() => {
        let testElement1 = document.getElementById("allSignalDiagrams");

        if (testElement1) {
          test1 = true;
        }

        let testElement2 = document.getElementById("signalDiagramHeader");

        if (testElement2) {
          test2 = true;
        }

        if (test1 === true && test2 === true) {
          let timeNow = this.get("timeNow");
          this.showActualBar(timeNow, "joker");
          let alertsShown = this.get("alertsShown");
          this.showAlertsBars(alertsShown);
          clearInterval(createDiagramContent);
        }
      }, 333);
    },

    reloadTimeNow() {
      _jquery.default.get(_app.default.API_URL + "/time").then(data => {
        let timeNow = Math.round(data.time / 1000000);
        this.set("timeNow", timeNow);
      });
    },

    drawBreadcrumbs() {
      let thirdNaviRoute = this.get("detailsController").get("thirdNaviRoute");
      let that = this;
      this.set("routeActiveSecondAlerts", false);
      this.set("routeActiveSecondLearning", false);

      switch (thirdNaviRoute) {
        case "alerts":
          {
            that.set("routeActiveSecondAlerts", true);
            break;
          }

        case "learning":
          {
            that.set("routeActiveSecondLearning", true);
            break;
          }

        default:
          {
            break;
          }
      }
    },

    actions: {
      firstRunActions() {
        this.generateDiagramIcons();
        this.drawBreadcrumbs();
        Ember.run.later(() => {
          this.resizeAlertsList();
        }, 100);
      },

      drawBreadcrumbs() {
        this.drawBreadcrumbs();
      },

      resizeWindow() {
        let isIt = (0, _jquery.default)(".signalDiagramTimePointer");

        if (isIt.length > 0) {
          isIt.remove();
          this.resizeAlertsList();
          this.reloadTimeNow();
          this.generateDiagramIcons();
        }
      },

      alertsSecondModeAction() {
        this.get("detailsController").set("thirdNaviRoute", "alerts");
        this.drawBreadcrumbs();
        this.transitionToRoute("devices.details.alerts");
      },

      learningModeAction() {
        this.get("detailsController").set("thirdNaviRoute", "learning");
        this.drawBreadcrumbs();
        this.transitionToRoute("devices.details.alerts.learning");
      },

      chooseAlerts(thisAlert) {
        let visibleAlertState = thisAlert.visibleAlert;
        Ember.set(thisAlert, "visibleAlert", !visibleAlertState);
      },

      closeButton(chosenAlert) {
        Ember.set(chosenAlert, "visibleAlert", false);
      },

      saveButton(chosenAlert) {
        let deviceId = chosenAlert.deviceId;
        let alertTypeId = chosenAlert.alertTypeId;
        let alertId = chosenAlert.alertSpan.start;

        _jquery.default.post(_app.default.API_URL + "/alerts/" + deviceId + "/" + alertTypeId + "/" + alertId + "/create-record", () => {
          (0, _jquery.default)("#modalAlertSaved").modal('show');
          Ember.run.later(() => {
            (0, _jquery.default)("#modalAlertSaved").modal('hide');
          }, 2500);
        });
      },

      decreaseCounts() {
        (0, _jquery.default)(".signalDiagramTimePointer").remove();
        this.reloadTimeNow();
        let alertsShown = this.get("alertsShown");

        if (alertsShown > 10) {
          this.set("alertsShown", alertsShown - 10);
        }

        this.generateDiagramIcons();
      },

      increaseCounts() {
        this.reloadTimeNow();
        (0, _jquery.default)(".signalDiagramTimePointer").remove();
        let allAlertsCount = this.get("allAlertsCount");
        let alertsShown = this.get("alertsShown");
        let newAlertsShown = alertsShown;

        if (alertsShown < 100 && alertsShown < allAlertsCount) {
          newAlertsShown = alertsShown + 10;
        }

        if (newAlertsShown > allAlertsCount) {
          newAlertsShown = allAlertsCount;
        }

        this.set("alertsShown", newAlertsShown);
        this.generateDiagramIcons();
      },

      reloadAlerts() {
        console.log("reloadAllContentForAlerts - copy like setupController Handler");
        this.set('isLoading', true);
        let deviceId = this.get('model').get("id");
        let oldestAlert;

        _jquery.default.get(_app.default.API_URL + "/time").then(timeData => {
          let timeNow = Math.round(timeData.time / 1000000);
          oldestAlert = timeNow;
          this.set("timeNow", timeNow);
          let shortForId = timeNow % 1000000;
          this.set("shortForId", shortForId);
        });

        _jquery.default.get(_app.default.API_URL + "/alerts/" + deviceId + "?size=80").then(responseData => {
          this.set('isLoading', false);
          let metaData = responseData.meta;
          this.set("alertMeta", metaData);
          let alertInformations = metaData.informations;
          this.set("alertInformations", alertInformations);
          let alertWarnings = metaData.warnings;
          this.set("alertWarnings", alertWarnings);
          let alertCriticals = metaData.criticals;
          this.set("alertCriticals", alertCriticals);
          let alertEmergencies = metaData.emergencies;
          this.set("alertEmergencies", alertEmergencies);
          let allAlertsCount = alertWarnings + alertCriticals + alertEmergencies + alertInformations;
          this.set("allAlertsCount", allAlertsCount);
          let alertData = responseData.alerts;
          let iCount = 0;
          alertData.forEach(thisData => {
            let dateNow = new Date();
            let timeStampNow = dateNow.getTime();
            thisData.alertId = timeStampNow + iCount;
            thisData.visibleAlert = false;
            let alertStart = thisData.alertSpan.start;
            let shortenedAlertStart = Math.round(alertStart / 1000000);

            if (shortenedAlertStart < oldestAlert) {
              oldestAlert = shortenedAlertStart;
            }

            iCount++;
          });
          this.set("allAlerts", alertData);
          this.set("oldestAlert", oldestAlert);
        });

        let allSignalTypes = this.get('model').get("signalTypes");
        this.set("allSignalTypes", allSignalTypes);
        let allSignalTypesObject = {};

        if (allSignalTypes) {
          allSignalTypes.forEach(thisSignal => {
            let thisSignalId = thisSignal.get("signalTypeId");
            allSignalTypesObject[thisSignalId] = 0;
          });
        }

        this.set("allSignalTypesObject", allSignalTypesObject);
        this.generateDiagramIcons();
        Ember.run.later(() => {
          this.resizeAlertsList();
        }, 100);
      },

      runOnDeactivate() {
        this.set("allSignalTypes", null);
        this.set("allSignalTypesObject", null);
        this.set("alertsShown", 10);
        this.set("slicedRecordAlerts", null);
        this.set("allAlertsCount", 0);
        let allSignalDiagrams = document.getElementById("allSignalDiagrams");

        if (allSignalDiagrams) {
          while (allSignalDiagrams.firstChild) {
            allSignalDiagrams.removeChild(allSignalDiagrams.firstChild);
          }
        }
      },

      loadMore() {
        let alertsShownList = this.get('alertsShownList');
        let allAlertsCount = this.get('allAlertsCount');
        let edited = 0;

        if (allAlertsCount > alertsShownList + 80) {
          alertsShownList = alertsShownList + 80;
          edited++;
        } else if (allAlertsCount > alertsShownList) {
          alertsShownList = allAlertsCount;
          edited++;
        }

        if (edited) {
          this.set('alertsShownList', alertsShownList);
          let oldestAlert;

          _jquery.default.get(_app.default.API_URL + "/time").then(timeData => {
            let timeNow = Math.round(timeData.time / 1000000);
            oldestAlert = timeNow;
            this.set("timeNow", timeNow);
          });

          let deviceId = this.get('model').get('id');

          _jquery.default.get(_app.default.API_URL + "/alerts/" + deviceId + "?size=" + alertsShownList).then(responseData => {
            let alertData = responseData.alerts;
            let iCount = 0;
            alertData.forEach(thisData => {
              let dateNow = new Date();
              let timeStampNow = dateNow.getTime();
              thisData.alertId = timeStampNow + iCount;
              thisData.visibleAlert = false;
              let alertStart = thisData.alertSpan.start;
              let shortenedAlertStart = Math.round(alertStart / 1000000);

              if (shortenedAlertStart < oldestAlert) {
                oldestAlert = shortenedAlertStart;
              }

              iCount++;
            });
            this.set("allAlerts", alertData);
            this.set("oldestAlert", oldestAlert);
          });
        }
      }

    }
  });

  _exports.default = _default;
});