define("machine-monitoring/helpers/timestamp-to-readable-day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timestampToReadableDay = timestampToReadableDay;
  _exports.default = void 0;

  function timestampToReadableDay(params) {
    let originTimeStamp = Math.round(params / 1000000); //1493283428168

    let thisTimeStamp = new Date(originTimeStamp);
    let days = thisTimeStamp.getDate();
    let months = thisTimeStamp.getMonth() + 1;
    let years = thisTimeStamp.getFullYear();

    if (days < 10) {
      days = '0' + days;
    }

    if (months < 10) {
      months = '0' + months;
    }

    return days + '.' + months + '.' + years;
  }

  var _default = Ember.Helper.helper(timestampToReadableDay);

  _exports.default = _default;
});