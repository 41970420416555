define("machine-monitoring/adapters/application", ["exports", "ember-data", "machine-monitoring/app"], function (_exports, _emberData, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    namespace: 'api',
    host: _app.default.API_HOST,

    handleResponse(status) {
      if (status == 401) {
        window.location.replace(_app.default.API_HOST + "/user-service/");
      } else {
        return this._super(...arguments);
      }
    },

    urlForQuery(query, modelName) {
      switch (modelName) {
        case 'record':
          return _app.default.API_URL + '/recordinfos/' + _app.default.chosenDeviceId;

        default:
          return this._super(...arguments);
      }
    },

    urlForFindAll(modelName) {
      switch (modelName) {
        case 'sensor':
          return _app.default.API_URL + '/ml/sensors';

        default:
          return this._super(...arguments);
      }
    }

  });

  _exports.default = _default;
});