define("machine-monitoring/configuration/customers/customer/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showDevices: false,
    showLocations: false,
    actions: {
      editCustomer() {
        console.log("controller editCustomer");
        let model = this.model;
        this.transitionToRoute("configuration.customers.customer.edit", model);
      },

      toggleShowLocations() {
        let showLocationsBefore = this.get('showLocations');
        this.set('showLocations', !showLocationsBefore);
      },

      toggleShowDevies() {
        let showDevicesBefore = this.get('showDevices');
        this.set('showDevices', !showDevicesBefore);
      },

      deleteThisCustomer() {
        console.log("controller deleteThisCustomer");
      }

    }
  });

  _exports.default = _default;
});