define("machine-monitoring/helpers/safe-css-bg-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.safeCssBgColor = safeCssBgColor;
  _exports.default = void 0;

  function safeCssBgColor(color) {
    return Ember.String.htmlSafe("color:#1a1c22;background-color:" + color + " !important;");
  }

  var _default = Ember.Helper.helper(safeCssBgColor);

  _exports.default = _default;
});