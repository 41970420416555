define("machine-monitoring/serializers/sensor", ["exports", "machine-monitoring/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'sensor_id',

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    normalize(typeClass, hash) {
      //init_config
      hash.resolution_x = hash.init_config.resolution_x;
      hash.resolution_y = hash.init_config.resolution_y;
      hash.max_cluster = hash.init_config.max_cluster; //algorithm_Config

      hash.alg_scale = hash.algorithm_config.scale;
      hash.alg_max_distance = hash.algorithm_config.max_distance;
      hash.alg_max_distance_sum = hash.algorithm_config.max_distance_sum;
      hash.alg_cluster_capacity = hash.algorithm_config.cluster_capacity; //detection_config

      hash.det_scale = hash.detection_config.scale;
      hash.det_max_distance = hash.detection_config.max_distance;
      hash.det_max_distance_sum = hash.detection_config.max_distance_sum;
      hash.det_cluster_capacity = hash.detection_config.cluster_capacity; //anomaly_Capture_Config

      hash.anomaly_threshold = hash.anomaly_capture_config.anomaly_threshold;
      hash.max_anomaly_clusters = hash.anomaly_capture_config.max_anomaly_clusters;
      hash.max_accepted_clusters = hash.anomaly_capture_config.max_accepted_clusters;
      hash.max_known_anomaly_clusters = hash.anomaly_capture_config.max_known_anomaly_clusters;
      hash.max_capture_count = hash.anomaly_capture_config.max_capture_count;
      hash.sensorId = hash.sensor_id;
      return this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});