define("machine-monitoring/translations/de-de", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "2D": "2D",
    "3D": "3D",
    "3er": "Große Kästen",
    "6er": "Kleine Kästen",
    "D": "D",
    "ID": "ID",
    "R": "R",
    "S": "S",
    "T": "T",
    "X": "X",
    "X1": "X1",
    "X2": "X2",
    "XResolution": "X Auflösung",
    "XY": "X/Y",
    "XY1": "erste X/Y",
    "XY2": "zweite X/Y",
    "Y": "Y",
    "Y1": "Y1",
    "Y2": "Y2",
    "YResolution": "Y Auflösung",
    "abort": "Abbruch",
    "absolute": "Absolut",
    "accept": "Akzeptieren",
    "accepted": "Akzeptiert",
    "acceptedAnomalies": "Akzeptierte Anomalien",
    "active": "Aktiv",
    "alert": "Alarm",
    "alertSaved": "Alarm als neue Aufnahme gespeichert",
    "alertType": "Alarm Typ",
    "alerting": "Alarmierung",
    "alerts": "Alarme",
    "algorithmConfig": "Algorithmus Einstellung",
    "all": "alles",
    "allMachines": "Alle Maschinen",
    "allSignals": "alle Signale",
    "amountOfClusters": "Anzahl der Cluster zum Lernen",
    "anomalieDetection": "Anomalie Erkennung",
    "anomalieImage": "Anomalie Bild",
    "anomalies": "Anomalien",
    "anomalySignalTypes": "Anomalie Signal Typen",
    "anomalyThreshold": "Anomalie Schwelle",
    "anomalyValue": "anomalie Wert",
    "at": "beim",
    "attentionNeeded": "Aufmerksamkeit benötigt",
    "averageValue": "Durchschnittswert",
    "begin": "Beginn",
    "binary": "binary",
    "buildYear": "Baujahr",
    "calcDistance": "berechne Distanz",
    "captureConfiguration": "Erfassungseinstellung",
    "category": "Kategorie",
    "ceilFloor": "Drüber/Drunter",
    "ceilingThreshold": "obere Schwelle",
    "choose": "Wähle",
    "choose4oszi": "Wähle für Oszilloskop",
    "chooseAlarmThresholds": "wähle die gewünschte Alarmschwelle",
    "chooseNew": "Wähle neu",
    "city": "Stadt",
    "clear": "Leeren",
    "close": "Schliesse",
    "cluster": "Cluster",
    "clusterCapacity": "Cluster Kapazität",
    "comparison": "Vergleich",
    "config": "Config",
    "configurateMachines": "Maschinen einstellen",
    "confirm": "Bestätigen",
    "control": "Kontroller",
    "controls": "Steuerung",
    "countCachedValues": "Zwischengespeicherte Werte zählen",
    "countCachedValuesHelp": "Angabe wie viele Werte bei der Verarbeitung zwischengespeichert werden.",
    "counter": "counter",
    "country": "Land",
    "create": "Erstellen",
    "createNewDiagram": "Erstelle neues Diagramm",
    "createSensorTemplate": "Sensor Vorlage erstellen",
    "created": "Erstellt",
    "critical": "Kritisch",
    "criticals": "Kritische",
    "customer": "Kunde",
    "customerInfo": "Kundeninformationen",
    "customers": "Kunden",
    "dashboard": "Dashboard",
    "dataBackground": "Daten Hintergrund",
    "dataOverview": "Datenübersicht",
    "datarate": {
      "fast": "schnelle Datenrate",
      "name": "Datenrate",
      "slow": "langsame Datenrate",
      "time": "Zeit Datenrate",
      "xy": "XY Datenrate"
    },
    "date": "Datum",
    "day": "Tag",
    "delete": "Löschen",
    "deleteCustomer": "Kunde löschen",
    "deleteDevice": "Lösche Gerät",
    "deletePair": "Paar löschen",
    "deleteRecord": "Lösche Aufnahme",
    "deleteSensorTemplate": "Sensor Vorlage löschen",
    "deleteSignal": "Lösche Signal",
    "desc": "Desc",
    "description": "Beschreibung",
    "descriptionPlease": "Bitte eine Beschreibung schreiben",
    "details": "Details",
    "detect": "Erkennt",
    "detecting": "Erkennen",
    "detectionConfig": "Erkennungseinstellung",
    "device": "Gerät",
    "deviceAlerts": "Gerät Alarme",
    "deviceID": "Geräte ID",
    "deviceInfo": "Geräteinfo",
    "deviceSerial": "Geräte Seriennummer",
    "devices": "Geräte",
    "diagram": "Diagramm",
    "diagramOptions": "Diagrammoptionen",
    "dismiss": "Verwerfen",
    "dismissChanges": "Verwirf Änderungen",
    "download": "Download",
    "dream": "Träumen",
    "duplicate": "Verdoppeln",
    "duration": "Dauer",
    "edit": "Bearbeiten",
    "editConfigForID": "Bearbeite Einstellung für Sensor mit ID",
    "editRelatedSignals": "Bearbeite verbundene Signale",
    "editSamplingRate": "Bearbeite Abtastrate",
    "editSensorWithID": "Editiere Sensor mit ID",
    "editThisDevice": "Bearbeite dieses Gerät",
    "emergencies": "Notfälle",
    "emergency": "Notfall",
    "end": "Ende",
    "enterTitle": "Trage den gewünschten Namen ein und bestätige die Abfrage",
    "export": "Export",
    "factory": "Fabrik",
    "faultInput": "Fehlerhafte Eingabe",
    "filename": "Dateiname",
    "filtering": "Filterung",
    "finishEditSignaltype": "beende Signaltypen bearbeitung",
    "floorThreshold": "untere Schwelle",
    "height": "Höhe",
    "hullCurve": "Hüllkurve",
    "icon": "Symbol",
    "import": "Import",
    "in": "in",
    "informations": "Informationen",
    "infos": "Infos",
    "init": "Init",
    "is": "ist",
    "knownAnomalies": "Bekannte Anomalien",
    "knownFailures": "Bekannte Fehler",
    "lastAnomalyTime": "letzte Anomalie Zeit",
    "latitude": "Breitengrad",
    "learn": "Lernst",
    "learned": "Gelernt",
    "learning": "Lernen",
    "length": "Länge",
    "limitBackground": "Begrenzung Hintergrund",
    "limitLine": "Begrenzungslinie",
    "limits": "Grenzen",
    "load": "Laden",
    "loadConfig": "lade Konfig",
    "loadConfigSensorID": "Lade Einstellung für Sensor mit ID",
    "loadMore": "lade mehr",
    "loading": "Lädt...",
    "location": "Standort",
    "locations": "Standorte",
    "logout": "Abmelden",
    "longitude": "Längengrad",
    "machineOffline": "Maschine ist NICHT online, keine Echtzeitdaten!",
    "machines": "Maschinen",
    "mainSignalValuesShown": "Hauptsignal Werte gezeigt",
    "manufacturer": "Hersteller",
    "max": "Max",
    "maxAlertActive": "Max Alarm Aktiv",
    "maxAnomalyCluster": "Maximum Verfügbare Cluster",
    "maxDistance": "max Distanz",
    "maxDistancesHelp": "Schwellwert für die maximale Distanz vom Hauptpfad (Ausreißer).",
    "maxX": "Max X",
    "maxY": "Max Y",
    "maximumDeviationSum": "Maximum summierte Abweichung",
    "maximumMeasuredValue": "Maximum gemessener Wert",
    "measuringUnit": "Messeinheit",
    "metadata": "Metadaten",
    "min": "Min",
    "minAlertActive": "Min Alarm Aktiv",
    "minAllowedClusterSize": "Min erlaubte Größe weil die Cluster bereits verwendet werden",
    "minMax": "Min/Max",
    "minX": "Min X",
    "minY": "Min Y",
    "minumumMeasuredValue": "Minumum gemessener Wert",
    "model": "Modell",
    "mostFaultyMachineType": "Anfälligste Maschinentypen",
    "ms": "ms",
    "name": "Name",
    "new": "Neu",
    "newCustomer": "neuer Kunde",
    "newDevice": "neues Gerät",
    "newDiagram": "neues Diagramm",
    "newName": "Neuer Name",
    "newPair": "neues Paar",
    "newSamplingRate": "Neue Abtastrate",
    "newSet": "Neues Set",
    "newSignalType": "neuer Signal Typ",
    "newSignaltype": "Neuer Signaltyp",
    "newSize": "neue Größe",
    "next": "Nächste",
    "no": "Nein",
    "noAlertsHere": "keine Alarme auf dieser Maschine",
    "noEqualChoosen": "Bitte ein Nummernpaar für die X/Y Diagramme auswählen.",
    "noFiles": "keine Dateien verfügbar",
    "noPairs": "keine Paare",
    "noProfiles": "keine Profile",
    "noRecordSelected": "keine Aufnahme ausgewählt",
    "noRecordsAvailable": "keine Aufnahme verfügbar",
    "noSavedAvailable": "KEINE gesicherten verfügbar",
    "noSignaltypesAdded": "Keine Signaltypen hinzugefügt",
    "noValuesChoosen": "Bitte wähle mindestens ein Wert für die Diagramme.",
    "notAllowed": "Nicht erlaubt",
    "nr": "Nr",
    "numberMachines": "Anzahl Maschinen",
    "oClock": "Uhr",
    "of": "von",
    "off": "Aus",
    "offline": "Offline",
    "ok": "Ok",
    "on": "An",
    "oneMonth": "1 Monat",
    "online": "Online",
    "options": "Optionen",
    "oscilloscope": "Oszilloskop",
    "overview": "Übersicht",
    "pagination": "Seitennummerierung",
    "picOfAnomaliePattern": "Bild des Anomalie Musters",
    "picOfMachine": "Bild dieser Maschine",
    "pleaseAddSignal": "Bitte ein Signal hinzufügen!",
    "pleaseTryAgain": "Bitte nochmal probieren",
    "pleaseWaitLoading": "Bitte warten, wird geladen",
    "points": "Punkte",
    "poweredWE": "powered by Webware-Experts OHG",
    "previous": "Vorheriges",
    "proactiveView": "Proaktive Ansicht",
    "production": "Produktion",
    "productionMonitoring": "Produktionsüberwachung",
    "profileLoaded": "Profil geladen",
    "profileLoadedLongA": "Das Profil namens",
    "profileLoadedLongB": "wurde geladen",
    "profileName": "Profilname",
    "reConfig": "Re-Config",
    "reInit": "Re-Init",
    "reallyDelete": "wirklich löschen",
    "reallyDeleteCustomer": "Möchten Sie wirklich diesen Kunden löschen?",
    "reallyDeleteDevice": "Möchten Sie wirklich das Gerät löschen?",
    "reallyDeleteRecord": "Möchten Sie wirklich diese Aufnahme löschen?",
    "reallyDeleteSensorTemplate": "Möchten Sie wirklich diese Sensorvorlage löschen?",
    "reallyDeleteSignal": "Möchten Sie wirklich das Signal löschen?",
    "reallyDuplicate": "wirklich duplizieren",
    "reallyDuplicateDevice": "Möchten Sie wirklich das Gerät duplizieren?",
    "reallyDuplicateSignal": "Möchten Sie wirklich das Signal duplizieren?",
    "reallyWantClearType": "Möchten Sie wirklich den Typ leeren",
    "realtime": "Echtzeit",
    "record": "Aufnahme",
    "recordName": "Aufnahme Name",
    "recordShown": "Aufnahme gezeigt",
    "recordedAt": "aufgenommen am",
    "records": "Aufnahmen",
    "relatedSignals": "verbundene Signale",
    "relative": "Relativ",
    "reloadAlerts": "Alarme neu laden",
    "reset": "Reset",
    "resetLearning": "gelerntes resetten",
    "resize": "Größe ändern",
    "resordedAlert": "aufgenommener Alarm",
    "samplingRates": "Aufnahmerate",
    "save": "Sichern",
    "saveConfigForID": "Sichere Einstellung für Sensor mit ID",
    "saveRecord": "speicher Aufnahme",
    "saveTemplate": "Vorlage Speicher",
    "saveThisRecord": "Möchten Sie diese Aufnahme speichern?",
    "saved": "gesichert",
    "savedProfiles": "Gespeicherte Profile",
    "sec": "sek",
    "selectIcon": "Symbol auswählen",
    "selected": "Ausgewählt",
    "sensor": "Sensor",
    "sensorScaling": "Sensor Skalierung",
    "sensorTemplates": "Sensor Vorlage",
    "sensordata": "Sensordaten",
    "serial": "Seriennummer",
    "serverTime": "Server Zeit",
    "setup": "Setup",
    "sevenDays": "7 Tage",
    "show": "Zeige",
    "showImage": "zeige Bild",
    "signal": "Signal",
    "signalID": "Signal ID",
    "signalName": "Signalname",
    "signalTypes": "Signaltypen",
    "signals": "Signale",
    "signaltype": "Signaltyp",
    "since": "seit",
    "singleValueDeviation": "Einzelwertabweichung",
    "snapshotSchedule": "Abbild Zeitplan",
    "spread": "Verbreitung",
    "sryNoData": "Entschuldigung, keine Daten verfügbar",
    "standardDeviation": "Standardabweichung",
    "start": "Start",
    "startDetecting": "starte Erkennung",
    "startLearning": "starte lernen",
    "startLine": "start Linie",
    "state": "Status",
    "stop": "Stop",
    "stopDetecting": "stoppe Erkennung",
    "stopLearning": "stoppe lernen",
    "stopLine": "stoppe Linie",
    "street": "Straße",
    "switchLanguage": "Sprache umschalten",
    "systemMessage": "System Nachricht",
    "template": "Vorlage",
    "textReallyDeletePair": "Möchten Sie das Paar wirklich löschen?",
    "textReallyResetLearning": "Möchten Sie das gelernte wirklich zurücksetzen?",
    "thatsNotAllowed": "und das ist nicht erlaubt!",
    "threeDays": "3 Tage",
    "time": "Zeit",
    "timeAlertFired": "Zeitpunkt des Alarms",
    "title": "Titel",
    "titlePlease": "Bitte ein Titel eingeben",
    "toggleNavigation": "Navigation umschalten",
    "toggleSignals": "Signale umschalten",
    "toggleView": "Ansicht umschalten",
    "twelveHours": "12 Stunden",
    "type": "Typ",
    "typeID": "Typ ID",
    "typeName": "Typ Name",
    "typeSize": "Typ Größe",
    "unit": "Einheit",
    "used4": "genutzt für",
    "userManagement": "Nutzerverwaltung",
    "username": "Baumann",
    "value": "Wert",
    "values": "Werte",
    "warning": "Warnung",
    "warningChangingSensorScaling": "Warnung: Änderung im \"Sensor Skalierung\" Parameter verwirft alle gelernten Cluster!",
    "warnings": "Warnungen",
    "weMonitor": "weMonitor",
    "width": "Breite",
    "writeNewName": "Schreibe neuen Namen",
    "xID": "X ID",
    "xScale": "X Skala",
    "xSensor": "X Sensor",
    "xSensorHelp": "Sensor ID des Sensors für die X-Achse",
    "xSensorMaxHelp": "Größter Wert für X-Achse",
    "xSensorMinHelp": "Kleinster Wert für X-Achse",
    "xSensorRate": "X Sensor Abtastrate",
    "xSensorRateHelp": "Abtastrate des Sensors für die X-Achse",
    "xyPairs": "XY-Paare",
    "yID": "Y ID",
    "ySensor": "Y Sensor",
    "ySensorHelp": "Sensor ID des Sensors für die Y-Achse",
    "ySensorMaxHelp": "Größter Wert für Y-Achse",
    "ySensorMinHelp": "Kleinster Wert für Y-Achse",
    "ySensorRate": "Y Sensor Rate",
    "ySensorRateHelp": "Abtastrate des Sensors für die Y-Achse",
    "year": "Jahr",
    "yes": "Ja",
    "youChoose": "Ihre Wahl",
    "zipCode": "PLZ"
  };
  _exports.default = _default;
});