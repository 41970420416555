define("machine-monitoring/devices/details/anomalie/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate(controller, model) {
      this.render("devices/details/anomalie", {
        into: "devices/details",
        controller: controller,
        model: model
      });
      this.render("anomalie-navigation", {
        into: "extend-navigation",
        outlet: "extend-navigation",
        controller: controller,
        model: model
      });
      controller.send("firstRunActions");
    },

    actions: {
      willTransition(transition) {
        if (transition.targetName == "devices.details.anomalie.index") {
          this.controllerFor("devices/details").set('thirdNaviRoute', 'overview');
          this.controllerFor("devices/details/anomalie").send("firstRunActions");
        }
      }

    }
  });

  _exports.default = _default;
});