define("machine-monitoring/configuration/customers/customer/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8dP8Scw3",
    "block": "{\"symbols\":[],\"statements\":[[0,\"Customer/edit\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/configuration/customers/customer/edit/template.hbs"
    }
  });

  _exports.default = _default;
});