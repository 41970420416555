define("machine-monitoring/devices/details/signal/realtime/controller", ["exports", "machine-monitoring/app", "chartjs", "jquery"], function (_exports, _app, _chartjs, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    socketService: Ember.inject.service("websockets"),
    isLoading: true,
    signalRun: false,
    signalCount: 0,
    idsToSave: null,
    averageDataRateTime: 300,
    averageDataRateXY: 10,
    signalRunTimeId: null,
    signalRunXYId: null,
    loadMode: null,
    recordRun: false,
    recordDuration: 0,
    recordPoints: 0,
    windowResizedTrigger: false,
    displayModeOne: false,
    displayModeTwo: false,
    displayModeTre: false,
    displayModeFor: false,
    displayModeFiv: false,
    // Realtime
    // st*** = ShortTerm Diagram
    stChartObj0: null,
    stChartObj1: null,
    stChartObj2: null,
    stChartObj3: null,
    stValueCount: 40,
    // lt*** = LongTerm Diagram
    ltChartObj: null,
    ltChartData: null,
    ltChartOptions: null,
    ltValueCount: 500,
    rtBackgroundTrue: true,
    rtLimitBackgroundTrue: true,
    rtLimitLineTrue: true,
    rtXScaleTrue: false,
    xyData: null,
    metaDataOne: null,
    metaDataTwo: null,
    signalTypeX1: null,
    signalTypeY1: null,
    signalTypeX2: null,
    signalTypeY2: null,

    init() {
      this._super(...arguments);

      this.allCustomObjects = [];
      this.signalMap = [];
      this.legendValues = [];
    },

    requestDeviceMetas() {
      let idsToRecordXY = this.get('idsToRecordXY');

      if (idsToRecordXY) {
        _jquery.default.get(_app.default.API_URL + "/time", timeData => {
          let startTime = timeData.time - 3600000000000;
          let startEndString = "?start=" + startTime + "&end=" + timeData.time;

          _jquery.default.get(_app.default.API_URL + "/values/minmax/" + this.get("model.device").get("id") + "/" + idsToRecordXY + startEndString, response => {
            let metaDataOne = this.get('metaDataOne');
            let metaDataTwo = this.get('metaDataTwo');
            response.signalTypes.forEach(thisValue => {
              if (metaDataOne[0] && metaDataOne[0].typeId == thisValue.id) {
                metaDataOne[0].min = thisValue.min;
                metaDataOne[0].max = thisValue.max;
              } else if (metaDataOne[1] && metaDataOne[1].typeId == thisValue.id) {
                metaDataOne[1].min = thisValue.min;
                metaDataOne[1].max = thisValue.max;
              }

              if (metaDataTwo[0] && metaDataTwo[0].typeId == thisValue.id) {
                metaDataTwo[0].min = thisValue.min;
                metaDataTwo[0].max = thisValue.max;
              } else if (metaDataTwo[1] && metaDataTwo[1].typeId == thisValue.id) {
                metaDataTwo[1].min = thisValue.min;
                metaDataTwo[1].max = thisValue.max;
              }
            });
            this.set('metaDataOne', metaDataOne);
            this.set('metaDataTwo', metaDataTwo);
            this.setupWebsocketConnectionRT();
          });
        });
      }
    },

    setupWebsocketConnectionRT() {
      let deviceId = this.get("model.device").get("id");
      const socket = this.get("socketService").socketFor(_app.default.WS_URL);
      socket.on("open", this.wsOpenHandler, this);
      socket.on("message", this.wsMessageHandler, this);
      socket.on('error', this.wsErrorHandler, this);
      this.set("socketRef", socket);
      let stopUrl = _app.default.API_URL + "/stoprecording/" + deviceId;
      this.set("stopUrl", stopUrl);
      let deleteUrl = _app.default.API_URL + "/recordings/" + deviceId;
      this.set("deleteUrl", deleteUrl);
    },

    wsOpenHandler() {
      let idsToRecordTime = this.get("idsToRecordTime");

      if (idsToRecordTime) {
        let averageDataRateTime = this.get('averageDataRateTime');

        _jquery.default.get(_app.default.API_URL + "/startrecording/" + this.get("model.device").get("id") + "/" + idsToRecordTime + "/" + averageDataRateTime + "?type=time", response => {
          this.set('signalRunTimeId', response.recordId);
        });

        this.set("signalRun", true);
        this.set('dateRecordStart', Date.now());
      } else {
        this.set('signalRunTimeId', null);
      }

      let idsToRecordXY = this.get('idsToRecordXY');

      if (idsToRecordXY) {
        let averageDataRateXY = this.get('averageDataRateXY');

        _jquery.default.get(_app.default.API_URL + "/startrecording/" + this.get("model.device").get("id") + "/" + idsToRecordXY + "/" + averageDataRateXY + "?type=xy", response => {
          this.set("recordRun", true);
          this.set('signalRunXYId', response.recordId);
        });

        this.set("signalRun", true);
        this.set('dateRecordStart', Date.now());
      } else {
        this.set('signalRunXYId', null);
      }

      (0, _jquery.default)('#detailWrapper').removeClass('invisible');
      (0, _jquery.default)('#detailWrapper').addClass('visible');
      this.set('isLoading', false);
    },

    wsMessageHandler(event) {
      if (event.data === "ping") {
        return;
      }

      let dateRecordStart = this.get('dateRecordStart');
      let recordDuration = Math.round((Date.now() - dateRecordStart) / 1000);
      this.set('recordDuration', recordDuration);
      let recordPoints = this.get('recordPoints');
      let loadMode = this.get('loadMode');

      if (loadMode == 1) {
        recordPoints++;
        this.updateChartData(_jquery.default.parseJSON(event.data));
      } else if (loadMode == 3 || loadMode == 5) {
        let signalRunXYId = this.get('signalRunXYId');
        let signalRunTimeId = this.get('signalRunTimeId');

        let rawData = _jquery.default.parseJSON(event.data);

        if (rawData.id == signalRunXYId) {
          this.set('xyData', rawData);
        } else if (rawData.id == signalRunTimeId) {
          recordPoints++;
          this.updateChartData(rawData);
        }
      } else if (loadMode == 2 || loadMode == 4) {
        recordPoints++;
        this.updateLTXYChart(_jquery.default.parseJSON(event.data));
        this.set('xyData', _jquery.default.parseJSON(event.data));
      }

      this.set('recordPoints', recordPoints);
    },

    wsErrorHandler() {
      _jquery.default.get(_app.default.API_URL + "/time").fail(function (jqXHR) {
        if (jqXHR.readyState == 4 && jqXHR.status == 401) {
          window.location.replace(_app.default.API_HOST + "/user-service/");
        }
      });
    },

    closeWebSockets() {
      this.stopWebsocketData();
      const socket = this.get("socketRef");

      if (socket) {
        socket.off("message", this.wsMessageHandler);
      }

      Ember.run.later(() => {
        this.get("socketService").closeSocketFor(_app.default.WS_URL);
      }, 100);
    },

    stopWebsocketData() {
      let signalRun = this.get("signalRun");

      if (signalRun) {
        let stopUrl = this.get("stopUrl");

        _jquery.default.get(stopUrl);

        this.set("signalRun", false);
      }
    },

    startRtDiagramConfig() {
      let ltData = {
        labels: [],
        datasets: []
      };
      this.set("ltChartData", ltData);
      let ltOptions = {
        animation: {
          duration: 0
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: false
          }],
          yAxes: []
        },
        point: {
          radius: 0
        }
      };
      this.set("ltChartOptions", ltOptions);
    },

    createTimeCanvas() {
      let shortSignalCol = (0, _jquery.default)("#shortSignalCol").width();
      this.set("stCanvasWidth", shortSignalCol);
      let signalCount = this.get("allCustomObjects").length;

      if ((0, _jquery.default)("#stCanvas0").length > 0) {
        // let shortSignalCol = $("#shortSignalCol");
        // let canvasOffset = shortSignalCol.offset().top;
        let windowHeight = window.innerHeight; //let canvasHeightTo = (windowHeight - canvasOffset - 45) / signalCount;

        let canvasHeightTo = (windowHeight - 419) / signalCount;

        if (canvasHeightTo < 100) {
          canvasHeightTo = 100;
        }

        for (let ii = 0; ii < signalCount; ii++) {
          let thisCanvasString = "#stCanvas" + ii;
          let thisCanvasBody = (0, _jquery.default)(thisCanvasString);
          thisCanvasBody.height(canvasHeightTo);
          let thisStChartObj = this.get("stChartObj" + ii);

          if (thisStChartObj) {
            thisStChartObj.update();
          }
        }
      }
    },

    updateChartData(dataPoint) {
      let stChartObj0 = this.get("stChartObj0");

      if (stChartObj0) {
        let ltChartObj = this.get("ltChartObj");
        let currentStLength = stChartObj0.data.datasets[0].data.length;
        let stValueCount = this.get("stValueCount");
        let ltValueCount = this.get("ltValueCount");
        let extracted = String(dataPoint.ts).substring(0, 13);
        let d = new Date(Number(extracted));
        let extractedSeconds = d.getSeconds() + "." + d.getMilliseconds().toString();
        let minutesNow;
        let box = d.getMinutes().toString();

        if (d.getMinutes() < 10) {
          minutesNow = "0" + box;
        } else {
          minutesNow = d.getMinutes().toString();
        }

        let timeNow = d.getHours() + ":" + minutesNow + "." + extractedSeconds;
        ltChartObj.data.labels.push(timeNow);
        let legendValues = this.get("legendValues");
        let signalMap = this.get("signalMap");
        dataPoint.vals.forEach(thisValue => {
          let thisValueType = thisValue.sID;
          let thisTypeIndex = signalMap[thisValueType];
          let thisValueValue = thisValue.val;
          let thisString = "stChartObj" + thisTypeIndex;
          let thisChartObj = this.get(thisString);
          let thisLegendValue = legendValues[thisTypeIndex];
          thisChartObj.data.datasets[0].data.push(thisValueValue);
          thisChartObj.data.labels.push(timeNow);
          let pushTo = currentStLength;

          if (pushTo >= stValueCount) {
            pushTo = stValueCount;
          }

          thisChartObj.data.datasets[1].data = [];
          thisChartObj.data.datasets[1].showLine = thisLegendValue.maxActive;

          if (thisLegendValue.maxActive) {
            thisChartObj.data.datasets[1].data[0] = thisLegendValue.maxAlert;
            thisChartObj.data.datasets[1].data[pushTo] = thisLegendValue.maxAlert;
          }

          thisChartObj.data.datasets[2].data = [];
          thisChartObj.data.datasets[2].showLine = thisLegendValue.minActive;

          if (thisLegendValue.minActive) {
            thisChartObj.data.datasets[2].data[0] = thisLegendValue.minAlert;
            thisChartObj.data.datasets[2].data[pushTo] = thisLegendValue.minAlert;
          }

          if (currentStLength >= stValueCount) {
            thisChartObj.data.labels.splice(0, 1);
            thisChartObj.data.datasets[0].data.splice(0, 1);
          }

          ltChartObj.data.datasets[signalMap[thisValueType]].data.push(thisValueValue);

          if (ltChartObj.data.datasets[signalMap[thisValueType]].data.length >= ltValueCount) {
            ltChartObj.data.datasets[signalMap[thisValueType]].data.splice(0, 1);
          }

          if (ltChartObj.data.labels.length >= ltValueCount) {
            ltChartObj.data.labels.splice(0, 1);
          }

          thisChartObj.update();
        });
        ltChartObj.update();
      }
    },

    updateLTXYChart(dataPoint) {
      let ltChartObj = this.get("ltChartObj");
      let ltValueCount = this.get("ltValueCount");
      let extracted = String(dataPoint.ts).substring(0, 13);
      let d = new Date(Number(extracted));
      let extractedSeconds = d.getSeconds() + "." + d.getMilliseconds().toString();
      let minutesNow;
      let box = d.getMinutes().toString();

      if (d.getMinutes() < 10) {
        minutesNow = "0" + box;
      } else {
        minutesNow = d.getMinutes().toString();
      }

      let timeNow = d.getHours() + ":" + minutesNow + "." + extractedSeconds;
      ltChartObj.data.labels.push(timeNow);
      let signalMap = this.get("signalMap");
      dataPoint.vals.forEach(thisValue => {
        let thisValueType = thisValue.sID;
        let thisValueValue = thisValue.val;
        ltChartObj.data.datasets[signalMap[thisValueType]].data.push(thisValueValue);

        if (ltChartObj.data.datasets[signalMap[thisValueType]].data.length >= ltValueCount) {
          ltChartObj.data.datasets[signalMap[thisValueType]].data.splice(0, 1);
        }

        if (ltChartObj.data.labels.length >= ltValueCount) {
          ltChartObj.data.labels.splice(0, 1);
        }
      });
      ltChartObj.update();
    },

    generateTimeDiagrams() {
      let rtBackground = this.get("rtBackgroundTrue");
      let rtXScale = this.get("rtXScaleTrue");
      let allCustomObjects = [];
      this.get("model.signalTypes").forEach(thisSignal => {
        let isSelected = thisSignal.get("selectedT");

        if (isSelected) {
          let thisSignalAlertType = thisSignal.get("alertType");
          let thisAlertTypeAlertProperties = thisSignalAlertType.get("alertProperties");
          let thisAlertTypeCeiling = thisAlertTypeAlertProperties.get("ceiling");
          let thisAlertTypeFloor = thisAlertTypeAlertProperties.get("floor");
          let realTimeSignalCustomObject = {
            typeId: thisSignal.get("signalTypeId"),
            name: thisSignal.get("name"),
            unit: thisSignal.get("unit"),
            maxActive: thisAlertTypeCeiling.get("active"),
            maxAlert: thisAlertTypeCeiling.get("emergency"),
            minActive: thisAlertTypeFloor.get("active"),
            minAlert: thisAlertTypeFloor.get("emergency")
          };
          allCustomObjects.pushObject(realTimeSignalCustomObject);
        }

        thisSignal.set("selectedT", false);
      });
      let signalCount = allCustomObjects.length;
      this.set("signalCount", signalCount);
      let signalRun = this.get("signalRun");

      if (!signalRun) {
        let shortRealTimeCol = document.getElementById("shortSignalCol");
        let ltData = this.get("ltChartData");
        let ltOptions = this.get("ltChartOptions");
        let signalTypeIdString = "";
        let legendValues = [];
        let ia = 0;
        let signalMap = this.get("signalMap");
        let stCanvasWidth = this.get("stCanvasWidth");
        let fourColorsBorder = ["rgba(0,255,0,1)", "rgba(255,0,255,1)", "rgba(0,255,255,1)", "rgba(255,255,0,1)"];
        let fourColorsBackground = ["rgba(0,255,0,0.3)", "rgba(255,0,255,0.3)", "rgba(0,255,255,0.3)", "rgba(255,255,0,0.3)"];
        allCustomObjects.forEach(thisCustomObject => {
          let thisCustomObjectId = thisCustomObject.typeId;
          let newDiv = document.createElement("div");
          newDiv.setAttribute("id", "stDiv" + ia);
          let newCanvas = document.createElement("canvas");
          newCanvas.setAttribute("id", "stCanvas" + ia);
          newCanvas.setAttribute("width", stCanvasWidth);
          newCanvas.setAttribute("height", "200");
          newDiv.appendChild(newCanvas);
          shortRealTimeCol.appendChild(newDiv);
          signalMap[thisCustomObjectId] = ia;
          signalTypeIdString = signalTypeIdString + thisCustomObject.typeId + ",";
          let stData = {
            labels: [],
            datasets: [{
              backgroundColor: fourColorsBackground[ia],
              strokeColor: fourColorsBorder[ia],
              borderColor: fourColorsBorder[ia],
              pointBackgroundColor: fourColorsBorder[ia],
              pointBorderColor: fourColorsBorder[ia],
              pointHoverBackgroundColor: fourColorsBorder[ia],
              pointHoverBorderColor: fourColorsBorder[ia],
              tension: 0.3,
              fill: rtBackground,
              data: []
            }, {
              backgroundColor: "rgba(175,175,175,0.3)",
              borderColor: "rgba(255,95,0,1)",
              pointRadius: 0,
              borderWidth: 1,
              fill: "end",
              spanGaps: true,
              showLine: true,
              data: []
            }, {
              backgroundColor: "rgba(175,175,175,0.3)",
              borderColor: "rgba(255,95,0,1)",
              pointRadius: 0,
              borderWidth: 1,
              fill: "start",
              spanGaps: true,
              showLine: true,
              data: []
            }]
          };
          let thisLegendValue = {
            name: thisCustomObject.name,
            color: fourColorsBorder[ia],
            type: thisCustomObjectId,
            unit: thisCustomObject.unit,
            selected: false,
            showLimit: true,
            minAlert: thisCustomObject.minAlert,
            minActive: thisCustomObject.minActive,
            maxAlert: thisCustomObject.maxAlert,
            maxActive: thisCustomObject.maxActive,
            allMin: thisCustomObject.minAlert,
            allMax: thisCustomObject.maxAlert
          };
          legendValues.pushObject(thisLegendValue);
          let stOptions = {
            animation: {
              duration: 0
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            scales: {
              xAxes: [{
                display: false
              }],
              yAxes: [{
                display: true,
                position: "left",
                ticks: {
                  beginAtZero: true
                },
                scaleLabel: {
                  display: false
                },
                gridLines: {
                  display: true,
                  color: "rgba(255,255,255,0.1)"
                },
                afterFit: scaleInstance => {
                  scaleInstance.width = 50;
                }
              }]
            },
            pointRadius: 1,
            pointBorderWidth: 0,
            pointHoverRadius: 20,
            borderWidth: 2
          };

          if (signalCount - 1 === ia) {
            stOptions.scales.xAxes[0].display = rtXScale;
          }

          let stContext = newCanvas.getContext("2d");
          let stNewChart = new _chartjs.default(stContext, {
            type: "line",
            data: stData,
            options: stOptions
          });
          this.set("stChartObj" + ia, stNewChart);
          let thisLtYAxis = {
            display: false,
            ticks: {
              beginAtZero: true
            },
            scaleLabel: {
              display: false
            },
            gridLines: {
              display: true,
              color: "rgba(255,255,255,0.1)"
            },
            id: "yLtId" + ia
          };
          ltOptions.scales.yAxes.pushObject(thisLtYAxis);
          let nextLtDataGraph = {
            backgroundColor: "rgba(0,0,0,0)",
            borderColor: fourColorsBorder[ia],
            tension: 0.3,
            radius: 0,
            borderWidth: 1,
            data: [],
            yAxisID: "yLtId" + ia
          };
          ltData.datasets.pushObject(nextLtDataGraph);
          ia++;
        });
        this.set("allCustomObjects", allCustomObjects);
        this.set("legendValues", legendValues);
        let stringLength = signalTypeIdString.length;
        let thisIds = signalTypeIdString.substring(0, stringLength - 1);
        this.set("idsToRecordTime", thisIds);
        this.set("ltData", ltData);
        this.set("signalMap", signalMap);
        let ltCanvasElement = document.getElementById("longRealTimeCanvas");
        let ltContext = ltCanvasElement.getContext("2d");
        let ltNewChart = new _chartjs.default(ltContext, {
          type: "line",
          data: ltData,
          options: ltOptions
        });
        this.set("ltChartObj", ltNewChart);
        return true;
      }
    },

    generateLTXYDiagrams() {
      let allCustomObjects = [];
      this.get("model.signalTypes").forEach(thisSignal => {
        if (thisSignal.get("selectedX1") || thisSignal.get("selectedY1") || thisSignal.get("selectedX2") || thisSignal.get("selectedY2")) {
          let realTimeSignalCustomObject = {
            typeId: thisSignal.get("signalTypeId"),
            name: thisSignal.get("name"),
            unit: thisSignal.get("unit")
          };
          allCustomObjects.pushObject(realTimeSignalCustomObject);
        }
        /*
        thisSignal.set("selectedX1", false);
        thisSignal.set("selectedY1", false);
        thisSignal.set("selectedX2", false);
        thisSignal.set("selectedY2", false);
        */

      });
      let signalCount = allCustomObjects.length;
      this.set("signalCount", signalCount);
      let signalRun = this.get("signalRun");

      if (!signalRun) {
        let ltData = this.get('ltChartData');
        let ltOptions = this.get("ltChartOptions");
        let ia = 0;
        let signalMap = this.get("signalMap");
        let fourColorsBorder = ["rgba(0,255,0,1)", "rgba(255,0,255,1)", "rgba(0,255,255,1)", "rgba(255,255,0,1)"];
        allCustomObjects.forEach(thisCustomObject => {
          let thisCustomObjectId = thisCustomObject.typeId;
          signalMap[thisCustomObjectId] = ia;
          let thisLtYAxis = {
            display: false,
            ticks: {
              beginAtZero: true
            },
            scaleLabel: {
              display: false
            },
            gridLines: {
              display: true,
              color: "rgba(255,255,255,0.1)"
            },
            id: "yLtId" + ia
          };
          ltOptions.scales.yAxes.pushObject(thisLtYAxis);
          let nextLtDataGraph = {
            backgroundColor: "rgba(0,0,0,0)",
            borderColor: fourColorsBorder[ia],
            tension: 0.3,
            radius: 0,
            borderWidth: 1,
            data: [],
            yAxisID: "yLtId" + ia
          };
          ltData.datasets.pushObject(nextLtDataGraph);
          ia++;
        });
        this.set("allCustomObjects", allCustomObjects);
        this.set("signalMap", signalMap);
        let ltCanvasElement = document.getElementById("longRealTimeCanvas");
        let ltContext = ltCanvasElement.getContext("2d");
        let ltNewChart = new _chartjs.default(ltContext, {
          type: "line",
          data: ltData,
          options: ltOptions
        });
        this.set("ltChartObj", ltNewChart);
        return true;
      }
    },

    beginTimeOnlyDiag() {
      this.startRtDiagramConfig();
      let createDiagram = setInterval(() => {
        let ltCanvas = (0, _jquery.default)("#longRealTimeCanvas");
        let testJ = ltCanvas.length;

        if (testJ > 0) {
          if (this.generateTimeDiagrams() == true) {
            this.createTimeCanvas();
            this.setupWebsocketConnectionRT();
          }

          clearInterval(createDiagram);
        }
      }, 100);
    },

    beginMixedDiag() {
      this.startRtDiagramConfig();
      let createDiagram = setInterval(() => {
        let ltCanvas = (0, _jquery.default)("#longRealTimeCanvas");
        let testJ = ltCanvas.length;

        if (testJ > 0) {
          if (this.generateTimeDiagrams() == true) {
            this.createTimeCanvas();

            if (this.generateXYDatas() == true) {
              this.requestDeviceMetas();
            }
          }

          clearInterval(createDiagram);
        }
      }, 100);
    },

    beginXYOnlyDiag() {
      this.startRtDiagramConfig();
      let createDiagram = setInterval(() => {
        let ltCanvas = (0, _jquery.default)("#longRealTimeCanvas");
        let testJ = ltCanvas.length;

        if (testJ > 0) {
          if (this.generateLTXYDiagrams() == true) {
            if (this.generateXYDatas() == true) {
              this.requestDeviceMetas();
            }
          }

          clearInterval(createDiagram);
        }
      }, 100);
    },

    generateXYDatas() {
      let signalTypeIdString = "";
      let allCustomObjectsXYOne = [];
      let allCustomObjectsXYTwo = [];
      this.get("model.signalTypes").forEach(thisSignal => {
        let isSelectedX1 = thisSignal.get("selectedX1");
        let isSelectedY1 = thisSignal.get("selectedY1");
        let isSelectedX2 = thisSignal.get("selectedX2");
        let isSelectedY2 = thisSignal.get("selectedY2");

        if (isSelectedX1 || isSelectedY1) {
          let box = 0;

          if (isSelectedY1) {
            box = 1;
          }

          signalTypeIdString = signalTypeIdString + thisSignal.get("signalTypeId") + ",";
          let realTimeSignalCustomObject = {
            typeId: thisSignal.get("signalTypeId"),
            name: thisSignal.get("name"),
            unit: thisSignal.get("unit"),
            orientation: box,
            liveView: true
          };
          allCustomObjectsXYOne.pushObject(realTimeSignalCustomObject);
        }

        if (isSelectedX2 || isSelectedY2) {
          let box = 0;

          if (isSelectedY2) {
            box = 1;
          }

          signalTypeIdString = signalTypeIdString + thisSignal.get("signalTypeId") + ",";
          let realTimeSignalCustomObject = {
            typeId: thisSignal.get("signalTypeId"),
            name: thisSignal.get("name"),
            unit: thisSignal.get("unit"),
            orientation: box,
            liveView: true
          };
          allCustomObjectsXYTwo.pushObject(realTimeSignalCustomObject);
        }

        thisSignal.set("selectedX1", false);
        thisSignal.set("selectedY1", false);
        thisSignal.set("selectedX2", false);
        thisSignal.set("selectedY2", false);
      });

      if (allCustomObjectsXYOne == [] && allCustomObjectsXYTwo.length > 0) {
        allCustomObjectsXYOne = allCustomObjectsXYTwo;
        allCustomObjectsXYTwo = [];
      }

      this.set('metaDataOne', allCustomObjectsXYOne);
      this.set('metaDataTwo', allCustomObjectsXYTwo);
      let stringLength = signalTypeIdString.length;
      let thisIds = signalTypeIdString.substring(0, stringLength - 1);
      this.set("idsToRecordXY", thisIds);
      return true;
    },

    stopRealTimeData() {
      let signalRun = this.get("signalRun");

      if (signalRun) {
        let stopUrl = this.get("stopUrl");
        let deleteUrl = this.get("deleteUrl");

        _jquery.default.ajax({
          url: stopUrl,
          type: "GET",
          success: () => {
            _jquery.default.ajax({
              url: deleteUrl,
              type: "DELETE"
            });
          }
        });

        this.set("signalRun", false);
      }

      let realTimeButton = document.getElementById("realTimeToggleButton");

      if (realTimeButton) {
        realTimeButton.blur();
      }
    },

    actions: {
      resizeWindow() {
        let windowResizedTrigger = this.get('windowResizedTrigger');
        this.set('windowResizedTrigger', !windowResizedTrigger);
      },

      firstRunAfterRender() {
        this.set('legendValues', []);
        let loadMode = this.get('loadMode');

        if (loadMode == 1) {
          this.beginTimeOnlyDiag();
        } else if (loadMode == 3 || loadMode == 5) {
          this.beginMixedDiag();
        } else if (loadMode == 2 || loadMode == 4) {
          this.beginXYOnlyDiag();
        }
      },

      toggleSignal() {
        let signalRun = this.get("signalRun");

        if (signalRun) {
          let stopUrl = this.get("stopUrl");

          _jquery.default.ajax({
            url: stopUrl,
            type: "GET",
            success: result => {
              this.set("idsToSave", result);
              this.set("signalRun", false);
              (0, _jquery.default)("#modalSaveRecord").modal("show");
            }
          });
        } else {
          this.wsOpenHandler();
          this.set("signalRun", true);
        }
      },

      reloadSignal() {
        let ltChartObj = this.get("ltChartObj");
        let signalMap = this.get("signalMap");
        let signalRun = this.get("signalRun");

        if (signalRun) {
          let stopUrl = this.get("stopUrl");
          let deleteUrl = this.get("deleteUrl");

          _jquery.default.ajax({
            url: stopUrl,
            type: "GET",
            success: () => {
              this.set("signalRun", false);

              _jquery.default.ajax({
                url: deleteUrl,
                type: "DELETE"
              });

              signalMap.forEach(thisSignal => {
                ltChartObj.data.datasets[thisSignal].data = [];
                let thisString = "stChartObj" + thisSignal;
                let thisChartObj = this.get(thisString);
                thisChartObj.data.labels = [];
                thisChartObj.data.datasets[0].data = [];
                thisChartObj.update();
              });
              ltChartObj.update();
              this.wsOpenHandler();
            }
          });
        } else {
          signalMap.forEach(thisSignal => {
            ltChartObj.data.datasets[thisSignal].data = [];
            let thisString = "stChartObj" + thisSignal;
            let thisChartObj = this.get(thisString);
            thisChartObj.data.labels = [];
            thisChartObj.data.datasets[0].data = [];
            thisChartObj.update();
          });
          ltChartObj.update();
        }

        document.getElementById("realTimeReloadButton").blur();
      },

      reConfigSignals() {
        //////// stop realtime, remove diagrams/canvases, transitionTo osci
        let shortRealTimeCol = document.getElementById("shortSignalCol");

        if (shortRealTimeCol) {
          while (shortRealTimeCol.firstChild) {
            shortRealTimeCol.removeChild(shortRealTimeCol.firstChild);
          }
        }

        let ltChartObj = this.get("ltChartObj");
        let signalMap = this.get("signalMap");
        let signalRun = this.get("signalRun");

        if (signalRun) {
          let stopUrl = this.get("stopUrl");

          _jquery.default.ajax({
            url: stopUrl,
            type: "GET",
            success: () => {
              this.set("signalRun", false);
              signalMap.forEach(thisSignal => {
                ltChartObj.data.datasets[thisSignal].data = [];
                let thisString = "stChartObj" + thisSignal;
                let thisChartObj = this.get(thisString);
                thisChartObj.data.labels = [];
                thisChartObj.data.datasets[0].data = [];
                thisChartObj.update();
                thisChartObj.destroy();
                this.set(thisString, null);
              });

              if (ltChartObj) {
                ltChartObj.update();
                ltChartObj.destroy();
                this.set("ltChartObj", null);
              }

              this.set("signalMap", []);
              this.transitionToRoute('devices.details.signal');
            }
          });
        } else {
          signalMap.forEach(thisSignal => {
            let thisString = "stChartObj" + thisSignal;
            let thisChartObj = this.get(thisString);

            if (thisChartObj) {
              thisChartObj.destroy();
            }

            this.set(thisString, null);
          });

          if (ltChartObj) {
            ltChartObj.destroy();
            this.set("ltChartObj", null);
          }

          this.set("signalMap", []);
          this.transitionToRoute('devices.details.signal');
        }
      },

      dontSave() {
        let idsToSave = this.get("idsToSave");

        _jquery.default.ajax({
          url: _app.default.API_URL + "/recordings/" + idsToSave[0].recordId,
          type: "DELETE"
        });

        document.getElementById("realTimeToggleButton").blur();
      },

      saveRecord() {
        let recordTitle = this.get("recordTitle");
        let idsToSave = this.get("idsToSave");
        let jsonToSend = {
          name: recordTitle,
          xy: []
        };
        let loadMode = this.get('loadMode');

        if (loadMode > 1) {
          let metaDataOne = this.get('metaDataOne');
          let xAId, yAId;

          if (metaDataOne[0].orientation) {
            xAId = metaDataOne[1].typeId;
            yAId = metaDataOne[0].typeId;
          } else {
            xAId = metaDataOne[0].typeId;
            yAId = metaDataOne[1].typeId;
          }

          jsonToSend.xy.pushObject({
            "xSensor": xAId,
            "ySensor": yAId
          });
        }

        if (loadMode > 3) {
          let metaDataTwo = this.get('metaDataTwo');
          let xBId, yBId;

          if (metaDataTwo[0].orientation) {
            xBId = metaDataTwo[1].typeId;
            yBId = metaDataTwo[0].typeId;
          } else {
            xBId = metaDataTwo[0].typeId;
            yBId = metaDataTwo[1].typeId;
          }

          jsonToSend.xy.pushObject({
            "xSensor": xBId,
            "ySensor": yBId
          });
        }

        _jquery.default.post(_app.default.API_URL + "/recordings/" + idsToSave[0].recordId, JSON.stringify(jsonToSend));

        this.set("recordTitle", null);
        this.send("refreshModel");
        document.getElementById("realTimeToggleButton").blur();
      },

      //////////// on the fly configuration
      toggleRtBackground() {
        let rtBackgroundTrue = this.get("rtBackgroundTrue");
        this.set("rtBackgroundTrue", !rtBackgroundTrue);
        let signalCount = this.get("allCustomObjects").length;

        for (let io = 0; io < signalCount; io++) {
          let stThisChart = this.get("stChartObj" + io);
          stThisChart.config.data.datasets[0].fill = !rtBackgroundTrue;
        }
      },

      toggleRtLimitBackground() {
        let rtLimitBackgroundTrue = this.get("rtLimitBackgroundTrue");
        this.set("rtLimitBackgroundTrue", !rtLimitBackgroundTrue);
        let switchOneTo = false;
        let switchTwoTo = false;

        if (!rtLimitBackgroundTrue) {
          switchOneTo = "end";
          switchTwoTo = "start";
        }

        let signalCount = this.get("allCustomObjects").length;

        for (let io = 0; io < signalCount; io++) {
          let stThisChart = this.get("stChartObj" + io);
          stThisChart.config.data.datasets[1].fill = switchOneTo;
          stThisChart.config.data.datasets[2].fill = switchTwoTo;
        }
      },

      toggleRtLimitLine() {
        let rtLimitLineTrue = this.get("rtLimitLineTrue");
        this.set("rtLimitLineTrue", !rtLimitLineTrue);
        let switchTo = "rgba(255,95,0,0)";

        if (!rtLimitLineTrue) {
          switchTo = "rgba(255,95,0,1)";
        }

        let signalCount = this.get("allCustomObjects").length;

        for (let io = 0; io < signalCount; io++) {
          let stThisChart = this.get("stChartObj" + io);
          stThisChart.config.data.datasets[1].borderColor = switchTo;
          stThisChart.config.data.datasets[2].borderColor = switchTo;
        }
      },

      toggleRtXScale() {
        let rtXScaleTrue = this.get("rtXScaleTrue");
        this.set("rtXScaleTrue", !rtXScaleTrue);
        let signalCount = this.get("allCustomObjects").length - 1;
        let stThisChart = this.get("stChartObj" + signalCount);

        if (stThisChart) {
          stThisChart.options.scales.xAxes[0].display = !rtXScaleTrue;
        }
      },

      runToLeave() {
        this.set('recordPoints', 0);
        let signalMap = this.get("signalMap");
        signalMap.forEach(thisSignal => {
          let thisString = "stChartObj" + thisSignal;
          let thisChartObj = this.get(thisString);

          if (thisChartObj) {
            thisChartObj.destroy();
          }

          this.set(thisString, null);
        });
        this.stopRealTimeData();
        this.closeWebSockets();
        this.set("allCustomObjects", []);
        this.set('idsToSave', null);
        this.set("signalRun", false);
        this.set('isLoading', true);
        this.set("signalMap", []);
        this.set("ltChartObj", null);
        this.set("ltChartData", null);
        this.set("ltChartOptions", null);
        this.set("recordRun", false);
        let shortRealTimeCol = document.getElementById("shortSignalCol");

        if (shortRealTimeCol) {
          while (shortRealTimeCol.firstChild) {
            shortRealTimeCol.removeChild(shortRealTimeCol.firstChild);
          }
        }
      }

    }
  });

  _exports.default = _default;
});