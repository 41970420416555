define("machine-monitoring/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "2D": "2D",
    "3D": "3D",
    "3er": "Big Box",
    "6er": "Small Box",
    "D": "D",
    "ID": "ID",
    "R": "R",
    "S": "S",
    "T": "T",
    "X": "X",
    "X1": "X1",
    "X2": "X2",
    "XResolution": "X Resolution",
    "XY": "X/Y",
    "XY1": "first X/Y",
    "XY2": "second X/Y",
    "Y": "Y",
    "Y1": "Y1",
    "Y2": "Y2",
    "YResolution": "Y Resolution",
    "abort": "Abort",
    "absolute": "Absolute",
    "accept": "Accept",
    "accepted": "Accepted",
    "acceptedAnomalies": "Accepted Anomalies",
    "active": "Active",
    "alert": "Alert",
    "alertSaved": "Alert saved as a new Record",
    "alertType": "Alert Type",
    "alerting": "Alerting",
    "alerts": "Alerts",
    "algorithmConfig": "Algorithm Configuration",
    "all": "all",
    "allMachines": "All Machines",
    "allSignals": "all Signals",
    "amountOfClusters": "Amount of Clusters for Learning",
    "anomalieDetection": "Anomaly Detection",
    "anomalieImage": "Anomaly Image",
    "anomalies": "Anomalies",
    "anomalySignalTypes": "Anomaly Signal Types",
    "anomalyThreshold": "Anomaly Threshold",
    "anomalyValue": "anomaly Value",
    "at": "at",
    "attentionNeeded": "Attention Needed",
    "averageValue": "Average Value",
    "begin": "Begin",
    "binary": "binary",
    "buildYear": "Build",
    "calcDistance": "Calculate Distance",
    "captureConfiguration": "Capture Configuration",
    "category": "Category",
    "ceilFloor": "Ceil/Floor",
    "ceilingThreshold": "Ceiling Threshold",
    "choose": "Choose",
    "choose4oszi": "Choose for Oszilloscope",
    "chooseAlarmThresholds": "choose your desired alarm thresholds",
    "chooseNew": "Choose new",
    "city": "City",
    "clear": "Clear",
    "close": "Close",
    "cluster": "Cluster",
    "clusterCapacity": "Cluster Capacity",
    "comparison": "Comparison",
    "config": "Config",
    "configurateMachines": "Configurate Machines",
    "confirm": "Confirm",
    "control": "Control",
    "controls": "Controls",
    "countCachedValues": "Amount of cached values",
    "countCachedValuesHelp": "Sets amount of history values to be cached.",
    "counter": "counter",
    "country": "Country",
    "create": "Create",
    "createNewDiagram": "Create new Diagram",
    "createSensorTemplate": "create Sensor Template",
    "created": "Created",
    "critical": "Critical",
    "criticals": "Criticals",
    "customer": "Customer",
    "customerInfo": "Customer Info",
    "customers": "Customers",
    "dashboard": "Dashboard",
    "dataBackground": "Data Background",
    "dataOverview": "Data Overview",
    "datarate": {
      "fast": "fast Datarate",
      "name": "Datarate",
      "slow": "slow Datarate",
      "time": "Time Datarate",
      "xy": "XY Datarate"
    },
    "date": "Date",
    "day": "Day",
    "delete": "Delete",
    "deleteCustomer": "delete Customer",
    "deleteDevice": "Delete Device",
    "deletePair": "Delete Pair",
    "deleteRecord": "Delete Record",
    "deleteSensorTemplate": "delete Sensor Template",
    "deleteSignal": "Delete Signal",
    "desc": "Desc",
    "description": "Description",
    "descriptionPlease": "Please write a description",
    "details": "Details",
    "detect": "Detection",
    "detecting": "Detecting",
    "detectionConfig": "Detection Configuration",
    "device": "Device",
    "deviceAlerts": "Device Alerts",
    "deviceID": "Device ID",
    "deviceInfo": "Device Info",
    "deviceSerial": "Device Serial",
    "devices": "Devices",
    "diagram": "Diagram",
    "diagramOptions": "Diagram Options",
    "dismiss": "Dismiss",
    "dismissChanges": "Dismiss changes",
    "download": "Download",
    "dream": "Dream",
    "duplicate": "Duplicate",
    "duration": "Duration",
    "edit": "Edit",
    "editConfigForID": "Edit Config for Sensor with ID",
    "editRelatedSignals": "Edit Related Signals",
    "editSamplingRate": "Edit Sampling Rate",
    "editSensorWithID": "Edit Sensor with ID",
    "editThisDevice": "Edit this Device",
    "emergencies": "Emergencies",
    "emergency": "Emergency",
    "end": "End",
    "enterTitle": "Enter the desired title and confirm the query",
    "export": "Export",
    "factory": "Factory",
    "faultInput": "Fault Input",
    "filename": "Filename",
    "filtering": "Filtering",
    "finishEditSignaltype": "Finish edit Signaltype",
    "floorThreshold": "Floor Threshold",
    "height": "Height (y-axis resolution)",
    "hullCurve": "Hull Curve",
    "icon": "Icon",
    "import": "Import",
    "in": "in",
    "informations": "Informations",
    "infos": "Infos",
    "init": "Init",
    "is": "is",
    "knownAnomalies": "Known Anomalies",
    "knownFailures": "Known Failures",
    "lastAnomalyTime": "last Anomaly Time",
    "latitude": "Latitude",
    "learn": "Learning",
    "learned": "Learned",
    "learning": "Learning",
    "length": "Length",
    "limitBackground": "Limit Background",
    "limitLine": "Limit Line",
    "limits": "Limits",
    "load": "Load",
    "loadConfig": "load Config",
    "loadConfigSensorID": "Load Config for Sensor with ID",
    "loadMore": "load More",
    "loading": "Loading...",
    "location": "Location",
    "locations": "Locations",
    "logout": "Logout",
    "longitude": "Longitude",
    "machineOffline": "Machine is NOT online, no realtime data!",
    "machines": "Machines",
    "mainSignalValuesShown": "Main Signal Values shown",
    "manufacturer": "Manufacturer",
    "max": "Max",
    "maxAlertActive": "Max Alert Active",
    "maxAnomalyCluster": "Maximum Available Clusters",
    "maxDistance": "Max distance",
    "maxDistancesHelp": "Threshold of maximum distance from main value path to be measured (outliers).",
    "maxX": "Max X",
    "maxY": "Max Y",
    "maximumDeviationSum": "Maximum Deviation Sum",
    "maximumMeasuredValue": "Maximum measured Value",
    "measuringUnit": "Measuring Unit",
    "metadata": "Meta Data",
    "min": "Min",
    "minAlertActive": "Min Alert Active",
    "minAllowedClusterSize": "Min allowed size because clusters already used",
    "minMax": "Min/Max",
    "minX": "Min X",
    "minY": "Min Y",
    "minumumMeasuredValue": "Minumum measured Value",
    "model": "Model",
    "mostFaultyMachineType": "Most Faulty Machine Types",
    "ms": "ms",
    "name": "Name",
    "new": "New",
    "newCustomer": "new Customer",
    "newDevice": "new Device",
    "newDiagram": "New Diagram",
    "newName": "new Name",
    "newPair": "New pair",
    "newSamplingRate": "New Sampling Rate",
    "newSet": "New Set",
    "newSignalType": "new Signal Type",
    "newSignaltype": "New Signaltype",
    "newSize": "new Size",
    "next": "Next",
    "no": "No",
    "noAlertsHere": "No Alerts on this Machine",
    "noEqualChoosen": "Please select equal Numbers for X/Y Diagrams.",
    "noFiles": "No files available",
    "noPairs": "No pairs set up",
    "noProfiles": "No profiles available",
    "noRecordSelected": "no Record selected",
    "noRecordsAvailable": "NO Records available",
    "noSavedAvailable": "NO Saved available",
    "noSignaltypesAdded": "No Signaltypes added",
    "noValuesChoosen": "Please select at least one Value for Diagrams.",
    "notAllowed": "Not Allowed",
    "nr": "Nr",
    "numberMachines": "Number of Machines",
    "oClock": "o'clock",
    "of": "of",
    "off": "Off",
    "offline": "Offline",
    "ok": "Ok",
    "on": "On",
    "oneMonth": "1 Month",
    "online": "Online",
    "options": "Options",
    "oscilloscope": "Oscilloscope",
    "overview": "Overview",
    "pagination": "Pagination",
    "picOfAnomaliePattern": "picture of Anomaly Pattern",
    "picOfMachine": "Picture of this machine",
    "pleaseAddSignal": "Please add a Signal!",
    "pleaseTryAgain": "Please try again",
    "pleaseWaitLoading": "please wait, loading",
    "points": "Points",
    "poweredWE": "powered by Webware-Experts OHG",
    "previous": "Previous",
    "proactiveView": "Proactive View",
    "production": "Production",
    "productionMonitoring": "Production Monitoring",
    "profileLoaded": "profile loaded",
    "profileLoadedLongA": "The Profile named",
    "profileLoadedLongB": "has been loaded",
    "profileName": "Profile name",
    "reConfig": "Re-Config",
    "reInit": "Re-Init",
    "reallyDelete": "really delete",
    "reallyDeleteCustomer": "Do you really want to delete this Customer?",
    "reallyDeleteDevice": "Do you really want to delete this Device?",
    "reallyDeleteRecord": "Do you really want to delete this Record?",
    "reallyDeleteSensorTemplate": "Do you really want to delete this Sensor Template?",
    "reallyDeleteSignal": "Do you really want to delete this Signal?",
    "reallyDuplicate": "Really duplicate",
    "reallyDuplicateDevice": "Do you really want to duplicate this Device?",
    "reallyDuplicateSignal": "Do you really want to duplicate this Signal?",
    "reallyWantClearType": "Do you really want to clear Type",
    "realtime": "Realtime",
    "record": "Record",
    "recordName": "Record Name",
    "recordShown": "Record shown",
    "recordedAt": "recorded at",
    "records": "Records",
    "relatedSignals": "related Signals",
    "relative": "Relative",
    "reloadAlerts": "Reload Alerts",
    "reset": "Reset",
    "resetLearning": "Reset Learning",
    "resize": "resize",
    "resordedAlert": "Recorded Alert",
    "samplingRates": "Sampling Rates",
    "save": "Save",
    "saveConfigForID": "Save Config for Sensor with ID",
    "saveRecord": "save Record",
    "saveTemplate": "save Template",
    "saveThisRecord": "Do you want to save this record?",
    "saved": "saved",
    "savedProfiles": "Saved Profiles",
    "sec": "sec",
    "selectIcon": "Select Icon",
    "selected": "Selected",
    "sensor": "Sensor",
    "sensorScaling": "Sensor Scaling",
    "sensorTemplates": "Sensor Template",
    "sensordata": "Sensordata",
    "serial": "Serial",
    "serverTime": "Server Time",
    "setup": "Setup",
    "sevenDays": "7 Days",
    "show": "Show",
    "showImage": "Show Image",
    "signal": "Signal",
    "signalID": "Signal ID",
    "signalName": "Signalname",
    "signalTypes": "Signaltypes",
    "signals": "Signals",
    "signaltype": "Signaltype",
    "since": "since",
    "singleValueDeviation": "Single Value Deviation",
    "snapshotSchedule": "Snapshot Schedule",
    "spread": "Spread",
    "sryNoData": "Sorry, no Data available",
    "standardDeviation": "Standard deviation",
    "start": "Start",
    "startDetecting": "Start Detecting",
    "startLearning": "Start Learning",
    "startLine": "Start Line",
    "state": "State",
    "stop": "Stop",
    "stopDetecting": "Stop Detecting",
    "stopLearning": "Stop Learning",
    "stopLine": "End Line",
    "street": "Street",
    "switchLanguage": "switch Language",
    "systemMessage": "System Message",
    "template": "Template",
    "textReallyDeletePair": "Do you really want to delete this Pair?",
    "textReallyResetLearning": "Do you really want to reset Learning?",
    "thatsNotAllowed": "and that is not allowed!",
    "threeDays": "3 Days",
    "time": "Time",
    "timeAlertFired": "Time Alert fired",
    "title": "Title",
    "titlePlease": "Please type a title",
    "toggleNavigation": "Toggle navigation",
    "toggleSignals": "toggle Signals",
    "toggleView": "toggle View",
    "twelveHours": "12 Hours",
    "type": "Type",
    "typeID": "Type ID",
    "typeName": "Type Name",
    "typeSize": "Type Size",
    "unit": "Unit",
    "used4": "used for",
    "userManagement": "User Management",
    "username": "Baumann",
    "value": "Value",
    "values": "Values",
    "warning": "Warning",
    "warningChangingSensorScaling": "Warning: Changing \"Sensor Scaling\" parameters dismisses all learned clusters!",
    "warnings": "Warnings",
    "weMonitor": "weMonitor",
    "width": "Width (x-axis resolution)",
    "writeNewName": "Write the new Name",
    "xID": "X ID",
    "xScale": "X Scale",
    "xSensor": "X sensor",
    "xSensorHelp": "Sensor ID for x-axis",
    "xSensorMaxHelp": "Largest value of x-axis",
    "xSensorMinHelp": "Smallest value of x-axis",
    "xSensorRate": "X sensor sampling rate",
    "xSensorRateHelp": "Sampling rate x-axis sensor",
    "xyPairs": "XY-Pairs",
    "yID": "Y ID",
    "ySensor": "Y Sensor",
    "ySensorHelp": "Sensor ID for y-axis",
    "ySensorMaxHelp": "Largest value of y-axis",
    "ySensorMinHelp": "Smallest value of y-axis",
    "ySensorRate": "Y sensor sampling rate",
    "ySensorRateHelp": "Sampling rate y-axis sensor",
    "year": "Year",
    "yes": "Yes",
    "youChoose": "You choose",
    "zipCode": "ZIP"
  };
  _exports.default = _default;
});