define("machine-monitoring/helpers/timestamp-to-complete-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timestampToCompleteTime = timestampToCompleteTime;
  _exports.default = void 0;

  function timestampToCompleteTime(params) {
    let [arg1] = params;
    let originTimeStamp;

    if (arg1 > 2493283428168) {
      //1561540592340560750
      originTimeStamp = Math.round(arg1 / 1000000); // 1561540592340
    } else if (arg1 < 1000000000000) {
      // 1561535706
      originTimeStamp = arg1 * 1000; // 1561535706
    } else {
      // 1561535706
      originTimeStamp = arg1; // 1561535706
    }

    let thisTimeStamp = new Date(originTimeStamp);
    let day = thisTimeStamp.getDate();
    let month = thisTimeStamp.getMonth() + 1;
    let year = thisTimeStamp.getFullYear();

    if (day < 10) {
      day = '0' + day;
    }

    if (month < 10) {
      month = '0' + month;
    }

    let hours = thisTimeStamp.getHours();
    let minutes = thisTimeStamp.getMinutes();
    let seconds = thisTimeStamp.getSeconds();

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    return day + '.' + month + '.' + year + ' - ' + hours + ':' + minutes + ':' + seconds;
  }

  var _default = Ember.Helper.helper(timestampToCompleteTime);

  _exports.default = _default;
});