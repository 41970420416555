define("machine-monitoring/helpers/timestamp-to-readable-milliseconds-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timestampToReadableMillisecondsTime = timestampToReadableMillisecondsTime;
  _exports.default = void 0;

  function timestampToReadableMillisecondsTime(params) {
    let [arg1] = params;
    let originTimeStamp = Math.round(arg1 / 1000000); //1493283428168

    let thisTimeStamp = new Date(originTimeStamp);
    let hours = thisTimeStamp.getHours();
    let minutes = thisTimeStamp.getMinutes();
    let seconds = thisTimeStamp.getSeconds();
    let milliseconds = thisTimeStamp.getMilliseconds();

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    return hours + ':' + minutes + ':' + seconds + ',' + milliseconds;
  }

  var _default = Ember.Helper.helper(timestampToReadableMillisecondsTime);

  _exports.default = _default;
});