define("machine-monitoring/models/location", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    name: _emberData.default.attr('string'),
    street: _emberData.default.attr('string'),
    number: _emberData.default.attr('number'),
    zipCode: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('number'),
    longitude: _emberData.default.attr('number'),
    customer: _emberData.default.attr('string'),
    devices: _emberDataModelFragments.default.array(),
    selected: false
  });

  _exports.default = _default;
});