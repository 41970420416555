define("machine-monitoring/devices/details/signal/controller", ["exports", "machine-monitoring/app"], function (_exports, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    detailsController: Ember.inject.controller('devices.details'),
    selectedT: 0,
    selectedX: 0,
    selectedY: 0,
    selectedRecord: null,
    loadMode: 0,
    routeActiveOsciOverview: false,
    routeActiveOsciRecord: false,
    routeActiveOsciOsci: false,
    sensorToDelete: null,
    recordToDelete: null,

    resizeLists() {
      let recordsDivOffset = 85;
      let recordsPanel = $("#recordsPanel");

      if (recordsPanel.offset()) {
        recordsDivOffset = recordsPanel.offset().top;
      }

      ;
      let recordsNewDivHeight = window.innerHeight - recordsDivOffset - 20;
      $("#recordsScrollableList").css({
        maxHeight: recordsNewDivHeight
      });
      let sensorsDivOffset = 85;
      let sensorsPanel = $("#sensorsPanel");

      if (sensorsPanel.offset()) {
        sensorsDivOffset = sensorsPanel.offset().top;
      }

      ;
      let sensorsNewDivHeight = window.innerHeight - sensorsDivOffset - 20;
      $("#signalTypesScrollableList").css({
        maxHeight: sensorsNewDivHeight
      });
    },

    drawBreadcrumbs() {
      let thirdNaviRoute = this.get("detailsController").get("thirdNaviRoute");
      let that = this;
      this.set("routeActiveOsciOverview", false);
      this.set("routeActiveOsciRecord", false);
      this.set("routeActiveOsciOsci", false);

      switch (thirdNaviRoute) {
        case "overview":
          {
            that.set("routeActiveOsciOverview", true);
            break;
          }

        case "record":
          {
            that.set("routeActiveOsciRecord", true);
            break;
          }

        case "osci":
          {
            that.set("routeActiveOsciOsci", true);
            break;
          }

        default:
          {
            break;
          }
      }
    },

    checkFault() {
      let selectedFault = 9;
      let allSignalTypes = this.get('model.signalTypes');
      let hasX1 = false;
      let hasX2 = false;
      let hasY1 = false;
      let hasY2 = false;
      let tCounter = 0;
      allSignalTypes.forEach(thatSignal => {
        if (thatSignal.selectedX1) {
          hasX1 = true;
        }

        ;

        if (thatSignal.selectedX2) {
          hasX2 = true;
        }

        ;

        if (thatSignal.selectedY1) {
          hasY1 = true;
        }

        ;

        if (thatSignal.selectedY2) {
          hasY2 = true;
        }

        ;

        if (thatSignal.selectedT) {
          tCounter++;
        }

        ;
      });

      if (hasX1 == false && hasY1 == false && hasX2 == false && hasY2 == false) {
        if (tCounter > 0) {
          this.set('loadMode', 1);
          selectedFault = 0;
        } else {
          selectedFault = 2;
        }
      } else if (hasX1 == true && hasY1 == true && hasX2 == false && hasY2 == false) {
        if (tCounter > 0) {
          this.set('loadMode', 3);
          selectedFault = 0;
        } else {
          this.set('loadMode', 2);
          selectedFault = 0;
        }
      } else if (hasX1 == false && hasY1 == false && hasX2 == true && hasY2 == true) {
        if (tCounter > 0) {
          this.set('loadMode', 3);
          selectedFault = 0;
        } else {
          this.set('loadMode', 2);
          selectedFault = 0;
        }
      } else if (hasX1 == true && hasY1 == true && hasX2 == true && hasY2 == true) {
        if (tCounter > 0) {
          this.set('loadMode', 5);
          selectedFault = 0;
        } else {
          this.set('loadMode', 4);
          selectedFault = 0;
        }
      } else {
        selectedFault = 1;
      }

      return selectedFault;
    },

    haveFault(selectedFault) {
      if (selectedFault == 1) {
        $("#modalNoEqualChoosen").modal("show");
      } else if (selectedFault == 2) {
        $("#modalValuesChoosen").modal("show");
      } else if (selectedFault == 9) {
        console.log('somthing went wrong');
      }
    },

    actions: {
      drawBreadcrumbs() {
        this.drawBreadcrumbs();
      },

      resetBreadcrumb() {
        this.drawBreadcrumbs();
      },

      firstRunActions() {
        Ember.run.later(() => {
          this.resizeLists();
        }, 1000);
      },

      osciOverviewModeAction() {
        this.transitionToRoute("devices.details.signal");
      },

      osciRecordModeAction() {
        this.transitionToRoute("devices.details.signal.record");
      },

      resizeWindow() {
        this.resizeLists();
      },

      chooseRecord(thisRecord) {
        this.set('selectedRecord', thisRecord);
        this.transitionToRoute('devices.details.signal.record');
      },

      chooseRecordSensors(recordSensors) {
        let allSignalTypes = this.get('model.signalTypes');
        allSignalTypes.forEach(thatSignal => {
          thatSignal.selectedX1 = false;
          thatSignal.selectedY1 = false;
          thatSignal.selectedX2 = false;
          thatSignal.selectedY2 = false;
          thatSignal.selectedT = false;
        });
        allSignalTypes.forEach(thatSignal => {
          if (recordSensors.x1 == thatSignal.signalTypeId) {
            thatSignal.selectedX1 = true;
          }

          if (recordSensors.y1 == thatSignal.signalTypeId) {
            thatSignal.selectedY1 = true;
          }

          if (recordSensors.x2 == thatSignal.signalTypeId) {
            thatSignal.selectedX2 = true;
          }

          if (recordSensors.y2 == thatSignal.signalTypeId) {
            thatSignal.selectedY2 = true;
          }

          recordSensors.time.forEach(function (thatTime) {
            if (thatTime == thatSignal.signalTypeId) {
              thatSignal.selectedT = true;
            }
          });
        });
        let selectedFault = this.checkFault();

        if (selectedFault == 0) {
          this.transitionToRoute("devices.details.signal.realtime");
        } else {
          this.haveFault(selectedFault);
        }
      },

      createSensorTemplate() {
        let selectedFault = this.checkFault();

        if (selectedFault == 0) {
          $("#modalCreateSensorTemplate").modal("show");
        } else {
          this.haveFault(selectedFault);
        }
      },

      dontSaveSensorTemplate() {
        this.set('newSensorTemplateName', "");
        this.set('newSensorTemplateDescription', "");
      },

      saveSensorTemplate() {
        let that = this;
        let allSignalTypes = this.get('model.signalTypes');
        let {
          x1,
          y1,
          x2,
          y2
        } = 0;
        let timed = [];
        allSignalTypes.forEach(thatSignal => {
          if (thatSignal.selectedX1) {
            x1 = thatSignal.signalTypeId;
          }

          ;

          if (thatSignal.selectedX2) {
            x2 = thatSignal.signalTypeId;
          }

          ;

          if (thatSignal.selectedY1) {
            y1 = thatSignal.signalTypeId;
          }

          ;

          if (thatSignal.selectedY2) {
            y2 = thatSignal.signalTypeId;
          }

          ;

          if (thatSignal.selectedT) {
            timed.push(thatSignal.signalTypeId);
          }

          ;
        });
        let jsonToSend = {
          name: this.get('newSensorTemplateName'),
          description: this.get('newSensorTemplateDescription'),
          time: timed
        };

        if (x1 > 0) {
          jsonToSend.x1 = parseInt(x1);
        }

        if (y1 > 0) {
          jsonToSend.y1 = parseInt(y1);
        }

        if (x2 > 0) {
          jsonToSend.x2 = parseInt(x2);
        }

        if (y2 > 0) {
          jsonToSend.y2 = parseInt(y2);
        }

        $.post(_app.default.API_URL + "/configuration/application/record-view-templates/" + that.get('model.device.id'), JSON.stringify(jsonToSend), () => {
          that.set('newSensorTemplateName', "");
          that.set('newSensorTemplateDescription', "");
          $.get(_app.default.API_URL + "/configuration/application/record-view-templates/" + that.get('model.device.id'), responseData => {
            that.set('recordSensorTemplate', responseData);
            that.resizeLists();
            Ember.run.later(() => {
              that.resizeLists();
            }, 1000);
          });
        });
      },

      deleteSensorTemplate(recordSensors) {
        this.set('sensorToDelete', recordSensors);
        $("#modalDeleteSensorTemplate").modal("show");
      },

      dontDeleteSensorTemplate() {
        this.set('sensorToDelete', null);
      },

      reallyDeleteSensorTemplate() {
        let that = this;
        let recordSensors = this.get('sensorToDelete');
        let deviceId = this.get('model.device.id');
        $.ajax({
          url: _app.default.API_URL + "/configuration/application/record-view-templates/" + deviceId + "/" + recordSensors.id,
          method: "DELETE"
        }).then(function () {
          that.set('sensorToDelete', null);
          $.get(_app.default.API_URL + "/configuration/application/record-view-templates/" + deviceId, responseData => {
            that.set('recordSensorTemplate', responseData);
            that.resizeLists();
          });
        });
      },

      deleteThisRecord(thisRecord) {
        this.set('recordToDelete', thisRecord);
        $("#modalDeleteRecord").modal("show");
      },

      dontDeleteRecord() {
        this.set('recordToDelete', null);
      },

      reallyDeleteRecord() {
        let thisRecord = this.get('recordToDelete');
        let that = this;
        let deviceId = this.get('model.device.id');
        let allRecords = this.get('model.records');
        let firstRecordToDelete = 0;
        let secondRecordToDelete = 0;
        let singeledRecords = [];
        let tmpSessionList = [];
        allRecords.forEach(thatRecord => {
          thatRecord.set("selected", false);

          if (thisRecord.session == thatRecord.session) {
            if (firstRecordToDelete == 0) {
              firstRecordToDelete = thatRecord.id;
            } else if (secondRecordToDelete == 0) {
              secondRecordToDelete = thatRecord.id;
            }
          } else if (!tmpSessionList.includes(thatRecord.session)) {
            singeledRecords.pushObject(thatRecord);
            tmpSessionList.push(thatRecord.session);
          }
        });

        if (firstRecordToDelete > 0) {
          $.ajax({
            url: _app.default.API_URL + "/recordinfos/" + deviceId + "/" + firstRecordToDelete,
            method: "DELETE"
          }).then(function () {
            if (secondRecordToDelete > 0) {
              $.ajax({
                url: _app.default.API_URL + "/recordinfos/" + deviceId + "/" + secondRecordToDelete,
                method: "DELETE"
              }).then(function () {
                that.set('recordToDelete', null);
                that.set('singeledRecords', singeledRecords);
                that.send("refreshModel");
                that.resizeLists();
              });
            } else {
              that.set('recordToDelete', null);
              that.set('singeledRecords', singeledRecords);
              that.send("refreshModel");
              that.resizeLists();
            }
          });
        }
      },

      selectThisSignalType(diagType, signalType) {
        let selectedT = this.get('selectedT');
        let killAllTime = false;

        if (diagType == 't') {
          if (selectedT < 4) {
            signalType.set('selectedT', !signalType.selectedT);
          } else if (selectedT == 4) {
            if (signalType.selectedT == true) {
              signalType.set('selectedT', !signalType.selectedT);
            } else {
              killAllTime = true;
            }
          } else {
            killAllTime = true;
          }
        }

        let allSignalTypes = this.get('model.signalTypes');
        let hasX1 = false;
        let hasX2 = false;
        let hasY1 = false;
        let hasY2 = false;
        let tCounter = 0;
        allSignalTypes.forEach(thatSignal => {
          if (thatSignal.selectedX1) {
            hasX1 = thatSignal;
          }

          ;

          if (thatSignal.selectedX2) {
            hasX2 = thatSignal;
          }

          ;

          if (thatSignal.selectedY1) {
            hasY1 = thatSignal;
          }

          ;

          if (thatSignal.selectedY2) {
            hasY2 = thatSignal;
          }

          ;

          if (killAllTime) {
            thatSignal.set('selectedT', false);
          }

          if (thatSignal.selectedT) {
            tCounter++;
          }

          ;
        });

        if (killAllTime) {
          signalType.set('selectedT', true);
        }

        this.set('selectedT', tCounter);

        if (diagType == 'x') {
          let xCounter = this.selectedX;

          if (hasX1 == false && hasX2 == false) {
            signalType.set('selectedX1', true);
            xCounter = 1;
          } else if (hasX1 && hasX2 == false) {
            signalType.set('selectedX2', true);
            xCounter = 2;

            if (hasX1 == signalType) {
              signalType.set('selectedX1', false);
              xCounter = 1;
            }
          } else if (hasX1 == false && hasX2) {
            if (hasX2 == signalType) {
              signalType.set('selectedX2', false);
              xCounter = 0;
            } else {
              signalType.set('selectedX1', true);
              xCounter = 2;
            }
          } else if (hasX1 && hasX2) {
            if (hasX1 == signalType) {
              signalType.set('selectedX1', false);
              xCounter = 1;
            } else if (hasX2 == signalType) {
              signalType.set('selectedX2', false);
              xCounter = 1;
            } else {
              hasX1.set('selectedX1', false);
              hasX2.set('selectedX2', false);
              signalType.set('selectedX1', true);
              xCounter = 1;
            }
          }

          this.set('selectedX', xCounter);
        } else if (diagType == 'y') {
          let yCounter = this.selectedY;

          if (hasY1 == false && hasY2 == false) {
            signalType.set('selectedY1', true);
            yCounter = 1;
          } else if (hasY1 && hasY2 == false) {
            signalType.set('selectedY2', true);
            yCounter = 2;

            if (hasY1 == signalType) {
              signalType.set('selectedY1', false);
              yCounter = 1;
            }
          } else if (hasY1 == false && hasY2) {
            if (hasY2 == signalType) {
              signalType.set('selectedY2', false);
              yCounter = 0;
            } else {
              signalType.set('selectedY1', true);
              yCounter = 2;
            }
          } else if (hasY1 && hasY2) {
            if (hasY1 == signalType) {
              signalType.set('selectedY1', false);
              yCounter = 1;
            } else if (hasY2 == signalType) {
              signalType.set('selectedY2', false);
              yCounter = 1;
            } else {
              hasY1.set('selectedY1', false);
              hasY2.set('selectedY2', false);
              signalType.set('selectedY1', true);
              yCounter = 1;
            }
          }

          this.set('selectedY', yCounter);
        }
      },

      loadMore() {
        console.log('loadMore');
      },

      loadRealtime() {
        let selectedFault = this.checkFault();

        if (selectedFault == 0) {
          this.transitionToRoute("devices.details.signal.realtime");
        } else {
          this.haveFault(selectedFault);
        }
      }

    }
  });

  _exports.default = _default;
});