define("machine-monitoring/models/device", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    deviceId: _emberData.default.attr(),
    deviceType: _emberData.default.attr('number'),
    deviceTypeName: _emberData.default.attr('string'),
    deviceModel: _emberData.default.attr('string'),
    deviceManufacturer: _emberData.default.attr('string'),
    deviceSerial: _emberData.default.attr(),
    deviceYear: _emberData.default.attr('string'),
    picturePath: _emberData.default.attr('string'),
    health: _emberData.default.attr(),
    online: _emberData.default.attr('boolean'),
    oid: _emberData.default.attr('number'),
    signalTypes: _emberDataModelFragments.default.fragmentArray('signaltype'),
    records: _emberDataModelFragments.default.fragmentArray('record'),
    customer: _emberData.default.attr('number'),
    location: _emberData.default.attr('number'),
    selected: false
  });

  _exports.default = _default;
});