define("machine-monitoring/devices/details/anomalie/controller", ["exports", "machine-monitoring/app", "jquery"], function (_exports, _app, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    detailsController: Ember.inject.controller('devices.details'),
    routeActiveAnomalieCreate: false,
    routeActiveAnomalieTwoD: false,
    routeActiveAnomalieThreeD: false,
    hasPairs: false,
    hasProfiles: false,
    files: null,
    xyPairs: null,
    savedProfiles: null,
    xyPairToUse: null,

    getSavedDatas() {
      let deviceId = _app.default.chosenDeviceId;

      _jquery.default.ajax({
        url: _app.default.API_URL + "/pf/diagrams/" + deviceId
      }).done(pairs => {
        if (pairs.length > 0) {
          pairs.forEach(thatPair => {
            let state = thatPair.state;

            if (state == "initialized") {
              thatPair.canLearn = true;
              thatPair.canDetect = true;
            } else if (state == "learning") {
              thatPair.canLearn = false;
              thatPair.canDetect = true;
            } else if (state == "detecting") {
              thatPair.canLearn = true;
              thatPair.canDetect = false;
            }
          });
          this.set('hasPairs', true);
        } else {
          this.set('hasPairs', false);
        }

        this.set('xyPairs', pairs);
      });

      _jquery.default.ajax({
        url: _app.default.API_URL + "/pf/profiles/" + deviceId
      }).done(profiles => {
        if (profiles.length > 0) {
          this.set('profiles', profiles);
          this.set('hasProfiles', true);
        } else {
          this.set('files', null);
          this.set('hasProfiles', false);
        }
      });
    },

    drawBreadcrumbs() {
      let thirdNaviRoute = this.get("detailsController").get("thirdNaviRoute");
      let that = this;
      this.set("routeActiveAnomalieOverview", false);
      this.set("routeActiveAnomalieCreate", false);
      this.set("routeActiveAnomalieTwoD", false);
      this.set("routeActiveAnomalieThreeD", false);

      switch (thirdNaviRoute) {
        case "overview":
          {
            that.set("routeActiveAnomalieOverview", true);
            break;
          }

        case "create":
          {
            that.set("routeActiveAnomalieCreate", true);
            break;
          }

        case "twod":
          {
            that.set("routeActiveAnomalieTwoD", true);
            break;
          }

        case "threed":
          {
            that.set("routeActiveAnomalieThreeD", true);
            break;
          }

        default:
          {
            that.set("routeActiveAnomalieOverview", true);
            break;
          }
      }
    },

    actions: {
      firstRunActions() {
        this.drawBreadcrumbs();
        this.getSavedDatas();
      },

      actLearning(pair) {
        let doNow = pair.canLearn;
        let url = _app.default.API_URL + "/pf/diagram/" + pair.deviceID + "/" + pair.xSensorID + "/" + pair.ySensorID;

        if (doNow) {
          url = url + "/start-learning";
        } else {
          url = url + "/stop-learning";
        }

        _jquery.default.ajax({
          method: "POST",
          url: url
        }).done(() => {
          Ember.set(pair, 'canLearn', !doNow);
          this.getSavedDatas();
        });
      },

      actDetect(pair) {
        let doNow = pair.canDetect;
        let url = _app.default.API_URL + "/pf/diagram/" + pair.deviceID + "/" + pair.xSensorID + "/" + pair.ySensorID;

        if (doNow) {
          url = url + "/start-detection";
        } else {
          url = url + "/stop-detection";
        }

        _jquery.default.ajax({
          method: "POST",
          url: url
        }).done(() => {
          Ember.set(pair, 'canDetect', !doNow);
          this.getSavedDatas();
        });
      },

      loadThisProfile(profile) {
        let toSend = JSON.stringify({
          id: profile.id
        });
        let url = _app.default.API_URL + "/pf/diagram/" + profile.deviceID + "/" + profile.xSensorID + "/" + profile.ySensorID + "/load-profile";

        _jquery.default.ajax({
          method: "POST",
          url: url,
          contentType: "application/json; charset=utf-8",
          data: toSend
        }).done(() => {
          this.set('loadedProfile', profile);
          (0, _jquery.default)("#modalProfileLoaded").modal('show');
          Ember.run.later(() => {
            (0, _jquery.default)("#modalProfileLoaded").modal('hide');
          }, 3000);
        });
      },

      showDetails(pair) {
        let url = _app.default.API_URL + "/pf/diagram/" + pair.deviceID + "/" + pair.xSensorID + "/" + pair.ySensorID + "/opts";

        _jquery.default.ajax({
          url: url
        }).done(data => {
          let xyPairToUse = {
            opts: data,
            deviceId: pair.deviceID,
            xSensorID: pair.xSensorID,
            ySensorID: pair.ySensorID,
            state: pair.state,
            saved: false,
            name: null
          };
          this.set('xyPairToUse', xyPairToUse);
          this.transitionToRoute("devices.details.anomalie.details", pair.deviceID);
        }).fail(function (jqXHR, textStatus, errorThrown) {
          console.log('post failed jqXHR: ', jqXHR);
          console.log('post failed textStatus: ', textStatus);
          console.log('post failed errorThrown: ', errorThrown);
        });
      },

      anomalieOverviewAction() {
        this.get("detailsController").set("thirdNaviRoute", "overview");
        this.drawBreadcrumbs();
        this.transitionToRoute("devices.details.anomalie");
      },

      anomalieCreateModeAction() {
        this.get("detailsController").set("thirdNaviRoute", "create");
        this.drawBreadcrumbs();
        this.transitionToRoute("devices.details.anomalie.create");
      },

      anomalie2DModeAction() {
        this.get("detailsController").set("thirdNaviRoute", "twod");
        this.drawBreadcrumbs();
        this.transitionToRoute("devices.details.anomalie.twod");
      },

      anomalie3DModeAction() {
        this.get("detailsController").set("thirdNaviRoute", "threed");
        this.drawBreadcrumbs();
        this.transitionToRoute("devices.details.anomalie.threed");
      }

    }
  });

  _exports.default = _default;
});