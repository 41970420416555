define("machine-monitoring/models/sensor", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    is_initialized: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    is_learning: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    is_detecting: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    //init
    resolution_x: _emberData.default.attr({
      defaultValue: 1024
    }),
    resolution_y: _emberData.default.attr({
      defaultValue: 64
    }),
    max_cluster: _emberData.default.attr({
      defaultValue: 32
    }),
    //algorithm_config
    alg_scale: _emberData.default.attr({
      defaultValue: 32
    }),
    alg_max_distance: _emberData.default.attr({
      defaultValue: 3
    }),
    alg_max_distance_sum: _emberData.default.attr({
      defaultValue: 512
    }),
    alg_cluster_capacity: _emberData.default.attr({
      defaultValue: 512
    }),
    alg_use_min_max: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    alg_use_filtering: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    alg_use_hull_curve: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    //detection_config
    det_scale: _emberData.default.attr({
      defaultValue: 32
    }),
    det_max_distance: _emberData.default.attr({
      defaultValue: 3
    }),
    det_max_distance_sum: _emberData.default.attr({
      defaultValue: 512
    }),
    det_cluster_capacity: _emberData.default.attr({
      defaultValue: 512
    }),
    det_use_min_max: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    det_use_filtering: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    det_use_hull_curve: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    //anomaly_capture_config
    anomaly_threshold: _emberData.default.attr({
      defaultValue: 32
    }),
    max_anomaly_clusters: _emberData.default.attr({
      defaultValue: 20
    }),
    max_accepted_clusters: _emberData.default.attr({
      defaultValue: 20
    }),
    max_known_anomaly_clusters: _emberData.default.attr({
      defaultValue: 20
    }),
    max_capture_count: _emberData.default.attr(),
    // */
    //doNext: 0-nothing, 1-init, 2-startDetect, 3-startLearning, 4-config, 5-load, 6-save, 7-dream
    doNext: 0,
    sensorState: Ember.computed('is_initialized', 'is_learning', 'is_detecting', function () {
      let state = 'idle';

      if (this.get('is_detecting')) {
        state = 'Detecting';
      } else if (this.get('is_learning')) {
        state = 'Learning';
      } else if (!this.get('is_initialized')) {
        state = 'Uninitialized';
      }

      return state;
    })
  });

  _exports.default = _default;
});