define("machine-monitoring/components/machines-proactive/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8KiYFf2S",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",\"m-y\"],[12,\"id\",[29,[\"machine-card-b-\",[24,[\"data\",\"deviceID\"]]]]],[12,\"role\",\"button\"],[12,\"data-toggle\",\"tooltip\"],[12,\"data-html\",\"true\"],[12,\"title\",[29,[\"\\n  \",[24,[\"data\",\"DeviceInfo\",\"deviceManufacturer\"]],\"<br>\\n  \",[24,[\"data\",\"DeviceInfo\",\"deviceModel\"]],\"<br>\\n  \",[24,[\"data\",\"DeviceInfo\",\"deviceTypeName\"]],\"<br>\\n  \",[24,[\"data\",\"DeviceInfo\",\"deviceSerial\"]],\"<br>\\n  \",[24,[\"data\",\"DeviceInfo\",\"deviceYear\"]]]]],[3,\"action\",[[23,0,[]],\"showThisDevice\"]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card mb-0\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card-body\"],[8],[0,\"\\n      \"],[7,\"h5\",true],[8],[0,\"\\n        \"],[1,[24,[\"data\",\"DeviceInfo\",\"deviceModel\"]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"data\",\"DeviceInfo\",\"online\"]]],null,{\"statements\":[[0,\"          \"],[7,\"small\",true],[10,\"class\",\"smallOnline\"],[8],[1,[28,\"t\",[\"online\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"small\",true],[10,\"class\",\"smallOffline\"],[8],[1,[28,\"t\",[\"offline\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"canvas\",true],[10,\"width\",\"400\"],[10,\"height\",\"240\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/components/machines-proactive/template.hbs"
    }
  });

  _exports.default = _default;
});