define("machine-monitoring/configuration/customers/customer/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      let actLocations = [];

      if (model.get('location')) {
        let allLocations = this.get("store").findAll("location");
        model.get('location').forEach(thatLocation => {
          allLocations.forEach(thisLocation => {
            if (thatLocation == thisLocation.get('id')) {
              actLocations.pushObject(thisLocation);
            }
          });
        });
      }

      controller.set("actLocations", actLocations);
      let actDevices = [];

      if (model.get('devices')) {
        this.get("store").findAll("device").then(function (allDevices) {
          model.get('devices').forEach(thatDevice => {
            allDevices.forEach(thisDevice => {
              if (thatDevice == thisDevice.get('id')) {
                actDevices.pushObject(thisDevice);
              }
            });
          });
        });
      }

      controller.set("actDevices", actDevices);
    },

    renderTemplate(controller, model) {
      this.render("configuration/customers/customer", {
        into: "configuration/customers",
        outlet: "customerschild",
        controller: controller,
        model: model
      });
    }

  });

  _exports.default = _default;
});