define("machine-monitoring/controllers/application", ["exports", "jquery", "machine-monitoring/config/environment", "machine-monitoring/app", "ember-intl/hydrate"], function (_exports, _jquery, _environment, _app, _hydrate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    modulePrefix
  } = _environment.default;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    activeLocale: Ember.computed.readOnly('intl.locale'),
    dashboardActive: false,

    init() {
      this._super(...arguments);

      _jquery.default.ajaxSetup({
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true
      });

      (0, _jquery.default)(document).ajaxError(function (jqxhr) {
        if (jqxhr.readyState == 4 && jqxhr.status == 401) {
          window.location.replace(_app.default.API_HOST + "/user-service/");
        }
      });
      let moXHR = _app.default.moXHR;

      if (moXHR == false) {
        moXHR = new XMLHttpRequest();
      }

      moXHR.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 401) {
          window.location.replace(_app.default.API_HOST + "/user-service/");
        }
      };

      moXHR.open("GET", _app.default.API_URL + "/", true);
      moXHR.withCredentials = true;
      moXHR.send();
      _app.default.moXHR = moXHR;
      this.checkAlerts();
    },

    locales: Ember.computed(function () {
      return (0, _hydrate.lookupByFactoryType)('translations', modulePrefix).map(moduleName => moduleName.split('/').pop());
    }).readOnly(),
    selections: Ember.computed('locales.[]', 'activeLocale', function () {
      let active = Ember.get(this, 'activeLocale');
      return Ember.get(this, 'locales').map(locale => {
        return {
          locale: locale,
          active: active.indexOf(locale) > -1
        };
      });
    }).readOnly(),

    checkAlerts() {
      let that = this;

      _jquery.default.get(_app.default.API_URL + "/analytics").then(data => {
        if (data.attentionNeeded.length == 0 && data.faultyMachineTypes.length == 0 && data.proactiveHourly.length == 0) {
          that.set('dashboardActive', false);
        } else {
          that.set('dashboardActive', true);
        }
      });
    },

    actions: {
      switchLanguage(toThis) {
        return Ember.get(this, 'intl').set('locale', toThis);
      },

      goToConfiguration() {
        this.transitionToRoute("configuration");
      },

      goToUserManagement() {
        window.location.replace(_app.default.API_HOST + "/user-service/?management");
      },

      logout() {
        _jquery.default.post(_app.default.API_HOST + "/user-service/api/logout", () => {
          window.location.replace(_app.default.API_HOST + "/user-service/");
        });
      }

    }
  });

  _exports.default = _default;
});