define("machine-monitoring/components/xy-diag/component", ["exports", "xydiagram.js", "quad2D.js", "machine-monitoring/config/environment"], function (_exports, _xydiagram, _quad2D, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['colClass'],
    colClass: 'col-12',
    isOff: true,
    idX: 0,
    idY: 0,
    xyData: null,
    oldXYData: null,
    prevXYData: null,
    oldPrevXYData: null,
    loopData: [],
    loopCounter: 0,
    gl: null,
    updateFloat32Array: [],
    simulationTime: 0.0,
    xyDiagram: null,
    dieCutter: null,
    quad: null,
    deletaT: null,
    glowTimeInSecond: 0.6 * 0.5,
    valuesPerMilliSecond: 3,
    // from parent
    cPointsPer1_60Seconds: 50,
    // 10,
    c1_60Seconds: 400,
    //60 * 3,  // from parent
    minXValue: -2.0,
    // from parent
    maxXValue: 13.0,
    // from parent
    minYValue: -1.0,
    // from parent
    maxYValue: 3.0,
    // from parent
    tx: 0.0,
    sx: 1.0,
    getFromMousePos: false,
    deltaY: 0,
    prevRecordRun: false,
    lineColor: {},
    clearColor: {},
    oldWindowResizedTrigger: false,
    dataLength: 0,
    tryRun: true,

    didInsertElement() {
      this._super(...arguments);

      if (_environment.default.featureFlags.THEME_LIGHT) {
        this.lineColor.r = 0.3;
        this.lineColor.g = 0.3;
        this.lineColor.b = 0.3;
        this.clearColor.r = 0.95;
        this.clearColor.g = 0.95;
        this.clearColor.b = 0.95;
      } else {
        this.lineColor.r = 1.0;
        this.lineColor.g = 1.0;
        this.lineColor.b = 1.0;
        this.clearColor.r = 0.2;
        this.clearColor.g = 0.2;
        this.clearColor.b = 0.2;
      }

      this.set('tryRun', false);
      let colWidth = this.colWidth;
      let mainWrapperWidth = document.getElementById('mainWrapper').offsetWidth;
      let canvasWidth = mainWrapperWidth - 40;

      if (colWidth == 12) {
        this.set('colClass', 'col-12');
      } else if (colWidth == 6) {
        this.set('colClass', 'col-6');
        canvasWidth = mainWrapperWidth / 2 - 40;
      } else if (colWidth == 4) {
        this.set('colClass', 'col-4');
        canvasWidth = mainWrapperWidth / 3 - 40;
      } else if (colWidth == 100) {
        this.set('colClass', 'w-100');
        canvasWidth = mainWrapperWidth / 3 - 40;
      }

      let canvas = this.get('element').getElementsByTagName("CANVAS")[0];
      let xyCanvasBody = this.get('element');
      xyCanvasBody.width = canvasWidth;
      let canvasHeight = window.innerHeight - 383;

      if (colWidth == 100) {
        canvasHeight = canvasHeight / 2;
      }

      xyCanvasBody.height = canvasHeight;
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      let gl = canvas.getContext("webgl2", {
        antialias: true
      });
      let isWebGL2 = !!gl;

      if (!isWebGL2) {
        xyCanvasBody.innerHTML = 'WebGL 2 is not available.  See <a href="https://www.khronos.org/webgl/wiki/Getting_a_WebGL_Implementation">How to get a WebGL 2 implementation</a>';
        return;
      }

      const ext = gl.getExtension("EXT_color_buffer_float");

      if (!ext) {
        alert("need EXT_color_buffer_float");
        return;
      }

      this.set('gl', gl);
      this.set('canvas', canvas);
      this.didUpdateAttrs();
    },

    resizeWindow() {
      let colWidth = this.colWidth;
      let mainWrapperWidth = document.getElementById('mainWrapper').offsetWidth;
      let canvasWidth = mainWrapperWidth - 40;

      if (colWidth == 6) {
        canvasWidth = mainWrapperWidth / 2 - 40;
      } else if (colWidth == 4) {
        canvasWidth = mainWrapperWidth / 3 - 40;
      } else if (colWidth == 100) {
        canvasWidth = mainWrapperWidth / 3 - 40;
      }

      let canvas = this.get('element').getElementsByTagName("CANVAS")[0];
      let canvasHeight = window.innerHeight - 383;

      if (colWidth == 100) {
        canvasHeight = canvasHeight / 2;
      }

      canvas.style.width = canvasWidth + "px";
      canvas.style.height = canvasHeight + "px";
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
    },

    adjustDataLength() {
      let oldDataLength = this.get('dataLength');
      let newDataLength = this.get('metaData')[0].length;

      if (newDataLength > 0 && newDataLength != oldDataLength) {
        this.set('dataLength', newDataLength);

        if (newDataLength < 4096) {
          this.set('cPointsPer1_60Seconds', 1);
          this.set('c1_60Seconds', newDataLength);
        } else {
          let tmpBox = newDataLength / 4096;
          let newcPoint = Math.ceil(tmpBox);
          let newLines = newDataLength / newcPoint;
          this.set('cPointsPer1_60Seconds', newcPoint);
          this.set('c1_60Seconds', newLines);
        }

        return true;
      } else if (this.get('metaData')[0].liveView) {
        this.set('cPointsPer1_60Seconds', 1);
        this.set('c1_60Seconds', 4096);
        return true;
      } else {
        return false;
      }
    },

    didUpdateAttrs() {
      this._super(...arguments);

      if (this.isOff) {
        this.generateDiagram();
      } else if (this.get('windowResizedTrigger') != this.get('oldWindowResizedTrigger')) {
        this.set('oldWindowResizedTrigger', this.get('windowResizedTrigger'));
        this.resizeWindow();
      } else {
        let prevRecordRun = this.get('prevRecordRun');
        let recordRun = this.get('recordRun');

        if (recordRun != prevRecordRun) {
          this.set('prevRecordRun', recordRun);

          if (recordRun == true) {
            this.animate();
          }
        }

        let idX = this.idX;
        let idY = this.idY;
        let xyData = this.get('xyData');
        let oldXYData = this.get('oldXYData');
        let prevXYData = this.get('prevXYData');
        let oldPrevXYData = this.get('oldPrevXYData');
        let loopCounter = this.get('loopCounter');

        if (prevXYData && prevXYData != oldPrevXYData) {
          this.set('oldPrevXYData', prevXYData);
          this.preloadPrevValues();
        } else if (xyData && xyData != oldXYData) {
          this.set('oldXYData', xyData);
          let xyCPointsPer1_60Seconds = this.get('cPointsPer1_60Seconds') * 2;
          let loopData = this.get('loopData');

          if (xyData.vals) {
            xyData.vals.forEach(thatValue => {
              if (thatValue.sID == idX) {
                loopData[loopCounter] = thatValue.val;
              } else if (thatValue.sID == idY) {
                loopData[loopCounter + 1] = thatValue.val;
              }
            });

            if (loopCounter >= xyCPointsPer1_60Seconds - 2) {
              this.set('updateFloat32Array', loopData);
              this.xyDiagram.updateTextureLine(loopData);
              let newFloat32Array = new Float32Array(xyCPointsPer1_60Seconds);
              this.set('loopData', newFloat32Array);
              this.set('loopCounter', 0);
            } else {
              this.set('loopData', loopData);
              this.set('loopCounter', loopCounter + 2);
            }
          }
        }
      }
    },

    preloadPrevValues() {
      let loopCounter = 0;
      let lineNumbers = this.get('cPointsPer1_60Seconds');
      let maxNumbers = this.get('c1_60Seconds') * lineNumbers;
      let prevXYData = this.get('prevXYData');
      let loopData;
      let idX = this.idX;
      let idY = this.idY;
      let allX = prevXYData.vals.filter(function (el) {
        return el.sID == idX;
      });
      let allY = prevXYData.vals.filter(function (el) {
        return el.sID == idY;
      });

      if (allX.length > maxNumbers) {
        loopData = new Float32Array(maxNumbers * 2);
        let startSlice = allX.length - maxNumbers;
        let shortenAllX = allX.slice(startSlice, allX.length);
        let shortenAllY = allY.slice(startSlice, allY.length);
        let tmpCnt = 0;
        shortenAllX.forEach(thatValue => {
          loopData[tmpCnt] = thatValue.val;
          tmpCnt++;
          loopData[tmpCnt] = shortenAllY[loopCounter].val;
          tmpCnt++;
          loopCounter++;
        });
        this.xyDiagram.updateFullTexture(loopData);
      } else {
        let boxOne = Math.ceil(allX.length / lineNumbers);
        let boxTwo = boxOne * lineNumbers;
        loopData = new Float32Array(boxTwo * 2);
        let tmpCnt = 0;

        for (let i = 0; i < boxTwo; i++) {
          if (i < allX.length && i < allY.length) {
            loopData[tmpCnt] = allX[i].val;
            tmpCnt++;
            loopData[tmpCnt] = allY[i].val;
            tmpCnt++;
          }
        }

        this.xyDiagram.updatePartTexture(loopData, boxOne);
      }

      this.animate();
    },

    generateDiagram() {
      let metaData = this.get('metaData');

      if (metaData) {
        if (metaData.length > 0) {
          if (metaData[0].min || metaData[0].max) {
            if (this.adjustDataLength() == true) {
              this.set('isOff', false);
              let metaZeroDiffTenth = (metaData[0].max - metaData[0].min) * 0.1;
              let metaZeroMin = metaData[0].min - metaZeroDiffTenth;
              let metaZeroMax = metaData[0].max + metaZeroDiffTenth;
              let metaOneDiffTenth = (metaData[1].max - metaData[1].min) * 0.1;
              let metaOneMin = metaData[1].min - metaOneDiffTenth;
              let metaOneMax = metaData[1].max + metaOneDiffTenth;

              if (metaData[0].orientation) {
                this.set('idX', metaData[1].typeId);
                this.set('idY', metaData[0].typeId);
                this.set('minXValue', metaOneMin); // from parent

                this.set('maxXValue', metaOneMax); // from parent

                this.set('minYValue', metaZeroMin); // from parent

                this.set('maxYValue', metaZeroMax); // from parent

                this.set('nameX', metaData[1].name);
                this.set('nameY', metaData[0].name);
                this.set('unitX', metaData[1].unit);
                this.set('unitY', metaData[0].unit);
              } else {
                this.set('idX', metaData[0].typeId);
                this.set('idY', metaData[1].typeId);
                this.set('minXValue', metaZeroMin); // from parent

                this.set('maxXValue', metaZeroMax); // from parent

                this.set('minYValue', metaOneMin); // from parent

                this.set('maxYValue', metaOneMax); // from parent

                this.set('nameX', metaData[0].name);
                this.set('nameY', metaData[1].name);
                this.set('unitX', metaData[0].unit);
                this.set('unitY', metaData[1].unit);
              }

              let xText = document.createTextNode('X: ' + this.get('nameX') + ' in ' + this.get('unitX'));
              let yText = document.createTextNode('Y: ' + this.get('nameY') + ' in ' + this.get('unitY'));
              this.get('element').getElementsByTagName("DIV")[0].appendChild(xText);
              this.get('element').getElementsByTagName("DIV")[0].appendChild(document.createElement("BR"));
              this.get('element').getElementsByTagName("DIV")[0].appendChild(yText);
              let loopData = new Float32Array(20);
              this.set('loopData', loopData);
              let gl = this.get('gl');
              let canvas = this.get('canvas');
              let xyDiagram = new _xydiagram.default(gl, canvas.width, canvas.height, this.cPointsPer1_60Seconds, this.c1_60Seconds, this.minXValue, this.maxXValue, this.minYValue, this.maxYValue);
              xyDiagram.setPointSize(4.0);
              xyDiagram.setSelectedPointSize(8.0);
              xyDiagram.setPointColor(0.4, 0.5, 0.6, 0.4);
              xyDiagram.setBorderColor(0.7, 0.8, 0.9, 0.2);
              xyDiagram.setLineColor(this.lineColor.r, this.lineColor.g, this.lineColor.b, 1.0);
              xyDiagram.setBlendLength(this.glowTimeInSecond * 1000.0 * this.valuesPerMilliSecond);
              xyDiagram.create();
              this.set('xyDiagram', xyDiagram);
              let quad = new _quad2D.default(gl, canvas.width, canvas.height);
              quad.createWebGLResources(gl);
              quad.loadTexture(gl, "/assets/images/distance.png");
              this.set('quad', quad); //diagram.setBackgroundColor(0.0,1.0,0.0,1.0);
              //diagram.setLineColor(1.0,0.0,0.0,1.0);

              this.set('gl', gl);
              this.initDiv();
              let prevXYData = this.get('prevXYData');
              let oldPrevXYData = this.get('oldPrevXYData');

              if (prevXYData && prevXYData != oldPrevXYData) {
                this.set('oldPrevXYData', prevXYData);
                this.preloadPrevValues();
              } else {
                this.animate();
              }
            } else {
              if (this.tryRun) {
                Ember.run.later(() => {
                  this.generateDiagram();
                }, 100);
              }
            }
          } else {
            if (this.tryRun) {
              Ember.run.later(() => {
                this.generateDiagram();
              }, 100);
            }
          }
        }
      }
    },

    initDiv() {
      let canvasBox = this.get('element').getElementsByTagName("CANVAS")[0];
      canvasBox.addEventListener("wheel", e => {
        let sx = this.get('sx');
        let tx = this.get('tx');
        let delta = e.deltaY;

        if (e.shiftKey) {
          if (delta > 0 && sx < 1024) {
            sx *= 2;
          } else if (delta < 0 && sx > 1) {
            sx /= 2;
          }

          this.set('sx', sx);
        } else {
          if (delta > 0 && tx < 1.0) {
            tx += 0.1 / sx;
          } else if (delta < 0 && tx > -1.0) {
            tx -= 0.1 / sx;
          }

          this.set('tx', tx);
        }
      });
    },

    animate() {
      if (this.isOff) {
        return;
      }

      let gl = this.get('gl');
      gl.enable(gl.BLEND);
      gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE_MINUS_DST_ALPHA, gl.ONE);
      let xyDiagram = this.get('xyDiagram');

      if (!xyDiagram.isReady) {
        gl.clearColor(this.clearColor.r, this.clearColor.g, this.clearColor.b, 1.0);
        gl.clear(gl.COLOR_BUFFER_BIT);
      } else {
        gl.clearColor(this.clearColor.r, this.clearColor.g, this.clearColor.b, 1.0);
        gl.clear(gl.COLOR_BUFFER_BIT);
        let quad = this.get('quad');
        let sx = this.get('sx');
        let tx = this.get('tx');

        if (quad.isReady && quad.hasTex) {
          quad.setFragColor(0.0, 0.0, 0.5, 1.0);
          quad.drawTex(sx, sx * tx, 1.0, 0.0, false);
          xyDiagram.setSelectedPointSize(8.0);
          xyDiagram.draw(sx, sx * tx, 1.0, 0.0, false);
        } else {
          xyDiagram.setSelectedPointSize(8.0);
          xyDiagram.draw(sx, sx * tx, 1.0, 0.0, false);
        }

        quad.setFragColor(0.3, 0.4, 0.3, 0.8);
        quad.draw(0.25, 0.75, 0.25, 0.75, false);
        xyDiagram.setSelectedPointSize(4.0);
        xyDiagram.draw(0.25, 0.75, 0.25, 0.75, false);
        quad.setFragColor(1.0, 1.0, 1.0, 0.6);
        quad.draw(0.25 / sx, 0.75 - tx * 0.25, 0.25, 0.75, false);
      }

      this.set('gl', gl);

      if (this.get('recordRun')) {
        Ember.run.later(() => {
          this.animate();
        }, 14);
      } //window.requestAnimationFrame(this.animate());

    },

    willDestroyElement() {
      this.set('tryRun', false);
      this.set('isOff', true);

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});