define("machine-monitoring/comparison/controller", ["exports", "machine-monitoring/app", "chartjs", "jquery"], function (_exports, _app, _chartjs, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    setupComparisonShown: true,
    // create comparison model
    comparisonTitle: "",
    comparisonTitleFailed: false,
    comparisonDescription: "",
    comparisonDescriptionFailed: false,
    // select comparison data
    noSignalSelected: false,
    selectedDevice: null,
    selectedDeviceRecords: null,
    selectedRecord: null,
    selectedAllSignal: false,
    selectedSignal: null,
    // load comparison model
    chooseLoading: false,
    // create comparison model
    visibilityInfoPanel: false,
    modelLoaded: false,
    loadedRecordCounts: null,
    // comparison options
    visibilityOptionPanel: false,
    showRecords: 50,

    init() {
      this._super(...arguments);

      this.set("allDevices", []);
      this.set("selectedRecordSignals", []);
      this.set("selectedSignals", []);
      this.set("allCharts", []);
      this.set("showRelations", []);
    },

    thatDoOnLoad() {
      this.setSetupComparisonBox(true);
      (0, _jquery.default)("#setupComparison").offset({
        left: 0
      });
      (0, _jquery.default)("#setupComparison").height(window.innerHeight - 68);
    },

    resetData() {
      let selectedDevice = this.get("selectedDevice");

      if (selectedDevice) {
        selectedDevice.set("selected", false);
      }

      this.set("selectedDevice", null);
      let selectedRecord = this.get("selectedRecord");

      if (selectedRecord) {
        selectedRecord.set("selected", false);
      }

      this.set("selectedRecord", null);
      this.set("selectedSignal", null);
      this.set("allDevices", []);
      this.set("selectedDeviceRecords", []);
      this.set("selectedRecordSignals", []);
      this.set("selectedAllSignal", false);
      this.set("diagramColorsBorder", ["rgba(255,0,0,1)", "rgba(0,255,0,1)", "rgba(0,0,255,1)", "rgba(0,255,255,1)", "rgba(255,0,255,1)", "rgba(255,255,0,1)", "rgba(127,255,0,1)", "rgba(127,0,255,1)", "rgba(255,127,0,1)", "rgba(0,127,255,1)", "rgba(0,255,127,1)", "rgba(255,0,127,1)"]);
    },

    setSetupComparisonBox(toThat) {
      this.set("setupComparisonShown", toThat);

      if (toThat) {
        (0, _jquery.default)("#setupComparison").offset({
          left: 0
        });
      } else {
        (0, _jquery.default)("#setupComparison").offset({
          left: -(0, _jquery.default)("#setupComparison").width()
        });
      }
    },

    createCanvas() {
      let comparisonModel = this.get("comparisonModel");
      let recordCounts = comparisonModel.recs.length;
      this.set('loadedRecordCounts', recordCounts);
      let windowHeight = (0, _jquery.default)(window).height();
      let windowWidth = (0, _jquery.default)(window).width();
      let test = (0, _jquery.default)("#comparison-main-window");
      let finishedRenderCanvas = setInterval(() => {
        if (test) {
          clearInterval(finishedRenderCanvas);
          let canvasDivBox = (0, _jquery.default)("#comparison-main-div");
          canvasDivBox.empty();
          let tmpAdd = 0;

          if (recordCounts % 2) {
            tmpAdd = 20;
          }

          let comparisonUpperFrameHeight = tmpAdd;
          let comparisonLowerFrameHeight = 0;

          for (let i = 0; i < Math.floor(recordCounts / 2); i++) {
            comparisonUpperFrameHeight = comparisonUpperFrameHeight + 20;
            comparisonLowerFrameHeight = comparisonLowerFrameHeight + 20;
          }

          (0, _jquery.default)("#comparison-main-window").height(windowHeight - 60);
          (0, _jquery.default)("#comparison-upper-frame").height(comparisonUpperFrameHeight);
          (0, _jquery.default)("#comparison-lower-frame").height(comparisonLowerFrameHeight);
          let canvasHeight = windowHeight - (60 + comparisonUpperFrameHeight + comparisonLowerFrameHeight);
          (0, _jquery.default)("#comparison-main-div").height(canvasHeight);
          (0, _jquery.default)("#comparison-main-div").width(windowWidth);
          let diagramColorsBorder = this.get('diagramColorsBorder');
          let upperCanvasFrame = (0, _jquery.default)("#comparison-upper-frame");
          upperCanvasFrame.empty();
          let lowerCanvasFrame = (0, _jquery.default)("#comparison-lower-frame");
          lowerCanvasFrame.empty();
          let maxValueCounter = 0;

          for (let i = 0; i < recordCounts; i++) {
            let thatRecordLength = comparisonModel.recs[i].recordValues.length;

            if (thatRecordLength > maxValueCounter) {
              maxValueCounter = thatRecordLength;
            }
          }

          let allCharts = [];
          let showRelations = [];
          let firstRound = true;

          for (let i = 0; i < recordCounts; i++) {
            let newCanvas = document.createElement("canvas");
            newCanvas.setAttribute("id", "comparison-canvas-" + i);
            newCanvas.setAttribute("class", "comparison-canvas");
            newCanvas.setAttribute("height", canvasHeight);
            newCanvas.setAttribute("width", windowWidth);
            canvasDivBox.append(newCanvas);
            let diagramData = {
              labels: [],
              datasets: [{
                backgroundColor: "rgba(0,0,0,0)",
                borderColor: diagramColorsBorder[i],
                radius: 0,
                borderWidth: 1,
                tension: 0.3,
                data: []
              }]
            };
            let diagramOptions = {
              animation: {
                duration: 0
              },
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false
              },
              scales: {
                xAxes: [{
                  display: false
                }],
                yAxes: [{
                  display: false
                }]
              },
              point: {
                radius: 0
              }
            };
            let canvasContext = newCanvas.getContext("2d");
            let newChart = new _chartjs.default(canvasContext, {
              type: "line",
              data: diagramData,
              options: diagramOptions
            });
            let tempValue = 0;
            comparisonModel.recs[i].tempValue = tempValue;
            comparisonModel.recs[i].tempID = i;
            comparisonModel.recs[i].color = diagramColorsBorder[i];
            let showRecordCounter = this.get('showRecords');

            if (maxValueCounter < showRecordCounter) {
              showRecordCounter = maxValueCounter;
              this.set('showRecords', showRecordCounter);
            }

            let valuesActShown = showRecordCounter;

            if (firstRound) {
              showRelations.push(showRecordCounter);
              let startTime = comparisonModel.recs[i].recordValues[0].time;
              let endTime = comparisonModel.recs[i].recordValues[showRecordCounter].time;
              let timeDiff = endTime - startTime;
              this.set('firstTimeDiff', timeDiff);

              for (let j = 0; j < showRecordCounter; j++) {
                let thisEntry;

                if (comparisonModel.recs[i].recordValues[j]) {
                  thisEntry = comparisonModel.recs[i].recordValues[j];
                } else {
                  thisEntry = {
                    time: 0,
                    value: 0
                  };
                }

                newChart.data.labels.push(thisEntry.time);
                newChart.data.datasets[0].data.push(thisEntry.value);
              }
            } else {
              let endTime = comparisonModel.recs[i].recordValues[0].time + this.get('firstTimeDiff');
              valuesActShown = 0;

              for (let j = 0; j < comparisonModel.recs[i].recordValues.length; j++) {
                if (comparisonModel.recs[i].recordValues[j].time < endTime) {
                  valuesActShown++;
                  let thisEntry;

                  if (comparisonModel.recs[i].recordValues[j]) {
                    thisEntry = comparisonModel.recs[i].recordValues[j];
                  } else {
                    thisEntry = {
                      time: 0,
                      value: 0
                    };
                  }

                  newChart.data.labels.push(thisEntry.time);
                  newChart.data.datasets[0].data.push(thisEntry.value);
                } else {
                  showRelations.push(j);
                  break;
                }
              }
            }

            newChart.update();
            let maxValue = comparisonModel.recs[i].recordValues.length - valuesActShown;
            let newInput = document.createElement("input");
            newInput.setAttribute("id", "comparison-slider-" + (i + 1));
            newInput.setAttribute("class", "slider comparison-slider " + "comparison-slider-" + (i + 1));
            newInput.setAttribute("type", "range");
            newInput.setAttribute("min", "1");
            newInput.setAttribute("max", maxValue);
            newInput.setAttribute("value", tempValue);
            newInput.addEventListener("input", () => {
              this.valueSlider(i);
            });
            allCharts.pushObject(newChart);
            this.set('showRelations', showRelations);

            if (i % 2) {
              lowerCanvasFrame.append(newInput);
            } else {
              upperCanvasFrame.append(newInput);
            }

            firstRound = false;
          }

          this.set('allCharts', allCharts);
        }
      }, 100);
    },

    valueSlider(thatId) {
      let slidedValue = parseInt(document.getElementById("comparison-slider-" + (thatId + 1)).value);
      let comparisonModel = this.get("comparisonModel");
      let showRecords = this.get('showRelations')[thatId];
      let thatChart = this.get('allCharts')[thatId];
      thatChart.data.labels = [];
      thatChart.data.datasets[0].data = [];

      for (let iz = 0; iz < showRecords; iz++) {
        let thisData = comparisonModel.recs[thatId].recordValues[slidedValue + iz];

        if (thisData) {
          thatChart.data.labels.push(thisData.time);
          thatChart.data.datasets[0].data.push(thisData.value);
        }
      }

      thatChart.update();
      Ember.run.later(() => {
        thatChart.update();
      }, 100);
    },

    actions: {
      firstRunAfterRender() {
        this.thatDoOnLoad();
      },

      lastRunAfterDeaktivate() {
        this.set("comparisonModel", null);
        this.set("modelLoaded", false);
      },

      resizeCanvas() {
        let windowHeight = (0, _jquery.default)(window).height();
        let windowWidth = (0, _jquery.default)(window).width();
        let test = (0, _jquery.default)("#comparison-main-window");
        let finishedRenderCanvas = setInterval(() => {
          if (test) {
            clearInterval(finishedRenderCanvas);
            (0, _jquery.default)("#comparison-main-window").height(windowHeight - 55);
            let comparisonUpperFrameHeight = (0, _jquery.default)("#comparison-upper-frame").height();
            let comparisonLowerFrameHeight = (0, _jquery.default)("#comparison-lower-frame").height();
            let canvasHeight = windowHeight - (55 + comparisonUpperFrameHeight + comparisonLowerFrameHeight);
            (0, _jquery.default)("#comparison-main-div").height(canvasHeight);
            (0, _jquery.default)("#comparison-main-div").width(windowWidth);
          }
        }, 100);
      },

      toggleSetup() {
        let setupComparisonShown = this.get("setupComparisonShown");
        this.setSetupComparisonBox(!setupComparisonShown);
      },

      loadCollection() {
        console.log("loadCollection ---- following later");
        this.resetData();
        this.set("chooseLoading", true);
      },

      chooseThisSaved(thisSaved) {
        console.log("chooseThisSaved: ", thisSaved);
      },

      resetChooseSignals() {
        this.resetData();
        this.set("comparisonTitle", "");
        this.set("comparisonDescription", "");
        this.set("selectedSignals", []);
        this.set("chooseLoading", false);
      },

      chooseThisSignals() {
        let that = this;

        if (this.selectedSignals.length < 1) {
          this.set("noSignalSelected", true);
        } else {
          this.set("noSignalSelected", false);
        }

        if (this.get("comparisonTitle") == "") {
          this.set("comparisonTitleFailed", true);
        } else {
          this.set("comparisonTitleFailed", false);
        }

        if (this.get("comparisonDescription") == "") {
          this.set("comparisonDescriptionFailed", true);
        } else {
          this.set("comparisonDescriptionFailed", false);
        }

        if (this.selectedSignals.length < 1 || this.get("comparisonDescription") == "" || this.get("comparisonTitle") == "") {
          return;
        }

        let comparisonModel = {
          name: that.get("comparisonTitle"),
          description: that.get("comparisonDescription"),
          created: new Date(),
          recs: []
        };
        this.setSetupComparisonBox(false);
        let arrayToSend = [];
        let arrayReceives = [];
        let counter = 0;
        this.selectedSignals.forEach(thisSignal => {
          let testString = thisSignal.deviceId + ":" + thisSignal.recordId;

          if (!arrayToSend.includes(testString)) {
            arrayToSend.addObject(testString);
          }

          let thatRec = {
            signalId: parseInt(thisSignal.signalId),
            signalName: thisSignal.signalName,
            deviceId: parseInt(thisSignal.deviceId),
            deviceSerial: thisSignal.deviceSerial,
            recordId: parseInt(thisSignal.recordId),
            recordName: thisSignal.recordName,
            recordValues: [],
            color: "rgba(127,127,127,0.5)",
            tempID: 0
          };
          comparisonModel.recs.addObject(thatRec);
        });
        arrayToSend.forEach(thisSignal => {
          let indexOfDblPoint = thisSignal.indexOf(":");
          let recordIdValue = thisSignal.slice(indexOfDblPoint + 1, thisSignal.length);
          let deviceIdValue = thisSignal.slice(0, indexOfDblPoint);

          _jquery.default.ajax({
            url: _app.default.API_URL + "/recordinfos/" + deviceIdValue + "/" + recordIdValue
          }).done(data => {
            arrayReceives[counter] = {
              deviceId: parseInt(deviceIdValue),
              recordId: parseInt(recordIdValue),
              values: data
            };
            counter++;
          });
        });
        let finishedAjaxRequest = setInterval(() => {
          if (arrayToSend.length == counter) {
            comparisonModel.recs.forEach(thisRecs => {
              arrayReceives.forEach(thatReceived => {
                if (thisRecs.deviceId == thatReceived.deviceId && thisRecs.recordId == thatReceived.recordId) {
                  thatReceived.values.values.forEach(thatValue => {
                    if (thatValue.signal == thisRecs.signalId) {
                      thisRecs.recordValues.addObject(thatValue);
                    }
                  });
                }
              });
            });
            clearInterval(finishedAjaxRequest);
            this.set("comparisonModel", comparisonModel);
            this.set("modelLoaded", true);
            this.createCanvas();
          }
        }, 100);
      },

      addSignal() {
        this.get("store").findAll("device").then(allDevices => {
          this.set("allDevices", allDevices);
        });
        this.set("chooseLoading", false);
      },

      chooseThisDevice(thisDevice) {
        _app.default.chosenDeviceId = thisDevice.id;
        let that = this;
        this.get("allDevices").forEach(thatDevice => {
          thatDevice.set("selected", false);
        });
        thisDevice.set("selected", true);
        this.set("selectedDevice", thisDevice);
        let tmpRecordBox = [];
        this.get("store").query("record", {}).then(function (timeRecords) {
          timeRecords.forEach(thatRecord => {
            let testType = thatRecord.get("type");

            if (testType == 'time') {
              tmpRecordBox.push(thatRecord);
            }
          });
          that.set("selectedDeviceRecords", tmpRecordBox);
        });
        this.set("selectedRecordSignals", []);
        this.set("selectedRecord", null);
        this.set("selectedSignal", null);
        this.set("selectedAllSignal", false);
      },

      chooseThisRecord(thisRecord) {
        this.set("selectedRecord", thisRecord);
        this.get("selectedDeviceRecords").forEach(thatRecord => {
          thatRecord.set("selected", false);
        });
        thisRecord.set("selected", true);
        let allSignalTypes = this.get("selectedDevice").get("signalTypes");
        let tmpSelectedRecordSignals = [];
        thisRecord.get("signalTypes").forEach(thisRecordSignalType => {
          allSignalTypes.forEach(thisSignalType => {
            if (thisRecordSignalType === thisSignalType.get("signalTypeId")) {
              let thatType = {
                name: thisSignalType.get("name"),
                unit: thisSignalType.get("unit"),
                signalType: thisRecordSignalType,
                selected: false,
                values: []
              };
              tmpSelectedRecordSignals.addObject(thatType);
            }
          });
        });
        this.set("selectedRecordSignals", tmpSelectedRecordSignals);
        this.set("selectedSignal", null);
        this.set("selectedAllSignal", false);
      },

      chooseThisSignal(thisSignal) {
        this.selectedRecordSignals.forEach(thatSignal => {
          Ember.set(thatSignal, "selected", false);
        });
        let recordId = this.get("selectedRecord").get("id");
        let recordName = this.get("selectedRecord").get("name");

        if (!recordName) {
          recordName = "ID: " + recordId;
        }

        let signal = {
          deviceId: this.get("selectedDevice").get("id"),
          deviceSerial: this.get("selectedDevice").get("deviceSerial"),
          recordId: this.get("selectedRecord").get("id"),
          recordName: recordName
        };

        if (thisSignal === "all") {
          this.set("selectedAllSignal", true);
          signal.signalId = "all";
          signal.signalName = "all";
        } else {
          this.set("selectedAllSignal", false);
          Ember.set(thisSignal, "selected", true);
          signal.signalId = thisSignal.signalType;
          signal.signalName = thisSignal.name;
        }

        this.set("selectedSignal", signal);
      },

      confirmThisSignal() {
        let signal = this.get("selectedSignal");
        this.selectedSignals.addObject(signal);
        this.resetData();
      },

      removeThisDevice(thisSignal) {
        this.selectedSignals.removeObject(thisSignal);
      },

      toggleInfoPanel(toGo) {
        let box = this.get('visibilityInfoPanel');

        if (toGo) {
          box = true;
        }

        this.set('visibilityInfoPanel', !box);
      },

      toggleOptionPanel(toGo) {
        let box = this.get('visibilityOptionPanel');

        if (toGo) {
          box = true;
        }

        this.set('visibilityOptionPanel', !box);
      },

      dismissOptions() {
        console.log('dismissOptions');
      },

      acceptOptions() {
        console.log('acceptOptions');
      }

    }
  });

  _exports.default = _default;
});