define("machine-monitoring/templates/extend-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ikSQD33h",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\",true],[10,\"class\",\"nav-item\"],[8],[7,\"p\",true],[10,\"class\",\"navbar-text mb-0\"],[8],[1,[28,\"fa-icon\",[\"chevron-right\"],null],false],[9],[9],[0,\"\\n\"],[7,\"li\",true],[11,\"class\",[29,[\"nav-item \",[28,\"if\",[[24,[\"routeActiveAlerts\"]],\"active\"],null]]]],[8],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",\"nav-link\"],[3,\"action\",[[23,0,[]],\"alertsModeAction\"]],[8],[1,[28,\"t\",[\"alerting\"],null],false],[9],[9],[0,\"\\n\"],[7,\"li\",true],[11,\"class\",[29,[\"nav-item \",[28,\"if\",[[24,[\"routeActiveOsci\"]],\"active\"],null]]]],[8],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",\"nav-link\"],[3,\"action\",[[23,0,[]],\"rtModeAction\"]],[8],[1,[28,\"t\",[\"oscilloscope\"],null],false],[9],[9],[0,\"\\n\"],[7,\"li\",true],[11,\"class\",[29,[\"nav-item \",[28,\"if\",[[24,[\"routeActiveOverview\"]],\"active\"],null]]]],[8],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",\"nav-link\"],[3,\"action\",[[23,0,[]],\"overviewModeAction\"]],[8],[1,[28,\"t\",[\"dataOverview\"],null],false],[9],[9],[0,\"\\n\"],[4,\"if\",[false],null,{\"statements\":[],\"parameters\":[]},null],[1,[28,\"outlet\",[\"extend-navigation\"],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/templates/extend-navigation.hbs"
    }
  });

  _exports.default = _default;
});