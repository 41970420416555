define("machine-monitoring/devices/route", ["exports", "jquery", "machine-monitoring/app"], function (_exports, _jquery, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    thatDonutdataService: Ember.inject.service("that-donutdata"),

    model() {
      return this.get("store").findAll("device");
    },

    setupController(controller, model) {
      this._super(controller, model);

      let allDevices = [];

      _jquery.default.get(_app.default.API_URL + "/alerts").then(responseData => {
        model.forEach(thisDevice => {
          let thisDeviceId = parseInt(thisDevice.get("id"));
          allDevices[thisDeviceId] = {
            model: thisDevice,
            alerts: 0
          };
          responseData.deviceAlerts.forEach(thisDeviceAlerts => {
            let thisDevicealertId = parseInt(thisDeviceAlerts.deviceId);

            if (thisDeviceId === thisDevicealertId) {
              allDevices[thisDeviceId].alerts = thisDeviceAlerts.alerts;
              let thatDonutInfos = {};
              thatDonutInfos[thisDeviceId] = thisDeviceAlerts.alerts;
              this.get("thatDonutdataService").add(thatDonutInfos);
            }
          });
        });
        controller.set("allDevices", allDevices);
      });
    },

    renderTemplate(controller, model) {
      this.render("devices", {
        into: "application",
        outlet: "content",
        controller: controller,
        model: model
      });
    }

  });

  _exports.default = _default;
});