define("machine-monitoring/components/pagination-addin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A4BiBjaP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"morePages\"]]],null,{\"statements\":[[0,\"  \"],[7,\"nav\",true],[11,\"aria-label\",[28,\"t\",[\"pagination\"],null]],[8],[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"pagination justify-content-center\"],[8],[0,\"\\n      \"],[7,\"li\",true],[11,\"class\",[29,[\"page-item \",[28,\"if\",[[24,[\"possiblePrevPage\"]],\"\",\"disabled\"],null]]]],[8],[0,\"\\n        \"],[7,\"a\",false],[12,\"class\",\"page-link\"],[12,\"href\",\"#\"],[12,\"aria-label\",[28,\"t\",[\"previous\"],null]],[3,\"action\",[[23,0,[]],\"changePage\",0]],[8],[0,\"\\n          \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[1,[28,\"fa-icon\",[\"angle-double-left\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"li\",true],[10,\"class\",\"page-item\"],[8],[7,\"a\",true],[10,\"class\",\"page-link\"],[10,\"href\",\"#\"],[8],[1,[22,\"currentPage\"],false],[0,\"/\"],[1,[22,\"pages\"],false],[9],[9],[0,\"\\n      \"],[7,\"li\",true],[11,\"class\",[29,[\"page-item \",[28,\"if\",[[24,[\"possibleNextPage\"]],\"\",\"disabled\"],null]]]],[8],[0,\"\\n        \"],[7,\"a\",false],[12,\"class\",\"page-link\"],[12,\"href\",\"#\"],[12,\"aria-label\",[28,\"t\",[\"next\"],null]],[3,\"action\",[[23,0,[]],\"changePage\",1]],[8],[0,\"\\n          \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[1,[28,\"fa-icon\",[\"angle-double-right\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "machine-monitoring/components/pagination-addin/template.hbs"
    }
  });

  _exports.default = _default;
});