define("machine-monitoring/configuration/device/edit/controller", ["exports", "machine-monitoring/app", "jquery"], function (_exports, _app, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    chosenSignaltype: null,
    allDeviceIcons: null,

    init() {
      this._super(...arguments);

      this.newSignalType = {
        name: null,
        unit: null,
        description: null
      };
    },

    buildIcons() {
      this.set("allDeviceIcons", [{
        path: "/assets/images/Bohren_Transparent.png",
        selected: false
      }, {
        path: "/assets/images/Drehen_Transparent.png",
        selected: false
      }, {
        path: "/assets/images/Fräsen_Transparent.png",
        selected: false
      }, {
        path: "/assets/images/Maschine_neu (Test).png",
        selected: false
      }, {
        path: "/assets/images/Maschine_Transparent.png",
        selected: false
      }, {
        path: "/assets/images/Stanzen_Transparent.png",
        selected: false
      }, {
        path: "/assets/images/server.png",
        selected: false
      }, {
        path: "/assets/images/Fraesmaschine1.jpg",
        selected: false
      }]);
      let thisModel = this.get("model");
      let modelPicturePath = thisModel.get("picturePath");

      if (modelPicturePath) {
        this.get('allDeviceIcons').filterBy('path', modelPicturePath)[0].selected = true;
      }
    },

    clearNewSignalModal() {
      this.set("newSignalType.name", null);
      this.set("newSignalType.unit", null);
      this.set("newSignalType.description", null);
    },

    actions: {
      firstRunAfterRender() {
        this.buildIcons();
      },

      setThisCustomer(thisCustomer) {
        let thisModel = this.get("model");
        this.set('actCustomer', thisCustomer);
        thisModel.set('customer', thisCustomer.id);
      },

      setThisLocation(thisLocation) {
        let thisModel = this.get("model");
        this.set('actLocation', thisLocation);
        thisModel.set('location', thisLocation.id);
      },

      editDeviceIcon() {
        (0, _jquery.default)("#modalEditDeviceIcon").modal("show");
      },

      abortSelectDeviceIcon() {
        (0, _jquery.default)("#modalEditDeviceIcon").modal("hide");
      },

      finishtSelectDeviceIcon() {
        (0, _jquery.default)("#modalEditDeviceIcon").modal("hide");
      },

      selectThisDeviceIcon(thisIcon) {
        this.get('allDeviceIcons').forEach(thatIcon => {
          Ember.set(thatIcon, "selected", false);
        });
        Ember.set(thisIcon, "selected", true);
        this.set('model.picturePath', thisIcon.path);
      },

      saveChange() {
        let thisModel = this.get("model");
        let modelId = thisModel.get("id");
        thisModel.set("oid", parseInt(modelId));
        let stringifyModel = JSON.stringify(thisModel);

        _jquery.default.ajax({
          method: "PUT",
          url: _app.default.API_URL + "/devices/" + modelId,
          contentType: "text/plain",
          data: stringifyModel
        });

        this.transitionToRoute("configuration.device", thisModel);
      },

      dismissChange() {
        let thisModel = this.get("model");
        thisModel.rollbackAttributes();
        this.transitionToRoute("configuration.device", thisModel);
      },

      showThisSignal(thisSignal) {
        let thisModel = this.get("model");
        this.set("chosenSignaltype", thisSignal);
        this.transitionToRoute("configuration.device.edit.signal", thisModel);
      },

      newSignalType() {
        (0, _jquery.default)("#newSignalModal").modal("show");
      },

      dismissNewSignal() {
        this.clearNewSignalModal();
      },

      saveNewSignal() {
        let model = this.get("model");
        let newSignalTypeId = 0;
        let allSignalTypes = model.get("signalTypes");
        allSignalTypes.forEach(thisOldSignalType => {
          let thisOldId = thisOldSignalType.get("signalTypeId");

          if (thisOldId > newSignalTypeId) {
            newSignalTypeId = thisOldId;
          }
        });
        newSignalTypeId = newSignalTypeId + 1;
        let newSignaltype = {
          signalTypeId: newSignalTypeId,
          name: this.get("newSignalType.name"),
          unit: this.get("newSignalType.unit"),
          description: this.get("newSignalType.description")
        };
        model.get("signalTypes").pushObject(newSignaltype);
        this.clearNewSignalModal();
      }

    }
  });

  _exports.default = _default;
});