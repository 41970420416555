define("machine-monitoring/models/alert-threshold", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    active: _emberData.default.attr(),
    critical: _emberData.default.attr('number'),
    emergency: _emberData.default.attr('number'),
    warn: _emberData.default.attr('number')
  });

  _exports.default = _default;
});