define("machine-monitoring/models/alert-properties", ["exports", "ember-data-model-fragments"], function (_exports, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    ceiling: _emberDataModelFragments.default.fragment('alertThreshold'),
    floor: _emberDataModelFragments.default.fragment('alertThreshold')
  });

  _exports.default = _default;
});