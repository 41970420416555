define("machine-monitoring/devices/details/alerts/learning/controller", ["exports", "machine-monitoring/app", "jquery"], function (_exports, _app, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    choosenSensor: null,
    choosenSensorId: null,
    allConfigFileNames: null,
    actions: {
      //doNext: 0-nothing, 1-init, 2-startDetect, 3-startLearning, 4-config, 5-load, 6-save, 7-dream, 8-show
      useThisSensor(sensor, doNext) {
        let sensorId = sensor.get("id");
        this.set("choosenSensorId", sensorId);
        sensor.set("doNext", doNext);

        switch (doNext) {
          case 4:
            {
              //open config modal
              break;
            }

          case 1:
            {
              let is_initialized = sensor.get("is_initialized");

              if (!is_initialized) {
                this.transitionToRoute("devices.details.alerts.learning.sensor", sensor);
              }

              break;
            }

          case 2:
            {
              let is_detecting = sensor.get("is_detecting");
              let detectionUrl = _app.default.API_ML_URL + "/sensors/" + sensorId + "/detection/start";

              if (is_detecting) {
                detectionUrl = _app.default.API_ML_URL + "/sensors/" + sensorId + "/detection/stop";

                _jquery.default.post(detectionUrl, JSON.stringify({}));
              } else {
                _jquery.default.post(detectionUrl, JSON.stringify({}));
              }

              sensor.set("is_detecting", !is_detecting);
              break;
            }

          case 3:
            {
              //this.transitionToRoute('devices.details.alerts.learning.sensor',sensor);
              //POST /api/ml/sensors/:sensorId/learning/start || POST /api/ml/sensors/:sensorId/learning/stop
              break;
            }

          case 5:
            {
              //this.set('choosenSensor',sensor);
              //$.get(App.API_ML_URL + '/sensors/'+sensorId+'/persistence').then(responseData{
              //    this.set('allConfigFileNames',responseData.names);
              //    $('#modalAccLoadConfig').modal('show');
              //});
              break;
            }

          case 6:
            {
              //this.set('choosenSensor',sensor);
              //$('#modalAccSaveConfig').modal('show');
              break;
            }

          case 7:
            {
              // no trigger, no reaction
              break;
            }

          case 8:
            {
              let that = this;
              this.store.findRecord('device', _app.default.chosenDeviceId).then(function (thatDevice) {
                that.transitionToRoute("devices.details.alerts.learning.sensor", thatDevice, sensor);
              });
              break;
            }
        }
      }

    }
  });

  _exports.default = _default;
});